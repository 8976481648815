import { useTranslation } from 'react-i18next'

const SponsorshipBusinessModel = () => {
  const { t } = useTranslation('SponsorshipBusinessModel')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 72,
    title: t('title_bm72'),
    subTitle: t('subTitle_bm72'),
    main: t('main_bm72'),
    when: t('when_bm72'),
    who: t('who_bm72'),
    what: t('what_bm72'),
    how: t('how_bm72'),
    why: t('why_bm72'),
    examples: rtl
      ? [
          'نايكي - 1964 - الولايات المتحدة الأمريكية',
          'كوكا كولا - 1886 - الولايات المتحدة الأمريكية',
          'ماكدونالدز - 1940 - أمريكا',
          'آبل - 1976 - أمريكا',
          'مايكروسوفت - 1975 - الولايات المتحدة الأمريكية',
        ]
      : [
          'Nike - 1964 - USA',
          'Coca-Cola - 1886 - USA',
          "McDonald's - 1940 - USA",
          'Apple - 1976 - USA',
          'Microsoft - 1975 - USA',
        ],
    tags: rtl
      ? ['المنفعة المتبادلة', 'الدعم المالي', 'إعتراف بعلامة تجارية', 'شراكة']
      : [
          'Mutual Benefit',
          'Financial Support',
          'Brand Recognition',
          'Partnership',
        ],
    considerations: t('considerations_bm72'),
    combinations: ['$LBM2'],
    hashTagType: 'businessModelTemp',
  }
}

export default SponsorshipBusinessModel
