import { Avatar, Card } from '@mui/material'
import React, { useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import DepartmentCardUnit from './MoveToAnotherDepFormComponents/DepartmentCardUnit'
import MoveToAnotherDepFormButtons from './MoveToAnotherDepFormComponents/MoveToAnotherDepFormButtons'
import TitleHeader from '../../../../../../SmallComponents/Header'
import DepartmentsEnumArray from '../DepartmentsEnumArray'
import ClosedView from '../../../../../../SmallComponents/ClosedView'

const styles = () => ({
  elementsWrapper: {
    height: 'auto',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexFlow: 'row wrap',
    marginBottom: 10,
    position: 'relative',
  },
  viewCard: {
    display: 'flex',
    height: 'auto',
    flexDirection: 'column',
    width: 'auto',
    padding: '10px',
    margin: '10px',
    borderRadius: '10px !important',
    backgroundColor: 'rgba(217,224,252, 0.2) !important',
  },
  memWrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px',
    margin: '10px',
    borderRadius: '10px !important',
    backgroundColor: 'rgba(217,224,252, 0.2) !important',
    alignItems: 'center',
  },
})

const MoveToAnotherDepForm = ({ classes, ...props }) => {
  const { member, DCGProps } = props
  const [departmentId, setDepartmentId] = useState(Number(member?.departmentId))
  const isDarkModeActive = props?.isDarkModeActive || DCGProps?.isDarkModeActive
  const rtl = document.body.dir === 'rtl'
  return member?.subDepId && !member?.subDepManager ? (
    <ClosedView
      isDarkModeActive={isDarkModeActive}
      closeMessage={
        rtl
          ? 'لا يمكنك نقل هذا العضو ما لم تتم إزالته من قسمه الفرعي'
          : 'You cannot move this member to another department until they are removed from their sub-department.'
      }
    />
  ) : member?.subDepManager ? (
    <ClosedView
      isDarkModeActive={isDarkModeActive}
      closeMessage={
        rtl
          ? 'العضو الذي تحاول إزالته هو حاليًا مدير في قسم فرعي. للمتابعة في عملية الإزالة، سيتعين عليك تعيين شخص آخر كمدير جديد لهذا القسم الفرعي. يرجى التأكد من إمكانية نقل المدير الجديد إلى قسم آخر إذا لزم الأمر.'
          : 'The member you are trying to remove is currently a manager of a sub-department. To proceed with the removal, you will need to assign someone else as the new manager for this sub-department. Please make sure that the new manager is available to move to another department if needed.'
      }
    />
  ) : member?.departmentManager ? (
    <ClosedView
      isDarkModeActive={isDarkModeActive}
      closeMessage={
        rtl
          ? 'العضو الذي تحاول نقله هو حاليًا مدير هذا القسم. لنقله إلى قسم آخر، ستحتاج إلى إضافة مدير آخر إلى هذا القسم.'
          : 'The member you are trying to move is currently the manager of this department. In order to move them to another department, you will need to add another manager to this department.'
      }
    />
  ) : (
    <article style={{ color: isDarkModeActive ? 'white' : null }}>
      <TitleHeader headerText={rtl ? 'إختر القسم' : 'Choose the Department'} />
      <section className={classes.memWrapper}>
        <Avatar src={member.image} />
        <span
          style={{
            marginLeft: !rtl && 10,
            marginRight: rtl && 10,
            fontWeight: 500,
          }}
        >
          {member.name}
        </span>
      </section>
      <Card className={classes.viewCard}>
        <section className={classes.elementsWrapper}>
          {DepartmentsEnumArray.map((element, elementIndex) => (
            <DepartmentCardUnit
              element={element}
              key={elementIndex}
              elementIndex={elementIndex}
              departmentId={departmentId}
              setDepartmentId={setDepartmentId}
            />
          ))}
        </section>
      </Card>
      <MoveToAnotherDepFormButtons
        departmentId={departmentId}
        DCGProps={DCGProps}
        {...props}
      />
    </article>
  )
}

const WrappedMoveToAnotherDepForm = withStyles(styles)(MoveToAnotherDepForm)

export default WrappedMoveToAnotherDepForm
