import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ButtonsSystem from '../../../../../../../buttonsSystem/ButtonsSystem'
import FormDataLangModulator from '../../../../../../../formsSystem/FormDataLangModulator'
import FormsSystem from '../../../../../../../formsSystem/FormsSystem'

const NewPollOptionForm = ({
  DCGProps,
  setOptions,
  windowCloser,
  chosenHashTagElements,
  setChosenHashTagElements,
}) => {
  const { isDarkModeActive } = DCGProps
  const [formData, setFormData] = useState({})
  const rtl = document.body.dir === 'rtl'
  const { t } = useTranslation()
  const PollOptionConfigs = [
    {
      title: 'Title',
      formComponentType: 'text',
      value: '',
      placeholder: rtl ? 'أعدّ نص الإختيار' : 'Set Option Text',
      multiLine: true,
      minRows: 3,
    },
  ]
  const newformData = FormDataLangModulator(formData, t)
  const buttonsArray = [
    {
      title: rtl ? 'أضف' : 'Add',
      funs: () => {
        setOptions((ops) => [...ops, newformData])
        windowCloser()
      },
      disabled: !newformData?.Title,
    },
    {
      title: rtl ? 'إلغاء' : 'Cancel',
      funs: windowCloser,
    },
  ]

  return (
    <div style={{ color: isDarkModeActive ? 'white' : 'black' }}>
      <FormsSystem
        formComponentsArray={PollOptionConfigs}
        formComponentsValues={formData}
        setFormComponentsValues={setFormData}
        setChosenHashTagElements={setChosenHashTagElements}
        chosenHashTagElements={chosenHashTagElements}
        {...DCGProps}
      />
      <ButtonsSystem buttonsArray={buttonsArray} {...DCGProps} />
    </div>
  )
}

export default NewPollOptionForm
