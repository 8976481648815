import React from 'react'
import Media from 'react-media'
import UpImagesViewerMob from './UpImageViewerComponents/UpImagesViewerMob'
import UpImagesViewerPC from './UpImageViewerComponents/UpImagesViewerPC'

const UpImageViewer = (props) => {
  const mediaComponents = [
    <UpImagesViewerPC {...props} />,
    <UpImagesViewerMob {...props} />,
  ]
  return (
    <Media query='(max-width: 600px)'>
      {(matches) => {
        return matches ? mediaComponents[1] : mediaComponents[0]
      }}
    </Media>
  )
}

export default UpImageViewer
