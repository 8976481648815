import React, { useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import { Tooltip } from '@mui/material'
import NewFormDialogModel from '../../../../../formsSystem/NewFormDialogModel'

const styles = () => ({
  memberOption: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    fontSize: 12,
    cursor: 'pointer',
    backgroundColor: 'rgb(210, 221, 240)',
    color: 'white',
    padding: 10,
    borderRadius: '50%',
    transition: '0.3s',
    '&:hover': {
      padding: 14,
    },
  },
})

const MemberOptionUnitItem = ({
  classes,
  option,
  member,
  isDarkModeActive,
  DCGProps,
  modelProps,
}) => {
  const [isComponentOpen, setIsComponentOpen] = useState(false)
  const componentOpener = () => {
    setIsComponentOpen(true)
  }
  const componentClose = () => {
    setIsComponentOpen(false)
  }

  return (
    <span>
      <Tooltip title={option.tippyContent} placement='top'>
        <span onClick={componentOpener} className={classes.memberOption}>
          {option.icon}
        </span>
      </Tooltip>
      <NewFormDialogModel
        NewFormComponent={
          option.optionComponent ? (
            <option.optionComponent
              componentClose={componentClose}
              member={member}
              DCGProps={DCGProps}
              modelProps={modelProps}
            />
          ) : (
            ''
          )
        }
        newFormDialogClose={componentClose}
        isNewFormDialogOpen={isComponentOpen}
        isDarkModeActive={isDarkModeActive}
        clickAwayState={true}
        dialogTitle={option.dialogTitle}
      />
    </span>
  )
}

const WrappedMemberOptionUnitItem = withStyles(styles)(MemberOptionUnitItem)

export default WrappedMemberOptionUnitItem
