import { Card } from '@mui/material'
import React from 'react'

const TransparentBackCard = ({
  content,
  isDarkModeActive,
  margin,
  colored,
  imaged
}) => {
  const PF = process.env.REACT_APP_PUBLIC_FOLDER
  const coloredStyle = colored
    ? {
        background: 'rgb(15,14,36)',
        background:
          'linear-gradient(90deg, rgba(15,14,36,1) 12%, rgba(4,18,20,1) 63%, rgba(6,6,45,1) 97%)',
      }
    : imaged
    ? {
        backgroundImage: `url(${PF}various/dialogBack.jpg)`,
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        backdropFilter: 'brightness(50%)',
      }
    : {}
  return (
    <Card
      sx={{
        ...coloredStyle,
        backgroundColor: isDarkModeActive
          ? 'rgba(255,255,255,0.2)'
          : 'rgba(64,136,230,0.2)',
        padding: '10px',
        borderRadius: '15px',
        color: isDarkModeActive && 'white',
        margin,
      }}
    >
      {content}
    </Card>
  )
}

export default TransparentBackCard
