import { Routes, Route } from 'react-router-dom'
import AdsManagementHome from './components/AdsManagementHome'
import PostAdsManagement from './components/PostAdsManagement'
import AdsExplorer from './components/AdsExplorer'
import { useEffect, useState } from 'react'
import axios from 'axios'
import CryptoJS from 'crypto-js'
import cookies from 'js-cookie'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import moment from 'moment'
import HttpApi from 'i18next-http-backend'
import PayPalCancel from './components/PayPalReturnCancel/PayPalCancel'
import PayPalReturn from './components/PayPalReturnCancel/PayPalReturn'

async function arabic() {
  axios.defaults.baseURL = 'https://hutupia-backend.onrender.com/api/v1'

  if (document.body.dir === 'rtl') {
    await import('moment/locale/ar')
  } else if (document.body.dir === 'ltr') {
    await moment.updateLocale('en')
  }
}

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ['en', 'ar'],
    fallbackLng: 'en',
    detection: {
      order: [
        'querystring',
        'cookie',
        'localStorage',
        'sessionStorage',
        'path',
        'navigator',
        'subdomain',
        'htmlTag',
      ],
      caches: ['cookie'],
    },
    backend: {
      loadPath: '/assets/locales/{{lng}}/{{ns}}.json',
    },
    ns: ['translation'],
    defaultNS: 'translation',
  })

function App() {
  const USER_DECRYPT_SECRET_KEY = String(process.env.USER_DECRYPT_SECRET_KEY)
  const [user, setUser] = useState(null)

  const encryptedUser = cookies.get('user')

  useEffect(() => {
    try {
      if (encryptedUser) {
        const decryptedData = CryptoJS.AES.decrypt(
          encryptedUser,
          USER_DECRYPT_SECRET_KEY
        ).toString(CryptoJS.enc.Utf8)
        if (decryptedData) {
          setUser(JSON.parse(decryptedData))
        }
      }
    } catch (error) {
      console.error(error)
    }
  }, [cookies.user])

  useEffect(() => {
    arabic()
  }, [document.body.dir])

  const props = {
    user,
  }
  return (
    <Routes>
      <Route exact path='/' element={<AdsManagementHome {...props} />} />
      <Route
        exact
        path='/manage-ads/:adCampaignId'
        element={<PostAdsManagement {...props} />}
      />
      <Route exact path='/manage-ads' element={<AdsExplorer {...props} />} />
      <Route
        exact
        path='/paypal/order/return'
        element={<PayPalReturn {...props} />}
      />
      <Route
        exact
        path='/paypal/order/cancel'
        element={<PayPalCancel {...props} />}
      />
    </Routes>
  )
}

export default App
