import { useTranslation } from 'react-i18next'

const OpenSourceBusinessModel = () => {
  const { t } = useTranslation('OpenSourceBusinessModel')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 38,
    title: t('title_bm38'),
    subTitle: t('subTitle_bm38'),
    main: t('main_bm38'),
    when: t('when_bm38'),
    who: t('who_bm38'),
    what: t('what_bm38'),
    how: t('how_bm38'),
    why: t('why_bm38'),
    examples: rtl
      ? [
          'IBM - تأسست عام 1911 - الولايات المتحدة',
          'Microsoft - تأسست عام 1975 - الولايات المتحدة',
          'Netscape - تأسست 1994 - الولايات المتحدة',
          'ريد هات - تأسست عام 1993 - الولايات المتحدة',
          'لوكال موتورز - تأسست عام 2008 - الولايات المتحدة',
          'ويكيبيديا - تأسست عام 2001 - الولايات المتحدة',
          'mondoBIOTECH - تأسست 1998 - سويسرا',
        ]
      : [
          'IBM - Founded 1911 - United States',
          'Microsoft - Founded 1975 - United States',
          'Netscape - Founded 1994 - United States',
          'Red Hat - Founded 1993 - United States',
          'Local Motors - Founded 2008 - United States',
          'Wikipedia - Founded 2001 - United States',
          'mondoBIOTECH - Founded 1998 - Switzerland',
        ],
    tags: rtl
      ? [
          'التنمية التعاونية',
          'توفير التكاليف',
          'المصدر المفتوح',
          'المجتمع العام',
          'جهد جماعي',
          'حلول المشاريع',
        ]
      : [
          'Collaborative Development',
          'Cost Savings',
          'Open Source',
          'Public Community',
          'Collective Effort',
          'Enterprise Solutions',
        ],
    considerations: t('considerations_bm38'),
    combinations: ['$LBM22', '$LBM72'],
    hashTagType: 'businessModelTemp',
  }
}

export default OpenSourceBusinessModel
