import React from 'react'
import { BiShare } from 'react-icons/bi'
import MultiMillify from '../../SmallComponents/MultiMillify'

const ShareButtonConfig = ({ shareDialogOpener, styles, sharesCount }) => {
  return {
    icon: (
      <BiShare
        size={25}
        style={{
          ...styles?.bottomIcon,
        }}
      />
    ),
    value: (
      <span
        style={{
          display: sharesCount > 0 ? null : 'none',
          ...styles?.counterValue,
        }}
      >
        {MultiMillify(sharesCount)}
      </span>
    ),
    openFun: () => {
      if (typeof shareDialogOpener === 'function') shareDialogOpener()
    },
  }
}

export default ShareButtonConfig
