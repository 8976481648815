import { useTranslation } from 'react-i18next'

const Choice = () => {
  const { t } = useTranslation('Choice')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 14,
    category: t('category_ms14'),
    title: t('title_ms14'),
    subTitle: t('subTitle_ms14'),
    main: t('main_ms14'),
    how: t('how_ms14'),
    usedInModels: ['$LBM54'],
    tags: rtl
      ? [
          'تمايز السعر',
          'سمات',
          'السد',
          'المتوسط',
          'تتويج وتذويب',
          'ماركات موثوقة',
          'معايير الاختيار',
          'صناعة القرار',
        ]
      : [
          'Price Differentiation',
          'Features',
          'Capping',
          'Averaging',
          'Topping and Tailing',
          'Reliable Brands',
          'Choice Criteria',
          'Decision-making',
        ],
    examples: t('examples_ms14'),
    hashTagType: 'markStraAspect',
  }
}

export default Choice
