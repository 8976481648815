import React, { useContext, useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import { Tooltip } from '@mui/material'
import { Avatar } from '@mui/material'
import ListedDialogModel from '../../../../../../../../../gridedModels/ListedDialogModel'
import ListUnitModel from './ListUnitModel'
import { Link } from 'react-router-dom'
import FreeBar from '../../../../../../../../../skeleton/SkeletonComponents/FreeBar'
import TimeAgoFormattor from '../../../../../../../../../SmallComponents/TimeAgoFormattor'
import MultiMillify from '../../../../../../../../../SmallComponents/MultiMillify'
import BulletSeparator from '../../../../../../../../../SmallComponents/BulletSeparator'
import PinnedTag from '../../../../../../../../../SmallComponents/PinnedTag'
import { AuthContext } from '../../../../../../../../../../context/AuthContext'

const styles = () => ({
  avatarNameCategory: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
    marginBottom: 10,
  },
  companyName: {
    fontSize: 18,
    fontWeight: 500,
    cursor: 'pointer',
  },
  postingTime: {
    fontSize: 13,
    cursor: 'pointer',
    fontWeight: 500,
    marginRight: 10,
    flexDirection: 'row',
    display: 'flex',
  },
  avatar: {
    cursor: 'pointer',
    width: '50px !important',
    height: '50px !important',
  },
  '@media (max-width: 600px)': {
    companyName: {
      fontSize: 13,
    },
    postingTime: {
      fontSize: 11,
    },
    avatar: {
      cursor: 'pointer',
      width: '40px !important',
      height: '40px !important',
    },
  },
})

const NoteTopLeft = ({ classes, notesProps }) => {
  const { note, DCGProps, managerProfile } = notesProps
  const { isDarkModeActive } = DCGProps
  const { user } = useContext(AuthContext)
  const managerName = managerProfile?.firstName + ' ' + managerProfile?.lastName
  const managerAvatar = managerProfile?.profilePicture
  const [isViewersListOpen, setIsViewersListOpen] = useState(false)
  const viewersListOpener = () => {
    setIsViewersListOpen(true)
  }
  const viewersListClose = () => {
    setIsViewersListOpen(false)
  }
  const rtl = document.body.dir === 'rtl'
  return (
    <article className={classes.avatarNameCategory}>
      <Link to={`/profile${managerProfile?._id}`}>
        <Avatar
          src={managerAvatar}
          className={classes.avatar}
          sx={{
            margin: rtl ? '0 0 0 10px !important' : '0 10px 0 0 !important',
          }}
        />
      </Link>
      <section style={{ display: 'flex', flexDirection: 'column' }}>
        <span className={classes.companyName}>
          {managerProfile?.firstName ? managerName : <FreeBar height={20} />}
        </span>
        <span className={classes.postingTime}>
          <TimeAgoFormattor date={note?.createdAt} />
          {managerProfile?._id === user?._id && (
            <>
              <BulletSeparator />
              <Tooltip title={rtl ? 'اضغط للتفاصل' : 'Click for Details'}>
                <span
                  style={{
                    fontStyle: 'italic',
                    cursor: 'pointer',
                  }}
                  onClick={() => viewersListOpener()}
                >
                  {MultiMillify(note?.noteViewersIds?.length)}{' '}
                  {rtl ? 'مشاهدات' : 'Views'}
                </span>
              </Tooltip>
            </>
          )}
          <PinnedTag hide={note?.pinned} />
        </span>
      </section>
      <ListedDialogModel
        listDialogClose={viewersListClose}
        isListDialogOpen={isViewersListOpen}
        isDarkModeActive={isDarkModeActive}
        dialogTitle={rtl ? 'مشاهدوا الملاحظة' : 'Note Viewers'}
        ListUnitModelComponent={ListUnitModel}
        ListUnitModelComponentProps={{ note }}
        note={note}
      />
    </article>
  )
}

const WrappedNoteTopLeft = withStyles(styles)(NoteTopLeft)

export default WrappedNoteTopLeft
