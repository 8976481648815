import React from 'react'
import {
  FreeShare,
  ProjectProfileShare,
  HomeShare,
  GeneralShare,
} from './ShareDialogContentComponents'
import RoomEdit from './ShareDialogContentComponents/RoomEdit'

const ShareDialogContent = ({ shareSystemProps }) => {
  const { shareTypeIndex } = shareSystemProps

  const shareTypesComponents = [
    <GeneralShare shareSystemProps={shareSystemProps} />,
    <HomeShare shareSystemProps={shareSystemProps} />,
    <FreeShare shareSystemProps={shareSystemProps} />,
    <ProjectProfileShare shareSystemProps={shareSystemProps} />,
    <RoomEdit shareSystemProps={shareSystemProps} />,
  ]

  return <article>{shareTypesComponents[shareTypeIndex]}</article>
}

export default ShareDialogContent
