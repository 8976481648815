import { useTranslation } from 'react-i18next'

const CustomerMotivation = () => {
  const { t } = useTranslation('CustomerMotivation')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 6,
    category: t('category_ms06'),
    title: t('title_ms06'),
    subTitle: t('subTitle_ms06'),
    main: t('main_ms06'),
    how: t('how_ms06'),
    usedInModels: [],
    tags: rtl
      ? [
          'تحفيز العملاء',
          'البحث عن المتجر',
          'الاحتياجات الفسيولوجية',
          "سلامة الامن'",
          'الانتماء',
          'تعرُّف',
          'الذات',
          'تحليل الاتجاه',
          'حلول مستدامة',
        ]
      : [
          'Customer Motivation',
          'Market Research',
          'Physiological Needs',
          'Safety & Security',
          'Belonging',
          'Recognition',
          'Self-Actualization',
          'Trend Analysis',
          'Sustainable Solutions',
        ],
    examples: t('examples_ms06'),
    hashTagType: 'markStraAspect',
  }
}

export default CustomerMotivation
