import React from 'react'
import ButtonsSystem from '../../../../../../../../buttonsSystem/ButtonsSystem'
import CardTitlesModel from '../../../../../../../../fileSystem/MiniFileSystemComponents/MiniFSCardsModelComponents/MFSCardsViewModels/StandardCardComps/CardTitlesModel'

const ResearchApplicationContent = ({ opener, element, isDarkModeActive }) => {
  const application = element
  const rtl = document.body.dir === 'rtl'
  return (
    <CardTitlesModel
      title={application?.Title}
      isDarkModeActive={isDarkModeActive}
      cardFooter={
        <section style={{ width: '100%' }}>
          <ButtonsSystem
            buttonsArray={[
              {
                title: rtl ? 'عرض' : 'View',
                funs: () => opener(),
              },
            ]}
          />
        </section>
      }
    />
  )
}

export default ResearchApplicationContent
