import React from 'react'
import CoreComponent from './DetectionCoreComponents/CoreComponent'

const DetectionCore = ({
  text,
  textIndex,
  textPackage,
  hashTagSymbol,
  TextFormattorComp,
  NextDetection,
  furtherDetection,
  isDarkModeActive,
  notEngineProps,
}) => {
  // mention algorithm - i will make changes
  //inside the restTextFragments elemnts and
  //not in the main text, so not to affect the hashtag algorithm
  const textFragments = text.map((fragment) => {
    // now i will separte the mention parts to replace them further
    let detectedFragments = fragment
      .split(' ')
      .filter((word) => word.startsWith(hashTagSymbol))
      .concat(' ')

    let nonDetectedFragments = fragment
      .split(hashTagSymbol)
      .map((part, index) =>
        part.replace(
          detectedFragments.map((df) =>
            df?.substring(hashTagSymbol?.length, df?.length)
          )[index - 1],
          ''
        )
      )

    return detectedFragments.map((word, wordIndex) => (
      <CoreComponent
        key={wordIndex}
        NextDetection={NextDetection}
        TextFormattorComp={TextFormattorComp}
        furtherDetection={furtherDetection}
        textPackage={textPackage}
        word={word}
        isDarkModeActive={isDarkModeActive}
        notEngineProps={notEngineProps}
        nonDetectedFragments={nonDetectedFragments}
        wordIndex={wordIndex}
      />
    ))
  })
  return textFragments[textIndex]
}

export default DetectionCore
