import { useTranslation } from 'react-i18next'

const FuturePositions = () => {
  const { t } = useTranslation('FuturePositions')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 35,
    category: t('category_ms35'),
    title: t('title_ms35'),
    subTitle: t('subTitle_ms35'),
    main: t('main_ms35'),
    how: t('how_ms35'),
    usedInModels: [],
    tags: rtl
      ? [
          'البحث عن المتجر',
          'احتياجات العميل',
          'التقنيات الناشئة',
          'وضع السوق',
          'المنصب المستقبلي',
        ]
      : [
          'Market Research',
          'Customer Needs',
          'Emerging Technologies',
          'Market Position',
          'Future Position',
        ],
    examples: t('examples_ms35'),
    hashTagType: 'markStraAspect',
  }
}

export default FuturePositions
