const InfoDataIntialStateGen = (configs, separator) => {
  const initailState = () => {
    let tempObj = {}
    configs.forEach((config) => {
      tempObj[config.title.split(' ').join(separator || '')] = config.value
    })
    return tempObj
  }
  return initailState()
}

export default InfoDataIntialStateGen
