import { Button } from '@mui/material'
import React from 'react'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    margin: 10,
    padding: 10,
  },
  button: {
    borderRadius: '10px !important',
    margin: '2px !important',
    textTransform: 'none !important',
    fontWeight: '550 !important',
    width: '100% !important',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important",
  },
})

const LoadMoreButton = ({ setPage, classes, onClick }) => {
  const rtl = document.body.dir === 'rtl'
  return (
    <section className={classes.buttonWrapper}>
      <Button
        className={classes.button}
        onClick={() => {
          setPage((prev) => prev + 1)
          if (typeof onClick === 'function') onClick()
        }}
      >
        {rtl ? 'المزيد' : 'Load More'}
      </Button>
    </section>
  )
}

const WrappedLoadMoreButton = withStyles(styles)(LoadMoreButton)

export default WrappedLoadMoreButton
