import React from 'react'
import GPDFDBodyModel from '../GeneralPDFDocumentComps/GPDFDBodyModel'

const MarketingStrategyPDFBody = ({ button }) => {
  const { strategy } = button.PDFGenProps
  const configs = [
    {
      title: 'Segmentation',
      titleValue: strategy?.Segmentation,
      disableTrans: true,
    },
    {
      title: 'Targeting',
      titleValue: strategy?.Targeting,
      disableTrans: true,
    },
    {
      title: 'Positioning',
      titleValue: strategy?.Positioning,
      disableTrans: true,
    },
    {
      title: 'Monitoring',
      titleValue: strategy?.Monitoring,
      disableTrans: true,
    },
    {
      title: 'Goals',
      titleValue: strategy?.Goals,
      disableTrans: true,
    },
    {
      title: 'Budget',
      titleValue: strategy?.Budget,
      disableTrans: true,
    },
    {
      title: 'Market Size',
      titleValue: strategy?.MarketSize,
      disableTrans: true,
    },
    {
      title: 'Market Trends',
      titleValue: strategy?.MarketTrends,
      disableTrans: true,
    },
    {
      title: 'Competitor Analysis',
      titleValue: strategy?.CompetitorAnalysis,
      disableTrans: true,
    },
    {
      title: 'Consumer Behavior',
      titleValue: strategy?.ConsumerBehavior,
      disableTrans: true,
    },
    {
      title: 'Economic Trends',
      titleValue: strategy?.EconomicTrends,
      disableTrans: true,
    },

    {
      title: 'Product',
      titleValue: strategy?.Product,
      disableTrans: true,
    },
    {
      title: 'Price',
      titleValue: strategy?.Price,
      disableTrans: true,
    },
    {
      title: 'Place',
      titleValue: strategy?.Place,
      disableTrans: true,
    },
    {
      title: 'Promotion',
      titleValue: strategy?.Promotion,
      disableTrans: true,
    },
  ]
  return (
    <GPDFDBodyModel
      hideCreator
      GPDFBodyModelRightProps={{
        button,
        configs: configs,
      }}
      button={button}
    />
  )
}

export default MarketingStrategyPDFBody
