import React, { useEffect, useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import AccessCardGenerator from '../../../../../../../../../notificationsSystem/NotificationsComponents/ContractsNotificationsComponents/ContractsNotifsDialogRightComponents/NotificationBodyWithAccessCardComps/AccessCardGenerator'
import TransparentBackCard from '../../../../../../../../../SmallComponents/TransparentBackCard'
import MultiLangNumber from '../../../../../../../../../SmallComponents/MultiLangNumber'
import TimeAgoFormattor from '../../../../../../../../../SmallComponents/TimeAgoFormattor'
import GetOutChannelButton from './GetOutChannelButton'
import axios from 'axios'
import CentralTitle from '../../../../../../../../../SmallComponents/CentralTitle'
import ToolbarPanel from '../../../../../../../../../toolbarSystem/ToolbarPanel'

const styles = () => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    color: 'white',
  },
  creationDate: {
    fontSize: 11,
    fontWeight: 500,
    display: 'flex',
  },
  '@media (max-width: 600px)': {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '100%',
      color: 'white',
    },
  },
})

const ChannelContractAndBasics = ({ classes, ...props }) => {
  const { channel, DCGProps, searchSystemProps, folderPathProps } = props
  const rtl = document.body.dir === 'rtl'
  const [isChanelWithIssues, setIsChanelWithIssues] = useState(true)
  useEffect(() => {
    const fetchResult = async () => {
      try {
        const res = await axios.get(
          `/channels/channelIssueChecker/${channel?._id}`
        )
        setIsChanelWithIssues(res.data.result)
      } catch (error) {
        console.log(error)
      }
    }
    if (channel?._id) fetchResult()
  }, [channel?._id])
  const toolbarConfig = [
    {
      type: 'usersIcon',
      onOpen: '',
      count: String(MultiLangNumber(channel?.usersIds?.length)),
    },
    {
      type: 'projectsIcon',
      onOpen: '',
      count: String(MultiLangNumber(channel?.projectsIds?.length)),
    },
    {
      type: 'interestsIcon',
      onOpen: '',
      count: String(MultiLangNumber(channel?.elements?.length)),
    },
    // { type: 'contractIcon', onOpen: '', count: '' },
  ]
  return (
    <TransparentBackCard
      {...DCGProps}
      imaged
      margin={'0px auto 10px auto'}
      content={
        <article className={classes.wrapper}>
          <section style={{ marginRight: 10 }}>
            <AccessCardGenerator
              element={{
                id: channel?.contractId,
                title: `${channel?.Title} Contract`,
                hashTagType: 'contract',
              }}
              {...DCGProps}
              modelProps={{
                profileProps: { DCGProps },
              }}
            />
          </section>
          <section
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
              }}
            >
              <CentralTitle
                title={rtl ? 'معلومات اساسية' : 'Basic Information'}
              />
              <span className={classes.creationDate}>
                <TimeAgoFormattor date={channel?.createdAt} />
              </span>
            </div>
            <div style={{ margin: 10 }}>
              <ToolbarPanel config={toolbarConfig} />
            </div>
            {!isChanelWithIssues && (
              <GetOutChannelButton
                {...props}
                {...folderPathProps}
                {...searchSystemProps}
              />
            )}
          </section>
        </article>
      }
    />
  )
}

const WrappedChannelContractAndBasics = withStyles(styles)(
  ChannelContractAndBasics
)

export default WrappedChannelContractAndBasics
