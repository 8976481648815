import React from 'react'
import HomeChartPost from '../../homeFeed/homeFeedComponents/HomeFeedPostModelComponents/HomeChartPost'
import HomeGenerlPostModel from '../../homeFeed/homeFeedComponents/HomeFeedPostModelComponents/HomeGenerlPostModel'
import SharedPost from '../../homeFeed/homeFeedComponents/HomeFeedPostModelComponents/SharedPost'
import SocialPost from '../../homeFeed/homeFeedComponents/HomeFeedPostModelComponents/SocialPost'

const HomeFeedsSystemModels = ({ feedsSystemProps }) => {
  const {
    post,
    isUsedIn,
    hideTopAndBottom,
    isDarkModeActive,
    hideBottom,
    engineFuns,
    user,
    postsUsers,
    sharedPosts,
    sharedPostsUsers,
    socket,
    hideAddUserIcon,
    setPosts,
  } = feedsSystemProps

  const postProps = {
    isUsedIn,
    post,
    hideTopAndBottom,
    hideBottom,
    isDarkModeActive,
    hideRightOptions: true,
    isCommentingSystemActive: hideTopAndBottom,
    commentingSystemView: hideTopAndBottom,
    engineFuns,
    user,
    postsUsers,
    sharedPosts,
    sharedPostsUsers,
    socket,
    hideAddUserIcon,
    setPosts,
  }

  const homeFeedPosts = [
    <SocialPost postProps={postProps} />,
    <HomeChartPost postProps={postProps} />,
    <SharedPost postProps={postProps} />,
  ]

  const element = post?.postElement
  return element || post.postTypeIndex === 2 || post.postTypeIndex === 3 ? (
    <HomeGenerlPostModel postProps={postProps} />
  ) : (
    homeFeedPosts[post?.homePostFeedModelIndex]
  )
}

export default HomeFeedsSystemModels
