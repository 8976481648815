import axios from 'axios'
import React, { useContext, useState } from 'react'
import { AuthContext } from '../../../../../../../../context/AuthContext'
import ButtonsSystem from '../../../../../../../buttonsSystem/ButtonsSystem'
import ElementRequestsSenders from '../../../../../../../notificationsSystem/ElementRequestsSenders'
import NoPermissionedInteraction from '../../../../../../../notificationsSystem/NoPermissionedInteraction'
import NotificationCreator from '../../../../../../../notificationsSystem/NotificationCreator'
import { toast } from 'react-toastify'

const InvestmentPackageInteractionButtons = ({
  postMode,
  pack,
  DCGProps,
  depManager,
}) => {
  const { socket, project } = DCGProps
  const { user } = useContext(AuthContext)
  const senders = ElementRequestsSenders(pack?._id || pack?.id)
  const newRequest = {
    userId: user?._id,
    projectId: pack?.projectId,
    genre: 'investment',
    subgenre: 'invest',
    elementId: pack?._id || pack?.id,
  }
  const activeFun = async () => {
    try {
      const res = await axios.post('/requests', newRequest)
      socket.current.emit('addNotifUser', depManager?._id || project?.userId)
      NotificationCreator({
        associativity: 'investment',
        relation: 'ceo',
        conditionIndex: 0,
        accompaniedData: {
          projectId: pack?.projectId,
          requestId: res.data.request._id,
          elements: [
            {
              hashTagType: pack?.hashTagType,
              id: pack?._id || pack?.id,
              title: pack?.Title,
            },
          ],
        },
        category: 'contributors',
        userId: depManager?._id || project?.userId,
        currentUser: user,
        socket,
        CRNDelete: true,
        element: pack,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const [invest, setInvest] = useState(false)
  const rtl = document.body.dir === 'rtl'
  const buttonsArray = [
    {
      title: rtl ? 'إستثمر' : 'Invest',
      funs: () => {
        activeFun()
        setInvest(true)
        toast.success(
          rtl ? 'تم ارسال عرضك بنجاح' : 'Your offer has been sent successfully'
        )
      },
      cofirmMssg: true,
      disabled: invest,
    },
  ]
  return senders === undefined ? (
    <NoPermissionedInteraction checking={true} />
  ) : senders?.includes(user?._id) || invest ? (
    <NoPermissionedInteraction />
  ) : (
    <ButtonsSystem
      buttonsArray={buttonsArray}
      hide={
        !postMode ||
        user?._id === project?.userId ||
        user?.createdProjects?.includes(pack?.projectId) ||
        user?.contractedProjectsIds?.includes(project?._id)
      }
      {...DCGProps}
    />
  )
}

export default InvestmentPackageInteractionButtons
