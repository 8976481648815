import axios from 'axios'
import { useContext, useState } from 'react'
import { AuthContext } from '../../../../../../../../context/AuthContext'

const IssueDownVoteButtonConfig = ({
  activeFun,
  issue,
  confirmmationTitles,
  isDownvoted,
  setIsDownvoted,
  isUpvoted,
  setIsUpvoted,
}) => {
  const { user } = useContext(AuthContext)
  const [downvotes, setDownvotes] = useState(issue?.downvotes.length)
  const downvoteNumberHandler = async () => {
    setDownvotes((downvotes) => (isDownvoted ? downvotes - 1 : downvotes + 1))
    setIsDownvoted(!isDownvoted)
    try {
      if (user?._id && issue?._id)
        await axios.put(`/issues/${issue?._id}/downvote`, {
          userId: user?._id,
        })
    } catch (error) {
      console.log(error)
    }
  }
  return {
    title: isDownvoted ? `Remove Vote ${downvotes}` : `Downvote ${downvotes}`,
    funs: async () => {
      setIsDownvoted(!isDownvoted)
      downvoteNumberHandler()
      if (!isDownvoted) activeFun({ relation: 'voting', conditionIndex: 1 })
    },
    cofirmMssg: true,
    confirmmationTitles,
    disabled: isUpvoted,
  }
}

export default IssueDownVoteButtonConfig
