import React from 'react'
import ButtonModelUnit from './ButtonModelUnit'
import { PDFDownloadLink } from '@react-pdf/renderer'
import PDFDTemplates from './PDFGeneratorButtonComps/PDFDTemplates'

const PDFGeneratorButton = ({ button, element, isDarkModeActive }) => {
  const { type, fileName } = button.PDFGenProps
  const rtl = document.body.dir === 'rtl'
  return (
    <PDFDownloadLink
      document={
        PDFDTemplates({ button }).filter((temp) => temp.hashTagType === type)[0]
          .comp
      }
      fileName={fileName}
      style={{ width: '100%' }}
    >
      {({ loading }) =>
        loading ? (
          <ButtonModelUnit
            button={{
              ...button,
              title: rtl ? 'جارى تحضير ملف الـ PDF' : 'Preparing PDF',
              disabled: true,
            }}
            isDarkModeActive={isDarkModeActive}
            element={element}
          />
        ) : (
          <ButtonModelUnit
            button={{ ...button, title: rtl ? 'تحميل' : 'Download' }}
            isDarkModeActive={isDarkModeActive}
            element={element}
          />
        )
      }
    </PDFDownloadLink>
  )
}

export default PDFGeneratorButton
