import { useTranslation } from 'react-i18next'

const MembershipBusinessModel = () => {
  const { t } = useTranslation('MembershipBusinessModel')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 64,
    title: t('title_bm64'),
    subTitle: t('subTitle_bm64'),
    main: t('main_bm64'),
    when: t('when_bm64'),
    who: t('who_bm64'),
    what: t('what_bm64'),
    how: t('how_bm64'),
    why: t('why_bm64'),
    examples: rtl
      ? [
          'أمازون برايم - 1994 - الولايات المتحدة الأمريكية',
          'Spotify - 2006 - السويد',
          'Netflix - 1997 - الولايات المتحدة الأمريكية',
          'Stitch Fix - 2011 - الولايات المتحدة الأمريكية',
          'Dollar Shave Club - 2011 - الولايات المتحدة الأمريكية',
          'بلو أبرون - 2012 - الولايات المتحدة الأمريكية',
          'كوستكو - 1983 - الولايات المتحدة الأمريكية',
        ]
      : [
          'Amazon Prime - 1994 - USA',
          'Spotify - 2006 - Sweden',
          'Netflix - 1997 - USA',
          'Stitch Fix - 2011 - USA',
          'Dollar Shave Club - 2011 - USA',
          'Blue Apron - 2012 - USA',
          'Costco - 1983 - USA',
        ],
    tags: rtl
      ? ['الاحتفاظ بالعملاء', 'المحتوى الحصري', 'تخصيص الاشتراك']
      : ['Customer Retention', 'Exclusive Content', 'Customize Subscription'],
    considerations: t('considerations_bm64'),
    combinations: ['$LBM53'],
    hashTagType: 'businessModelTemp',
  }
}

export default MembershipBusinessModel
