import React, { useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import ExpandedUnitTopCard from './ExpandedModelUnitComponents/ExpandedUnitTopCard'
import ExpandedUnitDetailsCard from './ExpandedModelUnitComponents/ExpandedUnitDetailsCard'
import { Card, Grid } from '@mui/material'

const styles = () => ({
  requestContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  unitWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '5px 0 5px 0',
    padding: 10,
    borderRadius: '10px !important',
  },

  '@media (max-width: 600px)': {
    unitWrapper: {
      margin: '5px 0 5px 0',
      padding: 10,
    },
  },
})

const ExpandedModelUnit = ({ classes, props, element }) => {
  const [showDetails, setShowDetails] = useState(false)
  const {
    firsButtonConfigs,
    UnitCardDetailsComponent,
    UnitCardLeftComponent,
    DCGProps,
    requestsUsers,
    setRequests,
    isDarkModeActive,
  } = props
  const card = element

  return (
    <article className={classes.requestContainer}>
      <Card
        className={classes.unitWrapper}
        sx={{
          backgroundColor: isDarkModeActive
            ? 'rgba(255, 255, 255, 0.192)'
            : 'rgba(217,224,252, 0.2)',
          color: isDarkModeActive ? 'white' : 'inherit',
        }}
      >
        <ExpandedUnitTopCard
          card={card}
          showDetails={showDetails}
          setShowDetails={setShowDetails}
          firsButtonConfigs={firsButtonConfigs}
          UnitCardLeftComponent={UnitCardLeftComponent}
          DCGProps={DCGProps}
          requestsUsers={requestsUsers}
          setRequests={setRequests}
        />
        <ExpandedUnitDetailsCard
          showDetails={showDetails}
          card={card}
          UnitCardDetailsComponent={UnitCardDetailsComponent}
          exprops={firsButtonConfigs.props}
          DCGProps={DCGProps}
          requestsUsers={requestsUsers}
          setRequests={setRequests}
        />
      </Card>
    </article>
  )
}

const WrappedExpandedModelUnit = withStyles(styles)(ExpandedModelUnit)

export default WrappedExpandedModelUnit
