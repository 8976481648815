import axios from 'axios'
import React, { useState } from 'react'
import { useContext } from 'react'
import { AuthContext } from '../../../../../../../context/AuthContext'
import ButtonsSystem from '../../../../../../buttonsSystem/ButtonsSystem'
import ElementRequestsSenders from '../../../../../../notificationsSystem/ElementRequestsSenders'
import NoPermissionedInteraction from '../../../../../../notificationsSystem/NoPermissionedInteraction'
import NotificationCreator from '../../../../../../notificationsSystem/NotificationCreator'
import { toast } from 'react-toastify'

const TaskInteractionButtons = ({ postMode, task, DCGProps }) => {
  const { socket } = DCGProps
  const { user } = useContext(AuthContext)
  const senders = ElementRequestsSenders(task?._id || task?.id)
  const [start, setStart] = useState(false)

  const newRequest = {
    userId: user?._id,
    projectId: task?.projectId,
    genre: 'task',
    elementId: task?._id || task?.id,
    subgenre: 'candidate',
    requestData: {},
  }
  const activeFun = async (subgenre) => {
    try {
      const res = await axios.post('/requests', newRequest)
      socket.current.emit('addNotifUser', task?.TaskModeratorId)
      NotificationCreator({
        associativity: 'task',
        relation: 'creator',
        conditionIndex: 0,
        accompaniedData: {
          requestSubGenre: subgenre,
          projectId: task?.projectId,
          requestId: res.data.request._id,
          elements: [
            {
              hashTagType: task?.hashTagType,
              id: task?._id || task?.id,
              title: task?.Title,
              TaskDeadline: task?.TaskDeadline,
            },
          ],
        },
        category: 'contributors',
        userId: task?.TaskModeratorId,
        currentUser: user,
        socket,
        CRNDelete: true,
        element: task,
      })
    } catch (error) {
      console.log(error)
    }
  }
  const rtl = document.body.dir === 'rtl'
  const buttonsArray = [
    {
      title: rtl ? 'إبدأ' : 'Start',
      funs: () => {
        setStart(true)
        activeFun()
        toast.success(
          rtl ? 'تم ارسال عرضك بنجاح' : 'Your offer has been sent successfully'
        )
      },
      cofirmMssg: true,
      disabled: start,
    },
  ]
  return senders === undefined ? (
    <NoPermissionedInteraction checking={true} />
  ) : senders?.includes(user?._id) || start ? (
    <NoPermissionedInteraction />
  ) : (
    <ButtonsSystem
      buttonsArray={buttonsArray}
      hide={
        !postMode ||
        (task?.TaskStatus !== 'Still' && task?.TaskPairedContributorId) ||
        user?.createdProjects?.includes(task?.projectId) ||
        (task?.TaskStatus !== 'Delayed' && task?.TaskPairedContributorId) ||
        task?.TaskModeratorId === user?._id
      }
      {...DCGProps}
    />
  )
}

export default TaskInteractionButtons
