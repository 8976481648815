import React, { useState } from 'react'
import { Tooltip } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { AiOutlineUsergroupAdd } from 'react-icons/ai'
import ContributorAddition from './AddNewContributorComponents/ContributorAddition'
import DepContributorAddition from './AddNewContributorComponents/DepContributorAddition'

const styles = () => ({
  icon: {
    cursor: 'pointer',
    fontSize: 18,
    '&:hover': {
      opacity: 0.8,
    },
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
})

// add new member/contributro/manager
const AddNewContributor = ({ classes, noFSPanelProps }) => {
  const {
    showAddContributorIcon,
    addContributorTippy,
    // for adding the contributor to the department, after adding him to the project - this is a second step after writing contracts and approvals
    depAddition,
  } = noFSPanelProps

  // new contributor form
  const [isNewContribOpen, setIsNewContribOpen] = useState(false)

  const newContribOpener = () => {
    setIsNewContribOpen(true)
  }

  const newContribClose = () => {
    setIsNewContribOpen(false)
  }
  // dep addition form
  const [isNewDepContribOpen, setIsNewDepContribOpen] = useState(false)

  const newDepContribOpener = () => {
    setIsNewDepContribOpen(true)
  }
  const newDepContribClose = () => {
    setIsNewDepContribOpen(false)
  }
  return (
    <>
      <Tooltip title={addContributorTippy}>
        <span
          className={classes.iconWrapper}
          style={{ display: showAddContributorIcon ? null : 'none' }}
          onClick={() => {
            if (depAddition) {
              newDepContribOpener()
            } else {
              newContribOpener()
            }
          }}
        >
          <AiOutlineUsergroupAdd className={classes.icon} />
        </span>
      </Tooltip>
      <ContributorAddition
        {...noFSPanelProps}
        newContribClose={newContribClose}
        isNewContribOpen={isNewContribOpen}
      />
      <DepContributorAddition
        {...noFSPanelProps}
        newDepContribClose={newDepContribClose}
        isNewDepContribOpen={isNewDepContribOpen}
      />
    </>
  )
}

const WrappedAddNewContributor = withStyles(styles)(AddNewContributor)

export default WrappedAddNewContributor
