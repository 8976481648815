import { useContext } from 'react'
import { AuthContext } from '../../../../../../context/AuthContext'
import NotificationCreator from '../../../../../notificationsSystem/NotificationCreator'
import { toast } from 'react-toastify'

const MemberAssignedTasksConfigs = ({
  headerText,
  hideNavigator,
  tasks,
  DCGProps,
  user,
}) => {
  const { project, socket } = DCGProps
  const rtl = document.body.dir === 'rtl'
  const confirmmationTitles = [
    {
      title: rtl
        ? 'تم إرسال الطلب الى مسؤول المهمة'
        : 'Request is Sent to the Task Moderator',
      tip: rtl
        ? 'سوف تتلقى اشعاراً بالرد فى اشعارات المساهمين لديك'
        : 'You will recieve a notification with response in contributors Notifications rolls',
    },
  ]
  const buttonsArray = [
    {
      title: rtl ? 'تم' : 'Done',
      funs: async (task) => {
        if (socket?.current)
          socket?.current?.emit('addNotifUser', task?.TaskModeratorId)
        if (task)
          NotificationCreator({
            associativity: 'task',
            relation: 'creator',
            conditionIndex: 1,
            accompaniedData: {
              projectId: project?._id,
              elements: [
                {
                  hashTagType: task?.hashTagType,
                  id: task?._id,
                  title: task?.Title,
                  TaskDeadline: task?.TaskDeadline,
                  TaskPairedContributorId: task?.TaskPairedContributorId,
                },
              ],
            },
            category: 'contributors',
            userId: task?.TaskModeratorId,
            currentUser: user,
            socket,
          })
        toast.success(
          rtl
            ? 'تم ارسال اشعار الى القيّم على مهمتك بتطور المهمة'
            : 'Task moderator has been notified about your progress'
        )
      },
      disabled: (task) =>
        user?._id !== task?.TaskPairedContributorId
          ? true
          : task.TaskStatus === 'Done' || task.TaskStatus === 'Terminated',
      cofirmMssg: true,
      confirmmationTitles,
    },
    {
      title: rtl ? 'تمديد' : 'Extend',
      funs: (task) => {
        NotificationCreator({
          associativity: 'task',
          relation: 'creator',
          conditionIndex: 2,
          accompaniedData: {
            projectId: project?._id,
            elements: [
              {
                hashTagType: task?.hashTagType,
                id: task?._id,
                title: task?.Title,
                TaskDeadline: task?.TaskDeadline,
                TaskPairedContributorId: task?.TaskPairedContributorId,
              },
            ],
          },
          category: 'contributors',
          userId: task?.TaskModeratorId,
          currentUser: user,
          socket,
        })
        toast.success(
          rtl
            ? 'تم ارسال اشعار الى القيّم على مهمتك بتطور المهمة'
            : 'Task moderator has been notified about your progress'
        )
      },
      disabled: (task) =>
        user?._id !== task?.TaskPairedContributorId
          ? true
          : task.TaskStatus === 'Still' ||
            task.TaskStatus === 'Done' ||
            task.TaskStatus === 'Terminated',
      cofirmMssg: true,
      confirmmationTitles,
    },
  ]
  const unitsArray = tasks
  return {
    headerText: headerText
      ? headerText
      : rtl
      ? 'المهام المطلوبة'
      : 'Assigned Tasks',
    unitsArray,
    hideNavigator,
    modelUnitProps: {
      buttonsArray,
    },
  }
}

export default MemberAssignedTasksConfigs
