const ExternalLinkGenerator = (element, userProfile, projectProfile) => {
  const baseUrl = 'https://hutupia.com/elements/'
  const rtl = document.body.dir === 'rtl'
  const hashTagType = element?.hashTagType || ''
  const title =
    hashTagType === 'feedPost'
      ? rtl
        ? 'منشور'
        : 'Post'
      : element?.title ||
        element?.Title ||
        element?.name ||
        element?.ProductName
  const id = element?.id || element?._id || ''
  const link = `${encodeURIComponent(title)}/${encodeURIComponent(
    id
  )}/${hashTagType}`

  // user profile urls generation
  const userBaseUrl = 'https://hutupia.com/profile/'
  const profileLink = encodeURIComponent(element?._id)

  // project urls generation
  // user profile urls generation
  const projectBaseUrl = 'https://hutupia.com/projectProfile/'

  return userProfile
    ? userBaseUrl + profileLink
    : projectProfile
    ? projectBaseUrl
    : baseUrl + link
}

export default ExternalLinkGenerator
