import axios from 'axios'
import { useEffect, useState } from 'react'

const ElementRequestsSenders = (elementId) => {
  const [senders, setSenders] = useState()
  useEffect(() => {
    const fetchSenders = async () => {
      try {
        const res = await axios.get(
          `/requests/elementRequests/senders/${elementId}`
        )
        setSenders(res.data.senders)
      } catch (error) {
        console.log(error)
      }
    }
    if (elementId) fetchSenders()
  }, [elementId])
  return senders
}

export default ElementRequestsSenders
