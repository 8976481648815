import { useTranslation } from 'react-i18next'

const RevenueSharing = () => {
  const { t } = useTranslation('RevenueSharing')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 46,
    title: t('title_bm46'),
    subTitle: t('subTitle_bm46'),
    main: t('main_bm46'),
    when: t('when_bm46'),
    who: t('who_bm46'),
    what: t('what_bm46'),
    how: t('how_bm46'),
    why: t('why_bm46'),
    examples: rtl
      ? [
          'CDnow - تأسست 1994 - الولايات المتحدة الأمريكية',
          'آبل - تأسست عام 1976 - الولايات المتحدة الأمريكية',
          'HubPages - تأسست عام 2006 - الولايات المتحدة الأمريكية',
        ]
      : [
          'CDnow - Founded 1994 - USA',
          'Apple - Founded 1976 - USA',
          'HubPages - Founded 2006 - USA',
        ],
    tags: rtl
      ? ['مخاطر أقل', 'مربح للجانبين', 'شركات ناشئة']
      : ['Lower Risk', 'Win-win', 'Start-ups'],
    considerations: t('considerations_bm46'),
    combinations: ['$LBM2', '$LBM21', '$LBM25', '$LBM30', '$LBM37'],
    hashTagType: 'businessModelTemp',
  }
}

export default RevenueSharing
