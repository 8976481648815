import React from 'react'
import IdDepenedentMFSInsideView from './MiniFSInsideModelComponents/IdDepenedentMFSInsideView'
import IndexDependentMFSInsideView from './MiniFSInsideModelComponents/IndexDependentMFSInsideView'

const MiniFSInsideModel = (props) => {
  const { cardIdDependencyIndex } = props
  const components = [
    <IdDepenedentMFSInsideView {...props} />,
    <IndexDependentMFSInsideView {...props} />,
  ]
  return components[cardIdDependencyIndex]
}

export default MiniFSInsideModel
