import { useTranslation } from 'react-i18next'

const HiddenRevenueBusinessModel = () => {
  const { t } = useTranslation('HiddenRevenueBusinessModel')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 25,
    title: t('title_bm25'),
    subTitle: t('subTitle_bm25'),
    main: t('main_bm25'),
    when: t('when_bm25'),
    who: t('who_bm25'),
    what: t('what_bm25'),
    how: t('how_bm25'),
    why: t('why_bm25'),
    examples: rtl
      ? [
          'JCDecaux - تأسست عام 1964 - فرنسا.',
          'Google - تأسست عام 1998 - الولايات المتحدة الأمريكية',
          'مترو - 1995 في ستوكهولم - السويد - جريدة',
          'زاتو - تأسست عام 2004 - سويسرا',
          'Facebook - تأسست عام 2004 - الولايات المتحدة الأمريكية',
          'تويتر - تأسس عام 2006 - الولايات المتحدة الأمريكية',
        ]
      : [
          'JCDecaux - Founded in 1964 - France.',
          'Google - Founded 1998 - USA',
          'Metro - 1995 in Stockholm - Sweden - Newspaper',
          'Zattoo - Founded 2004 - Switzerland ',
          'Facebook - Founded 2004 - USA',
          'Twitter - Founded 2006 - USA',
        ],
    tags: rtl
      ? [
          'كل شيء مجاني',
          'الجهة الخارجية',
          'مبيعات غير مباشرة',
          'خدمة رخيصة',
          'تكاليف منخفضة',
          'قاعدة عملاء متزايدة',
        ]
      : [
          'All Free',
          'Third Party',
          'Indirect Sales',
          'Cheap Service',
          'Low Costs',
          'Increased Customer Base',
        ],
    considerations: t('considerations_bm25'),
    combinations: ['$LBM22', '$LBM64', '$LBM2'],
    hashTagType: 'businessModelTemp',
  }
}

export default HiddenRevenueBusinessModel
