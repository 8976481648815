import {
  AiFillEdit,
  AiFillPushpin,
  AiOutlinePushpin,
  AiOutlineSave,
} from 'react-icons/ai'
import { BsLink } from 'react-icons/bs'
import { MdDelete, MdSettings, MdAttachment } from 'react-icons/md'

const PopoverOptionsConfigs = (option) => {
  const configs = [
    {
      type: 'delete',
      PureIcon: MdDelete,
      ar_title: 'إزالة',
      en_title: 'Remove',
    },
    {
      type: 'settings',
      PureIcon: MdSettings,
      ar_title: 'اعدادات',
      en_title: 'Settings',
    },
    {
      type: 'attachments',
      PureIcon: MdAttachment,
      ar_title: 'مرفقات',
      en_title: 'Attachments',
    },
    {
      type: 'edit',
      PureIcon: AiFillEdit,
      ar_title: 'تعديل',
      en_title: 'Edit',
    },
    {
      type: 'pin',
      PureIcon: AiOutlinePushpin,
      ar_title: 'تثبيت',
      en_title: 'Pin',
    },
    {
      type: 'unpin',
      PureIcon: AiFillPushpin,
      ar_title: 'ازالة تثبيت',
      en_title: 'Unpin',
    },
    {
      type: 'save',
      PureIcon: AiOutlineSave,
      ar_title: 'حفظ',
      en_title: 'Save',
    },
    {
      type: 'external-link',
      PureIcon: BsLink,
      ar_title: 'احصل على رابط',
      en_title: 'Get Link',
    },
  ]
  return configs.find((c) => c.type === option?.type)
}

export default PopoverOptionsConfigs
