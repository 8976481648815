import { useTranslation } from 'react-i18next'

const PayPerUse = () => {
  const { t } = useTranslation('PayPerUse')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 40,
    title: t('title_bm40'),
    subTitle: t('subTitle_bm40'),
    main: t('main_bm40'),
    when: t('when_bm40'),
    who: t('who_bm40'),
    what: t('what_bm40'),
    how: t('how_bm40'),
    why: t('why_bm40'),
    examples: rtl
      ? [
          'Google - 1998 - الولايات المتحدة الأمريكية',
          'Daimler - 1924 - ألمانيا',
        ]
      : ['Google - 1998 - USA', 'Daimler - 1924 - Germany'],
    tags: rtl
      ? ['ولاء العملاء', 'الإيرادات الآمنة']
      : ['Customer Loyalty', 'Safe Revenues'],
    considerations: t('considerations_bm40'),
    combinations: ['$LBM31', '$LBM22', '$LBM13', '$LBM45'],
    hashTagType: 'businessModelTemp',
  }
}

export default PayPerUse
