import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { BsLink } from 'react-icons/bs'
import WindowProps from '../../windowViewer/WindowProps'
import { Tooltip } from '@mui/material'
import ShareExternalLinkDialog from './ExternalLinkSharingComps/ShareExternalLinkDialog'

const styles = () => ({
  icon: {
    cursor: 'pointer',
    fontSize: 18,
    '&:hover': {
      opacity: 0.8,
    },
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
})

const ExternalLinkSharing = ({ classes, noFSPanelProps }) => {
  const { showExternalLinkShare } = noFSPanelProps
  const { isWindowOpen, windowOpener, windowCloser } = WindowProps()
  const rtl = document.body.dir === 'rtl'

  return (
    <>
      <Tooltip title={rtl ? 'احصل على رابط للمشاركة' : 'Get Link to Share'}>
        <span
          className={classes.iconWrapper}
          style={{ display: showExternalLinkShare ? null : 'none' }}
          onClick={() => windowOpener()}
        >
          <BsLink className={classes.icon} />
        </span>
      </Tooltip>
      {isWindowOpen && (
        <ShareExternalLinkDialog
          noFSPanelProps={noFSPanelProps}
          isWindowOpen={isWindowOpen}
          windowCloser={windowCloser}
        />
      )}
    </>
  )
}

const WrappedExternalLinkSharing = withStyles(styles)(ExternalLinkSharing)

export default WrappedExternalLinkSharing
