import React, { useState, useEffect } from 'react'
import withStyles from '@mui/styles/withStyles'
import FormsComponents from '../../../formsSystem/FormsComponents'
import ShareTopProfileInfo from '../ShareModels/ShareTopProfileInfo'

const styles = () => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    wdith: '100%',
  },
})

const ShareDialogDescription = ({
  classes,
  shareSystemProps,
  departmentId,
  subDepId,
}) => {
  const { isDarkModeActive, setPostData, postData, post, project } =
    shareSystemProps
  const [textContent, setTextContent] = useState('')
  const [chosenHashTagElements, setChosenHashTagElements] = useState([])
  useEffect(() => {
    setPostData({
      ...postData,
      postTextContent: textContent,
      chosenHashTagElements: chosenHashTagElements,
      sharedPostId: post?._id,
    })
  }, [textContent, chosenHashTagElements])
  const rtl = document.body.dir === 'rtl'
  const formsCompsProps = {
    formComponentIndex: 0,
    chosenHashTagElements,
    setChosenHashTagElements,
    textContent,
    setTextContent,
    placeholder: rtl ? 'أضف شيئاً...' : 'Say Something..',
    isDarkModeActive,
    project,
    departmentId,
    subDepId,
  }
  return (
    <section className={classes.wrapper}>
      <ShareTopProfileInfo shareSystemProps={shareSystemProps} generalShare />
      <FormsComponents formsCompsProps={formsCompsProps} />
    </section>
  )
}

const WrappedShareDialogDescription = withStyles(styles)(ShareDialogDescription)

export default WrappedShareDialogDescription
