import React from 'react'
import { ProfileShareModel } from './'

const HomeShare = ({ shareSystemProps }) => {
  const rtl = document.body.dir === 'rtl'
  return (
    <ProfileShareModel
      shareSystemProps={{
        ...shareSystemProps,
        placeholder: rtl ? 'فكر و غيّر عالَمك' : 'Think and Change the World',
        shareOptionsModelType: 'home',
        feedsSystemCategory: 'home',
      }}
    />
  )
}

export default HomeShare
