import withStyles from '@mui/styles/withStyles'
import React, { useState, useEffect } from 'react'

const styles = () => ({
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    overflowX: 'auto',
    display: 'flex',
    position: 'sticky',
    width: '100%',
    zIndex: 9,
    height: 40,
  },
  title: {
    width: '100%',
    height: 20,
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapper1: {
    width: '100%',
    overflowX: 'auto',
    height: 40,
  },
  wrapper2: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    flexFlow: 'row',
    overflowX: 'auto',
    position: 'relative',
    height: 40,
    minWidth: '100%',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
  },
})

const DynamicTititle = ({ classes, element }) => {
  const [windowSize, setWindowSize] = useState(window.innerWidth)

  useEffect(() => {
    setWindowSize(window.innerWidth)
  }, [window.innerWidth])
  const vwOverflow = ((element.title.length * 10) / windowSize) * 100

  return (
    <section className={classes.titleContainer}>
      <span className={classes.title}>
        <article className={classes.wrapper1}>
          <section
            className={classes.wrapper2}
            style={{
              width: `${vwOverflow}vw`,
            }}
          >
            {element.title}
          </section>
        </article>
      </span>
    </section>
  )
}

const WrappedDynamicTititle = withStyles(styles)(DynamicTititle)

export default WrappedDynamicTititle
