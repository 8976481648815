import { toast } from 'react-toastify'

const ToastifyGeneralError = (error) => {
  const rtl = document.body.dir === 'rtl'
  toast.error(
    <div>
      {rtl
        ? 'حدث خطأ ما. يرجى المحاولة مرة أخرى لاحقًا.'
        : 'An error occurred. Please try again later.'}
    </div>
  )
}

export default ToastifyGeneralError
