import { useTranslation } from 'react-i18next'

const ECommerceBusinessModel = () => {
  const { t } = useTranslation('ECommerceBusinessModel')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 16,
    title: t('title_bm16'),
    subTitle: t('subTitle_bm16'),
    main: t('main_bm16'),
    when: t('when_bm16'),
    who: t('who_bm16'),
    what: t('what_bm16'),
    how: t('how_bm16'),
    why: t('why_bm16'),
    examples: rtl
      ? [
          'أمازون - تأسست 1994 - الولايات المتحدة الأمريكية',
          'Zappos - تأسست عام 1999 - الولايات المتحدة الأمريكية',
          'ASOS - تأسست عام 2000 - إنجلترا',
          'Flyeralarm - تأسست عام 2002',
        ]
      : [
          'Amazon - Founded 1994 - USA',
          'Zappos - Founded 1999 - USA',
          'ASOS - Founded 2000 - England',
          'Flyeralarm - Founded 2002',
        ],
    tags: rtl
      ? ['التوزيع عبر الإنترنت', 'التكلفة المنخفضة', 'السوق العالمية']
      : ['Online Distribution', 'Low Cost', 'Global Market'],
    considerations: t('considerations_bm16'),
    combinations: ['$LBM60', '$LBM54', '$LBM2', '$LBM62'],
    hashTagType: 'businessModelTemp',
  }
}

export default ECommerceBusinessModel
