import { useTranslation } from 'react-i18next'

const OnlineOfflineHybrid = () => {
  const { t } = useTranslation('OnlineOfflineHybrid')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 17,
    title: t('title_bm17'),
    subTitle: t('subTitle_bm17'),
    main: t('main_bm17'),
    when: t('when_bm17'),
    who: t('who_bm17'),
    what: t('what_bm17'),
    how: t('how_bm17'),
    why: t('why_bm17'),
    examples: [],
    tags: rtl
      ? [
          'منتجات باهظة الثمن',
          'تكلفة منخفضة',
          'تجميع المعلومات',
          'تثقيف العملاء',
          'وصول أسهل',
          'فلسفة الانتشار',
        ]
      : [
          'Expensive Products',
          'Low Cost',
          'Gather Information',
          'Educate Customer',
          'Easier Access',
          'Spread Philosophy',
        ],
    considerations: '',
    combinations: ['$LBM13', '$LBM16', '$LBM61'],
    hashTagType: 'businessModelTemp',
    numbers: [],
  }
}

export default OnlineOfflineHybrid
