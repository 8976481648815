import React from 'react'
import PostBodyModel from '../../leftbarDirectoryComponents/feedsModels/PostBodyModel'
import { ShareDialogDescription } from './'

const FreeShare = ({ shareSystemProps }) => {
  const { post, socket, isDarkModeActive } = shareSystemProps
  return (
    <>
      <ShareDialogDescription shareSystemProps={shareSystemProps} />
      <PostBodyModel
        postBodyProps={{
          post,
          socket,
          isDarkModeActive,
        }}
      />
    </>
  )
}

export default FreeShare
