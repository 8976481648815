import React from 'react'
import {
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { Home, Add, List as ListIcon } from '@mui/icons-material'
import { Link, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
  },
  active: {
    backgroundColor: theme.palette.primary.main,
  },
}))

const AdsManagementPageSideBar = ({ handleDrawerClose }) => {
  const location = useLocation()
  const classes = useStyles()

  const isActive = (path) => {
    return location.pathname === path
  }
  const rtl = document.body.dir === 'rtl'
  return (
    <div className={classes.root}>
      <Toolbar />
      <List sx={{ backgroundColor: 'background.paper' }}>
        <ListItem
          onClick={handleDrawerClose}
          component={Link}
          to='/'
          className={isActive('/') ? classes.active : ''}
        >
          <ListItemIcon>
            <Home sx={{ color: 'primary.main' }} />
          </ListItemIcon>
          <ListItemText primary={rtl ? 'الرئيسية' : 'Home'} />
        </ListItem>
        <ListItem
          onClick={handleDrawerClose}
          component={Link}
          to='/manage-ads'
          className={
            isActive('/manage-ads') ? classes.active : ''
          }
        >
          <ListItemIcon>
            <ListIcon sx={{ color: 'primary.main' }} />
          </ListItemIcon>
          <ListItemText primary={rtl ? 'تصفح الحملات' : 'Campaigns Explorer'} />
        </ListItem>
      </List>
    </div>
  )
}

export default AdsManagementPageSideBar
