import axios from 'axios'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NewInquiryFormsConfigs } from '../../../../../../../feedsSystem'
import EditableGridModel from '../../../../../../../formsSystem/EditableGridModel'
import FormDataLangModulator from '../../../../../../../formsSystem/FormDataLangModulator'
import InfoDataIntialStateGen from '../../../../../../../formsSystem/InfoDataIntialStateGen'
import HashTagElementsRefiner from '../../../../../../../SmallComponents/HashTagElementsRefiner'
import TitleHeader from '../../../../../../../SmallComponents/Header'

const InquiryProfileDetails = (props) => {
  const { inquiry, setIsUpdated, setCards, DCGProps, user } = props
  const rtl = document.body.dir === 'rtl'
  const infoArray = NewInquiryFormsConfigs()
    .filter((info) => info.formComponentType === 'text')
    .map((config, index) => ({
      title: config.title,
      titleValue: inquiry[config.title.split('_').join('')] || '',
      allowEmpty: true,
      helpertext: config.helpertext,
      wideMode: index !== 0,
      minRows: config.minRows,
      placeholder: config.placeholder,
    }))

  const [infoData, setInfoData] = useState(InfoDataIntialStateGen(infoArray))
  const { t } = useTranslation()
  const newInfoData = FormDataLangModulator(infoData, t)

  const [chosenHashTagElements, setChosenHashTagElements] = useState(
    HashTagElementsRefiner(inquiry) || []
  )
  const onSave = async () => {
    if (inquiry?._id) {
      try {
        const res = await axios.patch(`/inquiries/${inquiry?._id}`, {
          ...newInfoData,
          chosenHashTagElements,
        })
        setCards((inqs) => [
          ...inqs.filter((p) => p._id !== inquiry?._id),
          res.data.inquiry,
        ])
      } catch (error) {
        console.log(error)
      }
      setIsUpdated(true)
    }
  }

  const gridProps = {
    ...props,
    ...DCGProps,
    hideEditingTools: inquiry?.userId !== user?._id,
    infoArray,
    setChosenHashTagElements,
    chosenHashTagElements,
    hideHeader: true,
    infoData,
    setInfoData,
    onSave,
  }
  return (
    <>
      <TitleHeader headerText={rtl ? 'تفاصيل' : 'Inquiry Details'} />
      <EditableGridModel gridProps={gridProps} />
    </>
  )
}

export default InquiryProfileDetails
