import React from 'react'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  suggestCardWrapper: {
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'auto',
    width: 'calc(100vw-20px)',
    borderRadius: '0',
    marginTop: '5px',
  },
})

const NavigatorCardsMob = ({ classes, units, isDarkModeActive }) => {
  return (
    <section
      className={classes.suggestCardWrapper}
      style={{
        color: isDarkModeActive ? 'white' : null,
        background: isDarkModeActive ? 'rgb(49, 48, 53, 0.43)' : null,
      }}
    >
      {units}
    </section>
  )
}

const WrappedNavigatorCardsMob = withStyles(styles)(NavigatorCardsMob)

export default WrappedNavigatorCardsMob
