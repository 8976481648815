import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import EditableCardsWindow from '../../../../../../../fileSystem/EditableCardsWindow'
import TitleHeader from '../../../../../../../SmallComponents/Header'
import NewResearchApplicationForm from './ResearchActiveResourcesComps/NewResearchApplicationForm'
import ResearchApplicationContent from './ResearchActiveResourcesComps/ResearchApplicationContent'
import { AuthContext } from '../../../../../../../../context/AuthContext'

const ResearchApplications = ({ DCGProps, activity, depManager }) => {
  const [isUpdated, setIsUpdated] = useState(false)
  const { project } = DCGProps
  const [resActApps, setResActApps] = useState([])
  const [isLoading, setIsLoading] = useState()
  const { user } = useContext(AuthContext)

  useEffect(() => {
    const fetchResActApps = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(
          `/researchApps/ResActResearchApps/${activity._id}`
        )
        setResActApps(res.data.researchApps)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    if (project?._id) fetchResActApps()
  }, [project?._id, isUpdated])

  const modResearchApps = resActApps?.map((researchactivity) => ({
    ...researchactivity,
    id: researchactivity?._id,
  }))
  const rtl = document.body.dir === 'rtl'
  const authorised =
    user?._id === depManager?.userId || user?._id === project?.userId
  return (
    <>
      <TitleHeader headerText={rtl ? 'تطبيقات' : 'Applications'} />
      <EditableCardsWindow
        showAddFab={authorised}
        cards={modResearchApps}
        DCGProps={DCGProps}
        StandardCardContent={ResearchApplicationContent}
        NewElementFormComponentProps={{
          setResActApps,
          activity,
        }}
        profileProps={{ setResActApps, depManager }}
        SearchIDFExtConfigs={{
          researchApp: true,
        }}
        newFormDialogTitle={rtl ? 'أضف تطبيقاَ جديداَ' : 'Add New Application'}
        placeholderValue={rtl ? 'تطبيقات' : 'Applications'}
        NewElementFormComponent={NewResearchApplicationForm}
        wrpperStyle={{
          maxHeight: 500,
          minHeight: 250,
          overflowY: 'auto',
        }}
      />
    </>
  )
}

export default ResearchApplications
