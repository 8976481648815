import { Button } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

const UpImageButton = ({ imageIcon, imagesHandler, isLoading }) => {
  const { t } = useTranslation()
  return (
    <Button
      component='label'
      color='inherit'
      style={{
        padding: '0px',
        textTransform: 'none',
        backgroundColor: 'transparent',
        marginLeft: -15,
        marginRight: -15,
      }}
      sx={{
        fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
      }}
      disabled={isLoading}
    >
      {isLoading ? t('loading') : imageIcon}
      <input
        type='file'
        hidden
        onChange={imagesHandler}
        accept='image/png, image/gif, image/jpeg, image/jpg'
      />
    </Button>
  )
}

export default UpImageButton
