import React from 'react'
import PostProps from './PostProps'
import HomePostConatinerModel from './HomePostConatinerModel'
import ChartPost from '../../../postSystem/ChartPost'
import TextPost from '../../../postSystem/TextPost'
import HomeChartPostMiddle from './HomeChartPostComponents/HomeChartPostMiddle'
import HomeChartPostBottom from './HomeChartPostComponents/HomeChartPostBottom'

const HomeChartPost = ({ postProps }) => {
  return (
    <HomePostConatinerModel
      postProps={{
        ...postProps,
        // for opeoning the post from the top of the shared post
        postBody: <ChartPost {...postProps} />,
        postText: <TextPost {...postProps} />,
      }}
      mainBody={
        <>
          <TextPost margins='15px' {...postProps} />
          <HomeChartPostMiddle
            postProps={PostProps(postProps, { commentsDialogProps: true })}
          />
          <HomeChartPostBottom postProps={postProps} />
        </>
      }
    />
  )
}

export default HomeChartPost
