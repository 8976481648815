import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../../context/AuthContext'
import ButtonsSystem from '../../../buttonsSystem/ButtonsSystem'
import NotificationCreator from '../../../notificationsSystem/NotificationCreator'
import TitleHeader from '../../../SmallComponents/Header'
import FileSystemGetOutAction from '../../FileSystemGetOutAction'
import ToastifyGeneralError from '../../../SmallComponents/ToastifyGeneralError'
import { toast } from 'react-toastify'

const RemoveProjectContributorDialogContent = ({
  noFSPanelProps,
  windowCloser,
}) => {
  const { socket, member, project, isDarkModeActive } = noFSPanelProps
  const rtl = document.body.dir === 'rtl'
  const { user } = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(false)
  const [tasksCount, setTasksCount] = useState(0)
  const [contributor, setContributor] = useState(null)
  const [ready, setReady] = useState(false)
  const [isChecking, setIsChecking] = useState(true)
  useEffect(() => {
    const fetchData = async () => {
      setIsChecking(true)
      try {
        const res = await axios.get(
          `/projectContributors/contractEnding/contributorTies/${project?._id}/${member?.userId}`
        )
        setTasksCount(res.data.tasksCount)
        setContributor(res.data.contributor)
        setReady(res.data.ready)
      } catch (error) {
        console.log(error)
      }
      setIsChecking(false)
    }
    if (project?._id && member?.userId) fetchData()
  }, [member?.userId, project?._id])
  const buttonsArray = [
    {
      title: rtl ? 'إلغاء' : 'Cancel',
      funs: windowCloser,
      disabled: isLoading,
    },
    {
      title: rtl
        ? isChecking
          ? 'جارى مراجعة البيانات'
          : isLoading
          ? 'جارى تنشيط بروتوكول الإلغاء'
          : 'تأكيد'
        : isChecking
        ? 'Checking Contributor Data'
        : isLoading
        ? 'Processing Contract Breaking Protocol'
        : 'Confirm',
      disabled: isLoading || !ready || isChecking,
      funs: async () => {
        try {
          setIsLoading(true)
          const res = await axios.get(`/contracts/${member?.contractId}`)
          if (res.data.contract) {
            socket.current.emit('addNotifUser', member?.userId)
            NotificationCreator({
              associativity: 'activeContract',
              relation: 'contributor',
              conditionIndex: 0,
              accompaniedData: {
                projectId: project?._id,
                elements: [
                  {
                    title: res.data.contract?.Title,
                    id: res.data.contract?._id,
                    hashTagType: res.data.contract?.hashTagType,
                  },
                ],
              },
              category: 'deals',
              userId: member?.userId,
              currentUser: user,
              socket,
            })
            setIsLoading(false)
            windowCloser()
            FileSystemGetOutAction(noFSPanelProps)
          }
          toast.success(
            rtl
              ? 'تم ارسال اشعار الى المشارك و سوف تتلقى رد منه'
              : 'A notifications is sent to the contributor and you will receive a response'
          )
        } catch (error) {
          ToastifyGeneralError(error)
        }
      },
    },
  ]
  return (
    <article style={{ color: isDarkModeActive && 'white' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          width: '100%',
        }}
      >
        <TitleHeader
          headerText={
            rtl ? 'إنهاء عقد التشارك' : 'Ending Contribution Contract'
          }
        />
      </div>
      <div>
        <p>
          {rtl
            ? 'يتطلب إزالة المساهم من المشروع إغلاق جميع الارتباطات المرتبطة به والتأكد من عدم توليه منصب مدير لأي قسم أو قسم فرعي.'
            : 'Removing a contributor from the project requires closing all the associated ties and ensuring that they are not a manager of any department or subdepartment.'}
        </p>
        <div style={{ display: ready ? 'none' : null }}>
          <p>
            {rtl
              ? 'يرجى مراجعة هذه القيود للمتابعة:'
              : 'Please review these restrictions before proceeding:'}
          </p>
          {tasksCount > 0 || contributor?.departmentManager ? (
            <p>
              {rtl
                ? 'المساهم لا يمكن إزالته، لأن'
                : 'The contributor cannot be removed because'}
              {tasksCount > 0 && rtl
                ? `إنه مسؤول عن تنفيذ ${tasksCount} مهام.`
                : tasksCount > 0
                ? ` they are responsible for completing ${tasksCount} tasks.`
                : ''}
              {tasksCount > 0 && contributor?.departmentManager && rtl
                ? 'و'
                : contributor?.departmentManager
                ? 'They are also a department manager.'
                : ''}
            </p>
          ) : null}
          {!tasksCount &&
          !contributor?.departmentManager &&
          contributor?.subDepManager ? (
            <p>
              {rtl
                ? 'المساهم لا يمكن إزالته، لأنه مدير قسم فرعي.'
                : 'The contributor cannot be removed because they are a subdepartment manager.'}
            </p>
          ) : null}
        </div>
        <p>
          {rtl
            ? 'يجب عدم تولي المساهم منصب مدير لأي قسم أو قسم فرعي. الأعضاء العاديون فقط مسموح بهم.'
            : 'The contributor should not hold any position as a manager of any department or subdepartment.'}
        </p>
        <p>
          {rtl
            ? 'عند المتابعة، سيتم إرسال إشعار إلى المساهم بطلبك، وبمجرد الموافقة منه، سيتم إتمام العملية وسيتم فسخ العقد الحالي الذي يربطه بالمشروع.'
            : 'By proceeding, a notification will be sent to the contributor with your request. Once they agree, the process will be completed, and the current contract connecting them to the project will be terminated.'}
        </p>
      </div>
      <ButtonsSystem buttonsArray={buttonsArray} />
    </article>
  )
}

export default RemoveProjectContributorDialogContent
