import { useTranslation } from 'react-i18next'

const CrossSellingBusinessModel = () => {
  const { t } = useTranslation('CrossSellingBusinessModel')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 9,
    title: t('title_bm09'),
    subTitle: t('subTitle_bm09'),
    main: t('main_bm09'),
    when: t('when_bm09'),
    who: t('who_bm09'),
    what: t('what_bm09'),
    how: t('how_bm09'),
    why: t('why_bm09'),
    examples: rtl
      ? [
          'شل الملكية الهولندية (شل) - تأسست عام 1907 - شركة النفط والغاز',
          'ايكيا - تأسست عام 1943 - شركة سويدية',
          'تشيبو - تأسست عام 1949 - تاجر تجزئة للقهوة وسلسلة قهوة ألمانية',
        ]
      : [
          'Royal Dutch Shell (Shell) - Founded 1907 - Oil & Gas Company',
          'IKEA - Founded 1943 - Swedish Company',
          'Tchibo - Founded 1949 - German coffee retailer and coffee chain',
        ],
    tags: rtl
      ? ['العملاء المعاد استخدامهم', 'خدمة كاملة', 'التركيز على العملاء']
      : [
          'Reused Customers',
          'Satisfaction',
          'Complete Service',
          'Customer Focus',
          'Teach Customer',
        ],
    considerations: t('considerations_bm09'),
    combinations: ['$LBM65', '$LBM66', '$LBM22'],
    hashTagType: 'businessModelTemp',
  }
}

export default CrossSellingBusinessModel
