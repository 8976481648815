import React from 'react'
import TimeAgoFormattor from '../../../SmallComponents/TimeAgoFormattor'
import PostPrivacyFlag from './PTLeftFlagComps/PostPrivacyFlag'
import PostTypeFlag from './PTLeftFlagComps/PostTypeFlag'

const PTLeftFlags = ({ postProps, wz }) => {
  const { post } = postProps
  const rtl = document.body.dir === 'rtl'
  return (
    <div style={{ fontSize: wz ? 12 : 10 }}>
      <PostTypeFlag postProps={postProps} />
      {post?.Promoted ? (
        <>{rtl ? 'مُمٌول' : 'Sponsered'}</>
      ) : (
        <>
          <TimeAgoFormattor date={post?.createdAt} />
          <PostPrivacyFlag postProps={postProps} />
        </>
      )}
    </div>
  )
}

export default PTLeftFlags
