import { Chip } from '@mui/material'
import React from 'react'

const SmallButtonWrapper = ({ button, isDarkModeActive, hide }) => {
  return (
    !hide && (
      <Chip
        label={button}
        style={{
          color: 'white',
          cursor: 'pointer',
          background: isDarkModeActive
            ? 'rgba(31,126,222, 0.6)'
            : 'rgba(31,126,222, 0.4)',
          margin: 'auto 2px',
          fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
        }}
      />
    )
  )
}

export default SmallButtonWrapper
