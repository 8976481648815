import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { Button } from '@material-ui/core'
import { MdUpload } from 'react-icons/md'
import TitleHeader from '../../../SmallComponents/Header'
import CustomCircularProgress from '../../../SmallComponents/CustomCircularProgress'

const styles = () => ({
  defaultButtonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
})

const UploadButton = ({
  classes,
  filesHnalder,
  UploadButtonComponent,
  cutimizedUpload,
  files,
  maxFilesNumber,
  uploadHeaderText,
  isLoading,
}) => {
  const upladingComponents = [
    <section className={classes.defaultButtonWrapper}>
      <TitleHeader headerText={uploadHeaderText} />
      <Button
        disabled={Boolean(files?.length === maxFilesNumber && maxFilesNumber)}
        component='label'
        color='inherit'
        style={{
          padding: '0px',
          textTransform: 'none',
          backgroundColor: 'transparent',
        }}
      >
        <MdUpload size={26} style={{ margin: '3px auto -3px auto' }} />
        <input
          type='file'
          hidden
          onChange={filesHnalder}
          accept='application/pdf'
        />
      </Button>
    </section>,
    <Button
      component='label'
      color='inherit'
      style={{
        textTransform: 'none',
        padding: '1px',
        backgroundColor: 'transparent',
      }}
    >
      <input
        type='file'
        hidden
        onChange={filesHnalder}
        accept='application/pdf'
      />
      {isLoading ? <CustomCircularProgress size={15} /> : UploadButtonComponent}
    </Button>,
  ]
  return cutimizedUpload ? upladingComponents[1] : upladingComponents[0]
}

const WrappedUploadButton = withStyles(styles)(UploadButton)

export default WrappedUploadButton
