import { Avatar } from '@mui/material'
import React from 'react'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  listItem: {},
  avatar: {
    cursor: 'pointer',
    width: '30px !important',
    height: '30px !important',
  },
  name: {
    fontSize: 14,
    fontWeight: 400,
    cursor: 'pointer',
  },
  '@media (max-width)': {
    listItem: {
      marginBottom: '-2px !important',
    },
    name: {
      fontSize: 12,
      fontWeight: 400,
    },
    avatar: {
      width: '25px !important',
      height: '25px !important',
    },
  },
})

const UserListItemCard = ({ classes, element }) => {
  const rtl = document.body.dir === 'rtl'
  return (
    <span
      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
    >
      <Avatar
        src={element?.image}
        className={classes.avatar}
        sx={{
          margin: rtl ? '5px 0 5px 5px' : '5px 5px 5px 0',
        }}
      />
      <span className={classes.name}>{element?.name}</span>
    </span>
  )
}

const WrappedUserListItemCard = withStyles(styles)(UserListItemCard)

export default WrappedUserListItemCard
