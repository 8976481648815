import { useTranslation } from "react-i18next"

const LatentNeeds = () => {
  const { t } = useTranslation('LatentNeeds')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 9,
    category: t('category_ms09'),
    title: t('title_ms09'),
    subTitle: t('subTitle_ms09'),
    main: t('main_ms09'),
    how: t('how_ms09'),
    usedInModels: [],
    tags: rtl
      ? [
          'تحليل احتياجات العملاء',
          'البحث عن المتجر',
          'رؤية العميل',
          'ميزة تنافسية',
          'ابتكار المنتجات',
        ]
      : [
          'Customer Needs Analysis',
          'Market Research',
          'Customer Insight',
          'Competitive Advantage',
          'Product Innovation',
        ],
    examples: t('examples_ms09'),
    hashTagType: 'markStraAspect',
  }
}

export default LatentNeeds
