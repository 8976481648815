import { Button } from '@mui/material'
import React, { useState } from 'react'
import ImageUploaderSystem from '../uploadImageSystem/ImageUploaderSystem'
import { MdCamera } from 'react-icons/md'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  button: {
    borderRadius: '10px !important',
    margin: '2px !important',
    textTransform: 'none !important',
    fontWeight: '550 !important',
    width: '100% !important',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important",
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
})

const FormImagesUploader = ({ classes, images, setImages, pathName }) => {
  return (
    <>
      <section className={classes.buttonsWrapper}>
        <Button variant='outlined' className={classes.button}>
          <ImageUploaderSystem
            imageIcon={<MdCamera size={20} />}
            funtionalityIndex={0}
            images={images}
            setImages={setImages}
            pathName={pathName}
          />
        </Button>
      </section>
      <ImageUploaderSystem
        funtionalityIndex={1}
        images={images}
        setImages={setImages}
        pathName={pathName}
      />
    </>
  )
}

const WrappedFormImagesUploader = withStyles(styles)(FormImagesUploader)

export default WrappedFormImagesUploader