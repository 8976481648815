import React from 'react'
import { BsTools } from 'react-icons/bs'
import { GrBarChart, GrDocker } from 'react-icons/gr'
import { GiCoins, GiArchiveResearch, GiAbstract010 } from 'react-icons/gi'
import { useTranslation } from 'react-i18next'

const ProjectDepartments = () => {
  const { t } = useTranslation()
  const rtl = document.body.dir === 'rtl'
  const iconTitleStyle = { display: 'flex', alignItems: 'center' }
  const iconStyle = { margin: 10 }

  return [
    {
      name: (
        <span style={iconTitleStyle}>
          <BsTools style={iconStyle} />
          {t('Production_Department')}
        </span>
      ),
      tippyContent: rtl
        ? 'قسم الإنتاج هو المكان الذي من المفترض أن يتم تصنيع أو إنتاج معظم المنتجات'
        : 'Production Department is where most of products are meant to be synthesised or produced',
    },
    {
      name: (
        <span style={iconTitleStyle}>
          <GrBarChart style={iconStyle} />
          {t('Marketing_Department')}
        </span>
      ),
      tippyContent: rtl
        ? 'قسم التسويق هو المكان الذي يمكن فيه لفريق التسويق إجراء الحملات والأنشطة الأخرى المتعلقة بترويج المبيعات'
        : 'Marketing Department is where a marketing team can conduct campaigns and other sales promotion related activities',
    },
    {
      name: (
        <span style={iconTitleStyle}>
          <GrDocker style={iconStyle} />
          {t('Adminstration_Department')}
        </span>
      ),
      tippyContent: rtl
        ? 'من المفترض أن يشمل قسم الإدارة الرئيس التنفيذي وكبار المسؤولين التنفيذيين الآخرين الذين يشرفون على التوجيه الاستراتيجي وإدارة المنظمة.'
        : 'Administration department is supposed to include the CEO and other senior executives who oversee the strategic direction and management of the organization.',
    },
    {
      name: (
        <span style={iconTitleStyle}>
          <GiArchiveResearch style={iconStyle} />
          {t('R&D_Department')}
        </span>
      ),
      tippyContent: rtl
        ? '"قسم البحث والتطوير مسؤول عن إنشاء وتحسين المنتجات أو الخدمات القائمة على الابتكار العلمي والتكنولوجي."'
        : 'Research and development department is responsible for creating and improving products or services based on scientific and technological innovation.',
    },
    {
      name: (
        <span style={iconTitleStyle}>
          <GiCoins style={iconStyle} />
          {t('Financial_Department')}
        </span>
      ),
      tippyContent: rtl
        ? 'تتولى الإدارة المالية مهام المحاسبة والميزنة وإعداد التقارير والتدقيق في المنظمة.'
        : 'Financial department handles the accounting, budgeting, reporting and auditing functions of the organization.',
    },
    {
      name: (
        <span style={iconTitleStyle}>
          <GiAbstract010 style={iconStyle} />
          {t('Legal_Department')}
        </span>
      ),
      tippyContent: rtl
        ? ''
        : 'Legal department provides legal advice and representation to the organization on various matters such as contracts, compliance, disputes and regulations.',
    },
  ]
}

export default ProjectDepartments
