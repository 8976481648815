import { useTranslation } from "react-i18next"

const SegmentPriotirization = () => {
  const { t } = useTranslation('SegmentPriotirization')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 25,
    category: t('category_ms24'),
    title: t('title_ms24'),
    subTitle: t('subTitle_ms24'),
    main: t('main_ms24'),
    how: t('how_ms24'),
    usedInModels: [],
    tags: rtl
      ? [
          'ترتيب الشرائح',
          'المواقع التنافسية',
          'مفهوم العلامة التجارية',
          "توقعات العملاء'",
        ]
      : [
          'Segment Prioritization',
          'Competitive Positioning',
          'Brand Concept',
          'Customer Expectations',
        ],
    examples: t('examples_ms24'),
    hashTagType: 'markStraAspect',
  }
}

export default SegmentPriotirization
