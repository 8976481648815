import React from 'react'
import MiniFSCardInsideModel from '../MiniFSCardInsideModel'
import MFSInsideModel from './MFSInsideModel'

const IndexDependentMFSInsideView = ({
  MFSGProps,
  folderPathProps,
  searchSystemProps,
  modelProps,
  noFSPanelPropsSecondView,
  noFSPanelPropsFirstView,
}) => {
  const { MiniFSModelIndex, cards, cardId, setCardId } = MFSGProps

  const viewComponents = cards.map((component) =>
    // for the second model, see project modeling
    MiniFSModelIndex === 0 ? (
      <MFSInsideModel
        MFSGProps={MFSGProps}
        component={component}
        searchSystemProps={searchSystemProps}
        folderPathProps={folderPathProps}
        noFSPanelPropsSecondView={noFSPanelPropsSecondView}
        modelIProps={modelProps}
      />
    ) : (
      <MiniFSCardInsideModel
        component={component}
        searchSystemProps={searchSystemProps}
        MFSGProps={MFSGProps}
        folderPathProps={folderPathProps}
        noFSPanelPropsSecondView={noFSPanelPropsSecondView}
      />
    )
  )
  return viewComponents[cardId]
}

export default IndexDependentMFSInsideView
