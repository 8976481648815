import React, { useContext } from 'react'
import GalleryInfoProfileTopModel from '../../GalleryInfoProfileTopModel'
import axios from 'axios'
import DefaultChannelButton from '../../../../../../../channelsSystem/DefaultChannelButton'
import { AuthContext } from '../../../../../../../../context/AuthContext'

const InquiryProfileTop = ({ inquiry, DCGProps, setCards }) => {
  const rtl = document.body.dir === 'rtl'
  const { user } = useContext(AuthContext)
  const galleryProps = {
    hideEdit: user?._id !== inquiry?.userId,
    element: inquiry,
    DCGProps,
    emptyGalleryTitle: rtl ? 'هذا المتطلب' : 'Inquiry',
    pathName: `/users/${inquiry?.userId}/inquiries`,
    setElements: setCards,
    patchPath: `inquiries`,
    updateElementImages: async (image) => {
      await axios.patch(`/inquiries/${inquiry?._id}`, {
        images: inquiry?.images?.filter((im) => im === image),
      })
    },
    CustomTopContent: (
      <div
        style={{
          marginTop: 10,
          display:
            inquiry?.pairedUserId === user?._id || inquiry?.userId === user?._id
              ? null
              : 'none',
        }}
      >
        <DefaultChannelButton
          element={inquiry}
          DCGProps={DCGProps}
          onGetOut={async () => {
            try {
              await axios.patch(`/inquiries/${inquiry?._id}`, {
                pairedUserId: null,
              })
            } catch (error) {
              console.log(error)
            }
          }}
        />
      </div>
    ),
  }

  return <GalleryInfoProfileTopModel galleryProps={galleryProps} />
}

export default InquiryProfileTop
