import React, { useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import NoteBody from './ManagerNoteModelComponents/NoteBody'
import NoteTop from './ManagerNoteModelComponents/NoteTop'

const styles = () => ({
  noteCard: {
    borderRadius: '10px',
    padding: '10px ',
    margin: '10px 0 10px 0',
  },
  '@media (max-width: 600px)': {
    noteCard: {
      margin: '10px 0 10px 0',
    },
  },
})

const ManagerNoteModel = ({ classes, notesProps }) => {
  const {
    managersProfiles,
    DCGProps,
    note,
    // coming from the profile
    managerProfile,
  } = notesProps
  const { isDarkModeActive } = DCGProps
  const [editNoEditIndex, setEditNoEditIndex] = useState(0)
  const managerProf =
    managersProfiles?.find((prof) => prof._id === note?.managerId) ||
    managerProfile

  const [noteUpdateData, setNoteUpdateData] = useState({})
  const notesPropsII = {
    ...notesProps,
    setEditNoEditIndex,
    editNoEditIndex,
    noteUpdateData,
    managerProfile: managerProf,
    setNoteUpdateData,
  }
  return (
    <article
      className={classes.noteCard}
      style={{
        color: isDarkModeActive && 'white',
        background: isDarkModeActive
          ? 'rgba(31,126,222, 0.1)'
          : 'rgba(0,0,0,0.1)',
      }}
    >
      <NoteTop notesProps={notesPropsII} />
      <NoteBody notesProps={notesPropsII} />
    </article>
  )
}

const WrappedManagerNoteModel = withStyles(styles)(ManagerNoteModel)

export default WrappedManagerNoteModel
