import React from 'react'
import withStyles from '@mui/styles/withStyles'
import CommentModel from './CommentModel'
import NewCommentModule from './NewCommentModule'
import InfiniteScroll from 'react-infinite-scroll-component'
import CommentsDialogCore from './CommentsDialogCore'
import DNALoader from '../../SmallComponents/DNALoader'

const styles = () => ({
  cardBottom: {
    height: 30,
    width: ' 100%',
    display: 'flex',
  },
  panelWrapper: {
    width: '100%',
    height: '60px !important',
    display: 'flex',
    justifyContent: 'space-around !important',
    alignItems: 'center !important',
    alignContent: 'center !important',
  },
})

const CommentingPCVersion = ({ classes, commentProps }) => {
  const { postComments, setPage, currentResponse } = commentProps
  return (
    <>
      <CommentsDialogCore commentProps={commentProps} />
      <section className={classes.panelWrapper}>
        <NewCommentModule {...commentProps} />
      </section>
      <InfiniteScroll
        dataLength={postComments.length}
        next={() => setPage((prev) => prev + 1)}
        hasMore={currentResponse}
        scrollableTarget={'pc-scroll-right'}
        loader={<DNALoader />}
      >
        {postComments?.map((comment) => (
          <CommentModel
            comment={comment}
            key={comment?._id}
            {...commentProps}
          />
        ))}
      </InfiniteScroll>
    </>
  )
}

const WrappedCommentingPCVersion = withStyles(styles)(CommentingPCVersion)

export default WrappedCommentingPCVersion
