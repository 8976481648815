import React from 'react'
import ModelDialogWrappingII from '../dialogModelUnit/ModelDialogWrappingII'
import BookDialogContent from './BookDialogModelComponents/BookDialogContent'

const BookDialogModel = (props) => {
  const {
    bookDialogTitlte,
    bookDialogClose,
    isBookDialogOpen,
    clickAwayState,
    externalLinkMode,
  } = props
  const dialogsArray = [
    {
      title: bookDialogTitlte,
      closeFun: () => bookDialogClose(),
      open: isBookDialogOpen,
      content: <BookDialogContent {...props} />,
    },
  ]
  return externalLinkMode ? (
    <BookDialogContent {...props} />
  ) : (
    <ModelDialogWrappingII
      dialogsArray={dialogsArray}
      dialogMargins={'auto -20px auto -20px'}
      mdialogMargins={'auto -24px auto -24px'}
      comentingSystemOpen
      {...props}
      inactivateClickAway={clickAwayState}
    />
  )
}

export default BookDialogModel
