import React from 'react'
import FullInteractiveRequestDetailsModel from '../../../../../../../notificationsSystem/FullInteractiveRequestDetailsModel'

const InvestPackRequestDetails = (props) => {
  const { card, exprops, requestsUsers } = props
  const { pack } = exprops
  const rtl = document.body.dir === 'rtl'
  const request = card
  const requestingUser = requestsUsers?.find((u) => u?._id === request?.userId)

  return (
    <FullInteractiveRequestDetailsModel
      RequestText={
        <section>
          {requestingUser?.firstName + ' ' + requestingUser?.lastName}
          {rtl
            ? 'قدّم لهذه الباقة الاستثمارية'
            : 'applied for this Investment Package'}
        </section>
      }
      NotificationCreatorAcceptProps={{
        associativity: 'investment',
        relation: 'visitor',
        conditionIndex: 0,
        category: 'contributors',
        elements: [
          {
            hashTagType: pack?.hashTagType,
            id: pack?._id || pack?.id,
            title: pack?.Title || pack?.title,
          },
        ],
      }}
      NotificationCreatorRefuseProps={{
        associativity: 'investment',
        relation: 'visitor',
        conditionIndex: 0,
        category: 'contributors',
        elements: [
          {
            hashTagType: pack?.hashTagType,
            id: pack?._id || pack?.id,
            title: pack?.Title || pack?.title,
          },
        ],
      }}
      contributorProps={{
        projectId: pack?.projectId,
        MembershipState: 'Investor',
      }}
      requestingUser={requestingUser}
      {...props}
    />
  )
}

export default InvestPackRequestDetails
