import { useTranslation } from 'react-i18next'

const BuyerPower = () => {
  const { t } = useTranslation('BuyerPower')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 19,
    category: t('category_ms19'),
    title: t('title_ms19'),
    subTitle: t('subTitle_ms19'),
    main: t('subTitle_ms19'),
    how: t('how_ms19'),
    usedInModels: ['$LBM4', '$LBM54', '$LBM12'],
    tags: rtl
      ? [
          'مسابقة',
          'الموردون',
          'المشترون',
          'قوة السوق',
          'الموردين',
          'التسعير',
          'القدرة على المساومة',
          'القوة الشرائية العقلانية',
        ]
      : [
          'Competition',
          'Suppliers',
          'Buyers',
          'Market Power',
          'Supply Chain',
          'Pricing',
          'Bargaining Power',
          'Rational Buying Power',
        ],
    examples: t('examples_ms19'),
    hashTagType: 'markStraAspect',
  }
}

export default BuyerPower
