import React from 'react'
import withStyles from '@mui/styles/withStyles'
import '../../skeleton.css'
import FreeBar from '../FreeBar'
import { Grid } from '@mui/material'

const styles = () => ({
  profileTopWrapper: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'space-between',
    height: 'auto',
  },
  defaultViewWrapper: {
    width: '100%',
    height: 'auto',
    marginBottom: '5px',
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    flexDirection: 'column',
    width: '100%',
    display: 'flex',
  },
  name: {
    fontWeight: '600',
    fontSize: '20px',
  },

  followChatOptionsIcons: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '5px auto 0 auto',
    width: '80%',
  },
  followChatOptionsIconsItem: {
    fontSize: 21,
    padding: 5,
    cursor: 'pointer',
  },

  middleCardWrapper: {
    display: 'flex',
    marginTop: 10,
    flexDirection: 'column',
    height: 80,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  cardContainer: {
    height: '50%',
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  cardWrapper: {
    width: '100% !important',
    height: '97% !important',
    borderRadius: '15px !important',
    display: 'flex',
    justifyContent: 'center !important',
    alignContent: 'center !important',
    alignItems: 'center !important',
    backgroundColor: '#313131',
    margin: 4,
  },
  '@media (max-width: 600px)': {
    cardWrapper: {
      width: '100% !important',
      height: '50px!important',
      borderRadius: '0px !important',
      display: 'flex',
      justifyContent: 'center !important',
      alignContent: 'center !important',
      alignItems: 'center !important',
    },
  },
})

const MProfileTopSkeleton = ({ classes, hide }) => {
  const rtl = document.body.dir === 'rtl'
  return (
    <article className={classes.profileTopWrapper}>
      <section className={classes.defaultViewWrapper}>
        <article className={classes.container}>
          <span
            style={{
              position: 'absolute',
              top: 160,
              animation: 'skeleton 1s ease infinite alternate',
            }}
          >
            <div
              className='postSkAvatar'
              style={{ width: 150, height: 150 }}
            ></div>
          </span>
          <div
            className={classes.middleCardWrapper}
            style={{
              animation: 'skeleton 1s ease infinite alternate',
              paddingLeft: !rtl && 150,
              paddingRight: rtl && 150,
            }}
          >
            <section
              className={classes.followChatOptionsIcons}
              style={{ display: hide ? 'none' : 'flex' }}
            >
              {Array.from({ length: 4 }, (_, index) => (
                <span key={index} style={{ width: '100%', margin: 10 }}>
                  <FreeBar height={30} />
                </span>
              ))}
            </section>
            <span
              className={classes.name}
              style={{
                width: '100%',
                backgroundColor: '#313131',
                borderRadius: 25,
              }}
            >
              <FreeBar height={25} />
            </span>
          </div>
        </article>
        <article className={classes.overViewContainer}>
          <Grid
            container
            style={{
              height: '100%',
              width: '100%',
              animation: 'skeleton 1s ease infinite alternate',
            }}
          >
            {Array.from({ length: 4 }, (_, index) => (
              <Grid item xs={6} className={classes.cardContainer} key={index}>
                <div className={classes.cardWrapper}></div>
              </Grid>
            ))}
          </Grid>
        </article>
      </section>
    </article>
  )
}

const WrappedMProfileTopSkeleton = withStyles(styles)(MProfileTopSkeleton)

export default WrappedMProfileTopSkeleton
