import React from 'react'
import FeedsSystem from '../../../../feedsSystem/FeedsSystem'
import withStyles from '@mui/styles/withStyles'
import DeletedSharedPost from './DeletedSharedPost'

const styles = () => ({
  sharedContentWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    padding: 10,
    marginTop: '-10px',
    paddingTop: 0,
  },
})

const SharedPostMiddle = ({ classes, postProps }) => {
  const { isDarkModeActive, sharedPost, sharedPostUser, socket, setPosts } =
    postProps
  return (
    <section className={classes.sharedContentWrapper}>
      {sharedPost ? (
        <FeedsSystem
          feedsSystemProps={{
            // data of the post to be shared
            post: sharedPost,
            isUsedIn: 'shareSystem',
            hideRightOptions: true,
            hideBottom: true,
            isDarkModeActive,
            user: sharedPostUser,
            socket,
            hideAddUserIcon: true,
            setPosts,
          }}
        />
      ) : (
        <DeletedSharedPost />
      )}
    </section>
  )
}

const WrappedSharedPostMiddle = withStyles(styles)(SharedPostMiddle)

export default WrappedSharedPostMiddle
