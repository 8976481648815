import React, { useEffect, useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import TimeAgoFormattor from '../../../../../../../SmallComponents/TimeAgoFormattor'
import CentralTitle from '../../../../../../../SmallComponents/CentralTitle'
import axios from 'axios'
import Loading from '../../../../../../../SmallComponents/Loading'

const styles = () => ({
  creationDate: {
    fontSize: 11,
    fontWeight: 500,
  },
  wrapper: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  subdepName: {
    fontSize: 12,
    fontWeight: 500,
  },
})

const ReserachActivityTop = ({
  classes,
  activity,
  setIsUpdated,
  depManager,
  DCGProps,
}) => {
  const [subDep, setSubDep] = useState()
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    const fetchSubDep = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(`/subdepartments/${activity?.subDepId}`)
        setSubDep(res.data.subdepartment)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    fetchSubDep()
  }, [activity?.subDepId])
  return (
    <div className={classes.wrapper}>
      <CentralTitle title={activity?.Title} />
      <section
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center',
          alignItems: 'center',
        }}
      >
        <span className={classes.subdepName}>
          {isLoading ? <Loading /> : subDep?.Name}
        </span>
        <span className={classes.creationDate}>
          <TimeAgoFormattor date={activity?.createdAt} />
        </span>
      </section>
    </div>
  )
}

const WrappedReserachActivityTop = withStyles(styles)(ReserachActivityTop)

export default WrappedReserachActivityTop
