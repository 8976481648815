import React, { useState } from 'react'
import ButtonsSystem from '../../../buttonsSystem/ButtonsSystem'
import FormsComponents from '../../../formsSystem/FormsComponents'
import CentralTitle from '../../../SmallComponents/CentralTitle'
import FreeSelectComponent from '../../../formsSystem/FreeSelectComponent'
import { toast } from 'react-toastify'
import { Divider } from '@mui/material'
import DetailsGridModel from '../../../formsSystem/DetailsGridModel'

const IssueDecsirptionDialog = ({
  windowCloser,
  activeFun,
  formsCompsProps,
  DCGProps,
  option,
  setOption,
}) => {
  const { isDarkModeActive } = DCGProps
  const rtl = document.body.dir === 'rtl'
  const buttonsArray = [
    {
      title: rtl ? 'تأكيد' : 'Confirm',
      funs: () => {
        activeFun()
        windowCloser()
        toast.success(
          rtl
            ? 'تم إنشاء المشكلة بنجاح ونشر منشور. تم إخطار حاملي العقود حول هذه القضية'
            : 'The issue has been created successfully and a post has been published. The contract holders have been notified about the issue'
        )
      },
      cofirmMssg: true,
    },
    { title: rtl ? 'إلغاء' : 'Cancel', funs: () => windowCloser() },
  ]
  const formsCompsPropsII = {
    ...formsCompsProps,
    formComponentIndex: 0,
    ...DCGProps,
  }

  const menuOptions = [
    {
      title: rtl ? 'عرض العقد' : 'View Contract ',
    },
    {
      title: rtl ? 'عرض الاتفاقية العامة' : 'View General Agreement',
    },
  ]
  const infoArray = [
    {
      title: rtl ? 'أمان العقد' : 'Contract Security',
      titleValue: (
        <FreeSelectComponent
          {...DCGProps}
          menuOptions={menuOptions}
          option={option}
          setOption={setOption}
        />
      ),
    },
  ]
  return (
    <article style={{ color: isDarkModeActive && 'white' }}>
      <CentralTitle title={rtl ? 'تفاصيل المشكلة' : 'Issue Details'} />
      <DetailsGridModel infoArray={infoArray} />
      <FormsComponents formsCompsProps={formsCompsPropsII} />
      <div
        style={{
          fontSize: 12,
          padding: 10,
          color: isDarkModeActive ? 'white' : 'inherit',
          fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
        }}
      >
        {rtl
          ? 'إذا قمت بإثارة مشكلة حول هذا العقد سيؤثر ذلك على تقييم الملف الشخصى بك و كذلك المستخدمين الاخرين بالعقد'
          : 'Invoking a contract affects your Hutupia portfolio ranking alongside with other users in the contract'}
        <br />
        {rtl
          ? 'يحدد أمان العقد ما إذا كان المستخدمون الآخرون الذين يراجعون المشكلة سيكونون قادرين على معاينة العقد أو الاتفاقية العامة فقط'
          : 'Contract security determines if other users who revise the issue will be able to preview the contract or only the general agreement'}
      </div>
      <ButtonsSystem buttonsArray={buttonsArray} />
    </article>
  )
}

export default IssueDecsirptionDialog
