import React from 'react'
import MentionDetectorElement from '../DectectorsElements/MentionDetectorElement'
import DetectionCore from '../DetectionCore'
import NextDetectionModel from '../DetectionCoreComponents/CoreComponentComps/NextDetectionModel'

const MentionDetector = (props) => {
  return (
    <DetectionCore
      {...props}
      hashTagSymbol={'@'}
      TextFormattorComp={MentionDetectorElement}
      NextDetection={NextDetectionModel}
      furtherDetection
    />
  )
}

export default MentionDetector
