import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { FaCalculator } from 'react-icons/fa'
import WindowViewerSystem from '../../../../windowViewer/WindowViewerSystem'
import WindowProps from '../../../../windowViewer/WindowProps'
import BusinessModelsConfigs from '../../../../projectProfile/ProjectProfileComponents/ProjectControlPanelComponents/ProjectModelingComponents/BusinessModelComponents/NavigateModelsComponents/BusinessModelsConfigs'

const styles = () => ({
  mentionWrapper: {
    fontWeight: 500,
    color: '#00527C',
    cursor: 'pointer',
  },
  businessMTIcon: {
    margin: '2px 0 -2px 0',
    color: '#002A5E',
  },
})

const LocalModelDetectorElement = ({
  classes,
  textPackage,
  word,
  isDarkModeActive,
  notEngineProps,
}) => {
  const modelId = Number(word.slice(4, word.length))
  const model = BusinessModelsConfigs().filter((mod) => mod.id === modelId)[0]
  const { isWindowOpen, windowOpener, windowCloser } = WindowProps()
  return (
    <>
      <span
        onClick={() => windowOpener()}
        className={classes.mentionWrapper}
        style={{
          display: word.trim() ? null : 'none',
          textShadow: isDarkModeActive
            ? '-1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white'
            : null,
        }}
      >
        <FaCalculator className={classes.businessMTIcon} /> {model?.title}
      </span>
      <WindowViewerSystem
        isWindowOpen={isWindowOpen}
        windowCloser={windowCloser}
        isDarkModeActive={isDarkModeActive}
        windowType='businessModelTemp'
        props={{
          ...notEngineProps,
          model,
        }}
      />
    </>
  )
}

const WrappedLocalModelDetectorElement = withStyles(styles)(
  LocalModelDetectorElement
)

export default WrappedLocalModelDetectorElement
