import React, { useEffect } from 'react'
import FormComponent from './FormsSystemComponents/FormComponent'

const FormsSystem = (propsII) => {
  const {
    formComponentsArray,
    formComponentsValues,
    setFormComponentsValues,
    initialFormData,
    customComponent,
    stopInitiator,
  } = propsII

  useEffect(() => {
    if (!stopInitiator) {
      let tempObject = {}
      formComponentsArray.forEach((element) => {
        return (tempObject[element.title.split(' ').join('')] = initialFormData
          ? initialFormData[element.title.split(' ').join('')]
          : '')
      })
      setFormComponentsValues(tempObject)
    }
  }, [formComponentsValues.length])

  return formComponentsArray.map((element, elementIndex) => {
    const modelProps = {
      ...propsII,
      element,
      elementIndex,
      customComponent: customComponent ? customComponent : {},
      optionsSetState: setFormComponentsValues,
      optionsState: formComponentsValues,
      initialFormData: initialFormData,
    }
    return <FormComponent modelProps={modelProps} key={elementIndex} />
  })
}

export default FormsSystem
