import { useTranslation } from 'react-i18next'

const EcosystemBusinessModel = () => {
  const { t } = useTranslation('EcosystemBusinessModel')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 71,
    title: t('title_bm71'),
    subTitle: t('subTitle_bm71'),
    main: t('main_bm71'),
    when: t('when_bm71'),
    who: t('who_bm71'),
    what: t('what_bm71'),
    how: t('how_bm71'),
    why: t('why_bm71'),
    examples: rtl
      ? [
          'أمازون - 1994 - الولايات المتحدة الأمريكية',
          'علي بابا - 1999 - الصين',
          'Airbnb - 2008 - الولايات المتحدة الأمريكية',
          'Tunstall - 1957 - المملكة المتحدة',
          'آبل - 1976 - أمريكا',
        ]
      : [
          'Amazon - 1994 - USA',
          'Alibaba - 1999 - China',
          'Airbnb - 2008 - USA',
          'Tunstall - 1957 - UK',
          'Apple - 1976 - USA',
        ],
    tags: rtl
      ? ['تعاون', 'ابتكار', 'تبادل البيانات', 'تأثير الشبكة', 'خلق القيمة']
      : [
          'Collaboration',
          'Innovation',
          'Data Sharing',
          'Network Effect',
          'Value Creation',
        ],
    considerations: t('considerations_bm71'),
    combinations: ['$LBM61', '$LBM51', '$LBM57', '$LBM2'],
    hashTagType: 'businessModelTemp',
  }
}

export default EcosystemBusinessModel
