import { Avatar } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { RiBuilding2Fill } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import withStyles from '@mui/styles/withStyles'
import axios from 'axios'
import Loading from '../../../../../../../SmallComponents/Loading'

const styles = () => ({
  profileWrapper: {
    width: '100%',
  },
  nameAvatar: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 10,
  },
  companyName: {
    fontWeight: 500,
    fontSize: 20,
    marginTop: 5,
  },
  ceo: {
    fontWeight: 500,
    fontSize: 18,
    marginTop: 5,
  },
})

const PartnerProjectAvatarName = ({ classes, company }) => {
  const [ceo, setCeo] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    const fetchCEO = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(`/users/oneUser/${company?.userId}`)
        setCeo(res.data.user)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    if (company?.userId) fetchCEO()
  }, [company?.userId])
  return (
    <section className={classes.nameAvatar}>
      <Link to={`/projectProfile/${company?._id}`}>
        <Avatar
          style={{ width: 100, height: 100 }}
          src={company?.projectPhoto || company?.image}
        >
          <RiBuilding2Fill size={45} />
        </Avatar>
      </Link>
      <span className={classes.companyName}>
        {company?.ProjectName || company?.name}
      </span>
      <span className={classes.ceo}>
        {isLoading ? <Loading /> : ceo?.firstName + ' ' + ceo?.lastName}
      </span>
    </section>
  )
}

const WrappedPartnerProjectAvatarName = withStyles(styles)(
  PartnerProjectAvatarName
)

export default WrappedPartnerProjectAvatarName
