import { Avatar, Grid } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import withStyles from '@mui/styles/withStyles'
import { RiBuilding2Fill } from 'react-icons/ri'
import { MdVerified } from 'react-icons/md'

const styles = () => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    margin: '10px auto 10px auto',
    padding: '10px',
    borderRadius: '10px !important',
    backgroundColor: 'rgba(217,224,252, 0.2) ',
    width: '100%',
  },
  unitWrapper: {
    display: 'flex',
    alignItems: 'center',
    margin: 10,
  },
  unitName: {
    fontSize: 15,
    fontWeight: 500,
  },
  '@media (max-width: 600px)': {
    unitName: {},
    container: {
      margin: 10,
    },
  },
})

const ResultModel = ({ classes, resultData, result }) => {
  const rtl = document.body.dir === 'rtl'
  return (
    <Grid container style={{ width: '100%' }}>
      <section className={classes.container}>
        <Grid item xs={resultData[1].gridXs[1]} className={classes.unitWrapper}>
          <Link to={resultData[0].path}>
            {result?.firstName ? (
              <Avatar src={resultData[0].image} />
            ) : (
              <Avatar src={resultData[0].image}>
                <RiBuilding2Fill />
              </Avatar>
            )}
          </Link>
          <Link to={resultData[0].path}>
            <span
              className={classes.unitName}
              style={{ marginLeft: !rtl && 10, marginRight: rtl && 10 }}
            >
              {resultData[0].name}
              {resultData[0]?.verified && (
                <MdVerified
                  color='blue'
                  style={{ margin: '2px 5px -2px 5px' }}
                />
              )}
            </span>
          </Link>
        </Grid>
        {resultData[1].resultInteractionsArray.map((action, actionIndex) => (
          <Grid
            key={actionIndex}
            item
            xs={
              (resultData[1].gridXs[0] - resultData[1].gridXs[1]) /
              resultData[1].resultInteractionsArray.length
            }
            className={classes.unitWrapper}
          >
            {action}
          </Grid>
        ))}
      </section>
    </Grid>
  )
}

const WrappedResultModel = withStyles(styles)(ResultModel)

export default WrappedResultModel
