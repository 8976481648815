import React from 'react'
import { FcNook } from 'react-icons/fc'

const AlertCard = ({ alertText }) => {
  const rtl = document.body.dir === 'rtl'
  return (
    <section
      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
    >
      <FcNook
        size={23}
        style={{ marginRight: !rtl && 10, marginLeft: rtl && 10 }}
      />
      <span style={{ fontWeight: 500 }}>{alertText}</span>
    </section>
  )
}

export default AlertCard
