const BusinessModelTemplateConfigs = [
  {
    title: 'title',
  },
  {
    title: 'subTitle',
  },
  {
    title: 'main',
  },
  {
    title: 'when',
  },
  {
    title: 'who',
  },
  {
    title: 'what',
  },
  {
    title: 'how',
  },
  {
    title: 'why',
  },
  {
    title: 'examples',
  },
  {
    title: 'tags',
  },
  {
    title: 'considerations',
  },
  {
    title: 'combinations',
  },
  {
    title: 'numbers',
  },
]

export default BusinessModelTemplateConfigs
