import React, { useContext, useState } from 'react'
import { Grid } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import FeedPostBottomModel from '../../../feedsSystem/FeedPostBottomModel'
import { AuthContext } from '../../../../context/AuthContext'
import { useEffect } from 'react'
import axios from 'axios'

const styles = () => ({
  gridWrapper: {
    width: '100% !important',
    display: 'flex',
    justifyContent: 'space-around',
  },
})

const HomePostBottom = ({ postProps, classes }) => {
  const {
    post,
    isUsedIn,
    hideTopAndBottom,
    hideBottom,
    setIsPostShared,
    setIsPublishClicked,
    isDarkModeActive,
    posts,
    user: postUser,
    sharedPosts,
    sharedPostsUsers,
    project,
    sharedPost,
    projectMode,
    setPosts,
  } = postProps
  const { user } = useContext(AuthContext)
  const [altSharedPost, setAltSharedPost] = useState()
  useEffect(() => {
    const fetchSharedPost = async () => {
      try {
        const res = await axios.get(`/posts/${post?.sharedPostId}`)
        setAltSharedPost(res.data.post)
      } catch (error) {
        console.log(error)
      }
    }
    if (posts?.find((p) => p._id === post?.sharedPostId) || sharedPost) {
      setAltSharedPost(
        posts?.find((p) => p._id === post?.sharedPostId) || sharedPost
      )
    } else {
      if (post?.sharedPostId) fetchSharedPost()
    }
  }, [post?.sharedPostId])
  const shareSystemProps = {
    shareTypeIndex: 0,
    post: post.sharedPostId ? altSharedPost : post,
    setIsPostShared,
    isUsedIn: 'shareSystem',
    setIsPublishClicked,
    isDarkModeActive,
    user,
    postUser,
    sharedPosts,
    sharedPostsUsers,
    project,
    projectMode,
    setPosts,
  }

  return (
    <Grid
      container
      className={classes.gridWrapper}
      style={{
        display:
          isUsedIn === 'shareSystem' || hideTopAndBottom || hideBottom
            ? 'none'
            : null,
      }}
    >
      <FeedPostBottomModel
        postProps={{
          ...postProps,
          buttons: ['like', 'comment', 'share'],
        }}
        shareSystemProps={shareSystemProps}
      />
    </Grid>
  )
}

const WrappedHomePostBottom = withStyles(styles)(HomePostBottom)

export default WrappedHomePostBottom
