import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { Card } from '@mui/material'

const styles = () => ({
  cardImageText: {
    display: 'flex',
    margin: '10px 25px 10px 25px',
    alignItems: 'center',
    flexDirection: 'column',
  },
  cardImage: {
    objectFit: 'cover',
    width: '110px',
    height: '110px',
    borderRadius: '10px',
    transition: '0.3s',
    opacity: '0.8',
    boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
    '&:hover': {
      boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)',
      width: '130px',
      height: '130px',
      opacity: '1',
    },
    cursor: 'pointer',
  },
  cardTitle: {
    display: 'flex',
    textAlign: 'center',
    cursor: 'pointer',
    fontSize: '15px',
    color: 'white',
    justifyContent: 'center',
  },

  titleCard: {
    padding: '3px !important',
    marginTop: '5px !important',
    width: '100% !important',
    borderRadius: '20px !important',
  },

  '@media (max-width: 600px)': {
    cardImageText: {
      margin: 10,
      alignItems: 'center',
      flexDirection: 'column',
    },
    cardTitle: {
      fontSize: '12px',
    },
    cardImage: {
      width: '90px',
      height: '90px',
    },
  },
})

const ImageCardModel = ({ classes, element, elementIndex, props }) => {
  const { MFSGProps, folderPathProps, modelProps, searchSystemProps } = props
  const card = element
  const cardIndex = elementIndex
  const { cards, setCardId, cardId, isDarkModeActive } = MFSGProps
  const { setSecondLevelFolderPath, setThirdLevelFolderPath } = folderPathProps
  const {
    folderPathNumber,
    setOpenCloseSubDepModelView,
    setHideNoFileSystemPanel,
  } = modelProps

  const { setShowSearchInput } = searchSystemProps
  const action = () => {
    setCardId(cardIndex)
    if (
      typeof setSecondLevelFolderPath === 'function' &&
      folderPathNumber === 2
    )
      setSecondLevelFolderPath(card.title)
    if (typeof setThirdLevelFolderPath === 'function' && folderPathNumber === 3)
      setThirdLevelFolderPath(card.title)
    if (
      typeof setOpenCloseSubDepModelView === 'function' &&
      folderPathNumber === 3
    )
      setOpenCloseSubDepModelView(1)
    if (typeof setShowSearchInput === 'function') setShowSearchInput(false)
    if (typeof setHideNoFileSystemPanel === 'function')
      setHideNoFileSystemPanel(true)
  }
  return (
    <div className={classes.cardImageText}>
      <img
        src={card.image}
        alt={`${card.title}'s image`}
        className={classes.cardImage}
        onClick={action}
      />
      <Card
        className={classes.titleCard}
        sx={{
          backgroundColor: isDarkModeActive
            ? 'rgba(210, 221, 240, 0.4)'
            : 'rgb(210, 221, 240)',
        }}
      >
        <span className={classes.cardTitle} onClick={action}>
          {card.title}
        </span>
      </Card>
    </div>
  )
}

const WrappedImageCardModel = withStyles(styles)(ImageCardModel)

export default WrappedImageCardModel
