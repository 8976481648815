import axios from 'axios'
import React, { useState } from 'react'
import ButtonsSystem from '../../../../../../../../buttonsSystem/ButtonsSystem'
import FormsSystem from '../../../../../../../../formsSystem/FormsSystem'
import TitleHeader from '../../../../../../../../SmallComponents/Header'
import TextEngineToolsFooter from '../../../../../../../../SmallComponents/TextEngineToolsFooter'
import FormImagesUploader from '../../../../../../../../uploadImageSystem/FormImagesUploader'
import NewConceptFormConfigs from './NewConceptFormComps/NewConceptFormConfigs'
import FullFormChecker from '../../../../../../../../formsSystem/FullFormChecker'
import FormDataInitialValueExtractor from '../../../../../../../../formsSystem/FormDataInitialValueExtractor'
import { useTranslation } from 'react-i18next'
import FormDataLangModulator from '../../../../../../../../formsSystem/FormDataLangModulator'

const NewConceptForm = ({
  DCGProps,
  setProjectResearchConcepts,
  GeneralDialogClose,
  activity,
  setResActConcepts,
}) => {
  const { project, isDarkModeActive } = DCGProps
  const [images, setImages] = useState([])
  const { t } = useTranslation()
  // order matters
  const [formData, setFormData] = useState({}) //1
  const newformData = FormDataLangModulator(formData, t) //2
  FormDataInitialValueExtractor(NewConceptFormConfigs(), setFormData) //3
  const [chosenHashTagElements, setChosenHashTagElements] = useState([])
  const rtl = document.body.dir === 'rtl'
  const [isCreating, setIsCreating] = useState(false)
  const buttonsArray = [
    {
      title: rtl ? 'إلغاء' : 'Cancel',
      funs: () => {
        GeneralDialogClose()
      },
    },
    {
      title: rtl
        ? isCreating
          ? 'جارى الإنشاء'
          : 'إنشاء'
        : isCreating
        ? 'Creating...'
        : 'Create',
      disabled:
        !FullFormChecker({
          FormConfig: NewConceptFormConfigs(),
          formData,
        }) || isCreating,
      funs: async () => {
        setIsCreating(true)
        try {
          const res = await axios.post(`/researchConcepts`, {
            ...newformData,
            projectId: project._id,
            researchActivityIds: activity?._id,
            images,
            chosenHashTagElements,
          })
          if (typeof setResActConcepts === 'function')
            setResActConcepts((cons) => [...cons, res.data.researchConcept])
          if (setProjectResearchConcepts)
            setProjectResearchConcepts((cons) => [
              ...cons,
              res.data.researchConcept,
            ])
          setIsCreating(false)
          GeneralDialogClose()
        } catch (error) {
          console.log(error)
        }
      },
    },
  ]
  return (
    <div style={{ color: isDarkModeActive && 'white' }}>
      <TitleHeader headerText={rtl ? 'صور المبدأ' : 'Concept Images'} />
      <FormImagesUploader images={images} setImages={setImages} />
      <TitleHeader headerText={rtl ? 'تفاصيل المبدأ' : 'Concept Details'} />
      <FormsSystem
        {...DCGProps}
        formComponentsArray={NewConceptFormConfigs()}
        formComponentsValues={formData}
        setFormComponentsValues={setFormData}
        setChosenHashTagElements={setChosenHashTagElements}
        chosenHashTagElements={chosenHashTagElements}
      />
      <ButtonsSystem buttonsArray={buttonsArray} />
      <TextEngineToolsFooter {...DCGProps} />
    </div>
  )
}

export default NewConceptForm
