import { useTranslation } from 'react-i18next'

const IntegratorBusinessModel = () => {
  const { t } = useTranslation('IntegratorBusinessModel')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 27,
    title: t('title_bm27'),
    subTitle: t('subTitle_bm27'),
    main: t('main_bm27'),
    when: t('when_bm27'),
    who: t('who_bm27'),
    what: t('what_bm27'),
    how: t('how_bm27'),
    why: t('why_bm27'),
    examples: rtl
      ? [
          'كارنيجي ستيل - تأسست عام 1870 - الولايات المتحدة الأمريكية',
          'إكسون موبيل - تأسست عام 1999 - الولايات المتحدة الأمريكية',
          'شركة فورد موتور - تأسست عام 1903 - الولايات المتحدة الأمريكية',
          'BYD - تأسست عام 2003 - الصين',
          'زارا - تأسست 1975 - إسبانيا',
          'Würth - تأسست عام 1945 - ألمانيا',
        ]
      : [
          'Carnegie Steel - Founded 1870 - USA',
          'Exxon Mobil - Founded 1999 - USA',
          'Ford Motor Company - Founded 1903 - USA',
          'BYD - Founded 2003 - China',
          'Zara - Founded 1975 - Spain',
          'Würth - Founded 1945 - Germany',
        ],
    tags: rtl
      ? [
          'لا يوجد طرف ثالث',
          'إنتاج سريع',
          'بدون تأخير',
          'استجابة السوق',
          'أعمال قابلة للتكيف',
        ]
      : [
          'No Third Party',
          'Quick Production',
          'No Delay',
          'Market Responsiveness',
          'Adaptable Business',
        ],
    considerations: t('considerations_bm27'),
    combinations: ['$LBM13'],
    hashTagType: 'businessModelTemp',
  }
}

export default IntegratorBusinessModel
