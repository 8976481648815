import React from 'react'
import ChartPost from '../../../../postSystem/ChartPost'
import TextPost from '../../../../postSystem/TextPost'
import HomePostBottom from '../../commonPostComponents/HomePostBottom'

const HomeChartPostBottom = ({ postProps }) => {
  return (
    <HomePostBottom
      postProps={{
        ...postProps,
        postBody: <ChartPost {...postProps} />,
        postText: <TextPost {...postProps} noShadowedText={true} />,
      }}
    />
  )
}

export default HomeChartPostBottom
