import { useTranslation } from 'react-i18next'

const TimeShareBusinessModel = () => {
  const { t } = useTranslation('TimeShareBusinessModel')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 68,
    title: t('title_bm68'),
    subTitle: t('subTitle_bm68'),
    main: t('main_bm68'),
    when: t('when_bm68'),
    who: t('who_bm68'),
    what: t('what_bm68'),
    how: t('how_bm68'),
    why: t('why_bm68'),
    examples: rtl
      ? [
          'ملكية ويندهام فاكيشن (1981 - الولايات المتحدة الأمريكية)',
          'عطلات ماريوت العالمية (1984 - الولايات المتحدة الأمريكية)',
          'هيلتون جراند فاكيشنز (1992 - الولايات المتحدة الأمريكية)',
          'Vacation Internationale (1972 - الولايات المتحدة الأمريكية)',
          'هوليداي إن كلوب فاكيشنز (1982 - الولايات المتحدة الأمريكية)',
        ]
      : [
          'Wyndham Vacation Ownership (1981 - USA)',
          'Marriott Vacations Worldwide (1984 - USA)',
          'Hilton Grand Vacations (1992 - USA)',
          'Vacation Internationale (1972 - USA)',
          'Holiday Inn Club Vacations (1982 - USA)',
        ],
    tags: rtl
      ? ['عقار للعطلات', 'تطوير على طراز المنتجع', 'ملكية مشتركة']
      : ['Vacation Property', 'Resort-Style Development', 'Shared Ownership'],
    considerations: t('considerations_bm68'),
    combinations: ['$LBM2'],
    hashTagType: 'businessModelTemp',
  }
}

export default TimeShareBusinessModel
