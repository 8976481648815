import React from 'react'
import ImagesSystem from '../../../../../../../../postSystem/ImagesSystem'
import LinksPlayerSystem from '../../../../../../../../postSystem/LinksPlayerSystem'
import GeneralPollBody from '../../../../../../../../generalPollSystem/GeneralPollBody'

const RoomSMDisCenter = (props) => {
  const { post, imageViewerDialogOpener } = props
  return (
    <>
      <LinksPlayerSystem url={post?.playerLink} />
      <GeneralPollBody {...props} pollData={post?.pollData} />
      <ImagesSystem
        imagesArray={post?.images}
        openFun={() => {
          if (typeof imageViewerDialogOpener === 'function')
            imageViewerDialogOpener()
        }}
        {...props}
      />
      <ImagesSystem imageViewer={true} imagesArray={post?.images} {...props} />
    </>
  )
}

export default RoomSMDisCenter
