import React from 'react'
import TransparentBackCard from '../../../../../../SmallComponents/TransparentBackCard'
import TextEngine from '../../../../../../textProcessingEngine/TextEngine'

const IssueMainContent = ({ issue, DCGProps }) => {
  return (
    <TransparentBackCard
      content={
        <TextEngine
          textContent={issue?.content || ''}
          textPackage={issue}
          {...DCGProps}
        />
      }
      {...DCGProps}
    />
  )
}

export default IssueMainContent
