import React from 'react'
import HomeGenerlPostModel from '../../homeFeed/homeFeedComponents/HomeFeedPostModelComponents/HomeGenerlPostModel'

const ProjectFeedsSystemModels = ({ feedsSystemProps }) => {
  const {
    post,
    isUsedIn,
    project,
    engineFuns,
    user,
    isDarkModeActive,
    hideBottom,
    socket,
    hideAddUserIcon,
    setPosts,
  } = feedsSystemProps
  const postProps = {
    post,
    isUsedIn,
    hideRightOptions: true,
    project,
    engineFuns,
    user,
    isDarkModeActive,
    hideBottom,
    socket,
    hideAddUserIcon,
    setPosts,
  }
  return <HomeGenerlPostModel postProps={postProps} />
}

export default ProjectFeedsSystemModels
