import { useTranslation } from 'react-i18next'

const SubscriptionBusinessModel = () => {
  const { t } = useTranslation('SubscriptionBusinessModel')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 54,
    title: t('title_bm54'),
    subTitle: t('subTitle_bm54'),
    main: t('main_bm54'),
    when: t('when_bm54'),
    who: t('who_bm54'),
    what: t('what_bm54'),
    how: t('how_bm54'),
    why: t('why_bm54'),
    examples: rtl
      ? [
          'Salesforce - تأسست عام 1999 - الولايات المتحدة الأمريكية',
          'Netflix - تأسست عام 1999 - الولايات المتحدة الأمريكية',
          'Blacksocks - تأسست 1999 - سويسرا',
          'جامبا - تأسست عام 2004 - ألمانيا',
          'Spotify - تأسست عام 2006 - السويد',
          'إعلام الإصدار التالي - تأسس عام 2011 - الولايات المتحدة الأمريكية',
          'Dollar Shave Club - تأسس عام 2012 - الولايات المتحدة الأمريكية',
        ]
      : [
          'Salesforce - Founded 1999 - USA',
          'Netflix - Founded 1999 - USA',
          'Blacksocks - Founded 1999 - Switzerland',
          'Jamba - Founded 2004 - Germany',
          'Spotify - Founded 2006 - Sweden',
          'Next Issue Media - Founded 2011 - USA',
          'Dollar Shave Club - Founded 2012 - USA',
        ],
    tags: rtl ? ['الايرادات الثابتة'] : ['Fixed Revenues'],
    considerations: t('considerations_bm54'),
    combinations: [],
    hashTagType: 'businessModelTemp',
  }
}

export default SubscriptionBusinessModel
