import React from 'react'
import withStyles from '@mui/styles/withStyles'
import CommentsDialog from '../commentsSystems/CommentsDialog'
import PostProps from '../homeFeed/homeFeedComponents/HomeFeedPostModelComponents/PostProps'
import PTLeft from './FeedPostTopModelComponents/PTLeft'
import PTRight from './FeedPostTopModelComponents/PTRight'

const styles = () => ({
  postTop: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 9,
    borderRadius: 10,
    borderTopLeftRadius: 0,
    borderBottomRightRadius: 0,
    margin: ' 0 0 5px 15px',
  },
  '@media (max-width: 600px)': {
    postTop: {
      borderRadius: '0 0 0 10px',
    },
  },
})

const FeedPostTopModel = ({ postProps, classes }) => {
  const { hideTopAndBottom, isDarkModeActive, hideBottom } = postProps
  const { commentsDialogOpener, commentsDialogClose, isCommentsDialogOpen } =
    PostProps({}, { commentsDialogProps: true })

  return (
    <>
      <div
        onClick={hideBottom ? commentsDialogOpener : undefined}
        className={classes.postTop}
        style={{
          cursor: hideBottom ? 'pointer' : null,
          display: hideTopAndBottom ? 'none' : null,
        }}
      >
        <PTLeft postProps={postProps} />
        <PTRight postProps={postProps} />
      </div>
      <CommentsDialog
        isCommentsDialogOpen={isCommentsDialogOpen}
        commentsDialogClose={commentsDialogClose}
        {...postProps}
      />
    </>
  )
}

const WrappedFeedPostTopModel = withStyles(styles)(FeedPostTopModel)

export default WrappedFeedPostTopModel
