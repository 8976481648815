import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Grid,
  Box,
} from '@mui/material'
import CountriesPhoneKeys from '../../SmallComponents/CountriesPhoneKeys'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import MultiMillify from '../../SmallComponents/MultiMillify'

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(2),
    minWidth: 200,
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: '100%',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important",
    textTransform: 'none !important',
    fontWeight: '550 !important',
  },
  label: {
    fontWeight: 'bold',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important",
  },
  select: {
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
  },
}))

const Gender = ['Male', 'Female', 'Other']
const AgeRanges = ['18-24', '25-34', '35-44', '45-54', '55-64', '65+']
const GeoRegions = [
  'Africa',
  'Asia',
  'Australia_and_Oceania',
  'Europe',
  'Middle_East',
  'North_America',
  'South_America',
]
const IndustryTypes = [
  'Agriculture',
  'Arts_&_Entertainment',
  'Automotive',
  'Beauty_&_Personal_Care',
  'Construction',
  'Education',
  'Energy_&_Utilities',
  'Food_&_Beverage',
  'Healthcare',
  'Hospitality_&_Tourism',
  'Information_Technology',
  'Legal_Services',
  'Manufacturing',
  'Marketing_&_Advertising',
  'Non-profit',
  'Real_Estate',
  'Retail',
  'Sports_&_Recreation',
  'Transportation_&_Logistics',
]
const ProjectPhases = ['Incubation', 'Start-up', 'Scale-up', 'Enterprise']
const countsOptions = [1000, 5000, 10000, 50000, 100000, 1000000, 10000000]

const PostAdConfigsForm = ({ setProcessing, adCampaign, setAdCampaign }) => {
  const classes = useStyles()
  const rtl = document.body.dir === 'rtl'
  const { t } = useTranslation()
  const [genders, setGenders] = useState([])
  const [ageRange, setAgeRange] = useState('')
  const [geoRegions, setGeoRegions] = useState([])
  const [countries, setCountries] = useState([])
  const [projectsPhases, setProjectsPhases] = useState([])
  const [projectsTypes, setProjectsTypes] = useState([])
  const [targetedUsersCount, setTargetedUsersCount] = useState('')

  const handleGenderChange = (event) => {
    setGenders(event.target.value)
  }

  const handleAgeRangeChange = (event) => {
    setAgeRange(event.target.value)
  }

  const handleGeoRegionsChange = (event) => {
    setGeoRegions(event.target.value)
  }

  const handleCountriesChange = (event) => {
    setCountries(event.target.value)
  }

  const handlePhasesChange = (event) => {
    setProjectsPhases(event.target.value)
  }

  const handleTypesChange = (event) => {
    setProjectsTypes(event.target.value)
  }

  const handleTargetedUsersCountChange = (event) => {
    setTargetedUsersCount(event.target.value)
  }

  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (event) => {
    setIsLoading(true)
    event.preventDefault()
    const res = await axios.post(
      `/adsManagment/targetingUsers/${adCampaign?._id}`,
      {
        genders,
        ageRange,
        geoRegions,
        countries: countries.map((c) => c?.code),
        projectsPhases,
        projectsTypes: projectsTypes.map((p) => p?.split('_').join(' ')),
        targetedUsersCount,
        // currency and cost should be calculated and sent here
        cost: 15,
        currency: 'pound',
      }
    )
    setProcessing(res.data.success)
    setAdCampaign(res.data.adCampaign)
    setIsLoading(false)
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel id='targeted-users-label'>
              {rtl ? 'عدد المستخدمين المستهدفين' : 'Targeted Users Count'}
            </InputLabel>
            <Select
              labelId='targeted-users-label'
              id='targeted-users'
              value={targetedUsersCount}
              onChange={handleTargetedUsersCountChange}
              variant='outlined'
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200,
                    overflowY: 'auto',
                  },
                },
              }}
            >
              {countsOptions?.map((c) => (
                <MenuItem value={c} key={c}>
                  {MultiMillify(c)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel id='age-range-label'>
              {rtl ? 'الفئة العمرية' : 'Age Range'}
            </InputLabel>
            <Select
              labelId='age-range-label'
              id='age-range'
              value={ageRange}
              onChange={handleAgeRangeChange}
              variant='outlined'
              className={classes.select}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200,
                    overflowY: 'auto',
                  },
                },
              }}
            >
              {AgeRanges.map((ageRange) => (
                <MenuItem key={ageRange} value={ageRange}>
                  {ageRange}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel id='gender-label'>
              {rtl ? 'الجنس' : 'Gender'}
            </InputLabel>
            <Select
              labelId='gender-label'
              id='gender'
              multiple
              value={genders}
              onChange={handleGenderChange}
              variant='outlined'
              className={classes.select}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200,
                    overflowY: 'auto',
                  },
                },
              }}
            >
              {Gender.map((gen) => (
                <MenuItem key={gen} value={gen}>
                  {t(gen)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel id='geo-regions-label'>
              {rtl ? 'المناطق الجغرافية' : 'Geo Regions'}
            </InputLabel>
            <Select
              labelId='geo-regions-label'
              id='geo-regions'
              multiple
              value={geoRegions}
              onChange={handleGeoRegionsChange}
              variant='outlined'
              className={classes.select}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200,
                    overflowY: 'auto',
                  },
                },
              }}
            >
              {GeoRegions.map((region) => (
                <MenuItem key={region} value={region}>
                  {t(region)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel id='countries-label'>
              {rtl ? 'دول' : 'Countries'}
            </InputLabel>
            <Select
              labelId='countries-label'
              id='countries'
              multiple
              value={countries}
              renderValue={(selectionArray) =>
                selectionArray.map(
                  (chosenElement, index) =>
                    countries.includes(chosenElement) && (
                      <span key={index}>{`${chosenElement.name}`}</span>
                    )
                )
              }
              onChange={handleCountriesChange}
              variant='outlined'
              className={classes.select}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200,
                    overflowY: 'auto',
                  },
                },
              }}
            >
              {CountriesPhoneKeys().map((country) => (
                <MenuItem key={country.code} value={country}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.formControl} fullWidth>
            {/* this is supposed to show the projects created by users, so i choose the projects that hasve certain fetures, start with the phase */}
            <InputLabel id='projects-phases-label'>
              {rtl ? 'مرحلة المشروع' : 'Project Phase'}
            </InputLabel>
            <Select
              labelId='projects-phases-label'
              id='Project_Phase'
              multiple
              value={projectsPhases}
              onChange={handlePhasesChange}
              variant='outlined'
              className={classes.select}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200,
                    overflowY: 'auto',
                  },
                },
              }}
            >
              {ProjectPhases.map((phase) => (
                <MenuItem key={phase} value={phase}>
                  {t(phase)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel id='projects-types-label'>
              {rtl ? 'نوع المشروع' : 'Project Type'}
            </InputLabel>
            <Select
              labelId='projects-types-label'
              id='Project_Phase'
              multiple
              value={projectsTypes}
              onChange={handleTypesChange}
              variant='outlined'
              className={classes.select}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200,
                    overflowY: 'auto',
                  },
                },
              }}
            >
              {IndustryTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {t(type)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Box display='flex' justifyContent='flex-end'>
            <Button
              variant='contained'
              color='primary'
              type='submit'
              className={classes.button}
              disabled={isLoading || !targetedUsersCount}
            >
              {isLoading
                ? rtl
                  ? 'جارى المعالجة'
                  : 'Calculating'
                : rtl
                ? 'حساب العدد المستهدف'
                : 'Calculate Target'}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  )
}

export default PostAdConfigsForm
