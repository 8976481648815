import React from 'react'
import GeneralPDFDocument from './GeneralPDFDocument'
import ProductDesignPDFBody from './ProductDesignPDFDocumentComps/ProductDesignPDFBody'

const ProductDesignPDFDocument = (props) => {
  return (
    <GeneralPDFDocument
      pages={[<ProductDesignPDFBody {...props} />]}
      {...props}
    />
  )
}

export default ProductDesignPDFDocument
