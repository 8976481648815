import axios from 'axios'
import React, { useContext } from 'react'
import ButtonsSystem from '../../../../../../../buttonsSystem/ButtonsSystem'
import { AuthContext } from '../../../../../../../../context/AuthContext'
import NotificationCreator from '../../../../../../../notificationsSystem/NotificationCreator'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'

const ResourceRequestDetails = (props) => {
  const { card, exprops, setRequests, requestsUsers, DCGProps } = props
  const { resource } = exprops
  const request = card
  const requestingUser = requestsUsers?.find((u) => u?._id === request?.userId)
  const { socket } = DCGProps
  const { user } = useContext(AuthContext)
  const project = request?.requestData?.project

  const NotificationFun = ({ response, elements }) => {
    socket.current.emit('addNotifUser', request?.userId)
    NotificationCreator({
      associativity: 'resource',
      relation: 'resourceContractor',
      conditionIndex: 0,
      accompaniedData: {
        response,
        elements,
      },
      category: 'contributors',
      userId: request?.userId,
      currentUser: user,
      socket,
    })
  }

  const activeFun = async ({ response }) => {
    try {
      setRequests((reqs) => reqs.filter((req) => req._id !== request?._id))
      if (response === 'refuse') {
        NotificationFun({
          response,
          elements: [
            {
              hashTagType: resource?.hashTagType,
              id: resource?._id || resource?.id,
              title: resource?.Title || resource?.title,
            },
          ],
        })
      }
      if (response === 'accept') {
        // will add the user or project to the resourve users ids of projects ids props
        await axios.patch(
          `/resources/${resource?._id || resource?.id}`,
          project?._id
            ? {
                projectsIds: [...resource?.projectsIds, project?._id],
              }
            : {
                usersIds: [...resource?.usersIds, request?.userId],
              }
        )
        // this will add the user or the project to the default channel
        const defaultChRes = await axios.patch(
          `/channels/channelsSystem/defaultChannel/${
            resource?._id || resource?.id
          }`,
          project?._id
            ? {
                projectId: project?._id,
              }
            : {
                contributorId: request?.userId,
              }
        )
        NotificationFun({
          response,
          elements: [
            {
              hashTagType: resource?.hashTagType,
              id: resource?._id || resource?.id,
              title: resource?.Title || resource?.title,
            },
            {
              hashTagType: defaultChRes?.data?.channel?.hashTagType,
              id: defaultChRes?.data?.channel?._id,
              title: defaultChRes?.data?.channel?.Title,
            },
          ],
        })
      }
      // request will be deleted after being responded to
      await axios.delete(`/requests/${request?._id}`)
    } catch (error) {
      console.log(error)
    }
  }

  const deleteFun = async () => {
    try {
      await axios.delete(`/requests/${request?._id}`)
      setRequests((reqs) => reqs.filter((req) => req._id !== request?._id))
    } catch (error) {
      console.log(error)
    }
  }
  const rtl = document.body.dir === 'rtl'
  const buttonsArray = [
    {
      title: rtl ? 'قبول' : 'Accept',
      funs: () => {
        activeFun({ response: 'accept' })
        toast.success(
          rtl
            ? `تم قبول ${requestingUser?.firstName} و إضافته الى القناة الافتراضية الخاصة بالمورد`
            : `${requestingUser?.firstName} has been accepted and added to the default resource channel`
        )
      },
    },
    {
      title: rtl ? 'رفض' : 'Refuse',
      funs: () => {
        activeFun({ response: 'refuse' })
        toast.info(rtl ? 'تم ارسال الرفض' : 'Refusal has been sent')
      },
    },
    {
      title: rtl ? 'حذف' : 'Delete',
      funs: () => {
        deleteFun()
        toast.warning(
          rtl ? 'تم مسح الطلب بنجاح' : 'Request has been deleted successfully'
        )
      },
    },
  ]

  return (
    <>
      <section>
        <span style={{ margin: 'auto 4px' }}>
          {requestingUser?.firstName + ' ' + requestingUser?.lastName}
        </span>
        {rtl ? (
          project ? (
            <>
              يطلب مشاركة هذا المورد فى مشروعة{' '}
              <Link to={project?._id}>{project?.ProjectName}</Link> فى
            </>
          ) : (
            'يريد ان يشارك فى هذا المورد'
          )
        ) : project ? (
          <>
            asks for adding this resource his project{' '}
            <Link to={project?._id}>{project?.ProjectName}</Link>
          </>
        ) : (
          'wants to contribute to this resource'
        )}
      </section>
      <ButtonsSystem buttonsArray={buttonsArray} {...DCGProps} />
    </>
  )
}

export default ResourceRequestDetails
