import { Line } from 'rc-progress'
import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { useTranslation } from 'react-i18next'
import { convertNumbers2Arabic } from 'to-arabic-numbers'

const styles = () => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    margin: '10px auto',
  },
  result: {
    fontSize: 15,
    fontWeight: 500,
    margin: '5px 0',
  },
})

const PollCardResultsView = ({ op, classes, totalVoters }) => {
  const { t } = useTranslation()
  const rtl = document.body.dir === 'rtl'
  const percent = (op) => {
    return Math.round(
      totalVoters === 0 ? 0 : (op?.votersIds?.length / totalVoters) * 100
    )
  }
  const percentValue = rtl
    ? convertNumbers2Arabic(`${percent(op)}`) + '٪'
    : percent(op) + '%'
  const votersNo = rtl
    ? convertNumbers2Arabic(`${op?.votersIds?.length}`)
    : op?.votersIds?.length
  return (
    <section className={classes.wrapper}>
      <div className={classes.result}>
        {op?.Title} {percentValue} - ({votersNo}) {t('Votes')}
      </div>
      <Line percent={percent(op)} strokeWidth={4} strokeColor={'#87d068'} />
    </section>
  )
}

const WrappedPollCardResultsView = withStyles(styles)(PollCardResultsView)

export default WrappedPollCardResultsView
