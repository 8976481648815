import { Card } from '@mui/material'
import React from 'react'
import NewCommentModule from '../NewCommentModule'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  panelWrapper: {
    position: 'absolute !important',
    zIndex: '2 !important',
    width: '100vw !important',
    height: '60px !important',
    bottom: '0 !important',
    borderRadius: '0px !important',
    display: 'flex',
    justifyContent: 'space-around !important',
    alignItems: 'center !important',
    alignContent: 'center !important',
    left: 0,
  },
})

const NewComemntModuleMView = ({ classes, ...props }) => {
  const { isDarkModeActive } = props
  return (
    <Card
      className={classes.panelWrapper}
      sx={{
        backgroundColor: isDarkModeActive ? 'rgba(49, 48, 53, 0.9)' : 'white',
        color: isDarkModeActive ? 'white' : null,
      }}
    >
      <NewCommentModule {...props} />
    </Card>
  )
}

const WrappedNewComemntModuleMView = withStyles(styles)(NewComemntModuleMView)

export default WrappedNewComemntModuleMView
