import { Card } from '@mui/material'
import React from 'react'
import DefaultImageGrapper from '../../../../SmallComponents/DefaultImageGrapper'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  cardImageText: {
    display: 'flex',
    margin: '10px 25px 10px 25px',
    alignItems: 'center',
    flexDirection: 'column',
    cursor: 'pointer',
    positiom: 'relative',
  },
  cardImage: {
    objectFit: 'cover',
    width: '110px',
    height: '110px',
    borderRadius: '10px',
    boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
    position: 'relative',
  },

  cardImageChecked: {
    objectFit: 'cover',
    width: '110px',
    height: '110px',
    borderRadius: '10px',
    position: 'absolute',
    opacity: 0.5,
  },
  cardTitle: {
    display: 'flex',
    textAlign: 'center',
    cursor: 'pointer',
    fontSize: '15px',
    color: 'white',
    justifyContent: 'center',
  },

  titleCard: {
    padding: '3px !important',
    backgroundColor: 'rgb(210, 221, 240) !important',
    marginTop: '5px !important',
    width: '100% !important',
    borderRadius: '20px !important',
  },
  '@media (max-width: 600px)': {
    cardTitle: {
      fontSize: '12px',
    },
    cardImage: {
      width: '80px',
      height: '80px',
    },
    cardImageChecked: {
      width: '80px',
      height: '80px',
    },
  },
})

const ChannelCardUnit = ({ classes, channelId, setChannelId, channel }) => {
  return (
    <div
      className={classes.cardImageText}
      onClick={() => setChannelId(channel._id)}
      onDoubleClick={() => setChannelId()}
    >
      <img
        src={DefaultImageGrapper('channel')}
        alt={`${channel.Title}'s Image`}
        className={classes.cardImage}
      />
      <img
        style={{ display: channelId === channel._id ? null : 'none' }}
        src={DefaultImageGrapper('checked')}
        alt={`${channel.Title}'s Image`}
        className={classes.cardImageChecked}
      />
      <Card className={classes.titleCard}>
        <span className={classes.cardTitle}>{channel.Title}</span>
      </Card>
    </div>
  )
}

const WrappedChannelCardUnit = withStyles(styles)(ChannelCardUnit)

export default WrappedChannelCardUnit
