import React, { useState } from 'react'
import { Button } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import NotificationsSentConfirmDialog from '../confirmationSystem/NotificationsSentConfirmDialog'
import StateGenerator from './StateGenerator'

const styles = () => ({
  button: {
    borderRadius: '10px !important',
    margin: '2px !important',
    textTransform: 'none !important',
    fontWeight: '550 !important',
    width: '100%',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important",
  },
})

const SendConfirmationButton = ({
  classes,
  sendText,
  confirmmationTitles,
  isDarkModeActive,
  sentText,
  onSend,
  disabled,
  isSending,
  onClose,
}) => {
  const [isRequestButtonClicked, setIsRequestButtonClicked] = useState()

  const {
    isConfirmDialogOpen,
    setIsConfirmDialogOpen,
    clickedButtonId,
    confirmDialogOpener,
  } = StateGenerator({
    confirmDialogProps: true,
    timeOutFun: onClose,
  })
  const rtl = document.body.dir === 'rtl'
  return (
    <section style={{ width: '100%' }}>
      <NotificationsSentConfirmDialog
        confirmmationTitles={confirmmationTitles}
        confirmationRTitleIndex={clickedButtonId}
        isConfirmDialogOpen={isConfirmDialogOpen}
        setIsConfirmDialogOpen={setIsConfirmDialogOpen}
        isDarkModeActive={isDarkModeActive}
      />
      <Button
        style={{ color: isDarkModeActive ? 'white' : null }}
        className={classes.button}
        disabled={isRequestButtonClicked || disabled || isSending}
        variant='outlined'
        onClick={() => {
          confirmDialogOpener()
          setIsRequestButtonClicked(true)
          onSend()
        }}
      >
        {isSending
          ? rtl
            ? 'جارى الإرسال...'
            : 'Sending...'
          : isRequestButtonClicked
          ? sentText
            ? sentText
            : rtl
            ? 'تم الإرسال'
            : 'Sent'
          : sendText}
      </Button>
    </section>
  )
}

const WrappedSendConfirmationButton = withStyles(styles)(SendConfirmationButton)

export default WrappedSendConfirmationButton
