import React from 'react'
import GeneralPDFDocument from './GeneralPDFDocument'
import InvestPackPDFBody from './InvestPackPDFDocumentComps/InvestPackPDFBody'

const InvestPackPDFDocument = (props) => {
  return (
    <GeneralPDFDocument pages={[<InvestPackPDFBody {...props} />]} {...props} />
  )
}

export default InvestPackPDFDocument
