import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { Chip } from '@mui/material'
import TitleHeader from '../../../../../../../SmallComponents/Header'
import TextEngine from '../../../../../../../textProcessingEngine/TextEngine'

const styles = () => ({
  wrapper: {
    fontSize: 15,
    fontWeight: 500,
    padding: 10,
    margin: 10,
    borderRadius: 10,
    background: 'rgba(217,224,252, 0.1)',
    fontStyle: 'oblique',
  },
})

const ModelInterrlations = ({ classes, props }) => {
  const { DCGProps, model } = props
  const { isDarkModeActive } = DCGProps
  const rtl = document.body.dir === 'rtl'
  return (
    <>
      <TitleHeader
        headerText={
          rtl ? 'نماذج محتمل ان تكون متجانسة' : 'Potentially Compatible Models.'
        }
        subHeaderText={
          rtl
            ? 'تقدم هذه النماذج مزيجًا مفيدًا محتملًا'
            : 'These models offer potential useful mixes.'
        }
      />
      <article className={classes.wrapper}>
        <section
          style={{ display: 'flex', flexFlow: 'row wrap', width: '100%' }}
        >
          {model?.combinations?.map((mod, idnex) => (
            <Chip
              key={idnex}
              label={
                <TextEngine
                  textContent={mod}
                  {...DCGProps}
                  notEngineProps={props}
                />
              }
              style={{
                color: isDarkModeActive ? 'white' : null,
                margin: '2px',
              }}
            />
          ))}
        </section>
      </article>
    </>
  )
}

const WrappedModelInterrlations = withStyles(styles)(ModelInterrlations)

export default WrappedModelInterrlations
