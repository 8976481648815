import React, { useEffect, useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import MultiLangNumber from '../../../../SmallComponents/MultiLangNumber'
import axios from 'axios'

const styles = () => ({
  title: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-evenly',
    fontWeight: 600,
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  subtitle: {
    fontSize: 12,
    margin: 'auto 5px',
  },
  subTitlesWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'row wrap',
    justifyContent: 'center',
  },
  '@media (max-width: 600px)': {
    title: {
      fontSize: 15,
    },
    subtitle: {
      fontSize: 10,
      margin: 'auto 4px',
    },
  },
})

const FeedPostModelUnitCardContent = ({ classes, unit, modelUnitProps }) => {
  const rtl = document.body.dir === 'rtl'
  const { postTitle } = modelUnitProps
  const [commentsCount, setCommentsCount] = useState(0)
  useEffect(() => {
    const fetchCount = async () => {
      try {
        const res = await axios.get(`/comments/commentsCount/${unit._id}`)
        setCommentsCount(res.data.commentsCount)
      } catch (error) {}
    }
    if (unit._id) fetchCount()
  }, [unit._id])
  return (
    <section className={classes.titleWrapper}>
      <span className={classes.title}>
        {postTitle
          ? postTitle
          : unit?.feedsSystemCategory === 'room'
          ? rtl
            ? 'منشور مُثبَت'
            : 'Pinned Post'
          : rtl
          ? 'منشور مُشارك'
          : 'Shared Post'}
      </span>
      <span className={classes.subTitlesWrapper}>
        <span className={classes.subtitle}>
          {MultiLangNumber(unit?.likes?.length)} {rtl ? 'إعجابات' : 'likes'}
        </span>
        &bull;
        <span className={classes.subtitle}>
          {MultiLangNumber(commentsCount || 0)} {rtl ? 'تعليقات' : 'Comments'}
        </span>
        <span
          style={{
            display: unit?.feedsSystemCategory === 'room' ? 'none' : null,
          }}
        >
          &bull;
        </span>
        <span
          className={classes.subtitle}
          style={{
            display: unit?.feedsSystemCategory === 'room' ? 'none' : null,
          }}
        >
          {MultiLangNumber(unit?.shares?.length)} {rtl ? 'مشاركات' : 'Shares'}
        </span>
      </span>
    </section>
  )
}

const WrappedFeedPostModelUnitCardContent = withStyles(styles)(
  FeedPostModelUnitCardContent
)

export default WrappedFeedPostModelUnitCardContent
