import React from 'react'

const ListedDialogModelContent = ({
  list,
  ListUnitModelComponent,
  ListUnitModelComponentProps,
  socket,
  isDarkModeActive,
  interactionType,
}) => {
  return (
    <article style={{ color: isDarkModeActive && 'white' }}>
      {list?.map((element, elementIndex) => (
        <ListUnitModelComponent
          element={element}
          key={elementIndex}
          socket={socket}
          interactionType={interactionType}
          isDarkModeActive={isDarkModeActive}
          {...ListUnitModelComponentProps}
        />
      ))}
    </article>
  )
}

export default ListedDialogModelContent
