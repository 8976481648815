import React from 'react'
import { useTranslation } from 'react-i18next'
import NavigatorCardsModel from '../../../../../../navigatorCardsModel/NavigatorCardsModel'
import TitleHeader from '../../../../../../SmallComponents/Header'

const SimilarTasks = ({ hideSimilarTasks, task, tasks, DCGProps }) => {
  const unitsArray =
    tasks?.filter(
      (t) => t.TaskStatus === task?.TaskStatus && t.id !== task.id
    ) || []
  const { t } = useTranslation()
  const rtl = document.body.dir === 'rtl'
  return (
    <span
      style={{
        display: hideSimilarTasks || unitsArray.length === 0 ? 'none' : null,
      }}
    >
      <TitleHeader
        headerText={
          rtl
            ? `مهام ${t(task?.TaskStatus)} أخرى`
            : `Other ${task?.TaskStatus} Tasks`
        }
      />
      <NavigatorCardsModel
        unitsArray={unitsArray}
        {...DCGProps}
        modelUnitProps={{
          elementsImages: [],
          imageKey: 'task',
          windowProps: {
            windowCardName: 'task',
            windowType: 'task',
            DCGProps,
            hideSimilarTasks: true,
          },
        }}
      />
    </span>
  )
}

export default SimilarTasks
