import React from 'react'

const Partners = () => {
  const rtl = document.body.dir === 'rtl'
  return rtl ? (
    <>
      شركاء الشركة هم أطراف يشكلون ويمتلكون عملًا تجاريًا معًا ، أو لديهم نوع من
      التحالف مع شركة أخرى. قد يكون شركاء الشركة أفرادًا أو شركات أو شراكات أخرى
      أو كيانات قانونية أخرى. قد يساهم شركاء الشركة في رأس المال والعمالة
      والمهارات والخبرة في الأعمال التجارية.
      <br />
      بعض الأسئلة التي يمكن أن تساعد في تحديد شركاء الشركة هي:
      <br />
      <br />
      <ul>
        <li>
          من هم أهم شركائنا؟ يمكن أن يساعد هذا السؤال في تحديد أصحاب المصلحة
          الرئيسيين الذين لديهم تأثير كبير على نجاح الأعمال أو فشلها.
        </li>
        <li>
          من هم موردونا الرئيسيون؟ يمكن أن يساعد هذا السؤال في تحديد مصادر
          المواد الخام أو المعدات أو الخدمات أو المعلومات التي يحتاجها العمل.
        </li>
        <li>
          ما هي الأنشطة التي يمكن لشركائنا الرئيسيين القيام بها أو ما هي
          الكفاءات الأساسية التي يمتلكونها؟ يمكن أن يساعد هذا السؤال في تحديد
          الأنشطة ذات القيمة المضافة أو المهارات التي يوفرها الشركاء للشركة أو
          لعملائها.
        </li>
        <li>
          ما الذي يخرجه الشركاء الرئيسيون من خلال العمل لدينا وكيف يمكننا ربطهم
          بنا؟ يمكن أن يساعد هذا السؤال في تحديد الفوائد والحوافز التي تحفز
          الشركاء على التعاون مع الشركة والحفاظ على علاقة طويلة الأمد.
        </li>
      </ul>
    </>
  ) : (
    <>
      Company partners are parties that form and own a business together, or
      have some kind of alliance with another business. Company partners may be
      individuals, corporations, other partnerships, or other legal entities.
      Company partners may contribute capital, labor, skills, and experience to
      the business.
      <br />
      Some questions that can help define company partners are:
      <br />
      <br />
      <ul>
        <li>
          Who are our most important partners? This question can help identify
          the key stakeholders that have a significant impact on the business's
          success or failure.
        </li>
        <li>
          Who are our main suppliers? This question can help identify the
          sources of raw materials, equipment, services, or information that the
          business needs to operate.
        </li>
        <li>
          What activities our main partners can undertake or what essential
          competencies do they have? This question can help identify the
          value-added activities or skills that the partners provide to the
          business or its customers.
        </li>
        <li>
          What do main parters get out by working for us and how can we bind
          them to us? This question can help identify the benefits and
          incentives that motivate the partners to collaborate with the business
          and maintain a long-term relationship.
        </li>
        <li></li>
      </ul>
    </>
  )
}

export default Partners
