import React from 'react'
import GPDFDBodyModel from '../GeneralPDFDocumentComps/GPDFDBodyModel'
import { MarketingStrategyAspectsConfigs } from '../../../../../suggestionsSystem/HashTagSuggestSystemComponents'

const ProductStrategyPDFBody = ({ button }) => {
  const { element } = button.PDFGenProps
  const chosenCards =
    element?.modelTempsIds.map((id) =>
      MarketingStrategyAspectsConfigs().find((card) => card?.id === id)
    ) || []

  const configs = chosenCards?.map((card) => ({
    title: card?.title,
    titleValue:
      (element?.details &&
        element?.details[card?.title?.split(' ')?.join('')]) ||
      '',
    disableTrans: true,
  }))

  return (
    <GPDFDBodyModel
      hideCreator
      GPDFBodyModelRightProps={{
        button,
        configs: configs,
      }}
      button={button}
    />
  )
}

export default ProductStrategyPDFBody
