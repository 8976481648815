import React from 'react'
import { Card, CardContent, CardMedia } from '@mui/material'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  card: {
    width: '170px',
    maxWidth: '200px',
    transition: '0.3s !important',
    cursor: 'pointer !important',
    opacity: '0.8',
    alignSelf: 'center !important',
    borderRadius: '10px !important',
    boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
    height: 180,
    margin: 10,
    '&:hover': {
      boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)',
      width: '190px',
      opacity: '1',
    },
  },
  media: {
    height: 0,
    paddingTop: '56.25%',
    marginTop: '30',
    borderRadius: '0',
  },
  cardHeader: {
    cursor: 'pointer',
    fontSize: '17px !important',
    display: 'flex',
    justifyContent: 'space-around !important',
    alignContent: 'center !important',
    alignItems: 'center !important',
    width: '100%',
  },
  '@media (max-width: 600px)': {
    cardHeader: {
      fontSize: '14px !important',
    },
  },
})

const CardMediaModelII = ({ element, elementIndex, props, classes }) => {
  const { folderPathProps, modelProps, searchSystemProps, MFSGProps } = props
  const card = element
  const cardIndex = elementIndex
  const { setCardId } = MFSGProps
  const { setSecondLevelFolderPath } = folderPathProps

  const { setShowSearchInput, setSearchQuery, setSearchInputValue } =
    searchSystemProps

  return (
    <Card className={classes.card}>
      <CardMedia
        className={classes.media}
        image={card?.image}
        onClick={() => {
          setCardId(cardIndex)
          if (typeof setSecondLevelFolderPath === 'function')
            setSecondLevelFolderPath(card.title)
        }}
      />
      <CardContent
        onClick={() => {
          setCardId(cardIndex)
          if (typeof setSecondLevelFolderPath === 'function')
            setSecondLevelFolderPath(card.title)
        }}
      >
        <span className={classes.cardHeader}>{card.title}</span>
      </CardContent>
    </Card>
  )
}

const WrappedCardMediaModelII = withStyles(styles)(CardMediaModelII)

export default WrappedCardMediaModelII
