import React, { useState } from 'react'
import { AiFillEdit, AiOutlineSave } from 'react-icons/ai'
import withStyles from '@mui/styles/withStyles'
import PopoverMenu from '../../../popoverMenu/PopoverMenu'
import { BsBook } from 'react-icons/bs'
import { GiBlackBook } from 'react-icons/gi'

const styles = () => ({
  wrapper: {
    postion: 'absolute',
    right: 0,
    display: 'flex',
    justifyContent: 'space-around',
    alignContent: 'center',
    alignItems: 'center',
  },
})

const EditingButtons = ({
  classes,
  hideEditingTools,
  setEditing,
  editing,
  info,
  onSave,
  hideInfoTitles,
}) => {
  const rtl = document.body.dir === 'rtl'
  const [opened, setOpened] = useState(!info?.titleValue)
  const optionsArray = [
    {
      type: 'edit',
      funs: () => setEditing(true),
      disabled: editing,
    },
    {
      type: 'save',
      funs: () => {
        setEditing(false)
        if (typeof onSave === 'function') onSave()
      },
      disabled: !editing,
    },
  ]
  return (
    <section
      className={classes.wrapper}
      style={{
        display: hideEditingTools || info?.unEditable ? 'none' : null,
        marginLeft: !rtl && 10,
        marginRight: rtl && 10,
        transition: '0.3s',
      }}
    >
      {hideInfoTitles ? (
        <>
          <AiOutlineSave
            style={{
              opacity: editing ? 1 : 0.5,
              cursor: editing ? 'pointer' : 'auto',
              marginLeft: rtl && 10,
              marginRight: !rtl && 10,
            }}
            onClick={() => {
              setEditing(false)
              if (typeof onSave === 'function') onSave()
            }}
            size={20}
          />
          <AiFillEdit
            size={20}
            style={{
              cursor: 'pointer',
              marginLeft: rtl && 10,
              marginRight: !rtl && 10,
            }}
            onClick={() => setEditing(true)}
          />
        </>
      ) : (
        <PopoverMenu
          optionsArray={optionsArray}
          anchorOpenFuns={() => setOpened(true)}
          anchorCloseFuns={() => setOpened(false)}
          anchorComp={
            opened || editing ? (
              <BsBook size={20} style={{ cursor: 'pointer' }} />
            ) : (
              <GiBlackBook size={20} style={{ cursor: 'pointer'}} />
            )
          }
        />
      )}
    </section>
  )
}

const WrappedEditingButtons = withStyles(styles)(EditingButtons)

export default WrappedEditingButtons
