import React, { useContext } from 'react'
import IssueDetails from './IssueProfileComponents/IssueDetails'
import IssueElements from './IssueProfileComponents/IssueElements'
import IssueInteractionButtons from './IssueProfileComponents/IssueInteractionButtons'
import IssueMainContent from './IssueProfileComponents/IssueMainContent'
import IssueTop from './IssueProfileComponents/IssueTop'
import IssueContractGeneralAgreement from './IssueProfileComponents/IssueContractGeneralAgreement'
import { AuthContext } from '../../../../../../context/AuthContext'

const IssueProfile = (props) => {
  const { DCGProps, issue } = props
  const { isDarkModeActive } = DCGProps
  const { user } = useContext(AuthContext)
  return (
    <article
      style={{
        width: '100%',
        color: isDarkModeActive ? 'white' : null,
      }}
    >
      <IssueTop {...props} />
      <IssueDetails {...props} />
      {issue?.securityIndex === 0 && <IssueElements {...props} />}
      {issue?.securityIndex === 1 && (
        <IssueContractGeneralAgreement {...props} />
      )}
      <IssueMainContent {...props} />
      {user?._id !== issue?.userId && <IssueInteractionButtons {...props} />}
    </article>
  )
}

export default IssueProfile
