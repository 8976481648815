import { Button } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../context/AuthContext'
import WindowProps from '../windowViewer/WindowProps'
import WindowViewerSystem from '../windowViewer/WindowViewerSystem'
import withStyles from '@mui/styles/withStyles'
import axios from 'axios'
import ToastifyGeneralError from '../SmallComponents/ToastifyGeneralError'

const styles = () => ({
  button: {
    borderRadius: '10px !important',
    margin: '2px !important',
    textTransform: 'none !important',
    fontWeight: '550 !important',
    width: '100% !important',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important",
    borderRadius: '15px !important',
    backgroundColor: 'rgba(255,255,255,0.6) !important',
    filter: 'brightness(0.8) !important',
    '&:hover': {
      filter: 'brightness(1) !important',
    },
  },
})

const DefaultChannelButton = ({
  classes,
  DCGProps,
  element,
  projectId: projId,
  onGetOut,
}) => {
  const projectId = projId || DCGProps?.project?._id
  const { isWindowOpen, windowOpener, windowCloser } = WindowProps()
  const [channel, setChannel] = useState()
  const [contract, setContract] = useState()
  const { user } = useContext(AuthContext)
  const {
    _id,
    title,
    Title,
    hashTagType,
    userId: elementUserId,
    projectId: elementProjectId,
    image,
    images,
  } = element
  const rtl = document.body.dir === 'rtl'
  const elementImage = image || (images && images[0])
  useEffect(() => {
    const fetchChannel = async () => {
      try {
        const res = await axios.get(
          `/channels/channelsSystem/defaultChannel/${_id}/?` +
            `elementTitle=${title || Title}` +
            `&elementHashTagType=${hashTagType}` +
            (user?._id ? `&userId=${user._id}` : '') +
            (projectId ? `&projectId=${projectId}` : '') +
            (rtl ? `&rtl=${rtl}` : '') +
            (elementUserId ? `&elementUserId=${elementUserId}` : '') +
            (elementProjectId ? `&elementProjectId=${elementProjectId}` : '') +
            (elementImage ? `&image=${elementImage}` : '')
        )
        setChannel(res.data.channel)
        setContract(res.data.contract)
      } catch (error) {
        ToastifyGeneralError(error)
      }
    }
    if (element) fetchChannel()
  }, [_id, title, hashTagType, user?._id, projectId])

  return (
    <>
      <Button className={classes.button} onClick={() => windowOpener()}>
        {rtl ? 'القناة الإفتراضية' : 'Default Channel'}
      </Button>
      {channel && (
        <WindowViewerSystem
          isWindowOpen={isWindowOpen}
          windowCloser={windowCloser}
          {...DCGProps}
          props={{
            windowCloser,
            channel,
            DCGProps,
            onGetOut,
          }}
          windowType={'channel'}
        />
      )}
    </>
  )
}

const WrappedDefaultChannelButton = withStyles(styles)(DefaultChannelButton)

export default WrappedDefaultChannelButton
