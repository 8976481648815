import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../context/AuthContext'
import ButtonsSystem from '../../buttonsSystem/ButtonsSystem'
import ConfigsPropsExtractor from '../../feedsSystem/ConfigsPropsExtractor'
import PostGeneratorEngine from '../../feedsSystem/PostGeneratorEngine'
import NewFormDialogModel from '../../formsSystem/NewFormDialogModel'
import NotificationCreator from '../../notificationsSystem/NotificationCreator'
import WindowProps from '../../windowViewer/WindowProps'
import IssueDecsirptionDialog from './ContractButtonsComps/IssueDecsirptionDialog'
import BreakContractDialog from './ContractButtonsComps/BreakContractDialog'
import ToastifyGeneralError from '../../SmallComponents/ToastifyGeneralError'

const ContractButtons = (props) => {
  const { contract, DCGProps, users, projects, members } = props
  const { socket } = DCGProps
  const { user } = useContext(AuthContext)
  const { isWindowOpen, windowOpener, windowCloser } = WindowProps()
  const {
    isWindowOpen: isWindowOpenII,
    windowOpener: windowOpenerII,
    windowCloser: windowCloserII,
  } = WindowProps()
  const [textContent, setTextContent] = useState('')
  const [chosenHashTagElements, setChosenHashTagElements] = useState([])
  const newIssuePost = (issue) => {
    return {
      userId: user?._id,
      ...PostGeneratorEngine(
        {
          ...ConfigsPropsExtractor({
            newPost: true,
          }),
        },
        4,
        'home',
        issue
      ),
    }
  }
  const [isChannelAssociated, setIsChannelAssociated] = useState()
  useEffect(() => {
    const fetchResults = async () => {
      try {
        const res = await axios.get(
          `/contracts/contractChecker/${contract?._id}`
        )
        setIsChannelAssociated(res.data.result)
      } catch (error) {}
    }
    if (contract?._id) fetchResults()
  }, [contract?._id])
  const [option, setOption] = useState(0)
  const activeNotification = async ({
    associativity,
    relation,
    conditionIndex,
    category,
    elements,
  }) => {
    members?.map((m) => {
      socket.current.emit('addNotifUser', m)
      NotificationCreator({
        associativity,
        relation,
        conditionIndex,
        accompaniedData: {
          elements: [
            {
              hashTagType: contract?.hashTagType,
              id: contract?._id,
              title: contract?.Title,
            },
            ...elements,
          ],
        },
        category,
        userId: m,
        currentUser: user,
        socket,
      })
    })
  }
  const rtl = document.body.dir === 'rtl'
  const buttonsArray = [
    {
      title: rtl ? 'إنهاء' : 'Break',
      funs: () => {
        windowOpenerII()
      },
      disabled: isChannelAssociated || !contract?.usersIds?.includes(user?._id),
    },
    {
      title: rtl ? 'إثارة مشكلة' : 'Invoke',
      funs: () => {
        windowOpener()
      },
    },
    {
      funs: null,
      type: 'PDF',
      PDFGenProps: {
        type: 'contract',
        fileName: contract?.Title,
        contract,
        users,
        projects,
      },
    },
  ]

  const activeFun = async () => {
    try {
      const res = await axios.post(`/issues`, {
        Title: rtl
          ? `مشكلة بـ ${contract?.Title}`
          : `${contract?.Title}'s Issue`,
        userId: user?._id,
        contractId: contract?._id,
        chosenHashTagElements,
        content: textContent,
        usersIds: contract?.usersIds,
        projectsIds: contract?.projectsIds,
        securityIndex: option,
      })
      if (res.data.issue)
        activeNotification({
          associativity: 'issue',
          relation: 'issued',
          conditionIndex: 0,
          category: 'deals',
          elements: [
            {
              hashTagType: res.data.issue?.hashTagType,
              id: res.data.issue?._id,
              title: res.data.issue?.Title,
            },
          ],
        })
      const newPost = res.data.issue && newIssuePost(res.data.issue)
      if (newPost) await axios.post(`/posts`, newPost)
      await axios.patch(`/contracts/${contract?._id}`, {
        ContractStatus: 'Pending',
      })
    } catch (error) {
      ToastifyGeneralError(error)
    }
  }

  const formsCompsProps = {
    textContent,
    setTextContent,
    chosenHashTagElements,
    setChosenHashTagElements,
    placeholder: rtl ? 'ما هو موضوع مشكلتك؟' : 'What is your issue is about?',
    ...DCGProps,
  }
  return (
    <>
      <ButtonsSystem buttonsArray={buttonsArray} {...DCGProps} />
      <NewFormDialogModel
        NewFormComponent={
          <IssueDecsirptionDialog
            DCGProps={DCGProps}
            windowCloser={windowCloser}
            activeFun={activeFun}
            formsCompsProps={formsCompsProps}
            option={option}
            setOption={setOption}
          />
        }
        newFormDialogClose={windowCloser}
        isNewFormDialogOpen={isWindowOpen}
        {...DCGProps}
        clickAwayState={true}
        dialogTitle={rtl ? 'إثارة مشكلة' : 'Invoking Issue'}
      />
      <NewFormDialogModel
        NewFormComponent={
          <BreakContractDialog windowCloser={windowCloserII} {...props} />
        }
        newFormDialogClose={windowCloserII}
        isNewFormDialogOpen={isWindowOpenII}
        {...DCGProps}
        clickAwayState={true}
        dialogTitle={rtl ? 'فسخ العقد' : 'Break Contract'}
      />
    </>
  )
}

export default ContractButtons
