import axios from 'axios'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ModifiedFormChecker } from '../../'
import { AuthContext } from '../../../../../context/AuthContext'
import { FeedContext } from '../../../../../context/FeedContext/FeedContext'
import NotificationCreator from '../../../../notificationsSystem/NotificationCreator'

export default function PostUpdateButtonConfig({ shareSystemProps }) {
  const {
    shareDialogClose,
    disablePostTypeOptions,
    setIsPostUpdated,
    post,
    postData,
    setPosts,
    socket,
  } = shareSystemProps
  const { user } = useContext(AuthContext)
  const { dispatch, notification } = useContext(FeedContext)
  const { t } = useTranslation()

  return {
    title: t('Update'),
    funs: async () => {
      shareDialogClose()
      if (typeof setIsPostUpdated === 'function') setIsPostUpdated(true)
      if (post?._id)
        try {
          const res = await axios.patch(`/posts/${post?._id}`, postData)
          dispatch({
            type: 'UPDATE_POST',
            payload: post,
          })
          setPosts((posts) => {
            let oldIndex = posts?.findIndex((p) => p._id === post?._id)
            return posts.map((p, i) => (i === oldIndex ? res.data.post : p))
          })
          if (post?._id && notification?.mentionedUsersIds) {
            Promise.all(
              notification?.mentionedUsersIds?.map((id) =>
                NotificationCreator({
                  associativity: 'post',
                  relation: 'mentioned',
                  conditionIndex: 0,
                  accompaniedData: { postId: post?._id },
                  category: 'general',
                  userId: id,
                  currentUser: user,
                  socket,
                })
              )
            )
            dispatch({
              type: 'REMOVE_MENTION',
            })
          }
        } catch (error) {
          console.log(error)
        }
    },
    displayConditions: disablePostTypeOptions ? null : 'none',
    disabled:
      ModifiedFormChecker(
        [
          { title: 'postTextContent' },
          { title: 'images' },
          { title: 'privacyType' },
          { title: 'playerLink' },
        ],
        postData,
        false,
        post || {}
      ) ||
      (!postData?.postTextContent &&
        !postData?.images?.length > 0 &&
        !postData?.playerLink),
  }
}
