import { Button } from '@mui/material'
import React, { useContext, useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import MultiLangNumber from '../../../../SmallComponents/MultiLangNumber'
import axios from 'axios'
import { AuthContext } from '../../../../../context/AuthContext'

const styles = () => ({
  button: {
    backgroundColor: 'rgb(210, 221, 240, 0.6) !important',
    borderRadius: '10px !important',
    margin: '2px !important',
    textTransform: 'none !important',
    color: 'white !important',
    fontWeight: '550 !important',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important",
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    flexFlow: 'row wrap',
  },
  '@media (max-width: 600px)': {
    button: {
      fontSize: '12px !important',
    },
  },
})

const TrustDistrustButtons = ({ classes, ...props }) => {
  const { user, portfolio } = props
  const { user: currentUser } = useContext(AuthContext)
  const [trustCount, setTrustCount] = useState(portfolio?.trust?.length)
  const [disTrustCount, setDisTrustCount] = useState(
    portfolio?.distrust?.length
  )
  const [isTrustButtonClicked, setIsTrustButtonClicked] = useState(
    portfolio?.trust?.includes(currentUser?._id) ||
      portfolio?.distrust?.includes(currentUser?._id)
  )
  const [isDisTrustButtonClicked, setIsDisTrustButtonClicked] = useState(
    portfolio?.distrust?.includes(currentUser?._id) ||
      portfolio?.trust?.includes(currentUser?._id)
  )
  const rtl = document.body.dir === 'rtl'

  return (
    <article className={classes.buttonsWrapper}>
      <Button
        className={classes.button}
        sx={{
          backgroundColor: isTrustButtonClicked
            ? 'blue !important'
            : 'rgb(210, 221, 240, 0.5) !important',
        }}
        variant='contained'
        disabled={isTrustButtonClicked}
        onClick={async () => {
          setTrustCount(trustCount + 1)
          setDisTrustCount(
            isDisTrustButtonClicked ? disTrustCount - 1 : disTrustCount
          )
          setIsTrustButtonClicked(true)
          setIsDisTrustButtonClicked(false)
          try {
            if (!portfolio?.trust?.includes(currentUser?._id))
              await axios.patch(`/portfolios/${portfolio?._id}`, {
                trust: [...portfolio?.trust, currentUser?._id],
              })
          } catch (error) {
            console.log(error)
          }
        }}
      >
        {rtl ? 'جدير بالثقة' : 'Trustworthy'} {MultiLangNumber(trustCount)}
      </Button>
      <Button
        className={classes.button}
        variant='contained'
        disabled={isDisTrustButtonClicked}
        sx={{
          backgroundColor: isDisTrustButtonClicked
            ? 'red !important'
            : 'rgb(210, 221, 240, 0.5) !important',
        }}
        onClick={async () => {
          setDisTrustCount(disTrustCount + 1)
          setTrustCount(isTrustButtonClicked ? trustCount - 1 : trustCount)
          setIsTrustButtonClicked(false)
          setIsDisTrustButtonClicked(true)
          try {
            if (!portfolio?.trust?.includes(currentUser?._id))
              await axios.patch(`/portfolios/${portfolio?._id}`, {
                trust: [...portfolio?.distrust, currentUser?._id],
              })
          } catch (error) {
            console.log(error)
          }
        }}
      >
        {rtl ? 'غير موثوق به' : 'Untrustworthy'}{' '}
        {MultiLangNumber(disTrustCount)}
      </Button>
      <Button
        variant='contained'
        className={classes.button}
        disabled={!(isTrustButtonClicked || isDisTrustButtonClicked)}
        onClick={async () => {
          setIsTrustButtonClicked(false)
          setIsDisTrustButtonClicked(false)
          try {
            if (portfolio?.trust?.includes(currentUser?._id)) {
              setTrustCount(isTrustButtonClicked ? trustCount - 1 : trustCount)
              await axios.patch(`/portfolios/${portfolio?._id}`, {
                trust: portfolio?.trust?.filter(
                  (u) => u?._id !== currentUser?._id
                ),
              })
            }
            if (portfolio?.distrust?.includes(currentUser?._id)) {
              setDisTrustCount(
                isDisTrustButtonClicked ? disTrustCount - 1 : disTrustCount
              )
              await axios.patch(`/portfolios/${portfolio?._id}`, {
                trust: portfolio?.distrust?.filter(
                  (u) => u?._id !== currentUser?._id
                ),
              })
            }
          } catch (error) {
            console.log(error)
          }
        }}
      >
        {rtl ? 'إزالة' : 'Remove'}{' '}
        {isTrustButtonClicked
          ? rtl
            ? 'جدير بالثقة'
            : 'Trustworthy'
          : isDisTrustButtonClicked
          ? rtl
            ? 'غير موثوق به'
            : 'Untrustworthy'
          : rtl
          ? 'رأى'
          : 'Opinion'}
      </Button>
    </article>
  )
}

const WrappedTrustDistrustButtons = withStyles(styles)(TrustDistrustButtons)

export default WrappedTrustDistrustButtons
