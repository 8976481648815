import React from 'react'
import { Text, StyleSheet } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  title: {
   fontFamily: 'Roboto',
    fontSize: 14,
    marginBottom: 10,
    textTransform: 'uppercase',
  },
})

const GPDFDTitle = ({ children }) => <Text style={styles.title}>{children}</Text>

export default GPDFDTitle
