import { useState, useEffect } from 'react'

const PostProps = (postProps, propsPackages) => {
  const { setIsCommentingSystemActive } = postProps
  //comments dialog funs
  const [isCommentsDialogOpen, setIsCommentsDialogOpen] = useState(false)
  const commentsDialogOpener = () => {
    setIsCommentsDialogOpen(true)
  }
  const commentsDialogClose = () => {
    setIsCommentsDialogOpen(false)
  }

  // for TextPost
  useEffect(() => {
    if (typeof setIsCommentingSystemActive === 'function')
      setIsCommentingSystemActive(isCommentsDialogOpen)
  }, [isCommentsDialogOpen])

  // ----------------------------
  // image viewer:
  const [isImageViewerDialogOpen, setIsImageViewerDialogOpen] = useState(false)
  const imageViewerDialogOpener = () => {
    setIsImageViewerDialogOpen(true)
  }
  const imageViewerDialogClose = () => {
    setIsImageViewerDialogOpen(false)
  }

  // ----------------
  //Share dialog funs
  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false)
  const shareDialogOpener = () => {
    setIsShareDialogOpen(true)
  }
  const shareDialogClose = () => {
    setIsShareDialogOpen(false)
  }

  // ------------------
  // edit system
  const [isPostEditDialogOpen, setIsPostEditDialogOpen] = useState(false)
  const postEditDialogOpener = () => {
    setIsPostEditDialogOpen(true)
  }
  const postEditDialogClose = () => {
    setIsPostEditDialogOpen(false)
  }

  const imageViewerProps = propsPackages.imageViewerProps
    ? {
        imageViewerDialogOpener,
        imageViewerDialogClose,
        isImageViewerDialogOpen,
      }
    : {}

  const commentsDialogProps = propsPackages.commentsDialogProps
    ? {
        commentsDialogOpener,
        commentsDialogClose,
        isCommentsDialogOpen,
      }
    : {}

  const shareSystemProps = propsPackages.shareSystemProps
    ? { shareDialogOpener, shareDialogClose, isShareDialogOpen }
    : {}

  const editingSystemProps = propsPackages.editingSystemProps
    ? { postEditDialogOpener, postEditDialogClose, isPostEditDialogOpen }
    : {}
  return {
    ...postProps,
    ...imageViewerProps,
    ...commentsDialogProps,
    ...shareSystemProps,
    ...editingSystemProps,
  }
}

export default PostProps
