import React from 'react'
import FreeBar from '../FreeBar'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  controlPanelWrappingCard: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'auto !important',
    width: '100% !important',
  },
  wrapper: {
    overflowY: 'hidden',
    zIndex: 1,
    overflowX: 'auto',
    width: '100vw',
    display: 'flex',
    flexDirection: 'row',
  },
  controlPanelButtonCard: {
    display: 'flex',
    width: '100%',
    cursor: 'pointer',
    backgroundColor: 'rgb(210, 221, 240) !important',
    minWidth: '45vw',
    alignContent: 'center !important',
    alignItems: 'center !important',
    justifyContent: 'space-around !important',
  },
  titleIconWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 500,
    fontSize: 15,
    width: '100%',
    padding: 2,
  },
  buttonicon: {
    display: 'flex',
    flexDirection: 'row',
    width: 'auto',
  },
  buttonName: {
    color: 'white',
    display: 'flex',
    flexDirection: 'row',
    width: 'auto',
  },
})

const MControlPanelSkeleton = ({ classes, hide }) => {
  return (
    <div
      className={classes.controlPanelWrappingCard}
      style={{ display: hide ? 'none' : 'flex' }}
    >
      <article className={classes.wrapper}>
        {Array.from({ length: 4 }, (_, index) => (
          <span key={index}>
            <div className={classes.controlPanelButtonCard}>
              <span
                className={classes.titleIconWrapper}
                style={{
                  animation: 'skeleton 1s ease infinite alternate',
                }}
              >
                <span
                  className={classes.buttonName}
                  style={{ width: '100%', borderRadius: 20 }}
                >
                  <FreeBar height={20} />
                </span>
              </span>
            </div>
          </span>
        ))}
      </article>
    </div>
  )
}

const WrappedMControlPanelSkeleton = withStyles(styles)(MControlPanelSkeleton)

export default WrappedMControlPanelSkeleton
