import React, { useState, useEffect } from 'react'
import Media from 'react-media'
import EditableInfoUnitMob from './EditableInfoUnitMob'
import EditableInfoUnitPC from './EditableInfoUnitPC'

const EditableInfoUnit = ({ gridProps, info }) => {
  const { setInfoData, infoData } = gridProps
  const [infoValue, setInfoValue] = useState(info.titleValue || '')
  const [editing, setEditing] = useState(!infoValue)
  useEffect(() => {
    if (typeof setInfoData === 'function')
      setInfoData({
        ...infoData,
        [info.title.split(' ').join(info?.separator || '')]: infoValue,
      })
  }, [infoValue])
  const gridPropsII = {
    ...gridProps,
    info,
    infoValue,
    editing,
    setInfoValue,
    setEditing,
  }
  const mediaComponents = [
    <EditableInfoUnitPC gridProps={gridPropsII} />,
    <EditableInfoUnitMob gridProps={gridPropsII} />,
  ]
  return info?.wideMode ? (
    mediaComponents[1]
  ) : (infoValue?.length < 50 && !info?.helpertext) ||
    typeof infoValue === 'number' ? (
    mediaComponents[0]
  ) : (
    <Media query='(max-width: 600px)'>
      {(matches) => {
        return matches ? mediaComponents[1] : mediaComponents[0]
      }}
    </Media>
  )
}

export default EditableInfoUnit
