import { MenuItem, Select } from '@mui/material'
import React, { useState, useEffect } from 'react'
import withStyles from '@mui/styles/withStyles'
import { useTranslation } from 'react-i18next'

const styles = () => ({
  select: {
    fontSize: '14px !important',
    appearance: 'none !important',
    outline: 'none !important',
  },
  optionItem: {
    fontSize: '14px !important',
  },
  '@media (max-width: 600px)': {
    select: {
      fontSize: '12px !important',
      appearance: 'none !important',
      outline: 'none !important',
    },
    optionItem: {
      fontSize: '12px !important',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    optionsIcons: {
      margin: ' 0 10px 0 2px ',
    },
  },
})

const SimpleSelectModel = ({
  classes,
  setClickAwayState,
  optionsSetState,
  optionsState,
  isDarkModeActive,
  element,
  initialFormData,
}) => {
  const {
    menuOptions,
    title,
    value,
    customMenuItem,
    CustomMenuItemComponent,
    disabled,
  } = element
  const stateName = title
  const defaultOption = value

  // share options
  const [shareOption, setshareOption] = useState(
    initialFormData
      ? initialFormData[title?.split(' ')?.join('')]
      : defaultOption
  )
  const selectShareOptionHandler = (event) => {
    setshareOption(event.target.value)
  }

  useEffect(() => {
    if (typeof setClickAwayState === 'function') setClickAwayState(false)
  }, [shareOption])

  useEffect(() => {
    optionsSetState({
      ...optionsState,
      [stateName.split(' ').join('')]:
        typeof shareOption === 'string'
          ? shareOption?.split('_')?.join(' ')
          : shareOption,
    })
  }, [shareOption])
  const { t } = useTranslation()
  return (
    <Select
      disabled={disabled}
      value={shareOption}
      onChange={(event) => {
        selectShareOptionHandler(event)
      }}
      autoWidth
      className={classes.select}
      style={{
        color: isDarkModeActive ? 'white' : 'black',
        fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
      }}
      variant='standard'
      // to suspend the clickaway effect until i end my work in the compoennt in which this component belongs
      onOpen={() => {
        if (typeof setClickAwayState === 'function') setClickAwayState(true)
      }}
    >
      {menuOptions?.map((option, optionIndex) => (
        <MenuItem
          value={option}
          key={optionIndex}
          className={classes.optionItem}
        >
          {customMenuItem ? (
            <CustomMenuItemComponent element={option} />
          ) : (
            <span
              style={{
                fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
              }}
            >
              {t(option)}
            </span>
          )}
        </MenuItem>
      ))}
    </Select>
  )
}

const WrappedSimpleSelectModel = withStyles(styles)(SimpleSelectModel)

export default WrappedSimpleSelectModel
