import { Divider, List, ListItem } from '@mui/material'
import React from 'react'

const ContractTerms = ({ isDarkModeActive }) => {
  const rtl = document.body.dir === 'rtl'
  const terms = rtl
    ? [
        'يعتبر العقود أو الاتفاقات المستخدمة في هوتوبيا أداة لتسهيل الاتفاقات بين المساهمين المحتملين. ولا يتم اعتبارها ملزمة قانونياً من قبل الموقع، ويتطلب التسجيل القانوني في حال تم التوصل إلى اتفاق ويتم استخدام نسخة PDF لأغراض أخرى.',
        'ستظل النسخة الرقمية من الاتفاق على النظام طالما لم يقرر المساهمون إزالتها. لن يتم حذف أو تعديل النسخة الرقمية بأي شكل من الأشكال إلا بموافقة جميع الأطراف المشاركة في الاتفاق.',
        'تُستخدم النسخ الرقمية لتتبع سلوك المساهمين في حالة وجود سلوك غير قانوني أو في حالة الاحتيال. كما يتم استخدام النسخة الرقمية لحساب تصنيف هوتوبيا للمساهمين. سيؤثر أي خرق للشروط المتفق عليها بين المساهمين، والتي يتم متابعتها برفع شكاوى ضد المخالفين، على جدول تقييم هوتوبيا والتوصيات، وسينذر المستخدمين الآخرين.',
      ]
    : [
        'The contracts or agreements used in Hutupia are considered as a tool to facilitate agreements between potential contributors. They are not considered legally binding by the website, and legal registration is required if an agreement has been reached and the PDF copy is intended for further use.',
        'The digital copy of the agreement will remain on the system as long as the contributors have not decided to remove it. The digital format will not be deleted or modified in any way unless approved by all parties included in the agreement.',
        'Digital copies are used to track the behavior of contributors in case of illegal behavior or fraud. The digital format is also used in the calculation of the Hutupia Rank of contributors. Any violation of the agreed terms between the contributors, which is followed by raising issues against the violators, will affect the Hutupia assessment chart and recommendations, and will alert other users.',
      ]
  return (
    <>
      <Divider
        style={{
          backgroundColor: isDarkModeActive ? 'white' : null,
          width: '70%',
          marginTop: 10,
        }}
      />
      <ul style={{ fontSize: 12, color: isDarkModeActive ? 'white' : null }}>
        {terms.map((term, index) => (
          <li key={index} style={{ padding: '2px auto' }}>
            {term}
          </li>
        ))}
      </ul>
    </>
  )
}

export default ContractTerms
