import { useTranslation } from 'react-i18next'

const SegmentLockOut = () => {
  const { t } = useTranslation('SegmentLockOut')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 30,
    category: t('category_ms30'),
    title: t('title_ms30'),
    subTitle: t('subTitle_ms30'),
    main: t('main_ms30'),
    how: t('how_ms30'),
    usedInModels: [],
    tags: rtl
      ? [
          'ميزة تنافسية',
          'القيمة السوقية',
          'أرباح / خسائر',
          'تأمين المنافسين خارج',
          ' آثار طويلة المدى',
          'مسابقة جديدة',
          'أهداف تنظيمية',
        ]
      : [
          'Segment Lockout',
          'Competitive Advantage',
          'Market Value',
          'Profits/Losses',
          'Lock Out Competitors',
          'Long-Term Effects',
          'New Competition',
          'Organizational Goals',
        ],
    examples: t('examples_ms30'),
    hashTagType: 'markStraAspect',
  }
}

export default SegmentLockOut
