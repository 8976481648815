import { useTranslation } from 'react-i18next'

const ValueMigration = () => {
  const { t } = useTranslation('ValueMigration')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 18,
    category: t('category_ms18'),
    title: t('title_ms18'),
    subTitle: t('subTitle_ms18'),
    main: t('main_ms18'),
    how: t('how_ms18'),
    usedInModels: [],
    tags: rtl
      ? ['إدراك الزبائن', 'مصداقية', "ميزة تنافسية'", 'خطة الهجرة']
      : [
          'Customer Perception',
          'Reliability',
          'Competitive Edge',
          'Migration Plan',
        ],
    examples: t('examples_ms18'),
    hashTagType: 'markStraAspect',
  }
}

export default ValueMigration
