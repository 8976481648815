const LastHashTagCalculator = ({ textContent }) => {
  const hashTagSymbolsArray = ['@', '$']
  const separateWords = textContent.split(' ')

  // breaks disables the tagging, so i need to fix this- this fixes it
  const breaksRemover = (separateWords) => {
    let CleanWords = []
    separateWords.forEach((word) => {
      word.includes('\n')
        ? CleanWords.push(...word.split('\n'))
        : CleanWords.push(word)
    })
    return CleanWords
  }

  // detecting the tagged words so i can edit the text anywhere and not only at the end of the text

  const alreadyTaggedWordsRemover = (taggedWords) => {
    const cluttered = taggedWords.filter(
      (word) => !word.slice(1, word.length).includes('$')
    )
    return cluttered[cluttered.length - 1]
  }

  const taggedWords = breaksRemover(separateWords).filter((word) =>
    hashTagSymbolsArray.find((symbol) => symbol === word.substring(0, 1))
  )

  const lastHashTag = alreadyTaggedWordsRemover(taggedWords)
  const lastHashTagSymbol = hashTagSymbolsArray.filter(
    (symbol) => symbol === lastHashTag?.substring(0, 1)
  )[0]
  return { lastHashTag, lastHashTagSymbol }
}

export default LastHashTagCalculator
