import { useTranslation } from 'react-i18next'

const BarterBusinessModel = () => {
  const { t } = useTranslation('BarterBusinessModel')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 7,
    title: t('title_bm07'),
    subTitle: t('subTitle_bm07'),
    main: t('main_bm07'),
    when: t('when_bm07'),
    who: t('who_bm07'),
    what: t('what_bm07'),
    how: t('how_bm07'),
    why: t('why_bm07'),
    examples: rtl
      ? [
          'Procter & Gamble (P&G) - تأسست عام 1837 - أوهايو بالولايات المتحدة الأمريكية',
          'PepsiCo - تأسست عام 1965 - الولايات المتحدة الأمريكية',
          'تويتر - تأسس عام 2006 - الولايات المتحدة الأمريكية',
        ]
      : [
          'Procter & Gamble (P&G) - Founded 1837 - Ohio USA',
          'PepsiCo - Founded 1965 - USA',
          'Twitter - Founded 2006 - USA',
        ],
    tags: rtl
      ? [
          'رعاية',
          'عميل محتمل',
          'تبادل المنتجات',
          'شركاء',
          'الصناعات الغذائية',
          'عرض تلفزيوني',
          'إعلانات إذاعية',
          'عينات المبيعات',
          'إعلانات وسائل التواصل الاجتماعي',
        ]
      : [
          'Sponsoring',
          'Potential Customer',
          'Products Exchange',
          'Partners',
          'Food Industry',
          'TV Shows',
          'Radio Advertising',
          'Sales Samples',
          'Social Media Ads',
        ],
    considerations: t('considerations_bm07'),
    combinations: ['$LBM14', '$LBM21', '$LBM2'],
    hashTagType: 'businessModelTemp',
  }
}

export default BarterBusinessModel
