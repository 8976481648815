import { useContext } from 'react'
import { AuthContext } from '../../../../../context/AuthContext'

const NewIdeaFormsConfigs = (idea) => {
  //  this will add him to the possible candidates for shared resources, and he will describe waht he can do with these resources and the offers he can make -- transportation through a certain path, storage in a certain place under certain conditions, ....etc -- this will put to the shares resources feed.
  //  if he wants to create open contract (this will be an option also in the global inquiry)
  const { user } = useContext(AuthContext)
  const disabled = user?._id !== idea?.userId
  const categories = [
    'Medical_Industry',
    'Domestic_Projects',
    'Food_Industry',
    'Agencies',
    'Athletics',
    'Engineering',
    'Teaching',
    'Art',
  ]

  const ideaNature = [
    'Creative_New_Idea',
    'Expanding_Existing_Idea',
    'Compete_with_Others',
  ]

  const intendedPlan = ['Small_Business', 'Scalable_Start-up', 'Enterprise']

  const planStates = ['Existent', 'Need_Someone_to_Plan']
  const rtl = document.body.dir === 'rtl'

  return [
    {
      title: 'Title',
      formComponentType: 'text',
      value: idea ? idea?.Title : '',
      placeholder: rtl ? 'عنوان الفكرة' : 'Idea Title',
      required: true,
      disabled: idea ? disabled : false,
    },
    {
      title: 'Idea_Category',
      formComponentType: 'select',
      value: idea ? idea?.IdeaCategory : categories[0],
      menuOptions: categories,
      helpertext: rtl
        ? 'ما هو التصنيف العام للفكرة؟'
        : 'Under what general category this new idea stands.',
      disabled: idea ? disabled : false,
    },
    {
      title: 'Idea_Nature',
      formComponentType: 'select',
      value: idea ? idea?.IdeaNature : ideaNature[0],
      menuOptions: ideaNature,
      helpertext: '',
      disabled: idea ? disabled : false,
    },
    {
      title: 'Intended_Planning',
      formComponentType: 'select',
      value: idea ? idea?.IntendedPlanning : intendedPlan[0],
      menuOptions: intendedPlan,
      helpertext: '',
      disabled: idea ? disabled : false,
    },
    {
      title: 'Plan_Status',
      formComponentType: 'select',
      value: idea ? idea?.PlanStatus : planStates[0],
      menuOptions: planStates,
      helpertext: '',
      disabled: idea ? disabled : false,
    },
    {
      // this will deterime if the contribution button in the post dialog is enabled or not, this button will send contribution requests to the idea owner
      title: 'Contribution_Requests',
      formComponentType: 'select',
      value: idea ? idea?.ContributionRequests : 'No_Need',
      menuOptions: ['No_Need', 'Only_Contributors_and_Followers', 'Everyone'],
      helpertext: '',
      hideInPost: true,
      disabled: idea ? disabled : false,
    },
  ]
}

export default NewIdeaFormsConfigs
