import React from 'react'
import { Grid } from '@mui/material'
import ImageUnit from './ImageUnit'

const UpImagesViewerPC = ({ images, removeImage }) => {
  return (
    <Grid container>
      {images.map((image, imageIndex) => (
        <Grid item lg={6} key={imageIndex}>
          <ImageUnit
            image={image}
            imageIndex={imageIndex}
            removeImage={removeImage}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default UpImagesViewerPC
