import { ListItem } from '@mui/material'
import React, { useState, useEffect } from 'react'
import withStyles from '@mui/styles/withStyles'
import UserListItemCard from './SuggListItemComponents/UserListItemCard'
import SuggListItemCardGenerator from './SuggListItemComponents/SuggListItemCardGenerator'

const styles = () => ({
  listItem: {
    cursor: 'pointer !important',
    '&:hover': {
      color: 'gray !important',
    },
  },
  '@media (max-width)': {
    listItem: {
      marginBottom: '-2px !important',
    },
  },
})

const SuggListItem = ({ props, element, classes }) => {
  const {
    setIsSuggeCardClicked,
    textContent,
    isSuggeCardClicked,
    isTyping,
    setChosenHashtagComp,
    hashTagSymbol,
    searchQuery,
  } = props

  const [isSearchQueryChanged, setIsSearchQueryChanged] = useState(false)
  useEffect(() => {
    if (searchQuery) {
      setIsSearchQueryChanged(true)
    } else {
      setIsSearchQueryChanged(false)
    }
  }, [searchQuery])
  return (
    <ListItem
      className={classes.listItem}
      style={{
        display:
          textContent.includes(hashTagSymbol) &&
          !isSuggeCardClicked &&
          !isTyping &&
          isSearchQueryChanged
            ? null
            : 'none',
      }}
      onClick={() => {
        setIsSuggeCardClicked(true)
        setChosenHashtagComp(element)
      }}
    >
      {hashTagSymbol === '@' ? (
        <UserListItemCard element={element} />
      ) : hashTagSymbol === '$' ? (
        SuggListItemCardGenerator(element)
      ) : null}
    </ListItem>
  )
}

const WrappedSuggListItem = withStyles(styles)(SuggListItem)

export default WrappedSuggListItem
