import { Button } from '@mui/material'
import React from 'react'
import withStyles from '@mui/styles/withStyles'
import NotificationCreator from '../../../../../notificationsSystem/NotificationCreator'

const styles = () => ({
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: 10,
  },
  button: {
    borderRadius: '10px !important',
    margin: '2px !important',
    textTransform: 'none !important',
    color: 'white !important',
    fontWeight: '550 !important',
    width: '100% !important',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important",
  },
})

const NewContribFormButtons = ({ classes, newContribClose, isDataFilled }) => {
  const rtl = document.body.dir === 'rtl'
  return (
    <section className={classes.buttonsWrapper}>
      <Button
        variant='contained'
        className={classes.button}
        onClick={() => newContribClose()}
      >
        {rtl ? 'رجوع' : 'Back'}
      </Button>
      <Button
        disabled={!isDataFilled}
        sx={{ backgroundColor: isDataFilled ? null : 'GrayText' }}
        variant='contained'
        className={classes.button}
        onClick={() => {
          newContribClose()
          NotificationCreator(
            'this will push a notification for the added user, and he will be propted with approval or refusal button, which will complete the process of addion - after that a contract will be signed, may be the addition will push a contract to the other contributor so to sign it. '
          )
        }}
      >
        {rtl ? 'أضف' : 'Add'}
      </Button>
    </section>
  )
}

const WrappedNewContribFormButtons = withStyles(styles)(NewContribFormButtons)

export default WrappedNewContribFormButtons
