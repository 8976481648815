import React from 'react'
import '../skeleton.css'
import withStyles from '@mui/styles/withStyles'
import FreeBar from './FreeBar'

const styles = () => ({
  wrapper: {
    borderRadius: '20px !important',
    margin: '2px 5px !important',
    width: '100% !important',
    display: 'flex !important',
    flexDirection: 'column',
    animation: 'skeleton 1s ease infinite alternate',
    alignContent: 'space-around !important',
  },
  button: {
    borderRadius: '10px !important',
    margin: '2px !important',
    textTransform: 'none !important',
    fontWeight: '550 !important',
    width: '100% !important',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important",
  },
  title: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-evenly',
    fontWeight: 600,
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'space-between',
  },
  '@media (max-width: 600px)': {
    cardContentWrapper: {},
    wrapper: {
      borderRadius: '10px !important',
      minWidth: '40vw !important',
      height: 'auto !important',
    },
  },
})

const NavigatorCardSkeleton = ({ classes, isDarkModeActive }) => {
  return (
    <div
      className={classes.wrapper}
      sx={{
        background: isDarkModeActive ? 'rgba(255,255,255,0.2)' : null,
        color: isDarkModeActive ? 'white' : null,
      }}
    >
      <FreeBar />
    </div>
  )
}

const WrappedNavigatorCardSkeleton = withStyles(styles)(NavigatorCardSkeleton)

export default WrappedNavigatorCardSkeleton
