import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../../../../../../../../context/AuthContext'
import withStyles from '@mui/styles/withStyles'
import axios from 'axios'
import { Button } from '@mui/material'
import NotificationCreator from '../../../../../../../../../notificationsSystem/NotificationCreator'
import { toast } from 'react-toastify'
import ProtocolRunnerLoader from '../../../../../../../../../SmallComponents/ProtocolRunnerLoader'
import deleteFile from '../../../../../../../../../../firebase/deleteFile'
import { Navigate, useNavigate } from 'react-router-dom'

const styles = () => ({
  button: {
    borderRadius: '10px !important',
    margin: '2px !important',
    textTransform: 'none !important',
    fontWeight: '550 !important',
    width: '100% !important',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important",
    borderRadius: '15px !important',
    backgroundColor: 'rgba(255,255,255,0.6) !important',
    filter: 'brightness(0.8) !important',
    '&:hover': {
      filter: 'brightness(1) !important',
    },
  },
})

const GetOutChannelButton = ({
  channel,
  classes,
  DCGProps,
  onGetOut,
  windowCloser,
  setCardId,
  setSecondLevelFolderPath,
  setShowSearchInput,
  setSearchQuery,
  setSearchInputValue,
  setCards,
  externalLinkMode,
}) => {
  const { socket } = DCGProps
  const navigate = useNavigate()
  const { user } = useContext(AuthContext)
  const rtl = document.body.dir === 'rtl'
  const [members, setMembers] = useState([])
  const [processing, setProcessing] = useState(false)
  const generalPortalFun = () => {
    if (typeof setCardId === 'function') setCardId(undefined)
    if (typeof setSearchInputValue === 'function') setSearchInputValue('')
    if (typeof setSecondLevelFolderPath === 'function')
      setSecondLevelFolderPath('')
    if (typeof setShowSearchInput === 'function') setShowSearchInput(false)
    if (typeof setSearchQuery === 'function') setSearchQuery('')
    if (typeof setSearchInputValue === 'function') setSearchInputValue('')
    if (typeof setCards === 'function')
      setCards((els) => els.filter((d) => d?._id !== channel?._id))
  }

  const isLastUser =
    (channel?.usersIds?.length === 1 && channel?.projectsIds?.length === 0) ||
    (channel?.usersIds?.length === 0 && channel?.projectsIds?.length === 1)
  const [channelUrls, setChannelUrls] = useState([])
  useEffect(() => {
    const fetchChUrls = async () => {
      try {
        const res = await axios.get(`/urls/channel/${channel?._id}`)
        setChannelUrls(res.data.urls)
      } catch (error) {
        console.log(error)
      }
    }
    if (isLastUser) fetchChUrls()
  }, [channel?._id])
  useEffect(() => {
    const fetchCeoIds = async () => {
      try {
        const res = await axios.get(
          `/contracts/contractProjectsCeos/${channel?.contractId}`
        )
        setMembers(res.data.ceosIds)
      } catch (error) {
        console.log(error)
      }
    }
    if (channel?.contractId) fetchCeoIds()
  }, [channel?.contractId])

  const projectId = user?.createdProjects?.find((p) =>
    channel?.projectsIds?.includes(p)
  )
  const [gotOut, setGotOut] = useState(false)
  const action = async () => {
    setProcessing(true)
    try {
      const res = await axios.patch(
        `/channels/remContributor/${channel?._id}`,
        channel?.usersIds?.includes(user?._id)
          ? {
              userId: user?._id,
            }
          : {
              projectId,
            }
      )

      if (res.data) {
        const elements = res.data.elements
        const mainElement = res.data.mainElement
        const hashTagType = mainElement?.hashTagType
        // removing elements
        await axios.patch(`/channels/${channel?._id}`, {
          elements: channel?.elements?.filter(
            (e) => !elements?.includes(e?.id)
          ),
        })
        if (typeof onGetOut !== 'function')
          if (hashTagType === 'idea') {
            // removing the user from contributors of the element
            await axios.patch(`/ideas/${mainElement?._id}`, {
              ideaContributorsIds: mainElement?.ideaContributorsIds?.filter(
                (u) => u !== user?._id
              ),
              permissionedUserIds: mainElement?.permissionedUserIds?.filter(
                (u) => u !== user?._id
              ),
            })
          } else if (hashTagType === 'globalInquiry') {
            await axios.patch(`/globalInquiries/${mainElement?._id}`, {
              globalInquiryContributorsIds:
                mainElement?.globalInquiryContributorsIds?.filter(
                  (u) => u !== user?._id
                ),
            })
          } else if (hashTagType === 'campaign') {
            await axios.patch(`/campaigns/${mainElement?._id}`, {
              campaignContributorsIds:
                mainElement?.campaignContributorsIds?.filter(
                  (u) => u !== user?._id
                ),
            })
          } else if (hashTagType === 'resource') {
            await axios.patch(`/resources/${mainElement?._id}`, {
              usersIds: mainElement?.usersIds?.filter((u) => u !== user?._id),
              projectsIds: mainElement?.projectsIds?.filter(
                (p) => p !== projectId
              ),
            })
          } else if (hashTagType === 'inquiry') {
            await axios.patch(`/inquiries/${mainElement?._id}`, {
              pairedUserId: null,
            })
          }
      }

      if (typeof windowCloser === 'function') windowCloser()
      toast.info(
        rtl
          ? `لقد قمت بمغادرة ${channel?.Title || channel?.title}`
          : `You have just left ${channel?.Title || channel?.title}`
      )
      if (typeof onGetOut === 'function') onGetOut()
      //   notifying the members that someone got out
      members?.map((m) => {
        socket.current.emit('addNotifUser', m)
        NotificationCreator({
          associativity: 'channel',
          relation: 'holders',
          conditionIndex: 0,
          accompaniedData: {
            elements: [
              {
                id: channel._id,
                hashTagType: channel.hashTagType,
                title: channel?.Title || channel?.title,
              },
            ],
          },
          category: 'contributors',
          userId: m,
          currentUser: user,
          socket,
        })
      })
      // deleting the channel if the last user
      if (isLastUser) {
        await axios.delete(`/channels/${channel?._id}`)
        if (channelUrls)
          Promise.all(
            channelUrls?.map((image) => {
              if (image) deleteFile(image)
            })
          )
      }
      generalPortalFun()
    } catch (error) {
      console.log(error)
    }
    // in case of external link
    if (externalLinkMode) navigate('/home')
    setProcessing(false)
    setGotOut(true)
  }

  return (
    <Button
      className={classes.button}
      onClick={() => action()}
      disabled={processing || gotOut}
    >
      {rtl ? (
        processing ? (
          <ProtocolRunnerLoader text='جارى تنفيذ بروتوكول انهاء التشارك' />
        ) : gotOut ? (
          'لقدت خرجكت بالفعل، قم بإعادة تحميل الصفحة'
        ) : (
          'خروج'
        )
      ) : processing ? (
        <ProtocolRunnerLoader text='Partnership Ending Protocol is Running' />
      ) : gotOut ? (
        'You are out, refresh the page'
      ) : (
        'Get Out'
      )}
    </Button>
  )
}

const WrappedGetOutChannelButton = withStyles(styles)(GetOutChannelButton)

export default WrappedGetOutChannelButton
