import React, { useEffect, useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import PTLImage from './PTLeftComponents/PTLImage'
import axios from 'axios'
import PTLeftName from './PTLeftComponents/PTLeftName'
import PTLeftFlags from './PTLeftComponents/PTLeftFlags'
import PTLeftFollow from './PTLeftComponents/PTLeftFollow'
import WindowSize from '../../SmallComponents/WindowSize'

const styles = () => ({
  postTopLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  postInfo: {
    fontSize: 20,
    fontWeight: 600,
    margin: 10,
    cursor: 'pointer',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
  },
  '@media (max-width: 600px)': {
    postInfo: {
      fontSize: 16,
      fontWeight: 550,
      margin: 8,
    },
  },
})

const PTLeft = ({ classes, postProps }) => {
  const { post, user: postUser, project, hideAddUserIcon } = postProps
  const [user, setUser] = useState(postUser?._id === post?.userId && postUser)
  useEffect(() => {
    const fetchRepUser = async () => {
      try {
        const res = await axios.get(`/users/oneUser/${post?.userId}`)
        setUser(res.data)
      } catch (error) {
        console.log(error)
      }
    }
    const fetchReProject = async () => {
      try {
        const res = await axios.get(`/projects/oneProject/${post?.userId}`)
        setUser(res.data.project)
      } catch (error) {
        console.log(error)
      }
    }
    if (
      (!postUser && post.feedsSystemCategory === 'home') ||
      (postUser?._id !== post?.userId && post.feedsSystemCategory === 'home')
    ) {
      fetchRepUser()
    } else if (!postUser || postUser?._id !== post?.userId) {
      fetchReProject()
    }
  }, [postUser, post?.userId])
  const widnwoSize = WindowSize()
  const wz = widnwoSize > 600
  return (
    <article className={classes.postTopLeft}>
      <PTLImage postProps={postProps} user={user} wz={wz} />
      <section className={classes.postInfo}>
        <PTLeftName postProps={postProps} user={user} wz={wz} />
        <PTLeftFlags postProps={postProps} user={user} wz={wz} />
      </section>
      {!hideAddUserIcon &&
        (user?.lastName || project?.ProjectName || user?.ProjectName) && (
          <PTLeftFollow postProps={postProps} user={user} wz={wz} />
        )}
    </article>
  )
}

const WrappedPTLeft = withStyles(styles)(PTLeft)

export default WrappedPTLeft
