import { Card, Popover } from '@mui/material'
import React, { useState } from 'react'
import { MdMoreVert } from 'react-icons/md'
import PopoverMenuOption from './PopoverMenuComps/PopoverMenuOption'

const PopoverMenu = ({
  optionsArray,
  hide,
  anchorComp,
  anchorOpenFuns,
  anchorCloseFuns,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handlePopoverClose = () => {
    setAnchorEl(null)
    if (typeof anchorCloseFuns === 'function') anchorCloseFuns()
  }
  const open = Boolean(anchorEl)
  const rtl = document.body.dir === 'rtl'
  return (
    <div
      style={{
        display: hide ? 'none' : null,
      }}
    >
      {anchorComp ? (
        <div
          onClick={(e) => {
            handlePopoverOpen(e)
            if (typeof anchorOpenFuns === 'function') anchorOpenFuns()
          }}
        >
          {anchorComp}
        </div>
      ) : (
        <MdMoreVert onClick={handlePopoverOpen} style={{ cursor: 'pointer' }} />
      )}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: rtl ? 'right' : 'left',
        }}
      >
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '10px',
          }}
        >
          {optionsArray?.filter((op)=> !op?.hideOp)?.map((option, index) => (
            <PopoverMenuOption
              key={index}
              option={option}
              handlePopoverClose={handlePopoverClose}
            />
          ))}
        </Card>
      </Popover>
    </div>
  )
}

export default PopoverMenu
