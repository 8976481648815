import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { AuthContext } from '../../../context/AuthContext'
import BusinessModelsConfigs from '../../projectProfile/ProjectProfileComponents/ProjectControlPanelComponents/ProjectModelingComponents/BusinessModelComponents/NavigateModelsComponents/BusinessModelsConfigs'
import MarketingStrategyAspectsConfigs from '../../projectProfile/ProjectProfileComponents/ProjectControlPanelComponents/ProjectModelingComponents/MarketingStrategiesComponents/MarketingStrategyAspectsComps/MarketingStrategyAspectsConfigs'
import SearchEngine from '../../searchSystem/SearchEngine'

const HashTagSystemData = (titleQuery, project, departmentId, subDepId) => {
  const [results, setResults] = useState([])
  const { user } = useContext(AuthContext)
  const projectId = useParams().projectId
  useEffect(() => {
    const fetchResults = async () => {
      try {
        const res = await axios.post(`/searchSystems/hashtagsearch`, {
          titleQuery,
          projectId: project?._id || projectId,
          userId: user?._id,
          departmentId,
          subDepId,
        })
        setResults(res.data.results)
      } catch (error) {
        console.log(error)
      }
    }
    if (titleQuery?.trim()?.length > 1) fetchResults()
  }, [titleQuery])
  const models =
    SearchEngine({
      searchQuery: titleQuery,
      searchElements: [
        ...BusinessModelsConfigs(),
        ...MarketingStrategyAspectsConfigs(),
      ],
      searchIdtentifiers: [
        'title',
        'tags',
        'main',
        'why',
        'who',
        'what',
        'how',
        'examples',
      ],
      firstLetterSearch: true,
      rawResults: true,
      alphabeticOrder: true,
      alphabeticOrderDir: true,
    }) || []
  return results?.concat(
    models.map((m) => ({
      id: m?.id,
      title: m?.title,
      hashTagType: m?.hashTagType,
    }))
  )
}

export default HashTagSystemData
