import axios from 'axios'
import React, { useEffect, useState } from 'react'
import ManagerNoteModel from './ManagerNoteModel'

const ManagerNoteProfile = (props) => {
  const { note } = props
  const [managerProfile, setManagerProfile] = useState(null)
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const res = await axios.get(`/users/oneUser/${note?.managerId}`)
        setManagerProfile(res.data.user)
      } catch (error) {
        console.log(error)
      }
    }
    if (note?.managerId) fetchUser()
  }, [note?.managerId])
  return <ManagerNoteModel notesProps={{ ...props, managerProfile }} />
}

export default ManagerNoteProfile
