import { useTranslation } from 'react-i18next'

const AugmentedNetworkBusinessModel = () => {
  const { t } = useTranslation('AugmentedNetworkBusinessModel')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 15,
    title: t('title_bm15'),
    subTitle: t('subTitle_bm15'),
    main: t('main_bm15'),
    when: t('when_bm15'),
    who: t('who_bm15'),
    what: t('what_bm15'),
    how: t('how_bm15'),
    why: t('why_bm15'),
    examples: rtl
      ? ['امواى - تأسست عام 1959 - الولايات المتحدة الأمريكية']
      : ['Amway - Founded 1959 - USA '],
    tags: rtl
      ? ['المشاركة في الشبكات', 'التكاليف المنخفضة', 'الشبكات التابعة']
      : ['Share in Networks', 'Low costs', 'Affiliate Networks'],
    considerations: t('considerations_bm15'),
    combinations: [],
    hashTagType: 'businessModelTemp',
  }
}

export default AugmentedNetworkBusinessModel
