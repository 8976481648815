import React, { useState, useEffect } from 'react'
import MultiLangNumber from '../../SmallComponents/MultiLangNumber'
import { withStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'

const styles = () => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    height: 100,
    alignContent: 'center',
    alignItems: 'center',
    width: '100%',
    borderRadius: '10px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    position: 'relative',
    overflow: 'hidden',
    textAlign: 'center',
  },
  rank: {
    fontWeight: 500,
    fontSize: 25,
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
    position: 'relative',
    zIndex: 2,
    color: '#fff',
    animation: '$processing 2s ease-out infinite',
  },
  rankFraction: {
    fontWeight: 500,
    fontSize: 15,
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
    position: 'absolute',
    bottom: 10, // Put rankFraction at the bottom
    left: 10, // Put rankFraction at a certain side
    zIndex: 2,
    color: '#fff',
  },
  background: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    zIndex: 1,
    background: 'linear-gradient(to right, #0072c6, #00a3e0, #0072c6)',
    animation: '$gradient 3s ease infinite',
    backgroundSize: '200%',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: '50px',
    fontWeight: 'bold',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
  },
  '@keyframes gradient': {
    '0%': {
      backgroundPosition: '0% 50%',
    },
    '50%': {
      backgroundPosition: '100% 50%',
    },
    '100%': {
      backgroundPosition: '0% 50%',
    },
  },
  '@keyframes processing': {
    '0%': {
      transform: 'translateY(0)',
    },
    '25%': {
      transform: 'translateY(10px)',
    },
    '50%': {
      transform: 'translateY(0)',
    },
    '75%': {
      transform: 'translateY(-10px)',
    },
    '100%': {
      transform: 'translateY(0)',
    },
  },
  letter: {
    position: 'absolute',
    display: 'inline-block',
    fontWeight: 'bold',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
    animation:
      '$fly 5s ease infinite, $rotate 10s linear infinite, $color 5s linear infinite, $scale 5s linear infinite',
  },
  '@keyframes fly': {
    '0%': {
      transform: 'translateY(0) translateX(0)',
    },
    '25%': {
      transform: 'translateY(-50%) translateX(-50%)',
    },
    '50%': {
      transform: 'translateY(0) translateX(-100%)',
    },
    '75%': {
      transform: 'translateY(50%) translateX(-50%)',
    },
    '100%': {
      transform: 'translateY(0) translateX(0)',
    },
  },
  '@keyframes rotate': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  '@keyframes color': {
    '0%': {
      color: '#0072c6',
    },
    '25%': {
      color: '#00a3e0',
    },
    '50%': {
      color: '#0072c6',
    },
    '75%': {
      color: '#00a3e0',
    },
    '100%': {
      color: '#0072c6',
    },
  },
  '@keyframes scale': {
    '0%': {
      transform: 'scale(1)',
    },
    '50%': {
      transform: 'scale(1.5)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
})

const NetworkRank = ({ classes, rankPoints }) => {
  const [rank, setRank] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setRank((prevRank) => Math.min(prevRank + 10, rankPoints))
    }, 1000 / 60)

    return () => clearInterval(interval)
  }, [rankPoints])

  const rankFraction = MultiLangNumber(`${rank}/10000`)
  const letters = ['H', 'u', 't', 'u', 'p', 'i', 'a'].sort(
    () => Math.random() - 0.5
  )
  const flyingLetters = letters.map((letter, index) => (
    <Typography
      key={index}
      variant='h5'
      className={classes.letter}
      style={{
        left: `${Math.random() * 100}%`,
        top: `${Math.random() * 100}%`,
      }}
    >
      {letter}
    </Typography>
  ))
  return (
    <section className={classes.wrapper}>
      <div className={classes.background}>{flyingLetters}</div>
      <div className={classes.rank}>{MultiLangNumber(rank)}</div>
      <div className={classes.rankFraction}>{rankFraction}</div>{' '}
      {/* Add rankFraction class here */}
    </section>
  )
}

const WrappedNetworkRank = withStyles(styles)(NetworkRank)

export default WrappedNetworkRank
