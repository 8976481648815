import React, { useEffect, useState } from 'react'
import TitleHeader from '../../SmallComponents/Header'
import ContractElementTerms from './ContractSubjectComponents/ContractElementTerms'
import NavigatorGenerator from '../../navigatorCardsModel/NavigatorGenerator'
import axios from 'axios'

const ContractSubject = (props) => {
  const { contract, DCGProps } = props
  const { isDarkModeActive } = DCGProps
  const [elements, setElements] = useState([])
  useEffect(() => {
    const fetchContractElements = async () => {
      try {
        const res = await axios.get(`/contractsElements/g/${contract?._id}`)
        setElements(res.data.elements)
      } catch (error) {
        console.log(error)
      }
    }
    fetchContractElements()
  }, [contract?._id])
  const regEelements = elements?.filter((e) => e?.elementId !== contract?._id)
  const mainElement = elements?.find((e) => e?.elementId === contract?._id)
  const rtl = document.body.dir === 'rtl'

  if (mainElement?.Title) {
    mainElement['Title'] = rtl ? 'الإتفاق العام' : 'General Agreement'
  } else if (mainElement?.title) {
    mainElement['title'] = rtl ? 'الإتفاق العام' : 'General Agreement'
  }

  const navigatorsArray = [
    {
      hideHeader: true,
      unitsArray: regEelements?.map((e) => ({ ...e, _id: e?.elementId })),
    },
  ]

  return (
    <section style={{ color: isDarkModeActive ? 'white' : null }}>
      <ContractElementTerms {...props} conEl={mainElement} main />
      <TitleHeader
        hideHeader={regEelements?.length === 0}
        headerText={rtl ? 'محتوى العقد' : 'Contract Content'}
      />
      <NavigatorGenerator {...props} navigatorsArray={navigatorsArray} />
      {regEelements.map((conEl) => (
        <ContractElementTerms key={conEl?._id} conEl={conEl} {...props} />
      ))}
    </section>
  )
}

export default ContractSubject
