import { useTranslation } from 'react-i18next'

const ReverseAuction = () => {
  const { t } = useTranslation('ReverseAuction')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 5,
    title: t('title_bm05'),
    subTitle: t('subTitle_bm05'),
    main: t('main_bm05'),
    when: t('when_bm05'),
    who: t('who_bm05'),
    what: t('what_bm05'),
    how: t('how_bm05'),
    why: t('why_bm05'),
    examples: rtl
      ? ['PriceLine - تأسس عام 1997']
      : ['PriceLine - Founded 1997'],
    tags: rtl
      ? [
          'موارد محدودة',
          'المدفوعات المخططة',
          'متغيرات المنتج',
          'إضفاء الطابع الشخصي',
          'سعر منخفض',
          'هيمنة السوق',
        ]
      : [
          'Limited Resources',
          'Planned Payments',
          'Product Variants',
          'Personalization',
          'Lower Price',
          'Market Domination',
        ],
    considerations: t('considerations_bm05'),
    combinations: ['$LBM2', '$LBM6'],
    hashTagType: 'businessModelTemp',
  }
}

export default ReverseAuction
