import React, { useEffect, useState } from 'react'
import TimePicker from 'react-time-picker'

const TimeInputModel = ({ optionsSetState, optionsState, element }) => {
  const { title, value, required, disabled } = element
  const stateName = title
  const defaultValue = value
  const [inputValue, setInputValue] = useState(defaultValue)

  useEffect(() => {
    optionsSetState({
      ...optionsState,
      [stateName.split(' ').join('')]: inputValue,
    })
  }, [inputValue])
  return (
    <div>
      <TimePicker
        onChange={setInputValue}
        value={inputValue}
        required={required}
        locale={document.body.dir === 'rtl' && 'ar'}
        disabled={disabled}
      />
    </div>
  )
}

export default TimeInputModel
