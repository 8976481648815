import React from 'react'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'column',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    borderRadius: 15,
    background: 'rgba(0,0,0,0.1)',
    margin: 10,
    padding: 10,
    flexDirection: 'column',
    justifyItems: 'center',
    alignItems: 'center',
    fontWeight: 500,
  },
})

const DeletedSharedPost = ({ classes }) => {
  const rtl = document.body.dir === 'rtl'
  return (
    <div className={classes.container}>
      <section className={classes.wrapper}>
        {rtl ? 'البيانات غير متوفرة' : 'Data is not Available'}
      </section>
    </div>
  )
}

const WrappedDeletedSharedPost = withStyles(styles)(DeletedSharedPost)

export default WrappedDeletedSharedPost
