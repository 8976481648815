import React from 'react'
import GeneralPDFDocument from './GeneralPDFDocument'
import ResourcePDFBody from './ResourcePDFDocumentComps/ResourcePDFBody'

const ResourcePDFDocument = (props) => {
  return (
  <GeneralPDFDocument pages={[<ResourcePDFBody {...props} />]} {...props} />
  )
}

export default ResourcePDFDocument
