import React, { useContext } from 'react'
import MemberBsicInfo from './MemberProfileComponents/MemberBsicInfo'
import MemberActivity from './MemberProfileComponents/MemberActivity'
import MemberAvatarName from './MemberProfileComponents/MemberAvatarName'
import MemberContract from './MemberProfileComponents/MemberContract'
import { AuthContext } from '../../../../context/AuthContext'

const MemberProfile = (props) => {
  const { member, DCGProps, departmentManager, members } = props
  const isDarkModeActive = props?.isDarkModeActive || DCGProps?.isDarkModeActive
  const { user } = useContext(AuthContext)
  const project = props?.project || DCGProps?.project
  const currentContributor = members?.find((m) => m?.userId === user?._id)
  const authorized =
    user?._id === project?.userId ||
    user?._id === member?.userId ||
    user?._id === departmentManager?.userId ||
    (currentContributor &&
      currentContributor?.subDepManager &&
      currentContributor?.subDepId === member?.subDepId)
  return (
    <article style={{ width: '100%', color: isDarkModeActive && 'white' }}>
      <MemberAvatarName {...props} />
      <MemberBsicInfo {...props} authorized={authorized} />
      {member?.userId !== project?.userId && authorized && (
        <>
          <MemberActivity {...props} />
          <MemberContract {...props} />
        </>
      )}
    </article>
  )
}

export default MemberProfile
