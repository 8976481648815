import React, { useContext } from 'react'
import { AuthContext } from '../../../../../../context/AuthContext'
import ResourceInteractionButtons from './ResourceProfileComps/ResourceInteractionButtons'
import ResourceProfileTop from './ResourceProfileComps/ResourceProfileTop'
import ResourceProperties from './ResourceProfileComps/ResourceProperties'
import ResourceRequests from './ResourceProfileComps/ResourceRequests'
import ResourceNavigators from './ResourceProfileComps/ResourceNavigators'

const ResourceProfile = (props) => {
  const { DCGProps, resource } = props
  const { isDarkModeActive } = DCGProps
  const { user } = useContext(AuthContext)
  return (
    <article
      style={{
        width: '100%',
        color: isDarkModeActive ? 'white' : null,
      }}
    >
      <ResourceProfileTop {...props} />
      <ResourceProperties {...props} />
      <ResourceNavigators {...props} />
      {user?._id === resource?.userId && <ResourceRequests {...props} />}
      <ResourceInteractionButtons {...props} />
    </article>
  )
}

export default ResourceProfile
