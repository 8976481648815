import React from 'react'
import withStyles from '@mui/styles/withStyles'
import NoteTopRight from './NoteTopComponents/NoteTopRight'
import NoteTopLeft from './NoteTopComponents/NoteTopLeft'

const styles = () => ({
  topWrapper: {
    marginRight: 10,
    marginLeft: 10,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  '@media (max-width: 600px)': {
    topWrapper: {
      marginRight: 0,
      marginLeft: 0,
      justifyContent: 'space-between',
      width: 'auto',
      alignItems: 'center',
    },
  },
})

const NoteTop = ({ classes, notesProps }) => {
  return (
    <article className={classes.topWrapper}>
      <NoteTopLeft notesProps={notesProps} />
      <NoteTopRight notesProps={notesProps} />
    </article>
  )
}

const WrappedNoteTop = withStyles(styles)(NoteTop)

export default WrappedNoteTop
