import React, { useContext, useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import EditableGridModel from '../../../../../../../formsSystem/EditableGridModel'
import Media from 'react-media'
import ModelStructureUnitTopPC from './ModelStructureUnitComps/ModelStructureUnitTopPC'
import ModelStructureUnitTopMob from './ModelStructureUnitComps/ModelStructureUnitTopMob'
import axios from 'axios'
import HashTagElementsRefiner from '../../../../../../../SmallComponents/HashTagElementsRefiner'
import { AuthContext } from '../../../../../../../../context/AuthContext'
import FormDataLangModulator from '../../../../../../../formsSystem/FormDataLangModulator'
import { useTranslation } from 'react-i18next'

const styles = () => ({
  container: {
    margin: 10,
  },
  topWrapper: {
    position: 'relative',
    with: '100%',
    minHeight: 70,
    paddingTop: 20,
    marginBottom: 10,
  },
  '@media (max-width: 600px)': {
    container: {
      margin: 0,
    },
  },
})

const ModelStructureUnit = ({
  classes,
  model,
  props,
  DCGProps,
  modelMods,
  customModel,
  externalLinkMode,
}) => {
  const rtl = document.body.dir === 'rtl'
  const { t } = useTranslation()
  const { project } = DCGProps
  const { user } = useContext(AuthContext)
  const [chosenHashTagElements, setChosenHashTagElements] = useState(
    HashTagElementsRefiner(modelMods) ||
      HashTagElementsRefiner(customModel) ||
      []
  )

  const infoArray = model?.customModel
    ? model?.infoArray
    : [
        {
          title: rtl ? `تخصيص ${t(model.title)}` : `Customize ${model.title}`,
          titleValue: model?.custom,
          allowEmpty: true,
          wideMode: true,
          minRows: 5,
        },
      ]
  const [infoData, setInfoData] = useState({})
  const newInfoData = FormDataLangModulator(infoData, t)
  const onSave = async () => {
    try {
      model?.customModel
        ? await axios.patch(`/projectsBusinessModels/${project?._id}`, {
            ...newInfoData,
            chosenHashTagElements,
          })
        : await axios.patch(
            `/businessModelCustomizationss/${props?.model?.id}/${project?._id}`,
            {
              [model.title.toLowerCase()]:
                infoData[
                  rtl
                    ? `تخصيص ${t(model.title)}`.split(' ').join('')
                    : `Customize ${model.title}`.split(' ').join('')
                ],
              chosenHashTagElements,
            }
          )
    } catch (error) {
      console.log(error)
    }
  }
  const mediaComponents = [
    <ModelStructureUnitTopPC model={model} props={props} DCGProps={DCGProps} />,
    <ModelStructureUnitTopMob
      model={model}
      props={props}
      DCGProps={DCGProps}
    />,
  ]

  const gridProps = {
    hideEditingTools: user?._id !== project?.userId,
    hideHeader: true,
    infoArray,
    setInfoData,
    infoData,
    setChosenHashTagElements,
    chosenHashTagElements,
    onSave,
    ...DCGProps,
  }
  return (
    <article className={classes.container}>
      <section className={classes.topWrapper}>
        <Media query='(max-width: 600px)'>
          {(matches) => {
            return matches ? mediaComponents[1] : mediaComponents[0]
          }}
        </Media>
      </section>
      <section
        style={{
          display:
            (model?.hideCustom || !project || externalLinkMode) && 'none',
        }}
      >
        <EditableGridModel gridProps={gridProps} />
      </section>
    </article>
  )
}

const WrappedModelStructureUnit = withStyles(styles)(ModelStructureUnit)

export default WrappedModelStructureUnit
