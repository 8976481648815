import HashTagCardsConfigs from '../HashTagCardsConfigs'

const TextSymbolPortion = ({ lastHashTagSymbol, chosenHashtagComp }) => {
  // the symbol part that appear in the textfield or the db textContent that will be recognized by the text processor to provide different compoennts in posts
  const hashTagSymbol = HashTagCardsConfigs().filter(
    (card) => card.hashTagType === chosenHashtagComp?.hashTagType
  )[0]?.hashTagSymbol
  return lastHashTagSymbol === '@'
    ? '@'
    : lastHashTagSymbol === '$'
    ? hashTagSymbol
    : null
}

export default TextSymbolPortion
