import React from 'react'
import CardTitlesModel from '../../fileSystem/MiniFileSystemComponents/MiniFSCardsModelComponents/MFSCardsViewModels/StandardCardComps/CardTitlesModel'

const GeneralCardContent = ({ element, isDarkModeActive, cardMarkProp }) => {
  return (
    <CardTitlesModel
      title={
        element.title || element.productName || element.Title || element.name
      }
      isDarkModeActive={isDarkModeActive}
      cardMark={element[cardMarkProp] || ''}
    />
  )
}

export default GeneralCardContent
