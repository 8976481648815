import React from 'react'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
  },
})

const ActivitySectionDetails = ({ classes, card }) => {
  const section = card
  return <section className={classes.wrapper}>{section.component}</section>
}

const WrappedActivitySectionDetails = withStyles(styles)(ActivitySectionDetails)

export default WrappedActivitySectionDetails
