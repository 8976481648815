import React, { useContext, useEffect, useState } from 'react'
import { AiFillLike, AiOutlineLike } from 'react-icons/ai'
import withStyles from '@mui/styles/withStyles'
import { AuthContext } from '../../../../context/AuthContext'
import axios from 'axios'
import NotificationCreator from '../../../notificationsSystem/NotificationCreator'
import MultiMillify from '../../../SmallComponents/MultiMillify'
import WindowProps from '../../../windowViewer/WindowProps'
import NewFormDialogModel from '../../../formsSystem/NewFormDialogModel'
import CommentRepliesDialogContent from './CommentRepliesDialogContent'
import SmallButtonWrapper from '../../../SmallComponents/SmallButtonWrapper'
import ToastifyGeneralError from '../../../SmallComponents/ToastifyGeneralError'

const styles = () => ({
  bottomIcon: {
    cursor: 'pointer',
    marginLeft: 5,
    marginRight: 5,
    color: '#333333',
  },
  counterValue: {
    fontSize: 12,
    cursor: 'pointer',
    fontWeight: 550,
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
    color: '#333333',
  },

  commentInteraction: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    padding: 4,
    borderRadius: 10,
  },
})

const CommentBottom = ({ classes, ...props }) => {
  const { comment, isDarkModeActive, socket, replyMode, post } = props
  const { user } = useContext(AuthContext)
  const [likes, setLikes] = useState(comment?.likes?.length)
  const [isLiked, setIsLiked] = useState(comment?.likes.includes(user?._id))
  const rtl = document.body.dir === 'rtl'
  const [isClicked, setIsClicked] = useState(false)
  const likeNumberHandler = async () => {
    setLikes((likes) => (isLiked ? likes - 1 : likes + 1))
    setIsLiked(!isLiked)
    try {
      if (user?._id && comment?._id)
        await axios.put(`/comments/${comment?._id}/like`, { userId: user?._id })
    } catch (error) {
      console.log(error)
      ToastifyGeneralError(error)
    }
  }

  const [repliesCount, setRepliesCount] = useState(0)
  useEffect(() => {
    const fetchRepliesCount = async () => {
      try {
        const res = await axios.get(
          `/comments/replies/repliesCount/${comment?._id}`
        )
        setRepliesCount(res.data.repliesCount)
      } catch (error) {
        console.log(error)
      }
    }
    if (comment?._id) fetchRepliesCount()
  }, [comment?._id])

  const notMod = async () => {
    try {
      await axios.delete('/notifications/customDelete/d', {
        headers: {},
        data: {
          userId: comment?.userId,
          associativity: 'post',
          category: 'general',
          relation: replyMode ? 'replies' : 'creator',
          conditionIndex: replyMode ? 1 : 3,
          accompaniedData: {
            postId: comment.postId || post?._id,
            commentId: replyMode ? comment?.commentId : comment?._id,
            replyId: comment?._id,
          },
          notThisUser: true,
          commentMode: !comment?.commentId,
          replyMode: comment?.commentId,
        },
      })
      setIsClicked(false)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if (
      likes > 1 &&
      isLiked &&
      (comment.postId || comment?.commentId) &&
      comment?.userId &&
      isClicked &&
      comment?.userId !== user?._id
    )
      notMod()
  }, [isLiked, comment?.userId, comment.postId, comment?.commentId])

  let likeIconToggle = [
    <AiOutlineLike
      className={classes.bottomIcon}
      style={{ color: 'white' }}
      size={20}
      onClick={() => {
        if (comment?.userId !== user?._id) notMod()
        likeNumberHandler()
        NotificationCreator({
          associativity: 'post',
          relation: replyMode ? 'replies' : 'creator',
          conditionIndex: replyMode ? 1 : 3,
          accompaniedData: {
            postId: comment.postId || post?._id,
            commentId: replyMode ? comment?.commentId : comment?._id,
            replyId: comment?._id,
          },
          category: 'general',
          userId: comment?.userId,
          currentUser: user,
          socket,
        })
      }}
    />,
    <AiFillLike
      className={classes.bottomIcon}
      style={{ color: 'white' }}
      size={20}
      onClick={async (e) => {
        likeNumberHandler()
        setIsClicked(true)
        if (
          likes === 1 &&
          user._id &&
          (comment?.postId || post?._id) &&
          comment?.userId
        )
          notMod()
      }}
    />,
  ]
  let likeIconHandler = !isLiked ? likeIconToggle[0] : likeIconToggle[1]
  const { isWindowOpen, windowOpener, windowCloser } = WindowProps()
  return (
    <section
      className={classes.commentInteraction}
      style={{
        marginTop: -3.5,
      }}
    >
      <SmallButtonWrapper
        isDarkModeActive={isDarkModeActive}
        button={
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center',
            }}
          >
            {likeIconHandler}
            <span
              className={classes.counterValue}
              style={{
                display: likes > 0 ? null : 'none',
                color: 'white',
              }}
            >
              {MultiMillify(likes)}
            </span>
          </span>
        }
      />
      <SmallButtonWrapper
        hide={replyMode}
        isDarkModeActive={isDarkModeActive}
        button={
          <span
            onClick={windowOpener}
            style={{ cursor: 'pointer', display: replyMode && 'none' }}
          >
            {MultiMillify(repliesCount)} {rtl ? 'ردود' : 'Replies'}
          </span>
        }
      />
      <NewFormDialogModel
        NewFormComponent={<CommentRepliesDialogContent {...props} />}
        isNewFormDialogOpen={isWindowOpen}
        isDarkModeActive={isDarkModeActive}
        clickAwayState={true}
        newFormDialogClose={windowCloser}
        dialogTitle={rtl ? 'ردود' : 'Replies'}
      />
    </section>
  )
}

const WrappedCommentBottom = withStyles(styles)(CommentBottom)

export default WrappedCommentBottom
