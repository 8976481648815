import { useTranslation } from 'react-i18next'

const BusinessPolarity = () => {
  const { t } = useTranslation('BusinessPolarity')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 16,
    category: t('category_ms16'),
    title: t('title_ms16'),
    subTitle: t('subTitle_ms16'),
    main: t('main_ms16'),
    how: t('how_ms16'),
    usedInModels: [],
    tags: rtl
      ? [
          'احتياجات العميل',
          'التوجه نحو السوق',
          'تكنولوجيا',
          'المنتج / الخدمة',
          'توقعات العملاء',
          'اتجاه الإنتاج / السوق',
          'منظمة انتقالية',
          'فهم نوع العمل',
          'نجاح',
          'إستراتيجية التكيف',
        ]
      : [
          'Customer Needs',
          'Market Orientation',
          'Technology',
          'Product/Service',
          'Customer Expectations',
          'Production/Market Orientation',
          'Transitioning Organisation',
          'Understanding Type of Business',
          'Success',
          'Adapting Strategy',
        ],
    examples: t('examples_ms16'),
    hashTagType: 'markStraAspect',
  }
}

export default BusinessPolarity
