import React, { useEffect, useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import { Avatar } from '@mui/material'
import { Tooltip } from '@mui/material'
import axios from 'axios'
import WindowViewerSystem from '../../../../../../../../../windowViewer/WindowViewerSystem'
import WindowProps from '../../../../../../../../../windowViewer/WindowProps'
import FreeBar from '../../../../../../../../../skeleton/SkeletonComponents/FreeBar'
import { Link } from 'react-router-dom'
import TimeAgoFormattor from '../../../../../../../../../SmallComponents/TimeAgoFormattor'
import DepartmentsEnumArray from '../../../../../DepartmentsComponents/DepartmentsEnumArray'
import BulletSeparator from '../../../../../../../../../SmallComponents/BulletSeparator'
import PinnedTag from '../../../../../../../../../SmallComponents/PinnedTag'

const styles = () => ({
  avatarNameCategory: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
    marginBottom: 10,
  },
  companyName: {
    fontSize: 18,
    fontWeight: 500,
    cursor: 'pointer',
  },
  postingTime: {
    fontSize: 13,
    cursor: 'pointer',
    fontWeight: 500,
    flexDirection: 'row',
    display: 'flex',
  },
  avatar: {
    cursor: 'pointer',
    width: '50px !important',
    height: '50px !important',
  },
  '@media (max-width: 600px)': {
    companyName: {
      fontSize: 13,
    },
    postingTime: {
      fontSize: 11,
    },
    avatar: {
      cursor: 'pointer',
      width: '40px !important',
      height: '40px !important',
    },
  },
})

const RoomSMDisTopLeft = ({ classes, postProps, subDep }) => {
  const {
    post,
    postsUsers,
    isDarkModeActive,
    user: postUser,
    project,
  } = postProps
  const { isWindowOpen, windowOpener, windowCloser } = WindowProps()
  const [user, setUser] = useState(
    postUser ? postUser : postsUsers?.find((u) => u?._id === post?.userId)
  )

  useEffect(() => {
    const fetchRepUser = async () => {
      try {
        const res = await axios.get(`/users/${post?.userId}`)
        setUser(res.data)
      } catch (error) {
        console.log(error)
      }
    }
    if (!postUser || postUser?._id !== post?.userId) fetchRepUser()
  }, [postUser, post?.userId])
  const department = DepartmentsEnumArray?.find(
    (d) => d?.id === post?.departmentId
  )?.title
  const rtl = document.body.dir === 'rtl'
  return (
    <article className={classes.avatarNameCategory}>
      <Link to={`/projectProfile/${post?.userId}`}>
        <Avatar
          className={classes.avatar}
          src={user?.profilePicture}
          sx={{ margin: rtl ? '0 0 0 10px' : '0 10px 0 0' }}
        />
      </Link>
      <section style={{ display: 'flex', flexDirection: 'column' }}>
        <span className={classes.companyName}>
          {user?.firstName ? (
            user?.firstName + ' ' + user?.lastName
          ) : (
            <FreeBar height={20} isDarkModeActive={isDarkModeActive} />
          )}
        </span>
        <span
          className={classes.postingTime}
          style={{ marginRight: !rtl && 10, marginLeft: rtl && 10 }}
        >
          <TimeAgoFormattor date={post?.createdAt} />
          <span
            style={{ display: project?.userId === user?._id ? 'none' : null }}
          >
            <BulletSeparator />
            <Tooltip title={rtl ? 'للمزيد من التفاصيل' : 'Click for Details'}>
              <span
                style={{ fontStyle: 'italic', cursor: 'pointer' }}
                onClick={windowOpener}
              >
                {rtl ? 'عرض' : 'view'}
              </span>
            </Tooltip>
          </span>
          <span style={{ display: project ? null : 'none' }}>
            <BulletSeparator />
            {project?.ProjectName || project?.name}
          </span>
          <span style={{ display: department ? null : 'none' }}>
            <BulletSeparator />
            {department}
          </span>
          <span style={{ display: subDep ? null : 'none' }}>
            <BulletSeparator />
            {subDep?.Name}
          </span>
          <PinnedTag hide={post?.roomPinned} />
        </span>
      </section>
      <WindowViewerSystem
        isWindowOpen={isWindowOpen}
        windowCloser={windowCloser}
        isDarkModeActive={isDarkModeActive}
        windowType='projectContributor'
        props={{
          member: { ...user, hashTagType: 'projectContributor' },
          DCGProps: { isDarkModeActive },
          project,
        }}
      />
    </article>
  )
}

const WrappedRoomSMDisTopLeft = withStyles(styles)(RoomSMDisTopLeft)

export default WrappedRoomSMDisTopLeft
