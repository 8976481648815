import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '../../../../../../../../context/AuthContext'
import ExpandedCardsSystem from '../../../../../../../expandedCardsSystem/ExpandedCardsSystem'
import TitleHeader from '../../../../../../../SmallComponents/Header'
import Loading from '../../../../../../../SmallComponents/Loading'
import MultiLangNumber from '../../../../../../../SmallComponents/MultiLangNumber'
import IdeaRequestDetails from './IdeaRequestsComps/IdeaRequestDetails'

const IdeaRequests = ({ hideIdeaRequests, DCGProps, idea }) => {
  //  any contribution requests and the content of the contribution request may be some more ideas or something added
  const rtl = document.body.dir === 'rtl'
  const { t } = useTranslation()
  const [requests, setRequests] = useState([])
  const [requestsUsers, setRequestsUsers] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const { user } = useContext(AuthContext)
  useEffect(() => {
    const fetchRequests = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(`/requests/elementRequests/${idea?._id}`)
        setRequests(res.data.requests)
        setRequestsUsers(res.data.requestsUsers)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    fetchRequests()
  }, [user?._id])
  return (
    <span
      style={{
        display: (hideIdeaRequests || user?._id !== idea?.userId) && 'none',
      }}
    >
      <TitleHeader
        headerText={`${MultiLangNumber(requests?.length)} ${t('Requests')}`}
      />
      {isLoading ? (
        <Loading />
      ) : (
        <ExpandedCardsSystem
          cards={requests}
          UnitCardDetailsComponent={IdeaRequestDetails}
          firsButtonConfigs={{
            hideButton: true,
            props: {
              idea,
            },
          }}
          searchSystemProps={{}}
          DCGProps={DCGProps}
          requestsUsers={requestsUsers}
          setRequests={setRequests}
        />
      )}
    </span>
  )
}

export default IdeaRequests
