import React from 'react'
import ActivitiesAndCompetencies from './ModelStructureConfigsComps/ActivitiesAndCompetencies'
import CostDrivers from './ModelStructureConfigsComps/CostDrivers'
import Customers from './ModelStructureConfigsComps/Customers'
import CustomerSegments from './ModelStructureConfigsComps/CustomerSegments'
import DistributionChannels from './ModelStructureConfigsComps/DistributionChannels'
import InternalResources from './ModelStructureConfigsComps/InternalResources'
import Partners from './ModelStructureConfigsComps/Partners'
import RevenueStreams from './ModelStructureConfigsComps/RevenueStreams'
import StakeholderGroup from './ModelStructureConfigsComps/StakeholderGroup'
import ValueProposition from './ModelStructureConfigsComps/ValueProposition'

const ModelStructureConfigs = ({ model }) => {
  const rtl = document.body.dir === 'rtl'
  return [
    {
      title: 'Who',
      definition: rtl
        ? 'من هم العملاء المستهدفون (شريحة العملاء)'
        : 'are the target customers (Customer Segment)',
      custom: '',
      customModel: true,
      infoArray: [
        {
          title: 'Customers',
          titleValue: model?.Customers,
          helpertext: <Customers />,
          allowEmpty: true,
          wideMode: true,
          minRows: 5,
        },
        {
          title: 'Stakeholder_Group',
          titleValue: model?.StakeholderGroup,
          helpertext: <StakeholderGroup />,
          allowEmpty: true,
          wideMode: true,
          minRows: 5,
        },
        {
          title: 'Distribution_Channels',
          titleValue: model?.DistributionChannels,
          helpertext: <DistributionChannels />,
          allowEmpty: true,
          wideMode: true,
          minRows: 5,
        },
        {
          title: 'Customer_Segments',
          titleValue: model?.CustomerSegments,
          helpertext: <CustomerSegments />,
          allowEmpty: true,
          wideMode: true,
          minRows: 5,
        },
      ],
    },
    {
      title: 'What',
      definition: rtl
        ? 'ماذا نقدم للعملاء ويلبي احتياجاتهم (القيمة المقترحة)'
        : 'do we offer the customers and fulfills their needs (Value Proposition)',
      custom: '',
      customModel: true,
      infoArray: [
        {
          title: 'Value_Proposition',
          titleValue: model?.ValueProposition,
          helpertext: <ValueProposition />,
          allowEmpty: true,
          wideMode: true,
          minRows: 5,
        },
      ],
    },
    {
      title: 'How',
      definition: rtl
        ? 'كيف ننتج عروضنا (سلسلة القيمة)'
        : 'do we produce our offerings (Value Chain)',
      custom: '',
      customModel: true,
      infoArray: [
        {
          title: 'Internal_Resources',
          titleValue: model?.InternalResources,
          helpertext: <InternalResources />,
          allowEmpty: true,
          wideMode: true,
          minRows: 5,
        },
        {
          title: 'Activities_and_Competencies',
          titleValue: model?.ActivitiesandCompetencies,
          helpertext: <ActivitiesAndCompetencies />,
          allowEmpty: true,
          wideMode: true,
          minRows: 5,
        },
        {
          title: 'Partners',
          titleValue: model?.Partners,
          helpertext: <Partners />,
          allowEmpty: true,
          wideMode: true,
          minRows: 5,
        },
      ],
    },
    {
      title: 'Why',
      definition: rtl
        ? 'لماذا سيكون نهجنا مربحًا (آلية الربح)؟'
        : 'our approach will be profitable (Profit Mechanism)',
      custom: '',
      customModel: true,
      infoArray: [
        {
          title: 'Cost_Drivers',
          titleValue: model?.CostDrivers,
          helpertext: <CostDrivers />,
          allowEmpty: true,
          wideMode: true,
          minRows: 5,
        },
        {
          title: 'Revenue_Streams',
          titleValue: model?.RevenueStreams,
          helpertext: <RevenueStreams />,
          allowEmpty: true,
          wideMode: true,
          minRows: 5,
        },
      ],
    },
  ]
}

export default ModelStructureConfigs
