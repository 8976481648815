import React from 'react'
import NewFormDialogModel from '../../../formsSystem/NewFormDialogModel'
import DepNewContributorForm from './DepContributorAdditionComps/DepNewContributorForm'

const DepContributorAddition = (props) => {
  const { newDepContribClose, isNewDepContribOpen } = props
  const rtl = document.body.dir === 'rtl'
  return (
    <NewFormDialogModel
      NewFormComponent={<DepNewContributorForm {...props} />}
      newFormDialogClose={newDepContribClose}
      isNewFormDialogOpen={isNewDepContribOpen}
      {...props}
      clickAwayState={true}
      dialogTitle={rtl ? 'أضف الى القسم' : 'Add to Department'}
    />
  )
}

export default DepContributorAddition
