import axios from 'axios'
import React, { useContext, useState } from 'react'
import { AuthContext } from '../../../../../../../context/AuthContext'
import ButtonsSystem from '../../../../../../buttonsSystem/ButtonsSystem'
import ElementRequestsSenders from '../../../../../../notificationsSystem/ElementRequestsSenders'
import NoPermissionedInteraction from '../../../../../../notificationsSystem/NoPermissionedInteraction'
import NotificationCreator from '../../../../../../notificationsSystem/NotificationCreator'

const CampaignInteractionButtons = ({
  postMode,
  DCGProps,
  campaign,
  depManager,
}) => {
  const { socket, project } = DCGProps
  const { user } = useContext(AuthContext)
  const senders = ElementRequestsSenders(campaign?._id || campaign?.id)
  const [contrib, setContrib] = useState(false)
  const newRequest = {
    userId: user?._id,
    projectId: campaign?.projectId,
    genre: 'campaign',
    subgenre: 'contribute',
    elementId: campaign?._id || campaign?.id,
  }
  const activeFun = async (subgenre) => {
    try {
      const res = await axios.post('/requests', newRequest)
      socket.current.emit('addNotifUser', depManager?._id || project?.userId)
      NotificationCreator({
        associativity: 'campaign',
        relation: 'creator',
        conditionIndex: 0,
        accompaniedData: {
          projectId: project?._id,
          requestSubGenre: subgenre,
          requestId: res.data.request._id,
          elements: [
            {
              hashTagType: campaign?.hashTagType,
              id: campaign?._id || campaign?.id,
              title: campaign?.Title,
            },
          ],
        },
        category: 'contributors',
        userId: depManager?._id || project?.userId,
        currentUser: user,
        socket,
        CRNDelete: true,
        element: campaign,
      })
    } catch (error) {
      console.log(error)
    }
  }
  const rtl = document.body.dir === 'rtl'
  const buttonsArray = [
    {
      title: rtl ? 'شارك' : 'Contribute',
      funs: () => {
        setContrib(true)
        activeFun()
      },
      cofirmMssg: true,
      disabled: contrib,
    },
  ]
  return senders === undefined ? (
    <NoPermissionedInteraction checking={true} />
  ) : senders?.includes(user?._id) || contrib ? (
    <NoPermissionedInteraction />
  ) : (
    <ButtonsSystem
      buttonsArray={buttonsArray}
      hide={
        !postMode ||
        user?.createdProjects?.includes(campaign?.projectId) ||
        user?.contractedProjectsIds?.includes(campaign?.projectId) ||
        user?._id === project?.userId ||
        user?._id === depManager?._id ||
        campaign?.campaignContributorsIds?.includes(user?._id)
      }
      {...DCGProps}
    />
  )
}

export default CampaignInteractionButtons
