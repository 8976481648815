import React from 'react'
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  denied: {
    color: 'GrayText',
    fontWeight: 500,
  },
}))

const DeniedAccess = () => {
  const classes = useStyles()
  const rtl = document.body.dir === 'rtl'

  const errorCode = 403
  return (
    <div className={classes.root}>
      <Typography variant='h6' className={classes.denied}>
        {rtl ? `دخول مرفوض (${errorCode})` : `Access Denied (${errorCode})`}
      </Typography>
    </div>
  )
}

export default DeniedAccess
