import React from 'react'

const CostDrivers = () => {
  const rtl = document.body.dir === 'rtl'
  return rtl ? (
    <>
      آلية الربح ومحركات التكلفة للشركة هي مفاهيم ذات صلة تصف كيف تجني الشركة
      المال وما يؤثر على تكاليفها. آلية الربح هي الطريقة التي تحقق بها الشركة
      إيرادات من منتجاتها أو خدماتها¹. محركات التكلفة هي العوامل التي تسبب
      تغييرًا في تكلفة نشاط أو منتج². بعض الأمثلة على آليات الربح الشائعة هي:
      <ul>
        <li>بيع المنتجات أو الخدمات بسعر أعلى من تكلفة إنتاجها</li>
        <li>فرض رسوم أو عمولات لتوفير الوصول إلى منصة أو شبكة</li>
        <li>تقديم الاشتراكات أو العضويات لتدفقات الإيرادات المتكررة</li>
        <li>ترخيص حقوق الملكية الفكرية أو براءات الاختراع لأطراف أخرى</li>
      </ul>
      بعض الأمثلة على محركات التكلفة الشائعة هي:
      <ul>
        <li> الحجم: عدد الوحدات المنتجة أو المباعة </li>
        <li>التعقيد: تنوع أو تخصيص المنتجات أو الخدمات</li>
        <li> الوقت: مدة أو تكرار الأنشطة أو العمليات </li>
        <li>الجودة: معايير أو مواصفات المنتجات أو الخدمات</li>
      </ul>
      قد تحتاج للإجابة على الأسئلة التالية:
      <br />
      <br />
      - ما هي التكاليف الرئيسية في نموذج أعمالنا؟
      <br />
      <br />
      هذا يعتمد على نوع نموذج العمل لديك. نموذج العمل هو وصف لكيفية إنشاء الشركة
      وتقديمها والتقاطها لقيمة عملائها. يجب أن تتضمن عناصر مثل القيمة المقترحة ،
      وشرائح العملاء ، وتدفقات الإيرادات ، وهيكل التكلفة والموارد الرئيسية³. بعض
      الأمثلة على التكاليف الرئيسية لنماذج الأعمال المختلفة هي:
      <ul>
        <li> التصنيع: المواد الخام والعمالة والآلات والنفقات العامة </li>
        <li> البيع بالتجزئة: الجرد والإيجار والمرافق والتسويق </li>
        <li> البرمجيات: التطوير والصيانة والاستضافة والأمان </li>
      </ul>
      <br />
      <br />
      - ما هي المخاطر المالية وكيف نتعامل معها؟
      <br />
      <br />
      هذا يعتمد على طبيعة وحجم عملك. المخاطر المالية هي عوامل عدم اليقين التي قد
      تؤثر على قدرتك على الوفاء بالتزاماتك المالية أو تحقيق أهدافك المالية. قد
      تنشأ من عوامل داخلية (مثل مشاكل التدفق النقدي) أو عوامل خارجية (مثل تقلبات
      السوق). بعض الأمثلة على المخاطر المالية وكيفية معالجتها نكون:
      <ul>
        <li>
          مخاطر الائتمان: مخاطر عدم قيام عملائك بالدفع لك في الوقت المحدد أو على
          الإطلاق. يمكنك معالجة هذا عن طريق إجراء فحوصات الائتمان ، وتحديد شروط
          الدفع وتحصيل الفواتير على الفور.
        </li>
        <li>
          مخاطر السيولة: مخاطر عدم وجود نقود كافية لتغطية نفقاتك أو التزاماتك.
          يمكنك معالجة ذلك من خلال إدارة رأس المال العامل الخاص بك ، والتنبؤ
          بالتدفق النقدي وتأمين خطوط الائتمان.
        </li>
        <li>
          مخاطر السوق: مخاطر أن تؤثر التغيرات في أسعار السوق على ربحيتك أو قدرتك
          التنافسية. يمكنك معالجة ذلك من خلال تنويع منتجاتك أو خدماتك ، وتحوط
          انكشافاتك ومراقبة تريندات السوق.
        </li>
      </ul>
    </>
  ) : (
    <>
      Profit mechanism and cost drivers of a company are related
      concepts that describe how a company makes money and what affects its
      costs. Profit mechanism is the way a company generates revenue from its
      products or services¹. Cost drivers are the factors that cause a change in
      the cost of an activity or a product². Some examples of common profit
      mechanisms are:
      <ul>
        <li>
          Selling products or services at a higher price than their cost of
          production
        </li>
        <li>
          Charging fees or commissions for providing access to a platform or a
          network
        </li>
        <li>
          Offering subscriptions or memberships for recurring revenue streams
        </li>
        <li>
          Licensing intellectual property rights or patents to other parties
        </li>
      </ul>
      Some examples of common cost drivers are:
      <ul>
        <li>Volume: The number of units produced or sold</li>
        <li>
          Complexity: The variety or customization of products or services
        </li>
        <li>Time: The duration or frequency of activities or processes</li>
        <li>
          Quality: The standards or specifications of products or services
        </li>
      </ul>
      To may need to answer the following questions:
      <br />
      <br />
      - What are the principal costs in our business model?
      <br />
      <br />
      This depends on the type of business model you have. A business model is a
      description of how a company creates, delivers and captures value for its
      customers. It should include elements such as value proposition, customer
      segments, revenue streams, cost structure and key resources³. Some
      examples of principal costs for different business models are:
      <ul>
        <li>Manufacturing: Raw materials, labor, machinery and overheads</li>
        <li>Retail: Inventory, rent, utilities and marketing</li>
        <li>Software: Development, maintenance, hosting and security</li>
      </ul>
      <br />
      <br />
      - What are the financial risks and how we address them?
      <br />
      <br />
      This depends on the nature and scale of your business. Financial risks are
      the uncertainties that may affect your ability to meet your financial
      obligations or achieve your financial goals. They may arise from internal
      factors (such as cash flow problems) or external factors (such as market
      fluctuations). Some examples of financial risks and how to address them
      are:
      <ul>
        <li>
          Credit risk: The risk that your customers will not pay you on time or
          at all. You can address this by conducting credit checks, setting
          payment terms and collecting invoices promptly.
        </li>
        <li>
          Liquidity risk: The risk that you will not have enough cash to cover
          your expenses or liabilities. You can address this by managing your
          working capital, forecasting your cash flow and securing credit lines.
        </li>
        <li>
          Market risk: The risk that changes in market prices will affect your
          profitability or competitiveness. You can address this by diversifying
          your products or services, hedging your exposures and monitoring
          market trends.
        </li>
      </ul>
    </>
  )
}

export default CostDrivers
