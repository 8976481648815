import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../../../../../../../context/AuthContext'
import ChannelPurpose from '../../../../../../../../channelsRightFeed/ChannelsRightFeedComps/FeedChannelProfileComps/ChannelPurpose'
import ChannelRequests from '../../../../../../../../channelsRightFeed/ChannelsRightFeedComps/FeedChannelProfileComps/ChannelRequests'
import TextEngineToolsFooter from '../../../../../../../../SmallComponents/TextEngineToolsFooter'
import ChannelContractAndBasics from './PartnershipChaneelProfileComps/ChannelContractAndBasics'
import ChannelNavigators from './PartnershipChaneelProfileComps/ChannelNavigators'
import ChannelProfileFeed from './PartnershipChaneelProfileComps/ChannelProfileFeed'
import ChannelSwitchingButtons from './PartnershipChaneelProfileComps/ChannelSwitchingButtons'
import ChannelTitle from './PartnershipChaneelProfileComps/ChannelTitle'
import ChannelUnAuthorizedAccess from './PartnershipChaneelProfileComps/ChannelUnAuthorizedAccess'
import axios from 'axios'

const PartnershipChannelProfile = (props) => {
  const { DCGProps, channel } = props
  const { user } = useContext(AuthContext)
  const [viewIndex, setViewIndex] = useState(0)
  const { isDarkModeActive } = DCGProps
  const authorizedAccess =
    channel?.usersIds?.includes(user?._id) ||
    user?.createdProjects?.find((p) => channel?.projectsIds?.includes(p))

  const [members, setMembers] = useState([])
  useEffect(() => {
    const fetchCeoIds = async () => {
      try {
        const res = await axios.get(
          `/contracts/contractProjectsCeos/${channel?.contractId}`
        )
        setMembers(res.data.ceosIds)
      } catch (error) {
        console.log(error)
      }
    }
    if (channel?.contractId) fetchCeoIds()
  }, [channel?.contractId])

  const propsII = {
    ...props,
    members,
    setMembers,
  }
  return authorizedAccess ? (
    <article
      style={{ color: isDarkModeActive ? 'white' : null, width: '100%' }}
    >
      <ChannelTitle {...propsII} />
      <ChannelSwitchingButtons
        {...propsII}
        viewIndex={viewIndex}
        setViewIndex={setViewIndex}
      />
      {viewIndex === 0 ? (
        <>
          <ChannelContractAndBasics {...propsII} />
          <ChannelPurpose {...propsII} />
          <ChannelNavigators {...propsII} />
          <ChannelRequests {...propsII} />
          <TextEngineToolsFooter {...DCGProps} />
        </>
      ) : (
        <ChannelProfileFeed {...propsII} />
      )}
    </article>
  ) : (
    <ChannelUnAuthorizedAccess {...propsII} />
  )
}

export default PartnershipChannelProfile
