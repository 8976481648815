import React, { useState } from 'react'
import { Tooltip } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { MdOutlineChangeCircle } from 'react-icons/md'
import NewFormDialogModel from '../../formsSystem/NewFormDialogModel'
import MoveToAnotherDepForm from '../../projectProfile/ProjectProfileComponents/ProjectControlPanelComponents/ProjectDepartmentsComponents/DepartmentsComponents/MembersComps/MoveToAnotherDepForm'

const styles = () => ({
  icon: {
    cursor: 'pointer',
    fontSize: 18,
    '&:hover': {
      opacity: 0.8,
    },
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
})

const MoveContributorToDepartment = ({ classes, noFSPanelProps }) => {
  const { showMoveContDepIcon } = noFSPanelProps
  const [isComponentOpen, setIsComponentOpen] = useState(false)
  const componentOpener = () => {
    setIsComponentOpen(true)
  }
  const componentClose = () => {
    setIsComponentOpen(false)
  }

  const rtl = document.body.dir === 'rtl'
  return (
    <>
      <Tooltip
        title={
          rtl
            ? 'نقل العضو إلى قسم آخر'
            : 'Move Contributor to Another Department'
        }
      >
        <span
          className={classes.iconWrapper}
          style={{ display: showMoveContDepIcon ? null : 'none' }}
          onClick={componentOpener}
        >
          <MdOutlineChangeCircle className={classes.icon} />
        </span>
      </Tooltip>
      <NewFormDialogModel
        NewFormComponent={
          <MoveToAnotherDepForm
            {...noFSPanelProps}
            componentClose={componentClose}
          />
        }
        newFormDialogClose={componentClose}
        isNewFormDialogOpen={isComponentOpen}
        {...noFSPanelProps}
        clickAwayState={true}
        dialogTitle={rtl ? 'النقل بين الأقسام' : 'Inter-Departments Trasnfer'}
      />
    </>
  )
}

const WrappedMoveContributorToDepartment = withStyles(styles)(
  MoveContributorToDepartment
)

export default WrappedMoveContributorToDepartment
