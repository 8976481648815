import React, { useState, useEffect } from 'react'
import deleteFile from '../../firebase/deleteFile'
import NewFormDialogModel from '../formsSystem/NewFormDialogModel'
import ShareDialogContent from './ShareDialogContent'
import ShareDialogTitle from './ShareDialogTitle'

const Activation = ({ shareSystemProps }) => {
  const {
    shareDialogClose,
    isShareDialogOpen,
    isDarkModeActive,
    shareTypeIndex,
    post,
    disablePostTypeOptions,
  } = shareSystemProps
  // post content - for the new post in either home or project
  const [postComponentId, setPostComponentId] = useState(0)
  const postTypeComponentViewer = (event) => {
    setPostComponentId(Number(event.target.value))
  }

  // grapping the content of the new post
  const [postData, setPostData] = useState(
    disablePostTypeOptions ? post : shareTypeIndex === 1 ? {} : {}
  )

  useEffect(() => {
    if (!isShareDialogOpen && !disablePostTypeOptions) setPostComponentId(0)
  }, [isShareDialogOpen, disablePostTypeOptions])

  const shareSystemPropsII = {
    postComponentId,
    postTypeComponentViewer,
    setPostData,
    postData,
    ...shareSystemProps,
  }

  const newImages = postData?.images?.filter(
    (im) => !post?.images?.includes(im)
  )
  return (
    <NewFormDialogModel
      NewFormComponent={
        <ShareDialogContent shareSystemProps={shareSystemPropsII} />
      }
      newFormDialogClose={() => {
        if (newImages?.length > 0)
          Promise.all(
            newImages?.map(async (image) => {
              if (image) await deleteFile(image)
            })
          )
        shareDialogClose()
      }}
      isNewFormDialogOpen={isShareDialogOpen}
      isDarkModeActive={isDarkModeActive}
      clickAwayState={true}
      dialogTitle={
        shareTypeIndex === 4 ? null : (
          <ShareDialogTitle shareSystemProps={shareSystemPropsII} />
        )
      }
    />
  )
}
const ShareSystem = ({ shareSystemProps }) => {
  const { isShareDialogOpen } = shareSystemProps
  if (isShareDialogOpen) {
    return <Activation shareSystemProps={shareSystemProps} />
  }
}

export default ShareSystem
