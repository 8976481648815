import React from 'react'
import { NormalPostModel } from '..'
import TextEngineToolsFooter from '../../../SmallComponents/TextEngineToolsFooter'

const GeneralPost = ({ placeholder, shareSystemProps, hideAddPostImages }) => {
  return (
    <>
      <NormalPostModel
        placeholder={placeholder}
        {...shareSystemProps}
        hideAddPostImages={hideAddPostImages}
      />
      <TextEngineToolsFooter {...shareSystemProps} />
    </>
  )
}

export default GeneralPost
