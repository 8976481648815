import { CircularProgress } from '@mui/material'
import React from 'react'

const CustomCircularProgress = ({ size }) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
      }}
    >
      <CircularProgress size={size} />
    </div>
  )
}

export default CustomCircularProgress
