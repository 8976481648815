import React from 'react'
import withStyles from '@mui/styles/withStyles'
import axios from 'axios'
import NotificationCreator from '../../../../../../../../notificationsSystem/NotificationCreator'

const styles = () => ({
  wrapper: {
    cursor: 'pointer',
    padding: 10,
    borderRadius: 15,
    margin: '4px auto',
    transition: '0.35s',
    '&:hover': {
      background: 'rgba(107,209,159,0.8)',
      margin: '4px 4px',
    },
  },
})

const PollCardVotingView = ({
  classes,
  op,
  setOptions,
  socket,
  project,
  defaultAction,
  setIsVoted,
  allVoters,
  totalVoters,
  user,
  sender,
  poll,
  onExVote,
  options,
  isDarkModeActive,
}) => {
  const onVote = async (option) => {
    try {
      if (!allVoters?.includes(user?._id)) {
        if (typeof onExVote === 'function') {
          setOptions((ops) => {
            let oldIndex = ops?.findIndex((p) => p._id === option?._id)
            return ops.map((p, i) =>
              i === oldIndex
                ? {
                    ...option,
                    votersIds: [...option?.votersIds, user?._id],
                  }
                : p
            )
          })
          const newOptions = () => {
            {
              let oldIndex = options?.findIndex((p) => p._id === option?._id)
              return options.map((p, i) =>
                i === oldIndex
                  ? {
                      ...option,
                      votersIds: [...option?.votersIds, user?._id],
                    }
                  : p
              )
            }
          }
          setIsVoted(true)
          onExVote(newOptions())
        } else {
          const res = await axios.patch(`/pollsOptions/${option?._id}`, {
            votersIds: [...option?.votersIds, user?._id],
          })
          setOptions((ops) => {
            let oldIndex = ops?.findIndex((p) => p._id === option?._id)
            return ops.map((p, i) => (i === oldIndex ? res.data.option : p))
          })
          setIsVoted(true)
          if (typeof defaultAction === 'function') defaultAction()
          if (poll?.Threshold === totalVoters + 1) {
            socket.current.emit('addNotifUser', sender?._id)
            NotificationCreator({
              associativity: 'project',
              relation: 'poll',
              conditionIndex: 1,
              accompaniedData: {
                projectId: project?._id,
                elements: [
                  {
                    id: poll?._id,
                    hashTagType: poll?.hashTagType,
                    title: poll?.Title,
                  },
                ],
              },
              category: 'contributors',
              userId: sender?._id,
              currentUser: user,
              socket,
            })
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div
      className={classes.wrapper}
      key={op?._id}
      onClick={() => onVote(op)}
      style={{
        background: isDarkModeActive
          ? 'rgba(255,255,255,0.2)'
          : 'rgba(100,238,204,0.5)',
      }}
    >
      {op?.Title}
    </div>
  )
}

const WrappedPollCardVotingView = withStyles(styles)(PollCardVotingView)

export default WrappedPollCardVotingView
