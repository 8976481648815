import React, { useState, useEffect } from 'react'
import GallerySideButton from './GallerySideButton'
import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  image: {
    width: '100%',
    borderRadius: 10,
    cursor: 'pointer',
    zIndex: 0,
  },
  galleryWrapper: {
    width: '100%',
    display: 'flex',
    position: 'relative',
  },
})

const GalleryPCView = ({
  classes,
  setViewedImageIndex,
  viewedImageIndex,
  imagesArray,
  openFun,
}) => {
  const [viewedImage, setViewedImage] = useState(
    typeof imagesArray === 'object' ? imagesArray[viewedImageIndex] : []
  )

  useEffect(() => {
    if (typeof imagesArray === 'object')
      setViewedImage(imagesArray[viewedImageIndex])
  }, [viewedImageIndex])
  return (
    <div className={classes.galleryWrapper}>
      <GallerySideButton
        shiftFun={() =>
          setViewedImageIndex(
            viewedImageIndex > 0 ? viewedImageIndex - 1 : viewedImageIndex
          )
        }
        rightLeftPosisionting={{ left: 0 }}
        hideIconButton={viewedImageIndex === 0}
        ShiftIcon={MdArrowBackIosNew}
        iconButtonEdge='start'
      />
      <img
        src={viewedImage}
        alt='Images Viewer System'
        className={classes.image}
        onClick={() => {
          openFun()
          setViewedImageIndex(viewedImageIndex)
        }}
      />
      <GallerySideButton
        shiftFun={() =>
          setViewedImageIndex(
            viewedImageIndex < imagesArray?.length - 1
              ? viewedImageIndex + 1
              : viewedImageIndex
          )
        }
        rightLeftPosisionting={{ right: 0 }}
        hideIconButton={viewedImageIndex === imagesArray?.length - 1}
        ShiftIcon={MdArrowForwardIos}
        iconButtonEdge='end'
      />
    </div>
  )
}

const WrappedGalleryPCView = withStyles(styles)(GalleryPCView)

export default WrappedGalleryPCView
