import axios from 'axios'
import React, { useEffect, useState } from 'react'
import NotificationCreator from '../../../notificationsSystem/NotificationCreator'
import { AiOutlineLike, AiFillLike } from 'react-icons/ai'

const ProjectPostLikeIcon = ({
  likeNumberHandler,
  post,
  socket,
  styles,
  likes,
  isLiked,
  user,
  projectAdmins,
}) => {
  const rtl = document.body.dir === 'rtl'
  const [isClicked, setIsClicked] = useState(false)
  const notMod = async () => {
    try {
      // the idea here is by not specifying any sender id, will delete the oldest version of the notification and leave the current version as it is.
      Promise.all(
        projectAdmins?.map(async (m) => {
          await axios.delete('/notifications/customDelete/d', {
            headers: {},
            data: {
              userId: m?._id,
              associativity: 'project',
              relation: 'admin',
              category: 'contributors',
              conditionIndex: 1,
              accompaniedData: {
                postId: post._id,
                projectId: post?.userId,
              },
              notThisUser: true,
            },
          })
        })
      )

      setIsClicked(false)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if (likes > 1 && isLiked && post._id && post?.userId && isClicked) notMod()
  }, [isLiked, post?.userId, post._id, projectAdmins])

  return [
    <AiOutlineLike
      style={styles?.bottomIcon}
      size={25}
      onClick={() => {
        notMod()
        likeNumberHandler()
        projectAdmins?.map((m) => {
          socket.current.emit('addNotifUser', m?._id)
          NotificationCreator({
            associativity: 'project',
            relation: 'admin',
            conditionIndex: 1,
            accompaniedData: {
              projectId: post?.userId,
              postId: post._id,
              elements: [
                {
                  id: post?._id,
                  hashTagType: post?.hashTagType,
                  title: rtl ? 'منشور' : 'Project Post',
                },
              ],
            },
            category: 'contributors',
            userId: m?._id,
            currentUser: user,
            socket,
          })
        })
      }}
    />,
    <AiFillLike
      style={styles?.bottomIcon}
      size={25}
      onClick={async (e) => {
        likeNumberHandler()
        setIsClicked(true)
        if (likes === 1 && user._id && post._id && post?.userId) notMod()
      }}
    />,
  ]
}

export default ProjectPostLikeIcon
