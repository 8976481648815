import React, { useState, useEffect } from 'react'
import MiniFSCardInsideModel from '../MiniFSCardInsideModel'

const IdDepenedentMFSInsideView = ({
  MFSGProps,
  folderPathProps,
  searchSystemProps,
  modelProps,
  noFSPanelPropsSecondView,
  noFSPanelPropsFirstView,
}) => {
  // for the components that depend on the id of the element like for the memebers

  const { MiniFSModelIndex, cards, cardId, setCardId } = MFSGProps

  const [chosenCard, setChosenCard] = useState()
  useEffect(() => {
    setChosenCard(cards.filter((card) => card?.id === cardId)[0])
  })
  return (
    <MiniFSCardInsideModel
      component={chosenCard}
      searchSystemProps={searchSystemProps}
      MFSGProps={MFSGProps}
      folderPathProps={folderPathProps}
      noFSPanelPropsSecondView={noFSPanelPropsSecondView}
    />
  )
}

export default IdDepenedentMFSInsideView
