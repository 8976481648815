import React, { useContext, useState } from 'react'
import FormsComponents from '../../../formsSystem/FormsComponents'
import withStyles from '@mui/styles/withStyles'
import axios from 'axios'
import { AuthContext } from '../../../../context/AuthContext'
import { FeedContext } from '../../../../context/FeedContext/FeedContext'
import NotificationCreator from '../../../notificationsSystem/NotificationCreator'
import { Card } from '@mui/material'
import ToastifyGeneralError from '../../../SmallComponents/ToastifyGeneralError'

const styles = () => ({
  textArea: {
    margin: '0',
    width: '96%',
    borderRadius: 10,
    padding: '4px 10px',
    fontWeight: 500,
  },
  wideTextArea: {
    margin: '0',
    width: '100%',
    borderRadius: 10,
    padding: '4px 0',
    fontWeight: 500,
  },
  panelWrapper: {
    position: 'absolute !important',
    zIndex: '2 !important',
    width: '100% !important',
    height: '60px !important',
    bottom: '0 !important',
    borderRadius: '0px !important',
    display: 'flex',
    justifyContent: 'space-around !important',
    alignItems: 'center !important',
    alignContent: 'center !important',
    left: 0,
  },
})

const NewReply = ({
  classes,
  project,
  departmentId,
  subDepId,
  isDarkModeActive,
  socket,
  post,
  comment,
  projectAdmins,
  replies,
  setReplies,
  setRepliesUsers,
}) => {
  const [chosenHashTagElements, setChosenHashTagElements] = useState([])
  const [textContent, setTextContent] = useState('')
  const rtl = document.body.dir === 'rtl'
  const { user } = useContext(AuthContext)
  const { notification, dispatch } = useContext(FeedContext)
  const [images, setImages] = useState([])
  const [playerLink, setPlayerLink] = useState()

  const newReply = {
    commentId: comment?._id,
    userId: user._id,
    text: textContent,
    chosenHashTagElements,
    images,
    likes: [],
    playerLink,
  }
  const notMod = async () => {
    try {
      if (
        post?.feedsSystemCategory === 'home' ||
        post?.feedsSystemCategory === 'room' ||
        post?.feedsSystemCategory === 'channel'
      ) {
        await axios.delete('/notifications/customDelete/d', {
          headers: {},
          data: {
            userId: comment?.userId,
            associativity: 'post',
            relation: 'replies',
            category: 'general',
            conditionIndex: 0,
            accompaniedData: {
              postId: post._id,
              commentId: comment?._id,
            },
            notThisUser: true,
            commentMode: true,
          },
        })
      } else {
        Promise.all(
          projectAdmins?.map(async (m) => {
            await axios.delete('/notifications/customDelete/d', {
              headers: {},
              data: {
                userId: m?._id,
                associativity: 'post',
                relation: 'replies',
                category: 'general',
                conditionIndex: 0,
                accompaniedData: {
                  postId: post._id,
                  projectId: post?.userId,
                  commentId: comment?._id,
                },
                notThisUser: true,
                commentMode: true,
              },
            })
          })
        )
      }
    } catch (error) {
      console.log(error)
    }
  }
  const onSend = async () => {
    if (comment?._id && user._id && (textContent || images)) {
      try {
        if (replies?.length > 0 && comment?.userId !== user?._id) notMod()
        const res = await axios.post(`/comments/replies`, newReply)
        setReplies((reps) => [res.data.reply, ...reps])
        setRepliesUsers((us) => [user, ...us])
      } catch (error) {
        console.log(error)
        ToastifyGeneralError(error)
      }
      if (
        post?.feedsSystemCategory === 'home' ||
        post?.feedsSystemCategory === 'room' ||
        post?.feedsSystemCategory === 'channel'
      ) {
        NotificationCreator({
          associativity: 'post',
          relation: 'replies',
          category: 'general',
          conditionIndex: 0,
          accompaniedData: { postId: post._id, commentId: comment?._id },
          category: 'general',
          userId: comment?.userId,
          currentUser: user,
          socket,
        })
      } else {
        projectAdmins?.map((m) => {
          socket.current.emit('addNotifUser', m?._id)
          NotificationCreator({
            associativity: 'post',
            relation: 'replies',
            conditionIndex: 0,
            accompaniedData: {
              projectId: post?.userId,
              postId: post._id,
              commentId: comment?._id,
            },
            category: 'general',
            userId: m?._id,
            currentUser: user,
            socket,
          })
        })
      }
      // for mention notification
      if (comment?._id && notification?.mentionedUsersIds) {
        Promise.all(
          notification?.mentionedUsersIds?.map(async (id) =>
            NotificationCreator({
              associativity: 'post',
              relation: 'mentioned',
              conditionIndex: 2,
              accompaniedData: { postId: post?._id, commentId: comment?._id },
              category: 'general',
              userId: id,
              currentUser: user,
              socket,
            })
          )
        )
        dispatch({
          type: 'REMOVE_MENTION',
        })
      }
    }
  }
  const formsCompsProps = {
    placeholder: rtl ? '  رد جديد' : '  New Reply',
    formComponentIndex: 1,
    chosenHashTagElements,
    setChosenHashTagElements,
    textContent,
    setTextContent,
    isDarkModeActive,
    autoSizeClassName: classes.wideTextArea,
    project,
    departmentId,
    subDepId,
    images,
    setImages,
    onSend,
    fixed: true,
    onKeyDown: (e) => {
      if (e.key === 'Enter') {
        onSend()
        setTextContent('')
        setImages([])
      }
    },
    link: playerLink,
    setLink: setPlayerLink,
  }
  return (
    <Card
      className={classes.panelWrapper}
      sx={{
        backgroundColor: isDarkModeActive ? 'rgba(49, 48, 53, 0.9)' : 'white',
        color: isDarkModeActive ? 'white' : null,
      }}
    >
      <FormsComponents formsCompsProps={formsCompsProps} />
    </Card>
  )
}

const WrappedNewReply = withStyles(styles)(NewReply)

export default WrappedNewReply
