import axios from 'axios'
import React from 'react'
import FullInteractiveRequestDetailsModel from '../../../../../../../notificationsSystem/FullInteractiveRequestDetailsModel'

const CampaignOfferDetails = (props) => {
  const { card, exprops, requestsUsers } = props
  const { campaign } = exprops
  const request = card
  const requestingUser = requestsUsers?.find((u) => u?._id === request?.userId)
  const rtl = document.body.dir === 'rtl'

  return (
    <FullInteractiveRequestDetailsModel
      RequestText={
        <section>
          {requestingUser?.firstName + ' ' + requestingUser?.lastName}{' '}
          {rtl
            ? 'يـ(تـ)ريد ان يـ(تـ)شارك فى هذه الحملة الترويجية'
            : 'wants to contribute to this Campaign'}
        </section>
      }
      onAccept={async () => {
        // to add him to the contributors
        await axios.patch(
          `/campaigns/addContributor/${campaign?._id || campaign?.id}`,
          {
            contributorId: request?.userId,
          }
        )
        // to add him to the default channel
        await axios.patch(
          `/channels/channelsSystem/defaultChannel/${
            campaign?._id || campaign?.id
          }`,
          {
            contributorId: request?.userId,
          }
        )
      }}
      NotificationCreatorAcceptProps={{
        associativity: 'campaign',
        relation: 'visitor',
        conditionIndex: 0,
        category: 'contributors',
        elements: [
          {
            hashTagType: campaign?.hashTagType,
            id: campaign?._id || campaign?.id,
            title: campaign?.Title || campaign?.title,
          },
        ],
      }}
      NotificationCreatorRefuseProps={{
        associativity: 'campaign',
        relation: 'visitor',
        conditionIndex: 0,
        category: 'contributors',
        elements: [
          {
            hashTagType: campaign?.hashTagType,
            id: campaign?._id || campaign?.id,
            title: campaign?.Title || campaign?.title,
          },
        ],
      }}
      contributorProps={{
        projectId: campaign?.projectId,
        MembershipState: 'Investor',
      }}
      requestingUser={requestingUser}
      {...props}
    />
  )
}

export default CampaignOfferDetails
