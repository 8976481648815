import React, { useEffect, useState } from 'react'
import { MdCamera } from 'react-icons/md'
import withStyles from '@mui/styles/withStyles'
import { Popover } from '@mui/material'
import CustomCircularProgress from '../../SmallComponents/CustomCircularProgress'

const styles = () => ({
  changeImageIcon: {
    position: 'absolute',
    zIndex: 1,
  },

  camerIconWrapper: {
    backgroundColor: 'rgba(255,255,255, 0.6)',
    color: 'black',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      fotnSize: 18,
      backgroundColor: 'rgba(255,255,255, 0.8)',
    },
  },
})

const ImageUpdatorPopover = ({
  hide,
  top,
  right,
  bottom,
  left,
  classes,
  content,
  isLoading,
  remImage,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handlePopoverClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  useEffect(() => {
    handlePopoverClose()
  }, [remImage])
  return (
    <>
      <span
        className={classes.changeImageIcon}
        style={{
          top,
          right,
          bottom,
          left,
          display: hide && 'none',
        }}
      >
        <span className={classes.camerIconWrapper}>
          {isLoading ? (
            <CustomCircularProgress size={20} />
          ) : (
            <MdCamera size={23} onClick={handlePopoverOpen} />
          )}
        </span>
      </span>
      <Popover
        open={isLoading ? false : open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {content}
      </Popover>
    </>
  )
}

const WrappedImageUpdatorPopover = withStyles(styles)(ImageUpdatorPopover)

export default WrappedImageUpdatorPopover
