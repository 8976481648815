import React from 'react'
import withStyles from '@mui/styles/withStyles'
import WindowViewerSystem from '../../../../../windowViewer/WindowViewerSystem'
import WindowProps from '../../../../../windowViewer/WindowProps'
import DistributedWinCardsConfigurer from '../../../../../navigatorCardsModel/NavigatorCardsModelComps/DistributedWinCardsConfigurer'
import HashTagCardsConfigs from '../../../../../suggestionsSystem/HashTagCardsConfigs'

const styles = () => ({
  mentionWrapper: {
    fontWeight: 500,
    cursor: 'pointer',
  },
  businessMTIcon: {
    margin: '2px 0 -2px 0',
  },
})

const DetectorElementModel = ({
  classes,
  textPackage,
  word,
  isDarkModeActive,
  notEngineProps,
}) => {
  const mentionitem = textPackage?.chosenHashTagElements?.filter(
    (element) => element?.identifier === word
  )[0]?.mentionElement
  const { Icon, iconColor, titleProperty, hashTagColor } =
    HashTagCardsConfigs().filter(
      (car) => car.hashTagType === mentionitem?.hashTagType
    )[0] || {}
  const mentionitemTitle = textPackage?.chosenHashTagElements?.filter(
    (element) => element?.identifier === word
  )[0]?.mentionElement[titleProperty]
  const { isWindowOpen, windowOpener, windowCloser } = WindowProps()
  const { modelUnitProps } = mentionitem
    ? DistributedWinCardsConfigurer([mentionitem])[0]
    : {}
  const wProps = {
    windowCloser,
    [mentionitem?.windowProps?.windowCardName]: mentionitem,
    ...mentionitem?.windowProps,
    ...modelUnitProps,
    ...notEngineProps,
  }
  const windowType = mentionitem?.windowProps?.windowType
  return (
    <>
      <span
        onClick={() => windowOpener()}
        className={classes.mentionWrapper}
        style={{
          display: word.trim() ? null : 'none',
          color: hashTagColor || 'white',
        }}
      >
        {Icon && (
          <Icon
            style={{ color: iconColor }}
            className={classes.businessMTIcon}
          />
        )}{' '}
        {mentionitemTitle}
      </span>
      {mentionitem?.hashTagType && (
        <WindowViewerSystem
          isWindowOpen={isWindowOpen}
          windowCloser={windowCloser}
          isDarkModeActive={isDarkModeActive}
          props={wProps}
          windowType={windowType}
        />
      )}
    </>
  )
}

const WrappedDetectorElementModel = withStyles(styles)(DetectorElementModel)

export default WrappedDetectorElementModel
