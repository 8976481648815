import { useTranslation } from 'react-i18next'

const ReverseEngineeringBusinessModel = () => {
  const { t } = useTranslation('ReverseEngineeringBusinessModel')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 47,
    title: t('title_bm47'),
    subTitle: t('subTitle_bm47'),
    main: t('main_bm47'),
    when: t('when_bm47'),
    who: t('who_bm47'),
    what: t('what_bm47'),
    how: t('how_bm47'),
    why: t('why_bm47'),
    examples: rtl
      ? [
          'تويوتا - تأسست عام 1937 - اليابان',
          'بريليانس الصين لصناعة السيارات - تأسست عام 1992 - الصين',
          'Pelikan - تأسست عام 1832 - سويسرا',
          'دينر - تأسست عام 1820 - سويسرا',
        ]
      : [
          'Toyota - Founded 1937 - Japan',
          'Brilliance China Auto - Founded 1992 - China',
          'Pelikan - Founded 1832 - Switzerland',
          'Denner - Founded 1820 - Switzerland',
        ],
    tags: rtl
      ? ['بحث وتطوير منخفض', 'سعر منخفض', 'منافسة قوية']
      : ['Reduced R&D', 'Low Price', 'Aggressive Competition'],
    considerations: t('considerations_bm47'),
    combinations: [],
    hashTagType: 'businessModelTemp',
  }
}

export default ReverseEngineeringBusinessModel
