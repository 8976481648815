import { useTranslation } from 'react-i18next'

const PlatformAsAService = () => {
  const { t } = useTranslation('PlatformAsAService')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 74,
    title: t('title_bm74'),
    subTitle: t('subTitle_bm74'),
    main: t('main_bm74'),
    when: t('when_bm74'),
    who: t('who_bm74'),
    what: t('what_bm74'),
    how: t('how_bm74'),
    why: t('why_bm74'),
    examples: rtl
      ? [
          'Heroku - تأسست عام 2007 - الولايات المتحدة',
          'Google App Engine- تأسس عام 2008 - الولايات المتحدة',
          'Microsoft Azure- تأسست عام 2010 - الولايات المتحدة',
          'AWS Elastic Beanstalk- تأسست عام 2011 - الولايات المتحدة',
          'OpenShift- تأسست عام 2011 - الولايات المتحدة',
          'Pivotal Cloud Foundry- تأسست عام 2013 - الولايات المتحدة',
          'IBM Bluemix- تأسست عام 2014 - الولايات المتحدة',
          'Oracle Cloud- تأسست عام 2016 - الولايات المتحدة',
          'Alibaba Cloud- تأسست عام 2009 - الصين',
          'Salesforce Heroku- تأسست عام 2007 - الولايات المتحدة',
        ]
      : [
          'Heroku - Founded 2007 - United States',
          'Google App Engine- Founded 2008 - United States',
          'Microsoft Azure- Founded 2010 - United States',
          'AWS Elastic Beanstalk- Founded 2011 - United States',
          'OpenShift- Founded 2011 - United States',
          'Pivotal Cloud Foundry- Founded 2013 - United States',
          'IBM Bluemix- Founded 2014 - United States',
          'Oracle Cloud- Founded 2016 - United States',
          'Alibaba Cloud- Founded 2009 - China',
          'Salesforce Heroku- Founded 2007 - United States',
        ],
    tags: rtl
      ? [
          'خدمات سحابية',
          'نماذج الخدمة',
          'تطوير التطبيقات',
          'مزود السحابة',
          'نشر البنية التحتية',
          'نموذج العمل',
          'تجارة صغيرة',
          'الشركات المبتدئة',
          'خدمة تكنولوجيا المعلومات',
          'حوسبة سحابية',
          'أعمال التجارة الإلكترونية',
          'الإستراتيجية والابتكار',
        ]
      : [
          'Cloud Services',
          'Service Models',
          'Application Development',
          'Cloud Provider',
          'Infrastructure Deployment',
          'Business Model',
          'Small Businesses',
          'Startup Companies',
          'IT Service',
          'Cloud Computing',
          'Ecommerce Business',
          'Strategy and Innovation',
        ],
    considerations: t('considerations_bm74'),
    combinations: ['$LBM75', '$LBM76', '$LBM60', '$LBM79', '$LBM80'],
    hashTagType: 'businessModelTemp',
  }
}

export default PlatformAsAService
