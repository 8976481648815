import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

// styling here is compatible only for the home feed, social posts, i need to make the styling dynamic to suit the rest of the feeds and posts

const styles = () => ({
  image: {
    width: '100%',
    maxHeight: 500,
    objectFit: 'contain',
    cursor: 'pointer',
  },
})

const SingleImageModel = ({ classes, imagesArray, openFun }) => {
  return (
    <div>
      <LazyLoadImage
        effect='blur'
        width={'100%'}
        src={imagesArray[0]}
        alt='Images Viewer System'
        className={classes.image}
        onClick={() => {
          openFun()
        }}
      />
    </div>
  )
}

const WrappedSingleImageModel = withStyles(styles)(SingleImageModel)

export default WrappedSingleImageModel
