import { Input, TextareaAutosize } from '@mui/material'
import React, { useState, useEffect, useRef } from 'react'
import withStyles from '@mui/styles/withStyles'
import TextDirectionDetector from '../../textProcessingEngine/TextEngineComponents/TextProcessorComponents/DetectionCoreComponents/TextDirectionDetector'
import HashTagSuggestSystem from '../../suggestionsSystem/HashTagSuggestSystem'

const styles = () => ({
  input: {},
  textArea: {
    width: 300,
  },
  '@media (max-width: 600px)': {
    textArea: {
      width: 180,
    },
  },
})

const SimpleInputModel = ({ classes, ...props }) => {
  const {
    optionsSetState,
    optionsState,
    isDarkModeActive,
    element,
    initialFormData,
  } = props
  const { title, value, placeholder, disabled, maxLettersCount, minRows } =
    element
  const stateName = title
  const defaultValue = value

  const [inputText, setInputText] = useState(
    initialFormData ? initialFormData[title.split(' ').join('')] : defaultValue
  )

  const inputTextHandler = (event) => {
    setInputText(event.currentTarget.value)
  }
 const anchorRef = useRef(null)
  useEffect(() => {
    optionsSetState({
      ...optionsState,
      [stateName.split(' ').join('')]: inputText,
    })
  }, [inputText])
  const [isSuggeCardClicked, setIsSuggeCardClicked] = useState(false)
  return element?.multiLine ? (
    <>
      <TextareaAutosize
        ref={anchorRef}
        disabled={disabled}
        minRows={minRows || 1}
        className={classes.textArea}
        style={{
          position: 'relative',
          color: isDarkModeActive ? 'white' : 'black',
          backgroundColor: isDarkModeActive
            ? 'rgb(255, 255, 255, 0.1)'
            : 'rgba(217,224,252, 0.2)',
          borderRadius: 10,
          padding: '4px auto',
          width: '100%',
          direction: inputText
            ? TextDirectionDetector(inputText)
            : document.body.dir,
        }}
        value={inputText}
        onChange={(event) => {
          inputTextHandler(event)
          setIsSuggeCardClicked(false)
        }}
        placeholder={' ' + placeholder}
        maxLength={maxLettersCount}
      />
      <HashTagSuggestSystem
        {...props}
        textContent={inputText}
        setTextContent={setInputText}
        isSuggeCardClicked={isSuggeCardClicked}
        setIsSuggeCardClicked={setIsSuggeCardClicked}
        anchorRef={anchorRef}
        cursorDirection={TextDirectionDetector(inputText)}
      />
    </>
  ) : (
    <Input
      disabled={disabled}
      style={{
        color: isDarkModeActive ? 'white' : 'black',
        direction: inputText
          ? TextDirectionDetector(inputText)
          : document.body.dir,
      }}
      value={inputText}
      onChange={(event) => inputTextHandler(event)}
      className={classes.input}
      placeholder={placeholder}
      type={element?.numberValue ? 'number' : 'text'}
      required={element?.required}
    />
  )
}

const WrappedSimpleInputModel = withStyles(styles)(SimpleInputModel)

export default WrappedSimpleInputModel
