import React from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'
import GPDFDList, {
  Item,
} from '../../../PDFTemplatesComps/GeneralPDFDocumentComps/GPDFDList'

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Lato Bold',
    fontSize: 11,
    marginBottom: 10,
  },
})

const UsersView = ({ name, users }) => (
  <View>
    <Text style={styles.title}>{name}</Text>
    <GPDFDList>
      {users.map((user, i) => (
        <Item key={i}>{'   ' + user?.firstName + ' ' + user?.lastName}</Item>
      ))}
    </GPDFDList>
  </View>
)

const ContractPDFUsers = ({ users }) => (
  <View>
    <UsersView name='Users' users={users} />
  </View>
)

export default ContractPDFUsers
