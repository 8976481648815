import React, { useState } from 'react'
import MarketingStrategyAspectsConfigs from '../../../ProjectModelingComponents/MarketingStrategiesComponents/MarketingStrategyAspectsComps/MarketingStrategyAspectsConfigs'
import ChooseProductStartegyAspects from './ProductStrategyProfileComps/ChooseProductStartegyAspects'
import ProductStrategyAspects from './ProductStrategyProfileComps/ProductStrategyAspects'
import ProductStrategyPreparation from './ProductStrategyProfileComps/ProductStrategyPreparation'

const ProductStrategyProfile = (props) => {
  const { strategy } = props
  const cards = MarketingStrategyAspectsConfigs()
  const [chosenCards, setChosenCards] = useState(
    strategy?.modelTempsIds.map((id) =>
      cards.find((card) => card?.id === id)
    ) || []
  )

  const propsII = {
    ...props,
    chosenCards,
    setChosenCards,
  }
  return (
    <div>
      <ProductStrategyAspects {...propsII} />
      <ChooseProductStartegyAspects {...propsII} />
      <ProductStrategyPreparation {...propsII} />
    </div>
  )
}

export default ProductStrategyProfile
