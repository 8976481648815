import React from 'react'

const StakeholderGroup = () => {
  const rtl = document.body.dir === 'rtl'
  return (
    <>
      {rtl
        ? 'مجموعة أصحاب المصلحة هي مجموعة من الأشخاص أو الشركات التي لها مصلحة أو تتأثر بإجراءات أو قرارات منظمة 12. يمكن أن تكون مجموعات أصحاب المصلحة داخلية (داخل المنظمة) أو خارجية (خارج المنظمة) 342. بعض الأمثلة على مجموعات أصحاب المصلحة هم العملاء والمساهمون والموظفون والموردون والمجتمعات والحكومات'
        : 'A stakeholder group is a group of people or organizations that have an interest in or are affected by the actions or decisions of an organization12. Stakeholder groups can be internal (within the organization) or external (outside the organization)342. Some examples of stakeholder groups are customers, shareholders, employees, suppliers, communities and governments.'}
    </>
  )
}

export default StakeholderGroup
