import axios from 'axios'
import React, { useEffect, useState } from 'react'
import BlocksLoader from '../../../../../../SmallComponents/BlocksLoader'
import TitleHeader from '../../../../../../SmallComponents/Header'
import TextEngineToolsFooter from '../../../../../../SmallComponents/TextEngineToolsFooter'
import ModelCoreStrategy from './ModelProfileComponents/ModelCoreStrategy'
import ModelInterrlations from './ModelProfileComponents/ModelInterrlations'
import ModelPopularity from './ModelProfileComponents/ModelPopularity'
import ModelProfileButtons from './ModelProfileComponents/ModelProfileButtons'
import ModelStructureUnit from './ModelProfileComponents/ModelStructureUnit'
import ModelTags from './ModelProfileComponents/ModelTags'
import ModelTitle from './ModelProfileComponents/ModelTitle'

const ModelProfile = (props) => {
  const { model, DCGProps, hidCustomEditing, externalLinkMode } = props
  const { project, isDarkModeActive } = DCGProps
  const rtl = document.body.dir === 'rtl'
  const [modelMods, setModelMods] = useState()
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    const fetchModelMods = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(
          `/businessModelCustomizationss/${model?.id}/${project?._id}`
        )
        setModelMods(res.data.model)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    if (project?._id) {
      fetchModelMods()
    } else {
      setIsLoading(false)
    }
  }, [model?.id, project?._id])

  const modelStructureConfigs = [
    {
      title: 'Who',
      definition: rtl ? 'هم العملاء المستهدفون؟' : 'are the target customers?',
      modelDervied: model?.who,
      custom: modelMods?.who,
      hideCustom: hidCustomEditing,
    },
    {
      title: 'What',
      definition: rtl
        ? 'نقدم للعملاء ويلبي احتياجاتهم؟'
        : 'do we offer the customers and fulfills their needs?',
      modelDervied: model?.what,
      custom: modelMods?.what,
      hideCustom: hidCustomEditing,
    },
    {
      title: 'How',
      definition: rtl ? 'ننتج عروضنا؟' : 'do we produce our offerings?',
      modelDervied: model?.how,
      custom: modelMods?.how,
      hideCustom: hidCustomEditing,
    },
    {
      title: 'Why',
      definition: rtl
        ? 'سيكون نهجنا مربحًا؟'
        : 'our approach will be profitable?',
      modelDervied: model?.why,
      custom: modelMods?.why,
      hideCustom: hidCustomEditing,
    },
    {
      title: 'When',
      definition: rtl
        ? 'يمكننا اعتماد هذا النموذج؟'
        : 'can we adopt this model?',
      modelDervied: model?.when,
      custom: modelMods?.when,
      hideCustom: true,
    },
    {
      title: 'PitFall',
      definition: rtl
        ? 'ضع في اعتبارك هذا إذا كنت ستطبقه'
        : 'Consider this if you will apply it',
      modelDervied: model?.considerations,
      hideCustom: true,
    },
  ]

  const propsII = {
    ...props,
    modelMods,
  }

  return isLoading ? (
    <BlocksLoader {...DCGProps} />
  ) : (
    <div style={{ color: isDarkModeActive && 'white' }}>
      <ModelTitle {...propsII} />
      <ModelCoreStrategy props={propsII} />
      <ModelTags {...propsII} />
      <TitleHeader headerText={rtl ? 'هيكل النموذج' : 'Model Strucure'} />
      {modelStructureConfigs
        .filter((m) => m.modelDervied)
        .map((str, index) => (
          <ModelStructureUnit
            model={str}
            key={index}
            props={props}
            DCGProps={DCGProps}
            modelMods={modelMods}
            externalLinkMode={externalLinkMode}
          />
        ))}
      <ModelPopularity {...propsII} />
      <ModelProfileButtons {...propsII} />
      <ModelInterrlations props={propsII} />
      <TextEngineToolsFooter
        {...DCGProps}
        hideTETFooter={hidCustomEditing || externalLinkMode}
      />
    </div>
  )
}

export default ModelProfile
