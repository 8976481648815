import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import ar from 'javascript-time-ago/locale/ar'
import { convertNumbers2Arabic } from 'to-arabic-numbers'

TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(ar)

const TimeAgoFormattor = ({ date }) => {
  const rtl = document.body.dir === 'rtl'
  const timeAgo = rtl ? new TimeAgo('ar') : new TimeAgo('en-US')
  return rtl
    ? convertNumbers2Arabic(timeAgo.format(new Date(date))).replace(
        'قبل',
        'منذ'
      )
    : timeAgo.format(new Date(date))
}

export default TimeAgoFormattor
