import React, { useContext } from 'react'
import DepTaskRequests from './DepTaskProfileComponents/DepTaskRequests'
import TaskDetails from './DepTaskProfileComponents/TaskDetails'
import TaskTerminationButton from './DepTaskProfileComponents/TaskTerminationButton'
import TextEngineToolsFooter from '../../../../../SmallComponents/TextEngineToolsFooter'
import SimilarTasks from './DepTaskProfileComponents/SimilarTasks'
import TaskInteractionButtons from './DepTaskProfileComponents/TaskInteractionButtons'
import { AuthContext } from '../../../../../../context/AuthContext'

const DepTaskProfile = (props) => {
  const { DCGProps, depManager, task } = props
  const { isDarkModeActive, project } = DCGProps
  const { user } = useContext(AuthContext)
  const authorised =
    user?._id === project?.userId ||
    user?._id === depManager?._id ||
    user?.createdProjects?.includes(task?.projectId) ||
    user?._id === task?.TaskModeratorId
  return (
    <article
      style={{
        width: '100%',
        color: isDarkModeActive ? 'white' : null,
      }}
    >
      <SimilarTasks {...props} />
      <TaskDetails {...props} />
      {authorised && (
        <>
          <DepTaskRequests {...props} />
          <TaskTerminationButton {...props} />
        </>
      )}

      <TaskInteractionButtons {...props} />
      {authorised && <TextEngineToolsFooter {...DCGProps} />}
    </article>
  )
}

export default DepTaskProfile
