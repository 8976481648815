const NewResearchConceptConfigs = () => {
  return [
    {
      title: 'Title',
      formComponentType: 'text',
      value: '',
      placeholder: 'Concept Title',
    },
  ]
}

export default NewResearchConceptConfigs