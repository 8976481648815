import React, { useEffect, useState } from 'react'
import ExpandedCardsSystem from '../../../../../../../expandedCardsSystem/ExpandedCardsSystem'
import TitleHeader from '../../../../../../../SmallComponents/Header'
import ProductRequestDetails from '../ProductServicesRequestsComponents/ProductRequestDetails'
import CustomCircularProgress from '../../../../../../../SmallComponents/CustomCircularProgress'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import MultiLangNumber from '../../../../../../../SmallComponents/MultiLangNumber'

const ProductServicesRequests = ({
  product,
  hideProductRequests,
  subDepId,
  DCGProps,
}) => {
  const [requests, setRequests] = useState([])
  const [requestsUsers, setRequestsUsers] = useState([])
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    const fetchRequests = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(`/requests/elementRequests/${product?._id}`)
        setRequests(res.data.requests)
        setRequestsUsers(res.data.requestsUsers)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    fetchRequests()
  }, [product?._id])

  return isLoading ? (
    <CustomCircularProgress />
  ) : (
    <span style={{ display: hideProductRequests ? 'none' : null }}>
      <TitleHeader
        headerText={`${MultiLangNumber(requests?.length)} ${t('Requests')}`}
      />
      <ExpandedCardsSystem
        cards={requests}
        UnitCardDetailsComponent={ProductRequestDetails}
        firsButtonConfigs={{
          hideButton: true,
          props: {
            product,
          },
        }}
        searchSystemProps={{}}
        DCGProps={DCGProps}
        requestsUsers={requestsUsers}
        setRequests={setRequests}
      />
    </span>
  )
}

export default ProductServicesRequests
