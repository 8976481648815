import { useTranslation } from 'react-i18next'

const AikidoBusinessModel = () => {
  const { t } = useTranslation('AikidoBusinessModel')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 3,
    title: t('title_bm03'),
    subTitle: t('subTitle_bm03'),
    main: t('main_bm03'),
    when: t('when_bm03'),
    who: t('who_bm03'),
    what: t('what_bm03'),
    how: t('how_bm03'),
    why: t('why_bm03'),
    examples: rtl
      ? [
          'سيكس فلاجز - شركة أمريكية',
          'ذي بودي شوب إنترناشونال بي إل سي - تأسست عام 1976',
          'سواتش - تأسست عام 1983 - الشركة المصنعة للساعات السويسرية',
          'سيرك دو سولي - تأسست عام 1984 - كندا',
          'نينتندو - تأسست عام 1889 - اليابان',
        ]
      : [
          'Six Flags - USA Company',
          'The Body Shop International plc - Founded 1976',
          'Swatch - Founded 1983 - Swiss watch manufacturer',
          'Cirque du Soleil - Founded 1984 - Canada',
          'Nintendo - Founded 1889 - Japan',
        ],
    tags: rtl
      ? [
          'دخول سريع إلى السوق',
          'الميزة الاستراتيجية',
          'عدم وجود أطر عمل للوافدين الجدد',
          'العملاء المناسبين من منافسيهم',
          'إعادة تصميم الخدمة',
          'توسيع نطاق الوصول إلى السوق',
        ]
      : [
          'Accelerated Market Entry',
          'Strategic Advantage',
          'Lack of Newcomer Frameworks',
          'Appropriate Customers from Rivals',
          'Service Redesign',
          'Broaden Market Reach',
        ],
    considerations: t('considerations_bm03'),
    combinations: ['$LBM2'],
    hashTagType: 'businessModelTemp',
  }
}

export default AikidoBusinessModel
