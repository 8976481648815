import React, { useContext, useState } from 'react'
import { AuthContext } from '../../../../../../../../context/AuthContext'
import PollCardVotingView from './PollCardComps/PollCardVotingView'
import PollCardResultsView from './PollCardComps/PollCardResultsView'
import PollCardTitle from './PollCardComps/PollCardTitle'
import moment from 'moment'

const PollCard = (props) => {
  const { poll, options, sender, interactedWith } = props
  const { user } = useContext(AuthContext)
  let allVoters = []
  options?.map((op) => {
    allVoters.push(...op?.votersIds)
  })

  const totalVoters = options
    ?.map((op) => op?.votersIds?.length)
    ?.reduce((prev, current) => prev + current)

  const [isVoted, setIsVoted] = useState(
    allVoters?.includes(user?._id) || interactedWith
  )

  const propsII = {
    ...props,
    totalVoters,
    setIsVoted,
    allVoters,
    user,
  }
  // here i need to test if the date of the poll is over or not
  const stillAllowed = poll?.Deadline
    ? moment(poll?.Deadline).isSameOrAfter(moment().format('YYYY-MM-DD'))
    : true

  return (
    <div
      style={{
        borderRadius: 15,
        background: 'rgba(255,255,255,0.4)',
        padding: 10,
      }}
    >
      <PollCardTitle poll={poll} {...props} />
      {options.map((op) =>
        stillAllowed ? (
          isVoted || sender?._id === user?._id ? (
            <PollCardResultsView op={op} key={op?._id} {...propsII} />
          ) : (
            <PollCardVotingView op={op} key={op?._id} {...propsII} />
          )
        ) : (
          <PollCardResultsView op={op} key={op?._id} {...propsII} />
        )
      )}
    </div>
  )
}

export default PollCard
