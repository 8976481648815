import React, { useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import ShadowGradient from '../SmallComponents/ShadowGradient'
import TextEngine from '../textProcessingEngine/TextEngine'
import TextDirectionDetector from '../textProcessingEngine/TextEngineComponents/TextProcessorComponents/DetectionCoreComponents/TextDirectionDetector'

const styles = () => ({
  textPostContainerCollapsed: {
    height: 100,
    overflow: 'hidden',
    display: 'block',
    fontSize: 16,
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
    fontWeight: 400,
  },
  textPostContainerExpanded: {
    height: 100,
    overflow: 'hidden',
    fontSize: 16,
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
    fontWeight: 400,
  },
  '@media (max-width: 600px)': {
    textPostContainerCollapsed: {
      fontSize: 14,
    },
    textPostContainerExpanded: {
      fontSize: 14,
    },
  },
})

const TextPost = ({
  classes,
  post,
  margins,
  // to control what to appear and not in the commenting system
  commentingSystemView,
  // for TextPost
  isCommentingSystemActive,
  isDarkModeActive,
  noShadowedText,
  engineFuns,
  socket,
}) => {
  const [textRegionHeight, setTextRegionHeight] = useState(
    Number(post?.postTextContent?.length)
  )
  const [isRegionClicked, setIsRegionClicked] = useState(false)

  const alwaysExpandedCondition =
    isCommentingSystemActive ||
    commentingSystemView ||
    post?.postTextContent.length < 200

  return noShadowedText ? (
    <article
      dir={TextDirectionDetector(post?.postTextContent)}
      className={classes.textPostContainerExpanded}
      style={{
        height: 'auto',
        position: 'relative',
        margin: margins,
        // this condition to hide the text from the commeniting system
        display: commentingSystemView ? 'none' : null,
        marginBottom: 10,
      }}
    >
      <TextEngine
        textContent={post?.postTextContent || ''}
        textPackage={post}
        textFuns={() => {}}
        isDarkModeActive={isDarkModeActive}
        socket={socket}
      />
    </article>
  ) : (
    <article
      dir={TextDirectionDetector(post?.postTextContent)}
      className={
        alwaysExpandedCondition
          ? classes.textPostContainerExpanded
          : isRegionClicked
          ? classes.textPostContainerExpanded
          : classes.textPostContainerCollapsed
      }
      style={{
        position: 'relative',
        height: alwaysExpandedCondition
          ? 'auto'
          : isRegionClicked
          ? textRegionHeight
          : Number(
              post?.postTextContent.length /
                Math.log2(Number(post?.postTextContent.length) / 20)
            ),
        margin: margins,
        // this condition to hide the text from the commeniting system
        display: commentingSystemView ? 'none' : null,
        // minHeight: isCommentingSystemActive ? 50 : null,
        marginBottom:
          post?.postTextContent.length < 150 || isRegionClicked ? 10 : 0,
      }}
    >
      <TextEngine
        textContent={post?.postTextContent || ''}
        textPackage={post}
        isDarkModeActive={isDarkModeActive}
        engineFuns={engineFuns}
        socket={socket}
      />

      <ShadowGradient
        textContent={post?.postTextContent}
        setTextRegionHeight={setTextRegionHeight}
        setIsRegionClicked={setIsRegionClicked}
        isRegionClicked={isRegionClicked}
        alwaysExpandedCondition={alwaysExpandedCondition}
      />
    </article>
  )
}

const WrappedTextPost = withStyles(styles)(TextPost)

export default WrappedTextPost
