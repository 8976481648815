import { StyleSheet, Text, View } from '@react-pdf/renderer'
import axios from 'axios'
import React, { useEffect, useState } from 'react'

const styles = StyleSheet.create({
  item: {
    flexDirection: 'row',
    marginBottom: 5,
  },
  bulletPoint: {
    width: 10,
    fontSize: 10,
  },
  itemContent: {
    flex: 1,
    fontSize: 10,
    fontFamily: 'Lato',
  },
})

const ElementCreator = ({ button }) => {
  const { element } = button.PDFGenProps
  const [elementUser, setElementUser] = useState()
  useEffect(() => {
    const ftechOneUser = async () => {
      try {
        const res = await axios.get(`/users/oneUser/${element?.userId}`)
        setElementUser(res.data.user)
      } catch (error) {
        console.log(error)
      }
    }
    if (element?.userId) ftechOneUser()
  }, [element?.userId])
  return (
    <View style={styles.item}>
      <Text style={styles.itemContent}>
        {elementUser?.firstName + ' ' + elementUser?.lastName}
      </Text>
    </View>
  )
}

export default ElementCreator
