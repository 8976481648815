import { useTranslation } from 'react-i18next'

const TwoSidedMarketBusinessModel = () => {
  const { t } = useTranslation('TwoSidedMarketBusinessModel')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 57,
    title: t('title_bm57'),
    subTitle: t('subTitle_bm57'),
    main: t('main_bm57'),
    when: t('when_bm57'),
    who: t('who_bm57'),
    what: t('what_bm57'),
    how: t('how_bm57'),
    why: t('why_bm57'),
    examples: rtl
      ? [
          'جوجل - 1998 - الولايات المتحدة الأمريكية',
          'داينرز كلوب - 1950 - الولايات المتحدة الأمريكية',
          'eBay - 1995 - الولايات المتحدة الأمريكية',
          'أمازون - 1994 - الولايات المتحدة الأمريكية',
          'زابوس - 1999 - الولايات المتحدة الأمريكية',
          'JCDecaux - 1964 - فرنسا',
          'فيسبوك - 2004 - الولايات المتحدة الأمريكية',
          'صحف مترو - 1969 - المملكة المتحدة',
        ]
      : [
          'Google - 1998 - USA',
          'Diners Club - 1950 - USA',
          'eBay - 1995 - USA',
          'Amazon - 1994 - USA',
          'Zappos - 1999 - USA',
          'JCDecaux - 1964 - France',
          'Facebook - 2004 - USA',
          'Metro newspapers - 1969 - UK',
        ],
    tags: rtl
      ? ['العروض', 'الصفقات', 'الاتصالات']
      : ['Win-win', 'Offers', 'Deals', 'Communication'],
    considerations: t('considerations_bm57'),
    combinations: ['$LBM22', '$LBM66'],
    hashTagType: 'businessModelTemp',
  }
}

export default TwoSidedMarketBusinessModel
