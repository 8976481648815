import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../../../../context/AuthContext'
import { useContext } from 'react'

export default function PostAdvertiseButtonConfig({ shareSystemProps }) {
  const { disablePostTypeOptions, post } = shareSystemProps
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { user } = useContext(AuthContext)
  return {
    title: t('Advertise'),
    funs: async () => {
      const res = await axios.post('/adsManagment', {
        userId: user?._id,
        postId: post?._id,
      })
      if (res.data.adCampaign)
        navigate(`/ads_management/manage-ads/${res.data.adCampaign?._id}`)
    },
    displayConditions: disablePostTypeOptions ? null : 'none',
    disabled: false,
  }
}
