import { Card } from '@mui/material'
import React from 'react'
import CentralTitle from '../../../../../../SmallComponents/CentralTitle'
import ContributorProjectSquareAvatar from '../../../../../../SmallComponents/ContributorProjectSquareAvatar'
import withStyles from '@mui/styles/withStyles'
import Loading from '../../../../../../SmallComponents/Loading'

const styles = () => ({
  elementsWrapper: {
    height: 'auto',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexFlow: 'row wrap',
    marginBottom: 10,
  },
  viewCard: {
    display: 'flex',
    height: 'auto',
    flexDirection: 'column',
    width: 'auto',
    padding: '10px',
    margin: '10px',
    borderRadius: '10px !important',
    backgroundColor: 'rgba(217,224,252, 0.2) !important',
  },
})

const MeetingAttendees = ({
  users,
  projects,
  classes,
  isLoading,
  isDarkModeActive,
}) => {
  const rtl = document.body.dir === 'rtl'
  return (
    <div style={{ color: isDarkModeActive ? 'white' : 'black' }}>
      {isLoading ? (
        <Loading />
      ) : (
        <section>
          <CentralTitle title={rtl ? 'مشروعات' : 'Projects'} />
          <Card className={classes.viewCard}>
            <section className={classes.elementsWrapper}>
              {projects.map((element) => (
                <ContributorProjectSquareAvatar
                  element={element}
                  key={element?._id}
                  isDarkModeActive={isDarkModeActive}
                />
              ))}
            </section>
          </Card>
          <CentralTitle title={rtl ? 'أعضاء' : 'Users'} />
          <Card className={classes.viewCard}>
            <section className={classes.elementsWrapper}>
              {users.map((element) => (
                <ContributorProjectSquareAvatar
                  element={element}
                  key={element?._id}
                  isDarkModeActive={isDarkModeActive}
                />
              ))}
            </section>
          </Card>
        </section>
      )}
    </div>
  )
}

const WrappedMeetingAttendees = withStyles(styles)(MeetingAttendees)

export default WrappedMeetingAttendees
