import { useTranslation } from "react-i18next"

const CustomerBehaviour = () => {
   const { t } = useTranslation('CustomerBehaviour')
   const rtl = document.body.dir === 'rtl'
  return {
    id: 7,
    category: t('category_ms07'),
    title: t('title_ms07'),
    subTitle: t('subTitle_ms07'),
    main: t('main_ms07'),
    how: t('how_ms07'),
    usedInModels: ['$LBM12'],
    tags: rtl
      ? [
          'تأثيرات شراء المستهلك',
          'معتقدات ثقافية',
          'التأثيرات الاجتماعية',
          'عوامل اقتصادية',
          'عوامل نفسية',
          "اقتراح القيمة للعملاء'",
          'علاقات العملاء',
          'احتياجات العميل',
        ]
      : [
          'Consumer Buying Influences',
          'Cultural Beliefs',
          'Sociological Influences',
          'Economic Factors',
          'Psychological Factors',
          'Customer Value Proposition',
          'Customer Relationships',
          'Customer Needs',
        ],
    examples: t('examples_ms07'),
    hashTagType: 'markStraAspect',
  }
}

export default CustomerBehaviour
