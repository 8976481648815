import { Button } from '@mui/material'
import React, { useContext } from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  AreaChart,
  Area,
  ResponsiveContainer,
} from 'recharts'
import { AuthContext } from '../../../context/AuthContext'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    alignContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  button: {
    borderRadius: '10px !important',
    margin: '2px !important',
    textTransform: 'none !important',
    fontWeight: '550 !important',
    width: '100% !important',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important",
  },
})

const SpreadsheetChart = ({
  headers,
  rows,
  StampValue,
  DCGProps,
  chartType,
  windowOpener,
  hideUpdate,
  classes,
}) => {
  const { project } = DCGProps
  const { user } = useContext(AuthContext)
  const data = []
  rows?.forEach((row, rowIndex) => {
    const obj = { stamp_value: StampValue(rowIndex) }
    headers?.forEach((header, colIndex) => {
      if (colIndex > 0) {
        obj[header] = Number(row[`value${colIndex}`])
      }
    })
    data.push(obj)
  })
  let colors = []
  for (let i = 0; i < 20; i++) {
    let hue = (i * 360) / 20
    let color = 'hsl(' + hue + ', 100%, 50%)'
    colors.push(color)
  }
  const chartTypeComps = [
    <LineChart
      width='100%'
      height={400}
      data={data}
      margin={{ top: 5, right: 0, left: 0, bottom: 5 }}
    >
      <XAxis dataKey='stamp_value' />
      <YAxis
        label={{ value: 'Y Axis', angle: -90, position: 'outsideLeft' }}
        width={50}
        interval={0}
      />
      <CartesianGrid strokeDasharray='3 3' />
      <Tooltip />
      <Legend />
      {headers?.map((_, index) => (
        <Line
          key={index}
          type='monotone'
          dataKey={`${headers[index]}`}
          stroke={colors[index]}
          activeDot={index === 0 && { r: 8 }}
        />
      ))}
    </LineChart>,
    <AreaChart
      width={600}
      height={300}
      data={data}
      margin={{ top: 5, right: 0, left: 0, bottom: 5 }}
    >
      <XAxis dataKey='stamp_value' />
      <YAxis
        label={{ value: 'Y Axis', angle: -90, position: 'outsideRight' }}
      />
      <CartesianGrid strokeDasharray='3 3' />
      <Tooltip />
      <Legend />
      {headers?.map((_, index) => (
        <Area
          animationBegin={800}
          animationDuration={2500}
          key={index}
          type='monotone'
          dataKey={`${headers[index]}`}
          stroke={colors[index]}
          fill='rgb(10, 75, 95)'
          activeDot={{ r: 8 }}
        />
      ))}
    </AreaChart>,
  ]
  const rtl = document.body.dir === 'rtl'
  return (
    <section className={classes.wrapper}>
      <ResponsiveContainer height={300} width={'100%'}>
        {chartTypeComps[chartType]}
      </ResponsiveContainer>
      <Button
        onClick={windowOpener}
        className={classes.button}
        style={{
          display: (user?._id !== project?.userId || hideUpdate) && 'none',
        }}
      >
        {rtl ? 'تعديل' : 'Edit'}
      </Button>
    </section>
  )
}

const WrappedSpreadsheetChart = withStyles(styles)(SpreadsheetChart)

export default WrappedSpreadsheetChart
