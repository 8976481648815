import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { useTranslation } from 'react-i18next'
import MultiMillify from '../../../SmallComponents/MultiMillify'
import { Bars } from 'react-loader-spinner'

const styles = () => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    alignContent: 'center',
    padding: '0.5rem',
    backgroundColor: 'rgba(255,255,255,0.3)',
    borderRadius: '0.5rem',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    flex: '1 1 auto',
    margin: '0.5rem',
    boxSizing: 'border-box',
    width: '45%',
  },
  icon: {
    fontSize: '3rem',
    marginBottom: '1rem',
  },
  title: {
    fontSize: '1.2rem',
    fontWeight: '500',
    textAlign: 'center',
    marginBottom: '1rem',
  },
  count: {
    fontSize: '2rem',
    fontWeight: '700',
  },
  '@media (max-width: 600px)': {
    section: {
      padding: '0.5rem',
      margin: '5px',
      //   width: '45vw',
      //   flexDirection: 'row',
    },
    icon: {
      marginBottom: 0,
    },
    title: {
      fontSize: '17',
      fontWeight: '500',
      marginBottom: 0,
    },
    count: {
      fontSize: '15',
      fontWeight: '400',
    },
  },
})

const MangScreenViewCard = ({ c, classes }) => {
  const { t } = useTranslation()
  const rtl = document.body.dir === 'rtl'
  return (
    <div className={classes.section}>
      <span className={classes.icon}>{c.icon}</span>
      <div variant='h5' className={classes.title}>
        {t(c?.title)}
      </div>
      {c?.count !== undefined ? (
        <div variant='h4' className={classes.count}>
          {MultiMillify(c?.count)}
        </div>
      ) : (
        <Bars
          height='10'
          width='10'
          color='#4fa94d'
          ariaLabel='bars-loading'
          wrapperStyle={{
            marginRight: !rtl && '3px',
            marginLeft: rtl && '3px',
          }}
          wrapperClass=''
          visible={true}
        />
      )}
    </div>
  )
}

export default withStyles(styles)(MangScreenViewCard)
