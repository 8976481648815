import React from 'react'
import ModelDialogWrappingIII from '../../../dialogModelUnit/ModelDialogWrappingIII'
import NewContributorForm from './ContributorAdditionComps/NewContributorForm'

const ContributorAddition = (props) => {
  const { isNewContribOpen } = props
  const dialogsArray = [
    {
      open: isNewContribOpen,
      content: <NewContributorForm {...props} />,
    },
  ]
  return (
    <ModelDialogWrappingIII
      dialogsArray={dialogsArray}
      {...props}
      dialogWidth='350px'
      dialogHeight='150px'
    />
  )
}

export default ContributorAddition
