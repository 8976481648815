import React from 'react'
import ModificationUnit from '../modEngine/ModEngineComponents/ModificationUnit'

const ModEngine = (props) => {
  const { modifications } = props
  return modifications.map((modUnit) => (
    <ModificationUnit modification={modUnit} key={modUnit?._id} {...props} />
  ))
}

export default ModEngine
