import { useTranslation } from 'react-i18next'

const BrandSecrets = () => {
  const { t } = useTranslation('BrandSecrets')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 37,
    category: t('category_ms37'),
    title: t('title_ms37'),
    subTitle: t('subTitle_ms37'),
    main: t('main_ms37'),
    how: t('how_ms37'),
    usedInModels: [],
    tags: rtl
      ? [
          'صناعة القرار',
          'تقليل المخاطر',
          'شراء الطيار الآلي',
          'التعبير عن الذات',
        ]
      : [
          'Decision-Making',
          'Risk-Reduction',
          'Autopilot-Buying',
          'Self-Expression',
        ],
    examples: t('examples_ms37'),
    hashTagType: 'markStraAspect',
  }
}

export default BrandSecrets
