import { Grid } from '@mui/material'
import React from 'react'
import MultiLangNumber from '../../SmallComponents/MultiLangNumber'

const MultipleImagesModel = ({ imagesArray, openFun }) => {
  const firstFourImages = imagesArray.filter((_, index) => index < 4)
  const rtl = document.body.dir === 'rtl'
  return (
    <Grid container>
      {firstFourImages.map((image, imageIndex) => (
        <Grid
          xs={6}
          style={{
            display: 'flex',
            justifyContent: 'center',
            position: 'relative',
          }}
          key={imageIndex}
          item
        >
          <img
            src={image}
            alt='Images Viewer System'
            key={imageIndex}
            style={{
              width: '100%',
              objectFit: 'cover',
              height: 200,
              cursor: 'pointer',
              filter: imageIndex === 3 ? 'brightness(40%)' : null,
              cursor: 'pointer',
              margin:
                imageIndex === 0
                  ? rtl
                    ? '0 0 2.5px 2.5px'
                    : '0 2.5px 2.5px 0'
                  : imageIndex === 1
                  ? rtl
                    ? '0 2.5px 2.5px 0'
                    : '0 0 2.5px 2.5px'
                  : imageIndex === 2
                  ? rtl
                    ? '2.5px 0 0 2.5px'
                    : '2.5px 2.5px 0 0'
                  : rtl
                  ? '2.5px 2.5px 0 0'
                  : '2.5px 0 0 2.5px',
            }}
            onClick={() => {
              openFun()
            }}
          />
          {imageIndex === 3 ? (
            <span
              style={{
                display: 'flex',
                justifyContent: 'center',
                zIndex: '1',
                alignContent: 'center',
                fontSize: 15,
                fontWeight: 500,
                color: 'white',
                position: 'absolute',
                alignItems: 'center',
                marginTop: '35%',
              }}
              onClick={() => {
                openFun()
              }}
            >
              {`+ ${MultiLangNumber(imagesArray.length - 4)} ${
                rtl ? 'صور اضافية' : 'More Photos'
              }`}
            </span>
          ) : null}
        </Grid>
      ))}
    </Grid>
  )
}

export default MultipleImagesModel
