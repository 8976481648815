import axios from 'axios'
import React, { useEffect, useState } from 'react'
import NetworkBasicInfo from '../../../../../network/NetworkDialogContentComponents/NetworkBasicInfo'
import NetworkRank from '../../../../../network/NetworkDialogContentComponents/NetworkRank'
import BlocksLoader from '../../../../../SmallComponents/BlocksLoader'
import UserAboutPersonal from './UserPortfolioContentComps/UserAboutPersonal'
import UserPortfolioChannels from './UserPortfolioContentComps/UserPortfolioChannels'
import UserPortfolioNavigators from './UserPortfolioContentComps/UserPortfolioNavigators'

const UserPortfolioContent = (props) => {
  const { user, isDarkModeActive } = props
  const [portfolio, setPortfolio] = useState()
  const [rankPoints, setRankPoints] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [publishedIdeas, setPublishedIdeas] = useState([])
  const [publishedInquiries, setPublishedInquiries] = useState([])
  const [publishedGlobalInquiries, setPublishedGlobalInquiries] = useState([])
  const [userEstablishedProjects, setEstablishedProjects] = useState()
  const [userResources, setUserResources] = useState([])
  const [userIssues, setUserIssues] = useState([])
  useEffect(() => {
    const fetchPortfolio = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(`/portfolios/userPortfolio/${user?._id}`)
        const resRank = await axios.get(`/portfolios/rank/user/${user?._id}`)
        const resIdeas = await axios.get(
          `/ideas/userIdeas/${user?._id}/?published=true`
        )
        const resInquirirs = await axios.get(
          `/inquiries/userInquiries/${user?._id}/?published=true`
        )
        const resGlobInqs = await axios.get(
          `/globalInquiries/userGlobalInquiries/${user?._id}/?published=true`
        )
        const resResources = await axios.get(
          `/resources/userResources/${user._id}/?published=true`
        )
        const resIssues = await axios.get(
          `/issues/userIssues/${user._id}/?allIssues={true}`
        )
        const resEst = await axios.get(`/projects/userProjects/${user?._id}`)
        setEstablishedProjects(resEst.data.establishedProjects)
        setPublishedIdeas(resIdeas.data.ideas)
        setPublishedInquiries(resInquirirs.data.inquiries)
        setPublishedGlobalInquiries(resGlobInqs.data.globalInquiries)
        setUserResources(resResources.data.resources)
        setUserIssues(resIssues.data.issues)
        setPortfolio(res.data.portfolio)
        setRankPoints(resRank.data.rankPoints)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    if (user?._id) fetchPortfolio()
  }, [user?._id])
  const propsII = {
    ...props,
    portfolio,
    rankPoints,
    publishedIdeas,
    publishedInquiries,
    publishedGlobalInquiries,
    userEstablishedProjects,
    userResources,
    userIssues,
  }
  return isLoading ? (
    <BlocksLoader isDarkModeActive={isDarkModeActive} />
  ) : (
    <div style={{ color: isDarkModeActive && 'white' }}>
      <NetworkRank {...propsII} />
      <NetworkBasicInfo {...propsII} />
      <UserAboutPersonal {...propsII} />
      <UserPortfolioNavigators {...propsII} />
      <UserPortfolioChannels {...propsII} />
    </div>
  )
}

export default UserPortfolioContent
