import { useTranslation } from 'react-i18next'

const FlatRateBusinessModel = () => {
  const { t } = useTranslation('FlatRateBusinessModel')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 19,
    title: t('title_bm19'),
    subTitle: t('subTitle_bm19'),
    main: t('main_bm19'),
    when: t('when_bm19'),
    who: t('who_bm19'),
    what: t('what_bm19'),
    how: t('how_bm19'),
    why: t('why_bm19'),
    examples: rtl
      ? [
          'السكك الحديدية الفدرالية السويسرية - 1902 - سويسرا',
          'نتفليكس - 1997 - الولايات المتحدة الأمريكية',
          'الرياضة المصور - 1936 - الولايات المتحدة الأمريكية',
          'الوقت - 1923 - الولايات المتحدة الأمريكية',
          'وايرد - 1993 - الولايات المتحدة الأمريكية',
          'سبوتيفاي - 2006 - السويد',
          'أنغامي - 2012 - لبنان',
        ]
      : [
          'Swiss Federal Railways - 1902 - Switzerland',
          'Netflix - 1997 - USA',
          'Sports Illustrated - 1936 - USA',
          'Time - 1923 - USA',
          'Wired - 1993 - USA',
          'Spotify - 2006 - Sweden',
          'Anghami - 2012 - Lebanon',
        ],
    tags: rtl
      ? [
          'الاستخدام المجاني',
          'غير محدود',
          'العرض اللامتناهي',
          'المزيد من المنتجات',
          'تكلفة منخفضة',
          'بوفيه مفتوح',
          'تدفق غير محدود',
        ]
      : [
          'Free Use',
          'Unlimited',
          'Infinite Supply',
          'More Products',
          'Low Cost',
          'Open Buffet',
          'Unlimited Streaming',
        ],
    considerations: t('considerations_bm19'),
    combinations: ['$LBM54', '$LBM40', '$LBM67', '$LBM22'],
    hashTagType: 'businessModelTemp',
  }
}

export default FlatRateBusinessModel
