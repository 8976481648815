import React from 'react'

const CustomerSegments = () => {
  const rtl = document.body.dir === 'rtl'
  return (
    <>
      {rtl
        ? 'شرائح العملاء هي مجموعات من العملاء أو الشركات التي لها نفس الخصائص أو السلوكيات أو التفضيلات التي تهدف الشركة إلى الوصول إليها أو تقديم خدماتها بمنتجاتها أو خدماتها. تعد شرائح العملاء إحدى اللبنات الأساسية في لوحة نموذج الأعمال، حيث تساعد في تحديد العملاء الأكثر ربحية واستهدافهم'
        : 'Customer segments are groups of customers or businesses that have similar characteristics, behaviors or preferences that a company aims to reach or serve with its products or services. Customer segments are one of the key building blocks in the business model canvas, as they help to identify and target the most profitable customers'}
    </>
  )
}

export default CustomerSegments
