// more properties will be added to this extractor to complete the full properties in the website

import {
  NewInquiryFormsConfigs,
  NewIdeaFormsConfigs,
  newProductFormConfigs,
  newTaskFormConfigs,
  NewJobFormConfigs,
} from '../feedsSystem'
import newSharedResourceConfig from '../leftbarDirectoryComponents/feedsModels/PostBodyModelComponents/SharedResourcePostModelComps/SharedResourceConfigs'
import IssueConfigs from '../projectProfile/ProjectProfileComponents/ProjectControlPanelComponents/ProjectDepartmentsComponents/IssuesComponents/IssueProfileComponents/IssueConfigs'
import NewResearchConceptConfigs from '../projectProfile/ProjectProfileComponents/ProjectControlPanelComponents/ProjectDepartmentsComponents/ResearchDevelopmentDepartmentComps/ResearchDevelopmentResultsComps/ResearchActivityProfileComps/ResearchMainConceptsComps/NewResearchConceptConfigs'
import BusinessModelTemplateConfigs from './SearchIdtentifiersExtractorComps/BusinessModelTemplateConfigs'

export default function SearchIdtentifiersExtractor(keys) {
  const basicConfig = [
    {
      title: 'title',
    },
    {
      title: 'name',
    },
  ]
  return basicConfig
    .concat(
      keys?.idea ? NewIdeaFormsConfigs() : [],
      keys?.product ? newProductFormConfigs() : [],
      keys?.task ? newTaskFormConfigs(0, 0) : [],
      keys?.job ? NewJobFormConfigs() : [],
      keys?.inquiry ? NewInquiryFormsConfigs() : [],
      keys?.resource ? newSharedResourceConfig() : [],
      keys?.issue ? IssueConfigs : [],
      keys?.campaign ? ['will contain camoaign configs'] : [],
      keys?.modelTemp
        ? BusinessModelTemplateConfigs
        : keys?.researchConcept
        ? NewResearchConceptConfigs()
        : []
    )
    .map((config) => config.title?.split(' ').join(''))
}
