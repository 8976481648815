import { useTranslation } from 'react-i18next'

const CashMachineBusinessModel = () => {
  const { t } = useTranslation('CashMachineBusinessModel')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 8,
    title: t('title_bm08'),
    subTitle: t('subTitle_bm08'),
    main: t('main_bm08'),
    when: t('when_bm08'),
    who: t('who_bm08'),
    what: t('what_bm08'),
    how: t('how_bm08'),
    why: t('why_bm08'),
    examples: rtl
      ? [
          'أمريكان إكسبريس - تأسست عام 1891 - الولايات المتحدة الأمريكية',
          'أمازون - تأسست 1994 - الولايات المتحدة الأمريكية',
          'PayPal - تأسست عام 1998 - الولايات المتحدة الأمريكية',
        ]
      : [
          'American Express - Founded 1891 - USA',
          'Amazon - Founded 1994 - USA',
          'PayPal - Founded 1998 - USA',
        ],
    tags: rtl
      ? [
          'سيولة أكثر',
          'نمو سريع',
          'الموردين الصبورين',
          'شروط جيدة',
          'المستودعات',
        ]
      : [
          'More Liquidity',
          'Grow Fast',
          'Patient Suppliers',
          'Good Terms',
          'Inventories',
        ],
    considerations: t('considerations_bm08'),
    combinations: ['$LBM54', '$LBM2', '$LBM62'],
    hashTagType: 'businessModelTemp',
  }
}

export default CashMachineBusinessModel
