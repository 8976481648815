import React, { useContext } from 'react'
import withStyles from '@mui/styles/withStyles'
import { Card } from '@mui/material'
import NotificationCreator from '../../notificationsSystem/NotificationCreator'
import GeneralConfirmationMssg from '../../confirmationSystem/GeneralConfirmationMssg'
import NotificationsSentConfirmDialog from '../../confirmationSystem/NotificationsSentConfirmDialog'
import StateGenerator from '../../SmallComponents/StateGenerator'
import axios from 'axios'
import ButtonsSystem from '../../buttonsSystem/ButtonsSystem'
import { AuthContext } from '../../../context/AuthContext'
import { toast } from 'react-toastify'
import ToastifyGeneralError from '../../SmallComponents/ToastifyGeneralError'

const styles = () => ({
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    position: 'absolute',
    bottom: 0,
    width: '100% !important',
    left: 0,
  },
})

const AddContractElementPermission = ({
  classes,
  showElementAdd,
  contract,
  windowCloser,
  channel,
  element,
  DCGProps,
  members,
}) => {
  const { socket, project } = DCGProps
  const { user } = useContext(AuthContext)
  const rtl = document.body.dir === 'rtl'
  const {
    isGeneralDialogOpen,
    GeneralDialogOpener,
    GeneralDialogClose,
    isConfirmDialogOpen,
    setIsConfirmDialogOpen,
    clickedButtonId,
    confirmDialogOpener,
  } = StateGenerator({
    GeneralProps: true,
    confirmDialogProps: true,
    timeOutFun: windowCloser,
  })
  const confirmmationTitles = [
    {
      title: rtl
        ? 'تم إرسال التعديلات للمتعاقدين للموافقة عليها'
        : 'Modification Sent to contractors for approval',
      tip: rtl
        ? 'سوف تتلقى اشعاراُ بالرد فى إشعارات العقود الخاص بك'
        : 'You will recieve a notification with response in contracts Notifications rolls',
    },
  ]

  const buttonsArray = [
    {
      title: rtl ? 'إلغاء' : 'Cancel',
      funs: windowCloser,
    },
    {
      title: rtl ? 'أضف' : 'Add',
      funs: GeneralDialogOpener,
    },
  ]
  return (
    <Card
      className={classes.buttonsWrapper}
      style={{ display: showElementAdd ? null : 'none' }}
    >
      <div style={{ width: '100%' }}>
        <ButtonsSystem buttonsArray={buttonsArray} />
      </div>
      <GeneralConfirmationMssg
        {...DCGProps}
        confirmMessg={
          rtl
            ? 'هل أنت متأكد من إرسال هذا التعديل الى المتعاقدين ليتم قبولة بشكل نهائى؟'
            : 'Are your sure you want to send your contractor to agree on this modification to permanently change it?'
        }
        confirmTitle={
          rtl
            ? 'لابد ان يتفق جميع المتعاقدين على أى تعديل'
            : 'Modiifcation should be agreed by contractors'
        }
        generalConfirmDialogClose={GeneralDialogClose}
        generalConfirmAction={async () => {
          confirmDialogOpener()
          toast.success(
            rtl
              ? 'تمت اضافة العنصر بنجاح'
              : 'Element has been added successfully'
          )
          try {
            if (!channel?.elements?.find((el) => el?.id === element?._id)) {
              // add to the channel and then i willa dd to the contract
              await axios.patch(`/channels/${channel?._id}`, {
                elements: [
                  ...channel?.elements,
                  {
                    id: element?._id,
                    title:
                      element?.Title ||
                      element?.title ||
                      element?.ProductName ||
                      element?.name,
                    image:
                      element?.image || (element?.images && element?.images[0]),
                    hashTagType: element?.hashTagType,
                    userId: element?.userId,
                    projectId: element?.projectId,
                  },
                ],
              })
              await axios.post(`/contractsElements`, {
                contractId: contract?._id,
                channelId: channel?._id,
                elementId: element?._id,
                Title:
                  element?.Title ||
                  element?.title ||
                  element?.ProductName ||
                  element?.name,
                image:
                  element?.image || (element?.images && element?.images[0]),
                hashTagType: element?.hashTagType,
              })
            }
          } catch (error) {
            ToastifyGeneralError(error)
          }
          // notifying the members that a user had added a new element
          members?.map((m) => {
            socket.current.emit('addNotifUser', m)
            NotificationCreator({
              associativity: 'activeContract',
              relation: 'contributor',
              conditionIndex: 2,
              accompaniedData: {
                projectId: project?._id,
                elements: [
                  {
                    id: contract?._id,
                    hashTagType: contract?.hashTagType,
                    title: contract?.Title,
                  },
                  {
                    id: channel?._id,
                    hashTagType: channel?.hashTagType,
                    title: channel?.Title,
                  },
                ],
              },
              category: 'deals',
              userId: m,
              currentUser: user,
              socket,
            })
          })
        }}
        isGeneralConfirmDialogOpen={isGeneralDialogOpen}
      />
      <NotificationsSentConfirmDialog
        {...DCGProps}
        confirmmationTitles={confirmmationTitles}
        confirmationRTitleIndex={clickedButtonId}
        isConfirmDialogOpen={isConfirmDialogOpen}
        setIsConfirmDialogOpen={setIsConfirmDialogOpen}
      />
    </Card>
  )
}

const WrappedAddContractElementPermission = withStyles(styles)(
  AddContractElementPermission
)

export default WrappedAddContractElementPermission
