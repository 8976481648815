import { Input } from '@mui/material'
import React, { useState, useEffect } from 'react'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  input: {},
  textArea: {
    width: 300,
  },
  '@media (max-width: 600px)': {
    textArea: {
      width: 180,
    },
  },
})

const DateInputModel = ({
  classes,
  isDarkModeActive,
  optionsSetState,
  optionsState,
  element,
}) => {
  const { title, value } = element
  const stateName = title
  const defaultValue = value

  const [inputValue, setInputValue] = useState(defaultValue)

  const inputValueHandler = (event) => {
    setInputValue(event.currentTarget.value)
  }

  useEffect(() => {
    optionsSetState({
      ...optionsState,
      [stateName.split(' ').join('')]: inputValue,
    })
  }, [inputValue])

  return (
    <Input
      style={{ color: isDarkModeActive ? 'white' : 'black' }}
      value={inputValue}
      onChange={(event) => inputValueHandler(event)}
      className={classes.input}
      type='date'
      disabled={element?.disabled}
    />
  )
}

const WrappedDateInputModel = withStyles(styles)(DateInputModel)

export default WrappedDateInputModel
