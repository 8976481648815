import { useTranslation } from 'react-i18next'

const LicensingBusinessModel = () => {
  const { t } = useTranslation('LicensingBusinessModel')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 30,
    title: t('title_bm30'),
    subTitle: t('subTitle_bm30'),
    main: t('main_bm30'),
    when: t('when_bm30'),
    who: t('who_bm30'),
    what: t('what_bm30'),
    how: t('how_bm30'),
    why: t('why_bm30'),
    examples: rtl
      ? [
          'والت ديزني - تأسست عام 1923 - الولايات المتحدة الأمريكية',
          'Anheuser-Busch - تأسست عام 1852 - الولايات المتحدة الأمريكية',
          'IBM - تأسست عام 1911 - الولايات المتحدة الأمريكية',
          'ARM - تأسست عام 1990 - إنجلترا',
          'رؤية كارل زايس - تأسست عام 1846 - ألمانيا',
          'BASF - تأسست عام 1865 - ألمانيا',
          'DIC2 - تأسست عام 1973 - إيطاليا',
        ]
      : [
          'Walt Disney - Founded 1923 - USA',
          'Anheuser-Busch - Founded 1852 - USA',
          'IBM - Founded 1911 - USA',
          'ARM - Founded 1990 - England',
          'Carl Zeiss Vision - Founded 1846 - Germany',
          'BASF - Founded 1865 - Germany',
          'DIC2 - Founded 1973 - Italy',
        ],
    tags: rtl
      ? [
          'التمايز التجاري',
          'الفوز',
          'شركة إلى شركة',
          'تطوير سهل',
          'الريادة في السوق',
          'ولاء العملاء',
          'مخاطر منخفضة',
          'استثمار الأفكار',
          'استقرار',
        ]
      : [
          'Business Differentiation',
          'Win-win',
          'Business-to-Business',
          'Easy Development',
          'Market Leadership',
          'Customer Loyalty',
          'Low Risk',
          'Monetize Ideas',
          'Stability',
        ],
    considerations: t('considerations_bm30'),
    combinations: ['$LBM28', '$LBM40', '$LBM69'],
    hashTagType: 'businessModelTemp',
  }
}

export default LicensingBusinessModel
