import withStyles from '@mui/styles/withStyles/withStyles'
import React from 'react'
import WindowProps from '../windowViewer/WindowProps'
import ModelDialogWrappingIII from '../dialogModelUnit/ModelDialogWrappingIII'
import PollAdditionDialogContent from './PollAdditionDialogContent'
import { MdHowToVote } from 'react-icons/md'
import { Button } from '@mui/material'
import NewFormDialogModel from '../formsSystem/NewFormDialogModel'

const styles = () => ({
  button: {
    borderRadius: '10px !important',
    margin: '2px !important',
    textTransform: 'none !important',
    fontWeight: '550 !important',
    width: '100% !important',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important",
  },
})

const PollAddButton = ({
  isDarkModeActive,
  setPollData,
  pollData,
  classes,
  chosenHashTagElements,
  setChosenHashTagElements,
  disabled,
}) => {
  const { isWindowOpen, windowOpener, windowCloser } = WindowProps()
  const rtl = document.body.dir === 'rtl'
  return (
    <>
      <Button
        disabled={disabled}
        onClick={windowOpener}
        variant='outlined'
        className={classes.button}
      >
        <MdHowToVote size={20} />
      </Button>
      <NewFormDialogModel
        NewFormComponent={
          <PollAdditionDialogContent
            setPollData={setPollData}
            windowCloser={windowCloser}
            isDarkModeActive={isDarkModeActive}
            pollData={pollData}
            chosenHashTagElements={chosenHashTagElements}
            setChosenHashTagElements={setChosenHashTagElements}
          />
        }
        dialogTitle={rtl ? 'تصويت جديد' : 'New Poll'}
        newFormDialogClose={windowCloser}
        isNewFormDialogOpen={isWindowOpen}
        isDarkModeActive={isDarkModeActive}
      />
    </>
  )
}
const WrappedPollAddButton = withStyles(styles)(PollAddButton)

export default WrappedPollAddButton
