import { useTranslation } from 'react-i18next'

const ExperienceSellingBusinessModel = () => {
  const { t } = useTranslation('ExperienceSellingBusinessModel')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 18,
    title: t('title_bm18'),
    subTitle: t('subTitle_bm18'),
    main: t('main_bm18'),
    when: t('when_bm18'),
    who: t('who_bm18'),
    what: t('what_bm18'),
    how: t('how_bm18'),
    why: t('why_bm18'),
    examples: rtl
      ? [
          'Harley-Davidson - تأسست عام 1903 - الولايات المتحدة الأمريكية',
          'ترميم الأجهزة - تأسست عام 1980 - الولايات المتحدة الأمريكية',
          'ستاربكس - الولايات المتحدة الأمريكية',
          'بارنز أند نوبل - الولايات المتحدة الأمريكية - مكتبة',
          'Trader Joe’s - USA',
          'ريد بُل - تأسست 1987 - النمسا',
        ]
      : [
          'Harley-Davidson - Founded 1903 - USA',
          'Restoration Hardware - Founded 1980 - USA',
          'Starbucks - USA',
          'Barnes & Noble - USA - Bookstore',
          'Trader Joe’s - USA',
          'Red Bull - Founded 1987 - Austria',
        ],
    tags: rtl
      ? [
          'اتصال عاطفي',
          'هوية العلامة التجارية',
          'وضع العلامة التجارية',
          'العلامة التجارية العاطفية',
          'تجارب العملاء',
        ]
      : [
          'Emotional Connection',
          'Brand Identity',
          'Brand Positioning',
          'Emotional Branding',
          'Customer Experiences',
        ],
    considerations: t('considerations_bm18'),
    combinations: ['$LBM17', '$LBM58'],
    hashTagType: 'businessModelTemp',
  }
}

export default ExperienceSellingBusinessModel
