import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { Card } from '@mui/material'

const styles = () => ({
  elementsWrapper: {
    height: 'auto',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexFlow: 'row wrap',
    marginBottom: 10,
  },
  viewCard: {
    display: 'flex',
    height: 'auto',
    flexDirection: 'column',
    width: 'auto',
    padding: '10px',
    margin: '10px',
    borderRadius: '10px !important',
    backgroundColor: 'rgba(217,224,252, 0.2) !important',
  },
})

const ChosenElementsViewed = ({
  classes,
  selectedElementsArray,
  ChosenElementModel,
  ChosenElementModelProps,
  isDarkModeActive,
}) => {
  return (
    <Card className={classes.viewCard}>
      <section className={classes.elementsWrapper}>
        {selectedElementsArray.map((element, elementIndex) => (
          <ChosenElementModel
            element={element}
            key={elementIndex}
             isDarkModeActive={ isDarkModeActive}
            {...ChosenElementModelProps}
          />
        ))}
      </section>
    </Card>
  )
}

const WrappedChosenElementsViewed = withStyles(styles)(ChosenElementsViewed)

export default WrappedChosenElementsViewed
