import React from 'react'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
  },
  wrapper: {
    width: 'auto',
    fontSize: 26,
    fontWeight: 600,
  },
  subHeader: {
    fontWeight: 400,
    fontSize: 14,
    fontStyle: 'oblique',
  },
  '@media (max-width: 600px)': {
    wrapper: {
      fontSize: 20,
      fontWeight: 500,
    },
  },
})

const TitleHeader = ({ classes, headerText, subHeaderText, hideHeader }) => {
  return (
    <>
      <article
        className={classes.container}
        style={{ display: hideHeader ? 'none' : null }}
      >
        <section className={classes.wrapper}>{headerText}</section>
        <section className={classes.subHeader}>{subHeaderText}</section>
      </article>
    </>
  )
}

const WrappedTitleHeader = withStyles(styles)(TitleHeader)

export default WrappedTitleHeader
