import axios from 'axios'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '../../../../../../../context/AuthContext'
import EditableGridModel from '../../../../../../formsSystem/EditableGridModel'
import FormDataLangModulator from '../../../../../../formsSystem/FormDataLangModulator'
import InfoDataIntialStateGen from '../../../../../../formsSystem/InfoDataIntialStateGen'
import ProjectDepartments from '../../../../../../newProject/NewProjectComponents/NewBusinessProjectComponents/NewProjectMembersComponents/ProjectDepartmentsArrays'
import HashTagElementsRefiner from '../../../../../../SmallComponents/HashTagElementsRefiner'

const JobDetails = ({ job, setCards, DCGProps }) => {
  const { project } = DCGProps
  const { user } = useContext(AuthContext)
  const { t } = useTranslation()
  const authorised =
    user?._id === project?.userId ||
    user?.createdProjects?.includes(job?.projectId)
  const infoArray = [
    {
      title: 'Title',
      titleValue: job?.Title,
      allowEmpty: authorised,
    },
    {
      title: 'Department',
      titleValue: ProjectDepartments()[job?.departmentId]?.name,
      unEditable: true,
    },
    {
      title: 'Description',
      titleValue: job?.Description,
      allowEmpty: authorised,
      wideMode: true,
      minRows: 4,
    },
    {
      title: 'Skills',
      titleValue: job?.Skills,
      allowEmpty: authorised,
      wideMode: true,
      minRows: 4,
    },
  ]
  const [infoData, setInfoData] = useState(InfoDataIntialStateGen(infoArray))

  const [chosenHashTagElements, setChosenHashTagElements] = useState(
    HashTagElementsRefiner(job) || []
  )
  const newInfoData = FormDataLangModulator(infoData, t)
  const onSave = async () => {
    try {
      const res = await axios.patch(`/jobs/${job?._id}`, {
        ...newInfoData,
        chosenHashTagElements,
      })
      setCards((jobs) => [
        ...jobs.filter((p) => p._id !== job?._id),
        res.data.job,
      ])
    } catch (error) {
      console.log(error)
    }
  }

  const rtl = document.body.dir === 'rtl'
  const gridProps = {
    hideEditingTools: !authorised,
    headerText: rtl ? 'تفاصيل الوظيفة' : 'Job Details',
    infoArray,
    setInfoData,
    infoData,
    onSave: onSave,
    chosenHashTagElements,
    setChosenHashTagElements,
    ...DCGProps,
    departmentId: 2,
  }
  return <EditableGridModel gridProps={gridProps} />
}

export default JobDetails
