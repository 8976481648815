import React from 'react'
import ImagesSystem from '../../../../postSystem/ImagesSystem'
import CommentsDialog from '../../../../commentsSystems/CommentsDialog'
import LinksPlayerSystem from '../../../../postSystem/LinksPlayerSystem'
import GeneralPollBody from '../../../../generalPollSystem/GeneralPollBody'

const PostCenter = ({ postProps }) => {
  const { post, commentsDialogOpener, imageViewerDialogOpener } = postProps

  return (
    <>
      <ImagesSystem
        imagesArray={post?.images}
        openFun={() => {
          if (typeof commentsDialogOpener === 'function') commentsDialogOpener()
          if (typeof imageViewerDialogOpener === 'function')
            imageViewerDialogOpener()
        }}
        {...postProps}
      />
      <CommentsDialog {...postProps} />
      <ImagesSystem
        imageViewer={true}
        imagesArray={post?.images}
        {...postProps}
      />
      <LinksPlayerSystem url={post?.playerLink} />
      <GeneralPollBody {...postProps} pollData={post?.pollData} />
    </>
  )
}

export default PostCenter
