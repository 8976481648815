import { useTranslation } from 'react-i18next'

const Branding = () => {
  const { t } = useTranslation('Branding')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 36,
    category: t('category_ms36'),
    title: t('title_ms36'),
    subTitle: t('subTitle_ms36'),
    main: t('main_ms36'),
    how: t('how_ms36'),
    usedInModels: [],
    tags: rtl
      ? [
          'اتصال عاطفي',
          'العملاء الزائدين',
          'كلمة إيجابية',
          'احتياجات العميل',
          'علامة تجارية جذابة',
          'تاكيد الجودة',
          'تجربة الزبون',
          'المكانة الأخلاقية',
        ]
      : [
          'Emotional Connection',
          'Overcharging Customers',
          'Word-of-Mouth',
          'Customer Needs',
          'Attractive Brand',
          'Quality Assurance',
          'Customer Experience',
          'Moral Standing',
        ],
    examples: t('examples_ms36'),
    hashTagType: 'markStraAspect',
  }
}

export default Branding
