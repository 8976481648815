import withStyles from '@mui/styles/withStyles'
import React, { useEffect, useRef, useState } from 'react'
import { Dialog, DialogTitle, DialogContent } from '@mui/material'
import { MdArrowLeft, MdArrowRight, MdDarkMode } from 'react-icons/md'
import DynamicTititle from './ModelDialogWrappingIIMobComponents/DynamicTititle'
import { HiColorSwatch } from 'react-icons/hi'
import { useLocation } from 'react-router-dom'
import UsePathnameEffect from '../../SmallComponents/UsePathnameEffect'

const styles = () => ({
  mdialogClosingIcon: {
    display: 'flex',
    cursor: 'pointer',
    fontSize: 28,
    transition: '0.2s',
    '&:hover': {
      fontSize: '30px !important',
    },
  },

  dialogDarkMode: {
    display: 'flex',
    cursor: 'pointer',
    fontSize: 28,
    transition: '0.2s',
    '&:hover': {
      fontSize: '30px !important',
    },
  },

  mtitleWrapper: {
    display: 'flex',
    alignItems: 'center !important',
    alignContent: 'center !important',
    justifyContent: 'space-between !important',
  },
  icon: {
    display: 'flex',
    alignContent: 'center  ',
    alignItems: 'center  ',
    justifyContent: 'center',
    position: 'sticky',
  },
  title: {
    height: 20,
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuIconWrapper: {
    display: 'flex',
    alignItems: 'center !important',
    alignContent: 'center !important',
    fontSize: 28,
  },
})

const Activation = withStyles(styles)(({ classes, ...props }) => {
  const {
    hideCloseIcon,
    mdialogMargins,
    comentingSystemOpen,
    isDarkModeActive,
    setIsDarkModeActive,
    element,
  } = props
  UsePathnameEffect(element.closeFun)
  const rtl = document.body.dir === 'rtl'
  const [colorMode, setColorMode] = useState(false)
  useEffect(() => {
    if (!isDarkModeActive) setColorMode(false)
  }, [isDarkModeActive])
  const PF = process.env.REACT_APP_PUBLIC_FOLDER
  const backOptions = isDarkModeActive
    ? colorMode
      ? {
          backgroundImage: `url(${PF}various/dialogBack.jpg)`,
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
          backdropFilter: 'brightness(50%)',
        }
      : {
          background: 'rgb(49, 48, 53)',
        }
    : {
        background: 'white',
      }
  return (
    <Dialog
      fullScreen={true}
      open={Boolean(element.open)}
      disableEnforceFocus
      PaperProps={{
        style: {
          transition: '0.15s',
          overflowX: mdialogMargins ? 'hidden' : 'inherit',
          ...backOptions,
        },
      }}
    >
      <DialogTitle
        className={classes.mtitleWrapper}
        sx={{
          height: comentingSystemOpen ? 20 : null,
          color: isDarkModeActive ? 'white' : 'inherit',
        }}
      >
        <span className={classes.icon}>{element.icon}</span>
        <DynamicTititle element={element} />
        <section className={classes.menuIconWrapper}>
          <HiColorSwatch
            onClick={() => {
              if (typeof setIsDarkModeActive === 'function')
                setIsDarkModeActive(!isDarkModeActive)
              setColorMode(!colorMode)
            }}
            style={{
              color: isDarkModeActive ? 'white' : 'blue',
              marginLeft: rtl && 15,
              marginRight: !rtl && 15,
            }}
          />
          <MdDarkMode
            onClick={() => {
              if (typeof setIsDarkModeActive === 'function')
                setIsDarkModeActive(!isDarkModeActive)
            }}
            className={classes.dialogDarkMode}
            style={{
              color: isDarkModeActive ? 'white' : 'rgb(79, 95, 114)',
              marginLeft: rtl && 10,
              marginRight: !rtl && 10,
            }}
          />
          {rtl ? (
            <MdArrowLeft
              onClick={
                typeof element.closeFun === 'function' ? element.closeFun : null
              }
              className={classes.mdialogClosingIcon}
              style={{ display: hideCloseIcon ? 'none' : null }}
            />
          ) : (
            <MdArrowRight
              onClick={
                typeof element.closeFun === 'function' ? element.closeFun : null
              }
              className={classes.mdialogClosingIcon}
              style={{ display: hideCloseIcon ? 'none' : null }}
            />
          )}
        </section>
      </DialogTitle>
      <DialogContent style={{ margin: mdialogMargins }}>
        {element.content}
      </DialogContent>
    </Dialog>
  )
})

const ModelDialogWrappingIIMob = (props) => {
  const { dialogsArray } = props
  return dialogsArray.map((element, index) => {
    return (
      element.open && <Activation {...props} key={index} element={element} />
    )
  })
}

export default ModelDialogWrappingIIMob
