import axios from 'axios'
import React, { useEffect, useState } from 'react'
import NotificationCreator from '../../../notificationsSystem/NotificationCreator'
import { AiOutlineLike, AiFillLike } from 'react-icons/ai'

const UserPostLikeIcon = ({
  likeNumberHandler,
  post,
  socket,
  styles,
  likes,
  isLiked,
  user,
}) => {
  const [isClicked, setIsClicked] = useState(false)
  const notMod = async () => {
    try {
      // the idea here is by not specifying any sender id, will delete the oldest version of the notification and leave the current version as it is.
      await axios.delete('/notifications/customDelete/d', {
        headers: {},
        data: {
          userId: post?.userId,
          associativity: 'post',
          relation: 'creator',
          category: 'general',
          conditionIndex: 1,
          accompaniedData: {
            postId: post._id,
          },
          notThisUser: true,
        },
      })

      setIsClicked(false)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if (
      likes > 1 &&
      isLiked &&
      post._id &&
      post?.userId &&
      isClicked &&
      post?.userId !== user?._id
    )
      notMod()
  }, [isLiked, post?.userId, post._id])
  return [
    <AiOutlineLike
      style={styles?.bottomIcon}
      size={25}
      onClick={() => {
        if (post?.userId !== user?._id) notMod()
        likeNumberHandler()
        NotificationCreator({
          associativity: 'post',
          relation: 'creator',
          conditionIndex: 1,
          accompaniedData: { postId: post._id },
          category: 'general',
          userId: post?.userId,
          currentUser: user,
          socket,
        })
      }}
    />,
    <AiFillLike
      style={styles?.bottomIcon}
      size={25}
      onClick={async (e) => {
        likeNumberHandler()
        setIsClicked(true)
        if (
          likes === 1 &&
          user._id &&
          post._id &&
          post?.userId &&
          post?.userId !== user?._id
        )
          notMod()
      }}
    />,
  ]
}

export default UserPostLikeIcon
