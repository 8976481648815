import React, { useState, useEffect } from 'react'
import NoResultsModel from '../NoResultsModel'

const SearchAlphebetic = ({
  searchResults,
  SearchedElementComponent,
  freeSearch,
  SearchedElementComponentProps,
  searchQuery,
  searchElements,
  alphabeticOrderDir,
  searchIdtentifiers,
  // for file system
  extraCondition,
  elementKey,
}) => {
  //1- finding the identifer that has results
  const goodIndentifier = searchIdtentifiers.filter(
    (identifier) =>
      searchElements.filter(
        (element) =>
          (typeof element[identifier] === 'string' &&
            element[identifier]
              ?.toUpperCase()
              ?.includes(searchQuery.toUpperCase())) ||
          (typeof element[identifier] === 'number' &&
            element[identifier]?.toString().includes(searchQuery))
      ).length > 0
  )[0]

  const responsesComponents = [
    <>
      {(
        extraCondition
          ? extraCondition && searchQuery.length > 0
          : searchQuery.length > 0
      ) ? (
        searchResults.length > 0 ? (
          searchResults
            ?.sort((a, b) =>
              (
                alphabeticOrderDir
                  ? a[goodIndentifier]?.toString()?.toUpperCase() >
                    b[goodIndentifier]?.toString()?.toUpperCase()
                  : a[goodIndentifier]?.toString()?.toUpperCase() <
                    b[goodIndentifier]?.toString()?.toUpperCase()
              )
                ? 1
                : (
                    alphabeticOrderDir
                      ? b[goodIndentifier]?.toString()?.toUpperCase() >
                        a[goodIndentifier]?.toString()?.toUpperCase()
                      : b[goodIndentifier]?.toString()?.toUpperCase() <
                        a[goodIndentifier]?.toString()?.toUpperCase()
                  )
                ? -1
                : 0
            )
            .map((element, elementIndex) => (
              <SearchedElementComponent
                key={elementKey ? element[elementKey] : elementIndex}
                elementIndex={elementIndex}
                element={element}
                props={SearchedElementComponentProps}
              />
            ))
        ) : (
          <NoResultsModel searchQuery={searchQuery} />
        )
      ) : (
        searchElements
          ?.sort((a, b) =>
            (
              alphabeticOrderDir
                ? a[goodIndentifier]?.toString()?.toUpperCase() >
                  b[goodIndentifier]?.toString()?.toUpperCase()
                : a[goodIndentifier]?.toString()?.toUpperCase() <
                  b[goodIndentifier]?.toString()?.toUpperCase()
            )
              ? 1
              : (
                  alphabeticOrderDir
                    ? b[goodIndentifier]?.toString()?.toUpperCase() >
                      a[goodIndentifier]?.toString()?.toUpperCase()
                    : b[goodIndentifier]?.toString()?.toUpperCase() <
                      a[goodIndentifier]?.toString()?.toUpperCase()
                )
              ? -1
              : 0
          )
          .map((element, elementIndex) => (
            <SearchedElementComponent
              key={elementKey ? element[elementKey] : elementIndex}
              element={element}
              elementIndex={elementIndex}
              props={SearchedElementComponentProps}
            />
          ))
      )}
    </>,
    // free search when i need just results to appear when they exist withiut any other componentd, and this is suitable for example for the Hashtag suggetsing system
    searchResults.map((element, elementIndex) => (
      <SearchedElementComponent
        key={elementKey ? element[elementKey] : elementIndex}
        elementIndex={elementIndex}
        element={element}
        props={SearchedElementComponentProps}
      />
    )),
  ]

  return freeSearch
    ? extraCondition
      ? extraCondition && searchQuery.length > 0
      : searchQuery.length > 0
      ? responsesComponents[1]
      : null
    : responsesComponents[0]
}

export default SearchAlphebetic
