import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { IconButton, Tooltip } from '@mui/material'
import FacebookIcon from '@mui/icons-material/Facebook'
import TwitterIcon from '@mui/icons-material/Twitter'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import TelegramIcon from '@material-ui/icons/Telegram'
import RedditIcon from '@material-ui/icons/Reddit'

const styles = () => ({
  socialButton: {
    marginLeft: 5,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  socialIcon: {
    fontSize: '2rem',
  },
  wrapper: { display: 'flex', justifyContent: 'center', flexDirection: 'row' },
})

const SocialMediaShare = ({
  classes,
  link,
  isDarkModeActive,
  title,
  element,
}) => {
  const rtl = document.body.dir === 'rtl'
  const PF = process.env.REACT_APP_PUBLIC_FOLDER

  const imageUrl =
    element?.image ||
    element?.profilePicture ||
    element?.coverPicture ||
    element?.projectPhoto ||
    element?.projectCover ||
    (element?.images && element?.images[0]) ||
    'https://firebasestorage.googleapis.com/v0/b/hutupia-300a3.appspot.com/o/HutupiaAssets%2FlogoBack144.png?alt=media&token=55cadb7f-7236-4ace-9e1b-7a94285352c5'
  const description =
    element?.Description ||
    element?.postTextContent ||
    element?.Content ||
    'Shared from Hutupia!'
  const url = encodeURIComponent(link)

  const handleFacebookShare = () => {
    const ftitle = encodeURIComponent(`Hutupia | ${title}`)
    const fdescription = encodeURIComponent(
      `${description} | Shared by Hutupia`
    )
    const image = encodeURIComponent(imageUrl)
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${url}&title=${ftitle}&description=${fdescription}&picture=${image}`,
      '_blank'
    )
  }

  const handleTwitterShare = () => {
    const text = `${title} ${link}`
    window.open(
      `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}`,
      '_blank'
    )
  }

  const handleWhatsAppShare = () => {
    window.open(
      `https://api.whatsapp.com/send?text=${encodeURIComponent(
        title + ' ' + link
      )}`,
      '_blank'
    )
  }

  const handleTelegramShare = () => {
    const message = encodeURIComponent(`Check out this on Hutupia`)
    window.open(`https://t.me/share/url?url=${url}&text=${message}`, '_blank')
  }

  const handleRedditShare = () => {
    const title = encodeURIComponent('Check out this link!')
    const urlEncoded = encodeURIComponent(url)
    window.open(
      `https://www.reddit.com/submit?title=${title}&url=${urlEncoded}`,
      '_blank'
    )
  }

  const socials = [
    {
      title: rtl ? 'مشاركة على الفيسبوك' : 'Share on Facebook',
      PureIcon: FacebookIcon,
      fun: handleFacebookShare,
    },
    {
      title: rtl ? 'شارك على تويتر' : 'Share on Twitter',
      PureIcon: TwitterIcon,
      fun: handleTwitterShare,
    },
    {
      title: rtl ? 'شارك على الواتس اب' : 'Share on WhatsApp',
      PureIcon: WhatsAppIcon,
      fun: handleWhatsAppShare,
    },
    {
      title: rtl ? 'انشر على تليجرام' : 'Share on Telegram',
      PureIcon: TelegramIcon,
      fun: handleTelegramShare,
    },
    {
      title: rtl ? 'انشر على ريديت' : 'Share on Reddit',
      PureIcon: RedditIcon,
      fun: handleRedditShare,
    },
  ]
  return (
    <section className={classes.wrapper}>
      {socials?.map((social, i) => (
        <Tooltip title={social.title} key={i}>
          <IconButton onClick={social.fun} className={classes.socialButton}>
            <social.PureIcon
              className={classes.socialIcon}
              style={{ color: isDarkModeActive && 'white' }}
            />
          </IconButton>
        </Tooltip>
      ))}
    </section>
  )
}

const WrappedSocialMediaShare = withStyles(styles)(SocialMediaShare)

export default WrappedSocialMediaShare
