import { useTranslation } from 'react-i18next'

const SelfService = () => {
  const { t } = useTranslation('SelfService')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 50,
    title: t('title_bm50'),
    subTitle: t('subTitle_bm50'),
    main: t('main_bm50'),
    when: t('when_bm50'),
    who: t('who_bm50'),
    what: t('what_bm50'),
    how: t('how_bm50'),
    why: t('why_bm50'),
    examples: rtl
      ? [
          'ايكيا - 1943 - السويد',
          'ماكدونالدز - 1940 - الولايات المتحدة',
          'BackWerk - ألمانيا - 2005',
          'Accorhotels - فرنسا - 1967',
        ]
      : [
          'IKEA - 1943 - Sweden',
          'McDonald’s - 1940 - United States',
          'BackWerk - Germany - 2005',
          'Accorhotels - France - 1967',
        ],
    tags: rtl
      ? [
          'أسعار منخفضة',
          'تكلفة منخفضة',
          'إضفاء الطابع الشخصي',
          'إدارة سهلة',
          'رضا العملاء',
        ]
      : [
          'Low Prices',
          'Low Cost',
          'Personalization',
          'Easy Management',
          'Customer Satisfaction',
        ],
    considerations: t('considerations_bm50'),
    combinations: ['$LBM6', '$LBM11', '$LBM31', '$LBM32', '$LBM34', '$LBM35'],
    hashTagType: 'businessModelTemp',
  }
}

export default SelfService
