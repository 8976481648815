import React from 'react'

const Customers = () => {
  const rtl = document.body.dir === 'rtl'
  return (
    <>
      {rtl
        ? 'العملاء هم أشخاص أو شركات تشتري سلعًا أو خدمات من شركة أخرى. العملاء مهمون لأنهم يحققون الإيرادات ويؤثرون على جودة وابتكار المنتجات والخدمات 2. يمكن أن يكون العملاء أيضًا مستهلكين أو مستخدمين نهائيين يستخدمون أو يستهلكون السلع أو الخدمات التي يشترونها'
        : 'Customers are people or businesses that buy goods or services from another business. Customers are important because they drive revenues and influence the quality and innovation of products and services2. Customers can also be consumers or end users who use or consume the goods or services they buy'}
    </>
  )
}

export default Customers
