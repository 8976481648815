import React from 'react'

const FunctioningSearchInput = ({ formsCompsProps }) => {
  const {
    setSearchQuery,
    setTextContent,
    searchInputClass,
    placeholder,
    textContent,
    cursorDirection,
    onKeyDown,
  } = formsCompsProps
  return (
    <>
      <input
        style={{
          direction: cursorDirection,
          fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
        }}
        placeholder={placeholder}
        className={searchInputClass}
        type={'text'}
        onKeyDown={onKeyDown}
        value={textContent}
        onChange={(event) => {
          setSearchQuery(event.target.value.trim())
          setTextContent(event.target.value)
        }}
      />
    </>
  )
}

export default FunctioningSearchInput
