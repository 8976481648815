import React, { useContext, useEffect, useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import { AiFillFolderOpen, AiFillFolder } from 'react-icons/ai'
import { HiColorSwatch } from 'react-icons/hi'
import { MdArrowLeft, MdArrowRight, MdDarkMode } from 'react-icons/md'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  ClickAwayListener,
} from '@mui/material'
import i18next from 'i18next'
import { SettingsContext } from '../../../context/SettingsContext/SettingsContext'
import UsePathnameEffect from '../../SmallComponents/UsePathnameEffect'

const styles = () => ({
  dialogClosingIcon: {
    display: 'flex',
    position: 'absolute',
    cursor: 'pointer',
    top: '18px',
    fontSize: 28,
    transition: '0.2s',
    '&:hover': {
      fontSize: '30px !important',
    },
  },

  dialogFullscreenIcon: {
    display: 'flex',
    position: 'absolute',
    cursor: 'pointer',
    top: '18px',
    fontSize: '28px',
    transition: '0.2s',
    '&:hover': {
      fontSize: '30px !important',
    },
    color: 'rgb(79, 95, 114)',
  },
  dialogDarkMode: {
    display: 'flex',
    position: 'absolute',
    cursor: 'pointer',
    top: '18px',
    fontSize: '28px',
    transition: '0.2s',
    '&:hover': {
      fontSize: '30px !important',
    },
  },
  dialogDarkModeII: {
    display: 'flex',
    position: 'absolute',
    cursor: 'pointer',
    top: '18px',
    fontSize: '28px',
    transition: '0.2s',
    '&:hover': {
      fontSize: '30px !important',
    },
  },

  titleWrapper: {
    display: 'flex',
    alignItems: 'center !important',
    alignContent: 'center !important',
  },

  icon: {
    display: 'flex',
    alignContent: 'center  ',
    alignItems: 'center  ',
    justifyContent: 'center',
  },
  title: {
    display: 'flex',
    alignContent: 'center  ',
    alignItems: 'center  ',
    justifyContent: 'center',
  },
})

const Activation = withStyles(styles)(({ classes, ...props }) => {
  const {
    dialogMargins,
    hideCloseIcon,
    inactivateClickAway,
    isDarkModeActive,
    setIsDarkModeActive,
    setIsDialogFullWidth,
    element,
  } = props
  // fullscreen mode func
  const [fullScreenMode, setFullScreenMode] = useState(false)
  const [colorMode, setColorMode] = useState(false)
  UsePathnameEffect(element.closeFun)
  useEffect(() => {
    if (!isDarkModeActive) setColorMode(false)
  }, [isDarkModeActive])
  const PF = process.env.REACT_APP_PUBLIC_FOLDER
  const lang = i18next.language
  const rtl = document.body.dir === 'rtl'
  const { settings } = useContext(SettingsContext)
  const backOptions = isDarkModeActive
    ? colorMode
      ? {
          backgroundImage: `url(${PF}various/dialogBack.jpg)`,
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
          backdropFilter: 'brightness(50%)',
        }
      : settings?.Mode === 'Dark'
      ? {
          background: 'rgb(15,14,36)',
          background:
            'linear-gradient(90deg, rgba(15,14,36,1) 12%, rgba(4,18,20,1) 63%, rgba(6,6,45,1) 97%)',
        }
      : { background: 'rgb(49, 48, 53)' }
    : {
        background: 'white',
      }
  return (
    <Dialog
      dir={lang === 'ar' ? 'rtl' : 'ltr'}
      maxWidth='md'
      fullWidth
      fullScreen={fullScreenMode}
      PaperProps={{
        style: {
          borderRadius: 10,
          transition: '0.15s',
          overflowX: dialogMargins ? 'hidden' : 'inherit',
          ...backOptions,
        },
      }}
      open={Boolean(element.open)}
    >
      <ClickAwayListener
        onClickAway={() => {
          if (typeof element.closeFun === 'function' && !inactivateClickAway)
            element.closeFun()
        }}
      >
        <span>
          <DialogTitle
            className={classes.titleWrapper}
            sx={{ color: isDarkModeActive ? 'white' : 'inherit' }}
          >
            {element?.icon}
            {typeof element.title === 'string' ? (
              <span
                style={{
                  fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
                }}
              >
                {element.title}
              </span>
            ) : (
              element.title
            )}
          </DialogTitle>
          <DialogContent
            style={{
              height: fullScreenMode ? '85vh' : `400px`,
              transition: '0.2s',
              margin: dialogMargins,
            }}
          >
            <HiColorSwatch
              onClick={() => {
                if (typeof setIsDarkModeActive === 'function')
                  setIsDarkModeActive(!isDarkModeActive)
                setColorMode(!colorMode)
              }}
              className={classes.dialogDarkModeII}
              style={{
                color: isDarkModeActive ? 'white' : 'blue',
                left: lang === 'ar' && '115px',
                right: lang === 'en' && '115px',
              }}
            />
            <MdDarkMode
              onClick={() => {
                if (typeof setIsDarkModeActive === 'function')
                  setIsDarkModeActive(!isDarkModeActive)
              }}
              className={classes.dialogDarkMode}
              style={{
                color: isDarkModeActive ? 'white' : 'rgb(79, 95, 114)',
                left: lang === 'ar' && '85px',
                right: lang === 'en' && '85px',
              }}
            />
            <AiFillFolderOpen
              onClick={() => {
                setFullScreenMode(!fullScreenMode)
                if (typeof setIsDialogFullWidth === 'function')
                  setIsDialogFullWidth(true)
              }}
              className={classes.dialogFullscreenIcon}
              style={lang === 'ar' ? { left: '50px' } : { right: '50px' }}
            />
            <AiFillFolder
              style={{
                display: fullScreenMode ? null : 'none',
                left: (lang === 'ar' || rtl) && '50px',
                right: (lang === 'en' || !rtl) && '50px',
              }}
              className={classes.dialogFullscreenIcon}
              onClick={() => {
                setFullScreenMode(!fullScreenMode)
                if (typeof setIsDialogFullWidth === 'function')
                  setIsDialogFullWidth(false)
              }}
            />
            {lang === 'ar' ? (
              <MdArrowLeft
                onClick={
                  typeof element.closeFun === 'function'
                    ? element.closeFun
                    : null
                }
                className={classes.dialogClosingIcon}
                style={{
                  display: hideCloseIcon ? 'none' : null,
                  color: isDarkModeActive ? 'white' : 'black',
                  left: '15px',
                }}
              />
            ) : (
              <MdArrowRight
                onClick={
                  typeof element.closeFun === 'function'
                    ? element.closeFun
                    : null
                }
                className={classes.dialogClosingIcon}
                style={{
                  display: hideCloseIcon ? 'none' : null,
                  color: isDarkModeActive ? 'white' : 'black',
                  right: '15px',
                }}
              />
            )}
            {element.content}
          </DialogContent>
        </span>
      </ClickAwayListener>
    </Dialog>
  )
})

const ModelDialogWrappingIIPC = (props) => {
  const { dialogsArray } = props
  return dialogsArray.map((element, elemntIdex) => {
    return (
      Boolean(element.open) && (
        <Activation {...props} key={elemntIdex} element={element} />
      )
    )
  })
}

export default ModelDialogWrappingIIPC
