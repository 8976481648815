import React from 'react'
import { Grid } from '@mui/material'
import RequestingUserModel from '../../../../notificationsSystem/RequestingUserModel'

const ExpandedUnitTopCardLeft = (props) => {
  const { UnitCardLeftComponent, firsButtonConfigs } = props
  return (
    <Grid item xs={firsButtonConfigs.hideButton ? 9 : 6}>
      {UnitCardLeftComponent ? (
        <UnitCardLeftComponent {...props} />
      ) : (
        <RequestingUserModel {...props} />
      )}
    </Grid>
  )
}

export default ExpandedUnitTopCardLeft
