import axios from 'axios'
import { useContext, useState } from 'react'
import { AuthContext } from '../../../../../../../../context/AuthContext'

const IssueUpVoteButtonConfig = ({
  activeFun,
  issue,
  confirmmationTitles,
  isDownvoted,
  setIsDownvoted,
  isUpvoted,
  setIsUpvoted,
}) => {
  const { user } = useContext(AuthContext)
  const [upvotes, setUpvotes] = useState(issue?.upvotes.length)

  const upvoteNumberHandler = async () => {
    setUpvotes((upvotes) => (isUpvoted ? upvotes - 1 : upvotes + 1))
    setIsUpvoted(!isUpvoted)
    try {
      if (user?._id && issue?._id)
        await axios.put(`/issues/${issue?._id}/upvote`, { userId: user?._id })
    } catch (error) {
      console.log(error)
    }
  }

  return {
    title: isUpvoted ? `Remove Vote ${upvotes}` : `Upvote ${upvotes}`,
    funs: async () => {
      setIsUpvoted(!isUpvoted)
      upvoteNumberHandler()
      if (!isUpvoted) activeFun({ relation: 'voting', conditionIndex: 0 })
    },
    cofirmMssg: true,
    confirmmationTitles,
    disabled: isDownvoted,
  }
}

export default IssueUpVoteButtonConfig
