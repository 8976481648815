import React from 'react'
import GPDFDBodyModel from '../GeneralPDFDocumentComps/GPDFDBodyModel'

const CampaignPDFBody = ({ button }) => {
  const { element } = button.PDFGenProps
  const configs = [
    {
      title: 'Campaign_Goal',
      titleValue: element?.CampaignGoal,
      disableTrans: true,
    },
    {
      title: 'Campaign_Target_Audience',
      titleValue: element?.CampaignTargetAudience,
      disableTrans: true,
    },
    {
      title: 'Campaign_Message',
      titleValue: element?.CampaignMessage,
      disableTrans: true,
    },
    {
      title: 'Campaign_Channels',
      titleValue: element?.CampaignChannels,
      disableTrans: true,
    },
    {
      title: 'Campaign_Execution',
      titleValue: element?.CampaignExecution,
      disableTrans: true,
    },
  ]
  return (
    <GPDFDBodyModel
      hideCreator
      GPDFBodyModelRightProps={{
        button,
        configs: configs,
        contribPath: `campaigns/contributors`,
        elementProp: 'contributors',
      }}
      button={button}
    />
  )
}

export default CampaignPDFBody
