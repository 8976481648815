import { Card, CardContent, CardMedia } from '@mui/material'
import React from 'react'
import withStyles from '@mui/styles/withStyles'
import DefaultImageGrapper from '../../../../SmallComponents/DefaultImageGrapper'

const styles = () => ({
  cardWrapper: {
    borderRadius: '10px !important',
    margin: '10px 0 10px 0 !important',
    width: 150,
    height: 200,
    cursor: 'pointer',
    opacity: 0.8,
    boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
    transition: '0.3s !important',
    '&:hover': {
      boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)',
      width: 160,
      opacity: 1,
    },
  },

  media: {
    height: 0,
    paddingTop: '56.25%',
    marginTop: '30',
    borderRadius: '0',
  },
})

const CardMediaModel = ({ element, elementIndex, props, classes }) => {
  const { folderPathProps, modelProps, searchSystemProps, MFSGProps } = props
  const card = element
  const cardIndex = elementIndex
  const { setCardId, isDarkModeActive } = MFSGProps
  const { setSecondLevelFolderPath } = folderPathProps
  const { setShowSearchInput, setSearchQuery, setSearchInputValue } =
    searchSystemProps
  const { defaultImage, imageKey, elementsImages } = modelProps
  const elemntImage = elementsImages?.filter(
    (image) => image.id === element.id
  )[0].image
  const generatedImage = imageKey && DefaultImageGrapper(imageKey)
  const image =
    elementsImages?.length > 0
      ? elemntImage
        ? elemntImage
        : defaultImage || generatedImage
      : defaultImage || generatedImage

  return (
    <Card
      className={classes.cardWrapper}
      sx={{
        backgroundColor: isDarkModeActive
          ? 'rgba(255, 255, 255, 0.192)'
          : 'inherit',
        color: isDarkModeActive ? 'white' : 'inherit',
      }}
      onClick={() => {
        setCardId(cardIndex)
        if (typeof setSecondLevelFolderPath === 'function')
          setSecondLevelFolderPath(card.title || card.name)
        if (typeof setShowSearchInput === 'function') setShowSearchInput(false)
        if (typeof setSearchQuery === 'function') setSearchQuery('')
        if (typeof setSearchInputValue === 'function') setSearchInputValue('')
      }}
    >
      <CardMedia className={classes.media} image={card?.image || image} />
      <CardContent> {card.title || card.name}</CardContent>
    </Card>
  )
}

const WrappedCardMediaModel = withStyles(styles)(CardMediaModel)

export default WrappedCardMediaModel
