import React from 'react'
import FreeBar from '../FreeBar'
import withStyles from '@mui/styles/withStyles'
import { Grid } from '@mui/material'
import '../../skeleton.css'

const styles = () => ({
  controlPanelButtonCard: {
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '20px !important',
  },
  clickedControlPanelButtonCard: {
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: '20px !important',
  },

  titleIconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 700,
    fontSize: 17,
    padding: 5,
    alignContent: 'center',
  },

  controlPanelItemText: {
    backgroundColor: '#313131',
    width: '100%',
  },
  gridConatiner: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },

  controlPanelWrappingCard: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    margin: '0 10px 10px 10px',
    flexFlow: 'row wrap',
    borderRadius: '25px !important',
  },
  '@media (max-width: 1000px)': {
    controlPanelItemText: {
      display: 'none',
    },
    controlPanelWrappingCard: {
      width: '100%',
      margin: '0 0 5px 0',
    },
  },
})

const ControlPanelSkeleton = ({ hide, classes }) => {
  const buttons = Array.from({ length: 4 }, (_, index) => (
    <Grid item xs={3} key={index}>
      <div className={classes.controlPanelButtonCard}>
        <span
          className={classes.titleIconWrapper}
          style={{
            animation: 'skeleton 1s ease infinite alternate',
            width: '100%',
          }}
        >
          <span
            className={classes.controlPanelItemText}
            style={{ width:'100%', borderRadius: 20 }}
          >
            <FreeBar height={40} />
          </span>
        </span>
      </div>
    </Grid>
  ))
  return (
    <div
      className={classes.controlPanelWrappingCard}
      style={{ display: hide ? 'none' : 'flex' }}
    >
      <Grid container className={classes.gridConatiner}>
        {buttons}
      </Grid>
    </div>
  )
}

const WrappedControlPanelSkeleton = withStyles(styles)(ControlPanelSkeleton)

export default WrappedControlPanelSkeleton
