import axios from 'axios'
import React, { useContext } from 'react'
import { AuthContext } from '../../../../../../../../context/AuthContext'
import DefaultChannelButton from '../../../../../../../channelsSystem/DefaultChannelButton'
import GalleryInfoProfileTopModel from '../../GalleryInfoProfileTopModel'

const GlobalInquiryProfileTop = ({ globalInquiry, DCGProps, setCards }) => {
  const rtl = document.body.dir === 'rtl'
  const { user } = useContext(AuthContext)
  const onGetOut = async () => {
    try {
      await axios.patch(`/globalInquiries/${globalInquiry?._id}`, {
        globalInquiryContributorsIds:
          globalInquiry?.globalInquiryContributorsIds?.filter(
            (u) => u !== user?._id
          ),
      })
    } catch (error) {
      console.log(error)
    }
  }
  const galleryProps = {
    hideEdit: user?._id !== globalInquiry?.userId,
    element: globalInquiry,
    DCGProps,
    emptyGalleryTitle: rtl ? 'هذه الرؤية' : 'Global Inquiry',
    pathName: `users/${globalInquiry?.userId}/globalInquiries`,
    setElements: setCards,
    patchPath: `globalInquiries`,
    updateElementImages: async (image) => {
      await axios.patch(`/globalInquiries/${globalInquiry?._id}`, {
        images: globalInquiry?.images?.filter((im) => im === image),
      })
    },
    CustomTopContent: (
      <div
        style={{
          marginTop: 10,
          display:
            globalInquiry?.globalInquiryContributorsIds?.includes(user?._id) ||
            globalInquiry?.userId === user?._id
              ? null
              : 'none',
        }}
      >
        <DefaultChannelButton
          element={globalInquiry}
          DCGProps={DCGProps}
          onGetOut={onGetOut}
        />
      </div>
    ),
  }
  return <GalleryInfoProfileTopModel galleryProps={galleryProps} />
}

export default GlobalInquiryProfileTop
