import React, { useContext } from 'react'
import withStyles from '@mui/styles/withStyles'
import { Card, Avatar, Grid } from '@material-ui/core'
import MemberDetailsUnitModel from './MemberCardModelComponents/MemberDetailsUnitModel'
import MemberOptionUnit from './MemberCardModelComponents/MemberOptionUnit'
import { AuthContext } from '../../../../../context/AuthContext'
import { Tooltip } from '@mui/material'

const styles = () => ({
  membersContainer: {
    marginTop: 10,
    marginBottom: 10,
  },
  memberCard: {
    width: 'auto',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // height: '100%',
  },
  memberAvatar: {
    display: 'flex',
    height: 70,
    width: 70,
    border: '2px solid black',
    cursor: 'pointer',
    transition: '0.3s',
    '&:hover': {
      height: 75,
      width: 75,
    },
  },
  memberDetailsCard: {
    marginTop: -20,
    padding: '20px 5px 5px 5px',
    width: 120,
    minWidth: 165,
    minHeight: 150,
    borderRadius: '20px !important',
  },
})

const MemberCardModel = ({ classes, element, props }) => {
  const member = element
  const { MFSGProps, folderPathProps, modelProps, searchSystemProps } = props
  const { setCardId, isDarkModeActive, DCGProps } = MFSGProps
  const setMemberId = setCardId
  const { setSecondLevelFolderPath } = folderPathProps
  const { setShowSearchInput, setSearchQuery, setSearchInputValue } =
    searchSystemProps
  const {
    memberOptions,
    hideMemberOptions,
    hideProfileView,
    windowOpener,
    hideTippy,
    buttonsArray,
  } = modelProps

  // this action depened on being a department or sub-departmen, the subdepart,ent will have its own set of functionality that suits its problems
  const { user } = useContext(AuthContext)
  const actions = () => {
    if (typeof setMemberId === 'function') setMemberId(member.id)
    if (typeof setSecondLevelFolderPath === 'function')
      setSecondLevelFolderPath(member.name)
    if (typeof setShowSearchInput === 'function') setShowSearchInput(false)
    if (typeof setSearchQuery === 'function') setSearchQuery('')
    if (typeof setSearchInputValue === 'function') setSearchInputValue('')
    if (
      typeof windowOpener === 'function' &&
      (member?._id !== user?._id || member?.id !== user?._id)
    )
      windowOpener()
  }
  const rtl = document.body.dir === 'rtl'
  return (
    <Grid item lg={4} className={classes.membersContainer}>
      <section className={classes.memberCard}>
        <Tooltip
          placement='top'
          disableHoverListener={hideTippy}
          title={
            rtl ? `افتح ملف ${member?.name}` : `Open ${member.name}'s Profile`
          }
        >
          <Avatar
            src={member.image}
            className={classes.memberAvatar}
            onClick={actions}
          />
        </Tooltip>
        <Card
          className={classes.memberDetailsCard}
          style={{
            backgroundColor: isDarkModeActive
              ? 'rgba(255, 255, 255, 0.192)'
              : 'inherit',
            color: isDarkModeActive ? 'white' : 'inherit',
          }}
        >
          <MemberOptionUnit
            memberOptions={memberOptions}
            hideMemberOptions={hideMemberOptions}
            member={member}
            isDarkModeActive={isDarkModeActive}
            DCGProps={DCGProps}
            modelProps={modelProps}
          />
          <MemberDetailsUnitModel
            actions={actions}
            member={member}
            hideProfileView={hideProfileView}
            isDarkModeActive={isDarkModeActive}
            DCGProps={DCGProps}
            buttonsArray={buttonsArray}
          />
        </Card>
      </section>
    </Grid>
  )
}

const WrappedMemberCardModel = withStyles(styles)(MemberCardModel)

export default WrappedMemberCardModel
