import React, { useContext } from 'react'
import withStyles from '@mui/styles/withStyles'
import Media from 'react-media'
import FreeBar from '../FreeBar'
import { List, ListItem } from '@mui/material'
import '../../skeleton.css'
import { AuthContext } from '../../../../context/AuthContext'

const styles = () => ({
  projectControlPanelWrapper: {
    flex: 7,
    justifyContent: 'center',
  },
  controlPanelContentWrapper: {
    width: '100%',
    height: '380px',
    display: 'flex',
    flex: 5,
    flexDirection: 'column',
    transition: '0.3s',
  },
  controllersBoardCard: {
    width: '100%',
    height: 'auto',
    margin: 0,
    display: 'flex',
    flex: 2,
    flexDirection: 'row',
    borderRadius: '20px',
  },

  menuItem: {
    margin: 5,
  },

  controllersCard: {
    display: 'flex',
    flexDirection: 'column',
    flex: 2,
    borderRadius: '20px',
  },

  list: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },

  mcontrollersCard: {
    borderRadius: '0',
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'auto',
    width: '100%',
  },

  mlist: {
    justifyContent: 'space-between',
    overflowY: 'hidden',
    zIndex: 1,
    overflowX: 'auto',
    width: '100vw',
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },

  mlistItem: {
    fontWeight: '500',
    color: 'white !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center !important',
    alignItems: 'center !important',
    alignContent: 'center !important',
    minWidth: '35vw',
    height: '100%',
  },

  modelWrapper: {
    height: '100%',
    display: 'flex',
    overflowY: 'auto',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexFlow: 'row wrap',
    marginTop: 20,
  },

  cardImageText: {
    display: 'flex',
    margin: '10px 25px 10px 25px',
    alignItems: 'center',
    flexDirection: 'column',
  },
  cardImage: {
    objectFit: 'cover',
    width: '110px',
    height: '110px',
    borderRadius: '10px',
    transition: '0.3s',
    opacity: '0.8',
    boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
    '&:hover': {
      boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)',
      width: '130px',
      height: '130px',
      opacity: '1',
    },
    animation: 'skeleton 1s ease infinite alternate',
    backgroundColor: '#313131',
    cursor: 'pointer',
  },
  cardTitle: {
    display: 'flex',
    textAlign: 'center',
    cursor: 'pointer',
    fontSize: '15px',
    color: 'white',
    justifyContent: 'center',
  },

  titleCard: {
    padding: '3px !important',
    backgroundColor: 'rgb(210, 221, 240) !important',
    marginTop: '5px !important',
    width: '100% !important',
    borderRadius: '20px !important',
  },
  '@media (max-width: 600px)': {
    controllersBoardCard: {
      flexDirection: 'column',
      borderRadius: '0',
    },

    projectControlPanelWrapper: {
      flexDirection: 'column',
    },
    controlPanelContentWrapper: {
      width: '100%',
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
      flex: 6,
    },
    cardImageText: {
      margin: 10,
      alignItems: 'center',
      flexDirection: 'column',
    },
    cardTitle: {
      fontSize: '12px',
    },
    cardImage: {
      width: '90px',
      height: '90px',
    },
  },
})

const ProjectControlPanelSkeleton = ({ classes, projectProps }) => {
  const { project } = projectProps
  const { user } = useContext(AuthContext)
  const rtl = document.body.dir === 'rtl'
  const hide =
    project?.userId !== user?._id ||
    (!user?.contractedProjectsIds?.includes(project?._id) &&
      project?.userId !== user?._id)
  const mediaComponents = [
    <div
      className={classes.controllersCard}
      style={{
        animation: 'skeleton 0.5s ease infinite alternate',
        background: 'rgba(147,165,194, 0.1)',
        padding: 10,
        marginLeft: !rtl && 10,
        marginRight: rtl && 10,
      }}
    >
      <List className={classes.list}>
        {Array.from({ length: 7 }, (_, index) => (
          <span className={classes.menuItem} key={index}>
            <FreeBar height={25} width={'60%'} />
          </span>
        ))}
      </List>
    </div>,
    <div className={classes.mcontrollersCard}>
      <section className={classes.mlist}>
        {Array.from({ length: 7 }, (_, index) => (
          <ListItem
            className={classes.mlistItem}
            key={index}
            style={{ animation: 'skeleton 1s ease infinite alternate' }}
          >
            <span className={classes.menuItem} style={{ width: '100%' }}>
              <FreeBar height={20} />
            </span>
          </ListItem>
        ))}
      </section>
    </div>,
  ]
  return (
    <article
      className={classes.projectControlPanelWrapper}
      style={{ display: hide ? 'none' : 'flex' }}
    >
      <div className={classes.controllersBoardCard}>
        <Media query='(max-width: 600px)'>
          {(matches) => {
            return matches ? mediaComponents[1] : mediaComponents[0]
          }}
        </Media>
      </div>
      <span className={classes.controlPanelContentWrapper}>
        <section
          className={classes.modelWrapper}
          style={{
            animation: 'skeleton 0.5s ease infinite alternate',
            background: 'rgba(147,165,194,0.1)',
            margin: 'auto 10px',
            borderRadius: 15,
          }}
        >
          {Array.from({ length: 6 }, (_, index) => (
            <div className={classes.cardImageText} key={index}>
              <div className={classes.cardImage}></div>
              <div className={classes.titleCard}>
                <span
                  className={classes.cardTitle}
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <FreeBar height={15} />
                </span>
              </div>
            </div>
          ))}
        </section>
      </span>
    </article>
  )
}

const WrappedProjectControlPanelSkeleton = withStyles(styles)(
  ProjectControlPanelSkeleton
)
export default WrappedProjectControlPanelSkeleton
