import { useTranslation } from 'react-i18next'

const PureBundlingBusinessModel = () => {
  const { t } = useTranslation('PureBundlingBusinessModel')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 66,
    title: t('title_bm66'),
    subTitle: t('subTitle_bm66'),
    main: t('main_bm66'),
    when: t('when_bm66'),
    who: t('who_bm66'),
    what: t('what_bm66'),
    how: t('how_bm66'),
    why: t('why_bm66'),
    examples: rtl
      ? [
          'HelloFresh - 2011 - ألمانيا',
          'بنفسجي - 2015 - الولايات المتحدة',
          'آبل - 1976 - الولايات المتحدة',
          'مايكروسوفت - 1975 - الولايات المتحدة',
          'أمازون - 1994 - الولايات المتحدة',
          'Dell - 1984 - الولايات المتحدة',
        ]
      : [
          'HelloFresh - 2011 - Germany',
          'Purple - 2015 - United States',
          'Apple - 1976 - United States',
          'Microsoft - 1975 - United States',
          'Amazon - 1994 - United States',
          'Dell - 1984 - United States',
        ],
    tags: rtl
      ? ['أسعار منخفضة', 'منفعة متبادلة']
      : ['Product Combination', 'Low Prices', 'Mutual Benefit'],
    considerations: t('considerations_bm66'),
    combinations: ['$LBM16'],
    hashTagType: 'businessModelTemp',
  }
}

export default PureBundlingBusinessModel
