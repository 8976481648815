import { Button } from '@mui/material'
import React from 'react'
import LinksPlayerSystem from '../LinksPlayerSystem'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    margin: 10,
    padding: 10,
  },
  button: {
    borderRadius: '10px !important',
    margin: '2px !important',
    textTransform: 'none !important',
    fontWeight: '550 !important',
    width: '100% !important',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important",
  },
})

const LinkPlayerPreview = ({ link, setLink, classes }) => {
  const rtl = document.body.dir === 'rtl'
  return (
    link && (
      <div>
        <Button className={classes.button} onClick={() => setLink('')}>
          {rtl ? 'حذف الرابط' : 'Remove Link'}
        </Button>
        <LinksPlayerSystem url={link} />
      </div>
    )
  )
}

const WrappedLinkPlayerPreview = withStyles(styles)(LinkPlayerPreview)

export default WrappedLinkPlayerPreview
