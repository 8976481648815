import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import MultipleMembersViewer from '../../../../../../../membersSystems/MultipleMembersViewer'
import TitleHeader from '../../../../../../../SmallComponents/Header'
import Loading from '../../../../../../../SmallComponents/Loading'
import { AuthContext } from '../../../../../../../../context/AuthContext'
import { toast } from 'react-toastify'
import NotificationCreator from '../../../../../../../notificationsSystem/NotificationCreator'

const IdeaContributors = ({ DCGProps, idea }) => {
  const [contributors, setContributors] = useState([])
  const { socket } = DCGProps
  const [isLoading, setIsLoading] = useState(true)
  const { user } = useContext(AuthContext)
  useEffect(() => {
    const fetchContributors = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(`/ideas/contributors/${idea?._id}`)
        setContributors(res.data.contributors)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    fetchContributors()
  }, [idea?._id])
  const modContributors = contributors?.map((cont) => ({
    ...cont,
    name: cont.firstName + ' ' + cont.lastName,
    title: cont.firstName + ' ' + cont.lastName,
    id: cont._id,
    image: cont.profilePicture,
    hashTagType: 'userContributor',
  }))
  const rtl = document.body.dir === 'rtl'
  const [clicked, setClicked] = useState(false)
  const modelProps = {
    buttonsArray:
      user?._id === idea?.userId
        ? [
            {
              title: rtl ? 'حذف' : 'Remove',
              disabled: clicked,
              funs: async (member) => {
                setClicked(true)
                const res = await axios.patch(
                  `/ideas/removeContributor/${idea?._id}`,
                  {
                    contributorId: member?._id,
                  }
                )
                const channel = res.data.channel
                // removing the elements
                if (channel) {
                  await axios.patch(`/channels/${channel?._id}`, {
                    elements: channel?.elements?.filter(
                      (e) => !res.data.elements?.includes(e?.id)
                    ),
                  })
                }
                socket.current.emit('addNotifUser', member?._id)
                NotificationCreator({
                  associativity: 'idea',
                  relation: 'visionReviewer',
                  conditionIndex: 2,
                  accompaniedData: {
                    elements: [
                      {
                        hashTagType: idea?.hashTagType,
                        id: idea?._id || idea?.id,
                        title: idea?.Title || idea?.title,
                      },
                      {
                        id: channel._id,
                        hashTagType: channel.hashTagType,
                        title: channel?.Title || channel?.title,
                      },
                    ],
                  },
                  category: 'contributors',
                  userId: member?._id,
                  currentUser: user,
                  socket,
                })

                toast.warning(
                  rtl
                    ? 'تم حذف المشارك، قم بإعادة الدخول للفكرة'
                    : 'Contributor has been removed successfully, please, reopen the idea'
                )
              },
            },
          ]
        : [],
  }
  return (
    <div>
      <TitleHeader headerText={rtl ? 'مساهمون' : 'Contributors'} />
      {isLoading ? (
        <Loading />
      ) : (
        <MultipleMembersViewer
          modelProps={modelProps}
          cards={modContributors}
          windowType='userContributor'
          DCGProps={DCGProps}
          emptySentence={rtl ? 'مساهمين' : 'contributors'}
        />
      )}
    </div>
  )
}

export default IdeaContributors
