import React from 'react'
import withStyles from '@mui/styles/withStyles'
import {
  SharingToFeed,
  AddNewTask,
  MoveContributorToSubDepartment,
  MoveContributorToDepartment,
  AddNewContributor,
  DeleteWindowFolderConfirmSent,
  DownloadAllContent,
  AddNewFile,
  CloseWindow,
  AddNewProduct,
} from './NoFileSystemPanelComponents'
import AddToPartnershipChannel from './NoFileSystemPanelComponents/AddToPartnershipChannel'
import GeneralAddIcon from './NoFileSystemPanelComponents/GeneralAddIcon'
import DownloadCVS from './DownloadCVS'
import DownloadPDF from './DownloadPDF'
import RemoveProjectContributor from './NoFileSystemPanelComponents/RemoveProjectContributor'
import AddToBoard from './NoFileSystemPanelComponents/AddToBoard'
import RemoveFromBoard from './NoFileSystemPanelComponents/RemoveFromBoard'
import ExternalLinkSharing from './NoFileSystemPanelComponents/ExternalLinkSharing'

const styles = () => ({
  panelCard: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '40%',
    padding: '10px !important',
    borderRadius: '20px 0px 20px 0px',
    backgroundColor: 'rgba(109, 177, 211, 0.1)',
  },
  '@media (max-width: 600px)': {
    panelCard: {
      marginTop: 10,
    },
  },
})

const NoFileSystemPanel = ({ noFSPanelProps, classes }) => {
  const { hideNoFileSystemPanel, openCloseSubDepModelView } = noFSPanelProps

  return (
    <span
      style={{
        display:
          openCloseSubDepModelView === 1 || hideNoFileSystemPanel
            ? 'none'
            : null,
        width: '100%',
      }}
    >
      <span className={classes.panelCard}>
        <CloseWindow noFSPanelProps={noFSPanelProps} />
        <AddNewFile noFSPanelProps={noFSPanelProps} />
        <DownloadAllContent noFSPanelProps={noFSPanelProps} />
        <DownloadCVS noFSPanelProps={noFSPanelProps} />
        <DownloadPDF noFSPanelProps={noFSPanelProps} />
        <DeleteWindowFolderConfirmSent noFSPanelProps={noFSPanelProps} />
        <AddNewContributor noFSPanelProps={noFSPanelProps} />
        <MoveContributorToDepartment noFSPanelProps={noFSPanelProps} />
        <MoveContributorToSubDepartment noFSPanelProps={noFSPanelProps} />
        <AddNewTask noFSPanelProps={noFSPanelProps} />
        <SharingToFeed noFSPanelProps={noFSPanelProps} />
        <AddNewProduct noFSPanelProps={noFSPanelProps} />
        <AddToPartnershipChannel noFSPanelProps={noFSPanelProps} />
        <GeneralAddIcon noFSPanelProps={noFSPanelProps} />
        <RemoveProjectContributor noFSPanelProps={noFSPanelProps} />
        <AddToBoard noFSPanelProps={noFSPanelProps} />
        <RemoveFromBoard noFSPanelProps={noFSPanelProps} />
        <ExternalLinkSharing noFSPanelProps={noFSPanelProps} />
      </span>
    </span>
  )
}

const WrappedNoFileSystemPanel = withStyles(styles)(NoFileSystemPanel)

export default WrappedNoFileSystemPanel
