import React, { useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import ChartPost from '../../../../postSystem/ChartPost'
import CommentsDialog from '../../../../commentsSystems/CommentsDialog'
import TextPost from '../../../../postSystem/TextPost'

const styles = () => ({
  postChartWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    cursor: 'pointer',
  },
})

const HomeChartPostMiddle = ({ classes, postProps }) => {
  const {
    post,
    commentsDialogOpener,
    isCommentsDialogOpen,
    commentsDialogClose,
    isCommentingSystemActive,
    socket
  } = postProps
  // comments counter - for the dialog that appears upon clicking the chart not the Comemnts icon
  const [commentsCount, setCommentsCount] = useState(post?.comments)

  return (
    <section>
      <section
        className={classes.postChartWrapper}
        onClick={() => commentsDialogOpener()}
      >
        <ChartPost {...postProps} />
      </section>
      <CommentsDialog
        isCommentsDialogOpen={isCommentsDialogOpen}
        commentsDialogClose={commentsDialogClose}
        postBody={<ChartPost {...postProps} />}
        postText={
          <TextPost
            post={post}
            isCommentingSystemActive={isCommentingSystemActive}
            noShadowedText={true}
            socket={socket}
          />
        }
        post={post}
        commentsCount={commentsCount}
        setCommentsCount={setCommentsCount}
      />
    </section>
  )
}

const WrappedHomeChartPostMiddle = withStyles(styles)(HomeChartPostMiddle)

export default WrappedHomeChartPostMiddle
