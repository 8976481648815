import React from 'react'
import GeneralPDFDocument from './GeneralPDFDocument'
import ProductStrategyPDFBody from './ProductStrategyPDFDocumentComps/ProductStrategyPDFBody'

const ProductStrategyPDFDocument = (props) => {
  return (
    <GeneralPDFDocument
      pages={[<ProductStrategyPDFBody {...props} />]}
      {...props}
    />
  )
}

export default ProductStrategyPDFDocument
