import React from 'react'
import '../skeleton.css'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  projectWallPaperWrapper: {
    height: 170,
    margin: '0 10px 10px 10px',
    borderRadius: 15,
    position: 'relative',
    animation: 'skeleton 1s ease infinite alternate',
    backgroundColor: '#313131',
  },

  '@media (max-width: 600px)': {
    projectWallPaperWrapper: {
      borderRadius: 0,
      margin: 0,
    },
  },
})
const ProfileCoverSkeleton = ({ classes }) => {
  return <div className={classes.projectWallPaperWrapper}>Loading</div>
}

const WrappedProfileCoverSkeleton = withStyles(styles)(ProfileCoverSkeleton)

export default WrappedProfileCoverSkeleton
