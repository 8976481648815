import { useTranslation } from 'react-i18next'

const PerformanceBasedContracting = () => {
  const { t } = useTranslation('PerformanceBasedContracting')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 43,
    title: t('title_bm43'),
    subTitle: t('subTitle_bm43'),
    main: t('main_bm43'),
    when: t('when_bm43'),
    who: t('who_bm43'),
    what: t('what_bm43'),
    how: t('how_bm43'),
    why: t('why_bm43'),
    examples: rtl
      ? [
          'رولز رويس - 1904 - المملكة المتحدة',
          'BASF - 1865 - ألمانيا',
          'زيروكس - 1906 - الولايات المتحدة الأمريكية',
        ]
      : [
          'Rolls-Royce - 1904 - UK',
          'BASF - 1865 - Germany',
          'Xerox - 1906 - USA',
        ],
    tags: rtl
      ? [
          'أسعار منخفضة',
          'ولاء العميل',
          'على أساس النتائج',
          'الاستعانة بمصادر خارجية',
          'أهداف الآداء',
        ]
      : [
          'Low Prices',
          'Customer Loyalty',
          'Outcome-Based',
          'Outsourcing',
          'Performance Goals',
        ],
    considerations: t('considerations_bm43'),
    combinations: ['$LBM31', '$LBM24', '$LBM18', '$LBM20'],
    hashTagType: 'businessModelTemp',
  }
}

export default PerformanceBasedContracting
