import { useTranslation } from 'react-i18next'

const UpsellingBusinessModel = () => {
  const { t } = useTranslation('UpsellingBusinessModel')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 65,
    title: t('title_bm65'),
    subTitle: t('subTitle_bm65'),
    main: t('main_bm65'),
    when: t('when_bm65'),
    who: t('who_bm65'),
    what: t('what_bm65'),
    how: t('how_bm65'),
    why: t('why_bm65'),
    examples: rtl
      ? [
          'آبل - 1976 - أمريكا',
          'أمازون - 1994 - الولايات المتحدة الأمريكية',
          'أوبر - 2009 - الولايات المتحدة الأمريكية',
          'Airbnb - 2008 - الولايات المتحدة الأمريكية',
        ]
      : [
          'Apple - 1976 - USA',
          'Amazon - 1994 - USA',
          'Uber - 2009 - USA',
          'Airbnb - 2008 - USA',
        ],
    tags: rtl
      ? [
          'استراتيجية البيع',
          'قيمة أعلى',
          'احتياجات العميل',
          'الحوافز المقدمة',
          'إدراك الميزانية',
        ]
      : [
          'Upselling Strategy',
          'Higher Value',
          'Customer Needs',
          'Incentives Offered',
          'Budget Aware',
        ],
    considerations: t('considerations_bm65'),
    combinations: ['$LBM40'],
    hashTagType: 'businessModelTemp',
  }
}

export default UpsellingBusinessModel
