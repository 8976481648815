import React from 'react'
import { Link } from 'react-router-dom'
import withStyles from '@mui/styles/withStyles'
import { RiBuilding2Fill } from 'react-icons/ri'
import { Avatar } from '@mui/material'

const styles = () => ({
  postProfileImage: {
    borderRadius: ' 50%',
    objectFit: 'cover',
    cursor: 'pointer',
  },
  avatar: {
    cursor: 'pointer',
    margin: '10px 10px 10px 0 !important',
  },
})

const PTLImage = ({ classes, postProps, user: genUser, wz }) => {
  const PF = process.env.REACT_APP_PUBLIC_FOLDER
  const { post, project, user } = postProps
  return post.feedsSystemCategory === 'home' ? (
    <Link to={`/profile/${post?.userId}`}>
      <img
        className={classes.postProfileImage}
        style={{
          width: wz ? 50 : 40,
          height: wz ? 50 : 40,
        }}
        src={
          user?.profilePicture ||
          genUser?.profilePicture ||
          `${PF}person/noAvatar.png`
        }
        alt='Profile Picture'
      />
    </Link>
  ) : (
    <Link to={`/projectProfile/${post?.userId}`}>
      <Avatar
        style={{
          width: wz ? '50px !important' : '40px !important',
          height: wz ? '50px !important' : '40px !important',
        }}
        src={
          project?.projectPhoto || user?.projectPhoto || genUser?.projectPhoto
        }
        className={classes.avatar}
      >
        <RiBuilding2Fill size={wz ? 25 : 20} />
      </Avatar>
    </Link>
  )
}

const WrappedPTLImage = withStyles(styles)(PTLImage)

export default WrappedPTLImage
