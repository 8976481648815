import axios from 'axios'
import React, { useEffect, useState } from 'react'
import NavigatorGenerator from '../../../../../../../navigatorCardsModel/NavigatorGenerator'

const ProductNavigators = (props) => {
  const { product } = props
  const [sharedPosts, setSharedPosts] = useState([])
  const [channels, setChannels] = useState([])
  useEffect(() => {
    const fetchProductPosts = async () => {
      try {
        const res = await axios.get(`/products/productPosts/${product?._id}`)
        const resChannels = await axios.get(
          `/channels/elementChannels/${product?._id}`
        )
        setSharedPosts(res.data.productPosts)
        setChannels(resChannels.data.channels)
      } catch (error) {
        console.log(error)
      }
    }
    fetchProductPosts()
  }, [product?._id])
  const rtl = document.body.dir === 'rtl'
  const navigatorsArray = [
    {
      headerText: rtl ? 'منشورات' : 'Posts',
      unitsArray: sharedPosts,
      NavigatorCardsModelProps: {},
    },
    {
      headerText: rtl ? 'قنوات تشارك تستخدم هذا المنتج': 'Channels uses this Product',
      unitsArray: channels,
      NavigatorCardsModelProps: {},
    },
  ]
  return <NavigatorGenerator {...props} navigatorsArray={navigatorsArray} />
}

export default ProductNavigators
