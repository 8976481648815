const NewAppFormConfigs = () => {
  const rtl = document.body.dir === 'rtl'
  return [
    {
      title: 'Title',
      formComponentType: 'text',
      value: '',
    },
    {
      title: 'Content',
      formComponentType: 'text',
      value: '',
      placeholder: rtl ? 'المحتوى' : 'Content',
      multiLine: true,
      minRows: 5,
    },
  ]
}

export default NewAppFormConfigs
