import axios from 'axios'
import React, { useEffect, useState } from 'react'
import ChannelProfileSMTop from './ChannelProfileFeedComps/ChannelProfileSMTop'
import ChannelSMFeed from './ChannelProfileFeedComps/ChannelSMFeed'

const ChannelProfileFeed = (props) => {
  const { channel } = props
  const [isLoading, setIsLoading] = useState(true)
  const [posts, setPosts] = useState([])
  const [postsUsers, setPostsUsers] = useState([])
  const [page, setPage] = useState(1)
  const [currentResponse, setCurrentResponse] = useState(true)
  const fetchPosts = async () => {
    setIsLoading(true)
    try {
      const res = await axios.get(
        `/posts/channelProfileFeed/${channel?._id}/?page=${page}&limit=15`
      )
      setCurrentResponse(Boolean(res.data.posts.length))
      setPosts(res.data.posts)
      setPostsUsers(res.data.postsUsers)
    } catch (err) {}
    setIsLoading(false)
  }
  useEffect(() => {
    fetchPosts()
  }, [channel?._id, page])

  const propsII = {
    ...props,
    isLoading,
    setPosts,
    posts,
    postsUsers,
    setPostsUsers,
    fetchPosts,
    page,
    setPage,
    currentResponse,
  }

  return (
    <>
      <section style={{ margin: 20 }}>
        <ChannelProfileSMTop {...propsII} />
      </section>
      <ChannelSMFeed {...propsII} />
    </>
  )
}

export default ChannelProfileFeed
