import React from 'react'
import { Select, MenuItem } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import WindowSize from '../SmallComponents/WindowSize'
import { useTranslation } from 'react-i18next'

const styles = () => ({
  select: {
    fontSize: '14px !important',
    appearance: 'none !important',
    outline: 'none !important',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important",
  },
  optionItem: {
    fontSize: '14px !important',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important",
  },
  '@media (max-width: 600px)': {
    select: {
      fontSize: '12px !important',
      appearance: 'none !important',
      outline: 'none !important',
    },
    optionItem: {
      fontSize: '12px !important',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
})

const FreeSelectComponent = ({
  classes,
  onChange,
  menuOptions,
  setClickAwayState,
  isDarkModeActive,
  disabled,
  option,
  setOption,
}) => {
  const rtl = document.body.dir === 'rtl'
  const windowSize = WindowSize()
  const { t } = useTranslation()
  return (
    <Select
      value={option}
      onChange={(e) => {
        if (typeof onChange === 'function') onChange(e)
        setOption(e.target.value)
      }}
      autoWidth
      style={{
        color: isDarkModeActive ? 'white' : null,
      }}
      className={classes.select}
      disabled={Boolean(disabled)}
      variant='standard'
      onOpen={() => {
        if (typeof setClickAwayState === 'function') setClickAwayState(true)
      }}
    >
      {menuOptions?.map((option, optionIndex) => (
        <MenuItem
          value={optionIndex}
          key={optionIndex}
          className={classes.optionItem}
        >
          {option.pureIcon && (
            <option.pureIcon
              style={{
                opacity: disabled ? 0.5 : 1,
                margin:
                  windowSize > 600
                    ? rtl
                      ? '2px 2px auto 10px'
                      : '2px 10px auto 2px'
                    : rtl
                    ? '0 2px 0 10px'
                    : '0 10px 0 2px',
              }}
            />
          )}
          {t(option.title)}
        </MenuItem>
      ))}
    </Select>
  )
}

const WrappedFreeSelectComponent = withStyles(styles)(FreeSelectComponent)

export default WrappedFreeSelectComponent
