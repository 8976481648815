import React from 'react'
import withStyles from '@mui/styles/withStyles'
import FreeBar from '../FreeBar'
import '../../skeleton.css'
import WindowSize from '../../../SmallComponents/WindowSize'

const styles = () => ({
  ProjectNameAndContracts: {
    height: 'auto',
    marginBottom: 10,
  },
  titleAndTools: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '20px',
  },
  introBottomWrapper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgba(217,224,252, 0.6) ',
    margin: '5px 10px 10px 10px',
    borderRadius: '15px',
  },

  nameLogoWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  nameContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: 'auto',
    alignItems: 'center',
    backgroundColor: 'rgba(217,224,252, 0.4) ',
    borderRadius: '15px',
    padding: 10,
    width: '100%',
  },

  titleDateWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },

  projectPhotoWrapper: {
    position: 'relative',
  },

  projectName: {
    width: 200,
    marginBottom: 5,
  },

  creationDate: {
    width: 200,
  },

  companyInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgba(217,224,252, 0.2) ',
    width: '100%',
    padding: 10,
    borderRadius: '15px',
    alignContent: 'center',
    justifyContent: 'center',
  },

  generalInfoWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  incubtaorWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    marginBottom: 10,
  },
  incubtaor: {
    fontWeight: 700,
    fontSize: 22,
    color: 'blue',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  iconTitle: {
    fontWeight: '500',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: 'rgb(18, 18, 51)',
    width: 200,
    margin: 5,
  },

  contractInvestChatFollowOptions: {
    margin: 5,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '65%',
    alignSelf: 'center',
    padding: 10,
    borderRadius: '10px',
  },

  interactionsButton: {
    margin: 5,
    width: 200,
  },

  '@media (max-width: 600px)': {
    titleAndTools: {
      borderRadius: '0px',
    },
    ProjectNameAndContracts: {
      marginBottom: 5,
    },
    introBottomWrapper: {
      margin: 0,
      borderRadius: '0px',
    },
    nameLogoWrapper: {
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    nameContainer: {
      margin: 0,
      padding: 0,
      borderRadius: '0px',
    },
    iconTitle: {
      width: '30vw',
    },
    companyInfoContainer: {
      width: 'auto',
    },
    incubtaor: {
      fontWeight: 700,
      fontSize: 15,
    },
    companyStateValues: {
      fontSize: 12,
      fontWeight: 500,
    },
  },
})

const ProjectIntroSkeleton = ({ classes }) => {
  const rtl = document.body.dir === 'rtl'
  const windowSize = WindowSize()
  return (
    <section className={classes.ProjectNameAndContracts}>
      <section className={classes.titleAndTools}>
        <article className={classes.nameLogoWrapper}>
          <section
            className={classes.nameContainer}
            style={{
              margin:
                windowSize > 600 &&
                (rtl ? '10px 10px 5px 5px' : '10px 5px 5px 10px'),
              paddingRight: !rtl && windowSize > 600 && 10,
              paddingLeft: rtl && windowSize > 600 && 10,
            }}
          >
            <span className={classes.projectPhotoWrapper}>
              <div
                className='postSkAvatar'
                style={{
                  animation: 'skeleton 1s ease infinite alternate',
                  width: windowSize > 600 ? '100px' : '700',
                  height: windowSize > 600 ? '100px' : '700',
                  margin: '18px',
                }}
              ></div>
            </span>
            <span className={classes.titleDateWrapper}>
              <span className={classes.projectName}>
                <FreeBar height={20} />
              </span>
              <span className={classes.creationDate}>
                <FreeBar height={10} width={'40%'} />
              </span>
            </span>
          </section>
          <section
            className={classes.companyInfoContainer}
            style={{ margin: rtl ? '10px 5px 5px 10px' : '10px 10px 5px 5px' }}
          >
            <section className={classes.incubtaorWrapper}>
              <span
                className={classes.incubtaor}
                style={{
                  marginRight: !rtl && windowSize < 600 && 10,
                  marginLeft: rtl && windowSize < 600 && 10,
                }}
              >
                <FreeBar height={20} />
              </span>
            </section>
            <section className={classes.generalInfoWrapper}>
              <section>
                {Array.from({ length: 3 }, (_, index) => (
                  <span className={classes.iconTitle} key={index}>
                    <FreeBar height={20} />
                  </span>
                ))}
              </section>
              <section>
                {Array.from({ length: 3 }, (_, index) => (
                  <span className={classes.iconTitle} key={index}>
                    <FreeBar height={20} />
                  </span>
                ))}
              </section>
            </section>
          </section>
        </article>
      </section>
      <span className={classes.introBottomWrapper}>
        <section className={classes.contractInvestChatFollowOptions}>
          {Array.from({ length: 4 }, (_, index) => (
            <span className={classes.interactionsButton} key={index}>
              <FreeBar height={30} />
            </span>
          ))}
        </section>
      </span>
    </section>
  )
}

const WrappedProjectIntroSkeleton = withStyles(styles)(ProjectIntroSkeleton)

export default WrappedProjectIntroSkeleton
