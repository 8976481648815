import axios from 'axios'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import EditableGridModel from '../../../../../../formsSystem/EditableGridModel'
import FormDataLangModulator from '../../../../../../formsSystem/FormDataLangModulator'
import InfoDataIntialStateGen from '../../../../../../formsSystem/InfoDataIntialStateGen'
import HashTagElementsRefiner from '../../../../../../SmallComponents/HashTagElementsRefiner'
import { AuthContext } from '../../../../../../../context/AuthContext'

const InvestmentPackageDetails = (props) => {
  const { pack, DCGProps, setCards } = props
  const { user } = useContext(AuthContext)
  const { project } = DCGProps
  const authorised =
    user?._id === project?.userId ||
    user?.createdProjects?.includes(pack?.projectId)
  const { t } = useTranslation()
  const infoArray = [
    {
      title: 'Title',
      titleValue: pack?.Title,
      allowEmpty: authorised,
    },
    {
      title: 'Description',
      titleValue: pack?.Description,
      allowEmpty: authorised,
      wideMode: true,
      minRows: 6,
    },
  ].concat(
    Array.from({ length: 8 }, (_, index) => ({
      title: `new_invest_title_${index + 1}`,
      titleValue:
        pack[
          t(`new_invest_title_${index + 1}`, { lng: 'en' })
            .split(' ')
            .join('')
        ],
      allowEmpty: authorised,
      wideMode: true,
      minRows: 6,
    }))
  )
  const rtl = document.body.dir === 'rtl'
  const [infoData, setInfoData] = useState(InfoDataIntialStateGen(infoArray))
  const [chosenHashTagElements, setChosenHashTagElements] = useState(
    HashTagElementsRefiner(pack) || []
  )
  const newInfoData = FormDataLangModulator(infoData, t)
  const onSave = async () => {
    if (pack?._id)
      try {
        const res = await axios.patch(`/investmentPackages/${pack?._id}`, {
          ...newInfoData,
          chosenHashTagElements,
        })
        setCards((packs) => [
          ...packs.filter((p) => p._id !== pack?._id),
          res.data.pack,
        ])
      } catch (error) {
        console.log(error)
      }
  }
  const gridProps = {
    hideEditingTools: !authorised,
    headerText: rtl ? 'تفاصيل الباقة' : 'Package Details',
    infoArray,
    setInfoData,
    infoData,
    ...DCGProps,
    setChosenHashTagElements,
    chosenHashTagElements,
    onSave,
    departmentId: 2,
  }
  return <EditableGridModel gridProps={gridProps} />
}

export default InvestmentPackageDetails
