import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { Card, Grid } from '@mui/material'

const styles = () => ({
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '90%',
    justifyContent: 'center',
    borderRadius: '20px !important',
    margin: 5,
    ' &:hover': {
      filter: 'brightness(130%)',
    },
  },
  bottomIcon: {
    cursor: 'pointer',
    marginLeft: 5,
    marginRight: 5,
    color: '#333333',
  },
  counterValue: {
    fontSize: 15,
    cursor: 'pointer',
    fontWeight: 550,
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
    color: '#333333',
  },
  iconWrapper: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

const PBCardsModel = ({
  classes,
  buttonsArray,
  isDarkModeActive,
  hideBottom,
}) => {
  return buttonsArray.map((button, buttonIndex) => (
    <Grid
      item
      xs={12 / buttonsArray.length}
      key={buttonIndex}
      style={{
        width: '100%',
        display: hideBottom ? 'none' : 'flex',
        justifyContent: 'center',
      }}
    >
      <Card
        className={classes.buttonWrapper}
        sx={{
          outline: isDarkModeActive
            ? 'rgb(10, 56, 182)61, 255,) solid 1px'
            : 'none',
          backgroundColor: isDarkModeActive ? 'rgba(255, 255, 255, 0.5)' : null,
        }}
        onClick={() => {
          if (typeof button.openFun === 'function') button.openFun()
        }}
      >
        <span className={classes.iconWrapper}>
          {button.icon}
          {button.value}
        </span>
      </Card>
    </Grid>
  ))
}

const WrappedPBCardsModel = withStyles(styles)(PBCardsModel)

export default WrappedPBCardsModel
