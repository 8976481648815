import React from 'react'
import CardTitlesModel from '../../../../../../fileSystem/MiniFileSystemComponents/MiniFSCardsModelComponents/MFSCardsViewModels/StandardCardComps/CardTitlesModel'

const CampaignCardModelContent = ({ element, isDarkModeActive }) => {
  const campaign = element
  return (
    <CardTitlesModel
      title={campaign?.title}
      cardMark={campaign.campaignStatus}
      markColor={
        campaign.campaignStatus === 'Open'
          ? 'blue'
          : campaign.campaignStatus === 'Closed'
          ? isDarkModeActive
            ? 'white'
            : 'gray'
          : 'green'
      }
      isDarkModeActive={isDarkModeActive}
    />
  )
}

export default CampaignCardModelContent
