import PairingMembersUnit from './NewTaskFormConfigsComponents/PairingMembersUnit'

export default function newTaskFormConfigs({ subDepId, membersProfiles }) {
  const subdepMembers = membersProfiles?.filter((m) => m.subDepId === subDepId)
  const rtl = document.body.dir === 'rtl'
  return [
    {
      title: 'Title',
      formComponentType: 'text',
      value: '',
      placeholder: rtl ? 'حدد عنواناًً للمهمة' : 'Name Your Task',
    },
    {
      title: 'Task_Deadline',
      formComponentType: 'date',
      value: '',
    },
    {
      title: 'Invest_Method',
      formComponentType: 'select',
      value: 'Regular',
      menuOptions: [
        'Regular',
        'One_Time_Wages',
        'Freelancing_Investment',
        'Repeated',
      ],
    },
    // choosing pairing means to set the task internal by defualt, otherwise it will be registered to DB as external,
    {
      // be carfule when you optain the value of this and push it the backend, i will abstract the id of the profile only.
      // after creating the task, if the id is defined i will push a notfication for the user chosen.
      // this will be after post method of creating the new task in the buttons
      title: 'Task_Pairing',
      formComponentType: 'select',
      value: 'Not_Paired_Yet',
      menuOptions: ['Not_Paired_Yet'].concat(
        ...((subDepId ? subdepMembers : membersProfiles) || [])
      ),
      customMenuItem: true,
      CustomMenuItemComponent: PairingMembersUnit,
    },
    {
      title: 'Task_Description',
      formComponentType: 'text',
      value: '',
      multiLine: true,
      placeholder: rtl ? 'صٍف المهمة' : 'Describe Your Task',
      minRows: 5,
    },
  ]
}
