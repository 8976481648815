import React from 'react'
import TextPost from '../../../postSystem/TextPost'
import withStyles from '@mui/styles/withStyles'
import ChartPost from '../../../postSystem/ChartPost'
import CommentsDialog from '../../../commentsSystems/CommentsDialog'

const styles = () => ({
  postChartWrapper: {
    display: 'flex',
    justifyContent: 'center !important',
    alignContent: 'center !important',
    borderRadius: '10px !important',
  },
  postWrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%'
  },
})

const ChartPostModel = ({ postBodyProps, classes }) => {
  const { commentsDialogOpener } = postBodyProps

  return (
    <article className={classes.postWrapper}>
      <TextPost {...postBodyProps} margins={'auto 10px'} />
      <span
        className={classes.postChartWrapper}
        onClick={() => commentsDialogOpener()}
      >
        <ChartPost {...postBodyProps} />
      </span>
      <CommentsDialog
        {...postBodyProps}
        postBody={<ChartPost {...postBodyProps} />}
        postText={<TextPost {...postBodyProps} />}
      />
    </article>
  )
}

const WrappedChartPostModel = withStyles(styles)(ChartPostModel)

export default WrappedChartPostModel
