import React from 'react'
import AdsManagementPageMain from './AdsManagementHomeComps/AdsManagementPageMain'
import AdsManagmentModel from './AdsManagmentModel'
import DocumentTitleChanger from './SmallComponents/DocumentTitleChanger'

const AdsManagementHome = (props) => {
  const rtl = document.body.dir === 'rtl'
  DocumentTitleChanger(rtl ? 'الاعلانات - الرئيسية' : 'Campiagns Home')
  return (
    <AdsManagmentModel
      Component={AdsManagementPageMain}
      ComponentProps={props}
    />
  )
}

export default AdsManagementHome
