import { Button, Grid } from '@mui/material'
import React from 'react'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  button: {
    borderRadius: '10px !important',
    margin: '2px !important',
    textTransform: 'none !important',
    fontWeight: '550 !important',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important",
  },
  cardItem: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  gridItem: {
    display: 'flex',
    justifyContent: 'center',
  },
})

const ExpandedUnitTopCardButtons = ({
  classes,
  showDetails,
  setShowDetails,
  firsButtonConfigs,
  card,
}) => {
  const rtl = document.body.dir === 'rtl'
  return (
    <>
      <Grid
        item
        xs={firsButtonConfigs.hideButton ? 0 : 3}
        className={classes.gridItem}
      >
        <Button
          onClick={() => {
            firsButtonConfigs.funs()
            firsButtonConfigs.setChosenCard(card)
          }}
          className={classes.button}
          disabled={firsButtonConfigs.disabled}
          style={{ display: firsButtonConfigs.hideButton ? 'none' : null }}
        >
          {firsButtonConfigs.title}
        </Button>
      </Grid>
      <Grid item xs={3} className={classes.gridItem}>
        <Button
          className={classes.button}
          onClick={() => setShowDetails(!showDetails)}
        >
          {rtl ? 'تفاصيل' : 'Details'}
        </Button>
      </Grid>
    </>
  )
}

const WrappedExpandedUnitTopCardButtons = withStyles(styles)(
  ExpandedUnitTopCardButtons
)

export default WrappedExpandedUnitTopCardButtons
