import React from 'react'
import GPDFDBodyModel from '../GeneralPDFDocumentComps/GPDFDBodyModel'

const ResourcePDFBody = ({ button }) => {
  const { element } = button.PDFGenProps
  const configs = [
    {
      title: 'Potential_Uses',
      titleValue: element?.PotentialUses,
      disableTrans: true,
    },
  ]
  return (
    <GPDFDBodyModel
      GPDFBodyModelRightProps={{
        button,
        configs: configs,
        mainContent: element?.Description,
      }}
      button={button}
    />
  )
}

export default ResourcePDFBody
