import { useTranslation } from 'react-i18next'

const BusinessDefinition = () => {
  const { t } = useTranslation('BusinessDefinition')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 17,
    category: t('category_ms17'),
    title: t('title_ms17'),
    subTitle: t('subTitle_ms17'),
    main: t('main_ms17'),
    how: t('how_ms17'),
    usedInModels: ['$LBM54', '$LBM22', '$LBM40'],
    tags: rtl
      ? [
          'تحديد الأهداف',
          'البحث عن المتجر',
          'نموذج العمل',
          'تطوير الماركة',
          'مخطط المنتج والخدمة',
          'خطة عمل',
          'إعداد الأعمال',
        ]
      : [
          'Identifying Goals',
          'Market Research',
          'Business Model',
          'Brand Development',
          'Product and Service Outline',
          'Business Plan',
          'Business Setup',
        ],
    examples: t('examples_ms17'),
    hashTagType: 'markStraAspect',
  }
}

export default BusinessDefinition
