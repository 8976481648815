import { Button } from '@mui/material'
import React from 'react'
import withStyles from '@mui/styles/withStyles'
import WindowViewerSystem from '../../windowViewer/WindowViewerSystem'
import WindowProps from '../../windowViewer/WindowProps'
import StateGenerator from '../../SmallComponents/StateGenerator'
import NotificationsSentConfirmDialog from '../../confirmationSystem/NotificationsSentConfirmDialog'
import WindowSize from '../../SmallComponents/WindowSize'

const styles = () => ({
  button: {
    borderRadius: '10px !important',
    margin: '2px !important',
    textTransform: 'none !important',
    fontWeight: '550 !important',
    width: '100% !important',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important",
  },
})

const ButtonModelUnit = ({
  classes,
  button,
  isDarkModeActive,
  element,
  buttonsArray,
}) => {
  const { isWindowOpen, windowOpener, windowCloser } = WindowProps()
  const {
    isConfirmDialogOpen,
    setIsConfirmDialogOpen,
    clickedButtonId,
    confirmDialogOpener,
  } = StateGenerator({
    chatProps: true,
    confirmDialogProps: true,
  })

  const windowSize = WindowSize()

  return (
    <>
      <Button
        onClick={() => {
          if (typeof button.funs === 'function') button.funs(element)
          if (button?.windowConfigs) windowOpener()
          if (button?.cofirmMssg) confirmDialogOpener()
        }}
        className={classes.button}
        type={button?.pathName && 'link'}
        href={button?.pathName}
        variant={
          windowSize > 600 && buttonsArray?.length < 3 ? 'outlined' : undefined
        }
        disabled={
          typeof button?.disabled === 'function'
            ? button?.disabled(element)
            : button?.disabled
        }
        sx={{
          color: isDarkModeActive ? 'white' : null,
          fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
        }}
      >
        {button.title}
      </Button>
      {button?.windowConfigs && (
        <WindowViewerSystem
          isWindowOpen={isWindowOpen}
          windowCloser={windowCloser}
          isDarkModeActive={isDarkModeActive}
          props={{
            windowCloser,
            ...button?.windowConfigs?.props,
          }}
          windowType={button?.windowConfigs?.windowType}
        />
      )}
      {button?.cofirmMssg && (
        <NotificationsSentConfirmDialog
          confirmmationTitles={button?.confirmmationTitles}
          confirmationRTitleIndex={clickedButtonId}
          isConfirmDialogOpen={isConfirmDialogOpen}
          setIsConfirmDialogOpen={setIsConfirmDialogOpen}
          isDarkModeActive={isDarkModeActive}
        />
      )}
    </>
  )
}

const WrappedButtonModelUnit = withStyles(styles)(ButtonModelUnit)

export default WrappedButtonModelUnit
