import React, { useContext, useEffect, useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import { Button } from '@mui/material'
import NotificationCreator from '../../../notificationsSystem/NotificationCreator'
import PostGeneratorEngine from '../../../feedsSystem/PostGeneratorEngine'
import axios from 'axios'
import { FeedContext } from '../../../../context/FeedContext/FeedContext'
import WidnowSize from '../../../SmallComponents/WindowSize'
import { toast } from 'react-toastify'

const styles = () => ({
  button: {
    borderRadius: '10px !important',
    textTransform: 'none !important',
    fontWeight: '550 !important',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important",
  },
})

const ShareDialogShareButton = ({ classes, shareSystemProps }) => {
  const rtl = document.body.dir === 'rtl'
  const {
    setIsPostShared,
    shareDialogClose,
    setSharesCount,
    sharesCount,
    setClickAwayState,
    post,
    postData,
    user,
    shareTypeIndex,
    socket,
    setPosts,
    setSharedPostConfigs,
    element,
    projectMode,
  } = shareSystemProps
  const { dispatch } = useContext(FeedContext)
  const freePost = { ...post, ...postData }
  const [projectAdmins, setProjectAdmins] = useState([])
  useEffect(() => {
    const fetchProject = async () => {
      try {
        const adminsRes = await axios.get(
          `/projects/o/projectAdmins/${post?.userId}`
        )
        setProjectAdmins(adminsRes.data.admins)
      } catch (error) {
        console.log(error)
      }
    }

    if (post?.feedsSystemCategory === 'page' && post?.userId) fetchProject()
  }, [post?.userId, post?.feedsSystemCategory])

  const notMod = async () => {
    try {
      if (post?.feedsSystemCategory === 'home') {
        await axios.delete('/notifications/customDelete/d', {
          headers: {},
          data: {
            userId: post?.userId,
            associativity: 'post',
            relation: 'creator',
            category: 'general',
            conditionIndex: 0,
            accompaniedData: {
              postId: post?._id,
            },
            notThisUser: true,
          },
        })
      } else {
        Promise.all(
          projectAdmins?.map(async (m) => {
            await axios.delete('/notifications/customDelete/d', {
              headers: {},
              data: {
                userId: m?._id,
                associativity: 'project',
                relation: 'admin',
                category: 'contributors',
                conditionIndex: 2,
                accompaniedData: {
                  postId: post._id,
                  projectId: post?.userId,
                },
                notThisUser: true,
              },
            })
          })
        )
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if (
      post?._id &&
      post?.userId &&
      post?.shares?.length > 0 &&
      post?.userId !== user?._id
    )
      notMod()
  }, [post?.userId, post?._id, projectAdmins])
  const widnowSize = WidnowSize()

  return (
    <Button
      variant={widnowSize > 600 ? 'outlined' : 'text'}
      style={{ margin: widnowSize > 600 && '2px !important' }}
      className={classes.button}
      onClick={async () => {
        if (post?.shares?.length > 0 && post?.userId !== user?._id) notMod()
        shareDialogClose()
        if (user?._id && post && postData)
          try {
            const res = await axios.post('/posts', {
              userId: user?._id,
              ...(shareTypeIndex === 2
                ? freePost
                : PostGeneratorEngine(postData, 2, 'home')),
            })
            if (shareTypeIndex === 0)
              await axios.patch(`/posts/${post?._id}`, {
                shares: [...post?.shares, user?._id],
              })
            if (res.data.post?._id) {
              dispatch({
                type: 'CREATE_POST',
                payload: res.data.post,
              })
              if (typeof setPosts === 'function' && !projectMode)
                setPosts((posts) => [res.data.post, ...posts])
            }
            if (typeof setSharedPostConfigs === 'function')
              setSharedPostConfigs({ postId: res.data.post?._id, element })
          } catch (error) {
            console.log(error)
          }
        if (shareTypeIndex === 0)
          if (post?.feedsSystemCategory === 'home') {
            NotificationCreator({
              associativity: 'post',
              relation: 'creator',
              conditionIndex: 0,
              accompaniedData: { postId: post?._id },
              category: 'general',
              userId: post?.userId,
              currentUser: user,
              socket,
            })
          } else {
            projectAdmins?.map((m) => {
              socket.current.emit('addNotifUser', m?._id)
              NotificationCreator({
                associativity: 'project',
                relation: 'admin',
                conditionIndex: 2,
                accompaniedData: {
                  projectId: post?.userId,
                  postId: post._id,
                  elements: [
                    {
                      id: post?._id,
                      hashTagType: post?.hashTagType,
                      title: rtl ? 'منشور' : 'Project Post',
                    },
                  ],
                },
                category: 'contributors',
                userId: m?._id,
                currentUser: user,
                socket,
              })
            })
          }
        if (typeof setIsPostShared === 'function') setIsPostShared(true)
        if (typeof setClickAwayState === 'function') setClickAwayState(false)
        if (typeof setSharesCount === 'function')
          setSharesCount(sharesCount + 1)
        toast.success(
          rtl ? 'تمت مشاركة المنشور بنجاح' : 'Post has been shared successfully'
        )
      }}
    >
      {rtl ? 'مشاركة' : 'Share'}
    </Button>
  )
}

const WrappedShareDialogShareButton = withStyles(styles)(ShareDialogShareButton)

export default WrappedShareDialogShareButton
