import React from 'react'
import FinStatPDFBody from './FinStatPDFDocumentComps/FinStatPDFBody'
import GeneralPDFDocument from './GeneralPDFDocument'

const FinStatPDFDocument = (props) => {
  return (
    <GeneralPDFDocument pages={[<FinStatPDFBody {...props} />]} {...props} />
  )
}

export default FinStatPDFDocument
