import { useTranslation } from 'react-i18next'

const BrandsManagment = () => {
  const { t } = useTranslation('BrandsManagment')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 27,
    category: t('category_ms27'),
    title: t('title_ms27'),
    subTitle: t('subTitle_ms27'),
    main: t('main_ms27'),
    how: t('how_ms27'),
    usedInModels: [],
    tags: rtl
      ? [
          'العلامات التجارية المتجانسة',
          'ماركات المظلات',
          'الماركات ذات الخط الواحد',
        ]
      : ['Monolithic Brands', 'Umbrella Brands', 'Single Line Brands'],
    examples: t('examples_ms27'),
    hashTagType: 'markStraAspect',
  }
}

export default BrandsManagment
