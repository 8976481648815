import React, { useEffect, useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import CardTitlesModel from '../StandardCardComps/CardTitlesModel'
import axios from 'axios'
import ModTasks from '../../../../../projectProfile/ProjectProfileComponents/ProjectControlPanelComponents/ProjectDepartmentsComponents/DepTasksComponents/ModTasks'
import { useTranslation } from 'react-i18next'

const styles = () => ({
  pairedCont: {
    fontSize: 14,
    fontWeight: 500,
  },
})

const TaskCardContent = ({
  classes,
  element,
  isDarkModeActive,
  membersProfiles,
}) => {
  const [completeTask, setCompleteTask] = useState()
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    const fetchTask = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(`/tasks/${element?.id}`)
        setCompleteTask(res.data.task)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    if (!element?.TaskStatus && element?.id) fetchTask()
  }, [element?.id, element?.id])
  const task = ModTasks({
    projectTasks: [element?.TaskStatus ? element : completeTask],
  })[0]
  const pairedMember = membersProfiles?.find(
    (m) => m._id === task?.TaskPairedContributorId
  )
  const [repPairedMember, setRepPairedMember] = useState(null)
  useEffect(() => {
    const fetchUser = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(`/tasks/taskPired/${task?._id || task?.id}`)
        setRepPairedMember(res.data?.user)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    if (!pairedMember && (task?._id || task?.id)) fetchUser()
  }, [task?._id || task?.id])
  const paired = pairedMember ? pairedMember : repPairedMember

  const { t } = useTranslation()
  const cardProps = isLoading
    ? {}
    : {
        markColor:
          task?.TaskStatus === 'Done'
            ? 'green'
            : task?.TaskStatus === 'Still'
            ? 'blue'
            : task?.TaskStatus === 'Due Today'
            ? 'yellow'
            : task?.TaskStatus === 'Terminated'
            ? isDarkModeActive
              ? 'white'
              : 'gray'
            : 'Red',
        cardFooter: (
          <span className={classes.pairedCont}>
            {paired?.firstName
              ? paired?.firstName + ' ' + paired?.lastName
              : t('Not_Paired_Yet')}
          </span>
        ),
      }
  return (
    <CardTitlesModel
      title={task?.Title || element.title}
      cardMark={task?.TaskStatus}
      isDarkModeActive={isDarkModeActive}
      {...cardProps}
    />
  )
}

const WrappedTaskCardContent = withStyles(styles)(TaskCardContent)

export default WrappedTaskCardContent
