import { useTranslation } from 'react-i18next'

const CustomerLoyaltyBusinessModel = () => {
  const { t } = useTranslation('CustomerLoyaltyBusinessModel')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 12,
    title: t('title_bm12'),
    subTitle: t('subTitle_bm12'),
    main: t('main_bm12'),
    when: t('when_bm12'),
    who: t('who_bm12'),
    what: t('what_bm12'),
    how: t('how_bm12'),
    why: t('why_bm12'),
    examples: rtl
      ? [
          'سبيري وهتشينسون (1909 ، الولايات المتحدة الأمريكية)',
          'الاسترداد - ألمانيا (2000 ، ألمانيا)',
        ]
      : [
          'Sperry & Hutchinson (1909, USA)',
          'Payback - Germany (2000, Germany)',
        ],
    tags: rtl
      ? ['خصومات', 'الشراء المتكرر', 'علاقات العملاء', 'نقاط', 'استرداد نقدي']
      : [
          'Discounts',
          'Frequent Purchase',
          'Customer Relationship',
          'Points',
          'Cashback',
        ],
    considerations: t('considerations_bm12'),
    combinations: ['$LBM29', '$LBM67', '$LBM40', '$LBM22'],
    hashTagType: 'businessModelTemp',
  }
}

export default CustomerLoyaltyBusinessModel
