import { useTranslation } from 'react-i18next'

const CommoditySlide = () => {
  const { t } = useTranslation('CommoditySlide')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 33,
    category: t('category_ms33'),
    title: t('title_ms33'),
    subTitle: t('subTitle_ms33'),
    main: t('main_ms33'),
    how: t('how_ms33'),
    usedInModels: [],
    tags: rtl
      ? [
          'المنافسة السعرية',
          'تقليل التكاليف',
          'رضا العملاء',
          'منتجات فريدة',
          'خدمة العملاء',
        ]
      : [
          'Price Competition',
          'Cost Reduction',
          'Customer Satisfaction',
          'Unique Products',
          'Customer Service',
          'Technological Advances',
        ],
    examples: t('examples_ms33'),
    hashTagType: 'markStraAspect',
  }
}

export default CommoditySlide
