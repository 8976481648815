import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
  },
  image: {
    width: '50%',
    height: 'auto',
    marginBottom: theme.spacing(4),
  },
  text: {
    fontWeight: 500,
    fontSize: '2rem',
    textTransform: 'uppercase',
  },
}))

const NotFound = ({ isDarkModeActive }) => {
  const classes = useStyles()
  const rtl = document.body.dir === 'rtl'

  return (
    <Box className={classes.wrapper}>
      <img
        src='/assets/various/brokenEgg.png'
        alt={rtl ? 'العنصر المحذوف' : 'removed item'}
        className={classes.image}
      />
      <Typography
        variant='h1'
        align='center'
        className={classes.text}
        color={isDarkModeActive ? 'text.primary' : 'text.secondary'}
      >
        {rtl ? 'غير متوفر' : 'Not Found'}
      </Typography>
      <Typography variant='body1' align='center' gutterBottom>
        {rtl
          ? 'نأسف، البيانات التي تبحث عنها غير متوفرة. ربما تم حذفها أو لم تكن موجودة من قبل.'
          : "We're sorry, the data you're looking for is not available. It may have been deleted or never existed."}
      </Typography>
      <Typography variant='body1' align='center'>
        {rtl
          ? 'يرجى المحاولة مرة أخرى لاحقًا أو الاتصال بالدعم إذا كنت بحاجة إلى مساعدة إضافية.'
          : 'Please try again later or contact support if you need further assistance.'}
      </Typography>
    </Box>
  )
}

export default NotFound
