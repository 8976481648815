import React from 'react'

const PollCardTitle = ({ poll }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        fontSize: 20,
        fontWeight: 500,
        marginTop: 15,
      }}
    >
      {poll?.Title}
    </div>
  )
}

export default PollCardTitle
