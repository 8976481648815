const ReverseFormDataLangModulator = (data) => {
  const modeifedFormData = Object.entries(data)
  let newFormData = {}
  modeifedFormData?.map((entry) => {
    newFormData[entry[0]?.split(' ').join('_')] =
      typeof entry[1] === 'string' && entry[1]?.split(' ')?.join('_')
  })
  return newFormData
}

export default ReverseFormDataLangModulator
