import React from 'react'
import NewFormDialogModel from '../../formsSystem/NewFormDialogModel'
import { Tooltip } from '@mui/material'
import WindowProps from '../../windowViewer/WindowProps'
import withStyles from '@mui/styles/withStyles'
import RemoveFromBoardDialogContent from './RemoveFromBoardComps/RemoveFromBoardDialogContent'
import { MdLeakRemove } from 'react-icons/md'

const styles = () => ({
  icon: {
    cursor: 'pointer',
    fontSize: 18,
    '&:hover': {
      opacity: 0.8,
    },
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
})

const RemoveFromBoard = ({ classes, noFSPanelProps }) => {
  const { showRemoveFromBoard } = noFSPanelProps
  const { isWindowOpen, windowOpener, windowCloser } = WindowProps()
  const rtl = document.body.dir === 'rtl'
  return (
    <>
      <Tooltip title={rtl ? 'إزالة من مجلس الإدارة' : 'End Contribution'}>
        <span
          className={classes.iconWrapper}
          style={{ display: showRemoveFromBoard ? null : 'none' }}
          onClick={() => windowOpener()}
        >
          <MdLeakRemove className={classes.icon} />
        </span>
      </Tooltip>
      <NewFormDialogModel
        NewFormComponent={
          <RemoveFromBoardDialogContent
            noFSPanelProps={noFSPanelProps}
            windowCloser={windowCloser}
          />
        }
        newFormDialogClose={windowCloser}
        isNewFormDialogOpen={isWindowOpen}
        {...noFSPanelProps}
        clickAwayState={true}
        dialogTitle={rtl ? 'إزالة من مجلي الإدارة' : 'End Contribution'}
      />
    </>
  )
}

const WrappedRemoveFromBoard = withStyles(styles)(RemoveFromBoard)

export default WrappedRemoveFromBoard
