import React from 'react'
import withStyles from '@mui/styles/withStyles'
import GalleryPCView from './PostImagesGalleryViewComponents/GalleryPCView'
import GalleryMobVersion from './PostImagesGalleryViewComponents/GalleryMobVersion'
import Media from 'react-media'

const styles = () => ({})

const PostImagesGalleryView = ({
  imagesArray,
  setViewedImageIndex,
  viewedImageIndex,
  openFun,
}) => {
  const mediaComponents = [
    <GalleryPCView
      openFun={openFun}
      viewedImageIndex={viewedImageIndex}
      setViewedImageIndex={setViewedImageIndex}
      imagesArray={imagesArray}
    />,
    <GalleryMobVersion openFun={openFun} imagesArray={imagesArray} />,
  ]

  return (
    <Media query='(max-width: 600px)'>
      {(matches) => {
        return matches ? mediaComponents[1] : mediaComponents[0]
      }}
    </Media>
  )
}

const WrappedPostImagesGalleryView = withStyles(styles)(PostImagesGalleryView)

export default WrappedPostImagesGalleryView
