import React from 'react'
import AdsManagmentModel from './AdsManagmentModel'
import AdsExplorerPage from './AdsExplorerComps/AdsExplorerPage'
import DocumentTitleChanger from './SmallComponents/DocumentTitleChanger'

const AdsExplorer = (props) => {
  const rtl = document.body.dir === 'rtl'
  DocumentTitleChanger(rtl ? 'متصفح الحملات الاعلانية' : 'Campaigns Explorer')
  return (
    <AdsManagmentModel Component={AdsExplorerPage} ComponentProps={props} />
  )
}

export default AdsExplorer
