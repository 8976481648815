import { useTranslation } from 'react-i18next'

const SegmentMigration = () => {
  const { t } = useTranslation('SegmentMigration')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 29,
    category: t('category_ms29'),
    title: t('title_ms29'),
    subTitle: t('subTitle_ms29'),
    main: t('main_ms29'),
    how: t('how_ms29'),
    usedInModels: [],
    tags: rtl
      ? [
          'هجرة العملاء',
          'استراتيجيات التجزئة',
          'المزيج التسويقي',
          'ملاحظات العملاء',
        ]
      : [
          'Customer Migration',
          'Segmentation Strategies',
          'Marketing Mix',
          'Customer Feedback',
        ],
    examples: t('examples_ms29'),
    hashTagType: 'markStraAspect',
  }
}

export default SegmentMigration
