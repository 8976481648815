import { StyleSheet, View } from '@react-pdf/renderer'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import GPDFDList, { Item } from './GPDFDList'
import GPDFDTitle from './GPDFDTitle'
import i18next from 'i18next'

const styles = StyleSheet.create({
  warpper: {
    marginTop: 20,
  },
})

const ElementContributors = ({ path, button, elementProp }) => {
  const { element } = button.PDFGenProps
  const [contributors, setContributors] = useState([])
  useEffect(() => {
    const fetchContributors = async () => {
      try {
        const res = await axios.get(`/${path}/${element?._id || element?.id}`)
        setContributors(res.data[elementProp])
      } catch (error) {
        console.log(error)
      }
    }
    if (element?._id || element?.id) fetchContributors()
  }, [element?._id, element?._id])
  const lang = i18next.language
  return (
    <View style={styles.warpper}>
      <GPDFDTitle>{lang === 'ar' ? 'مشاركين' : 'Contributors'}</GPDFDTitle>
      <View>
        <GPDFDList>
          {contributors.map((cont, i) => (
            <Item key={i}>{cont.firstName + ' ' + cont.lastName}</Item>
          ))}
        </GPDFDList>
      </View>
    </View>
  )
}

export default ElementContributors
