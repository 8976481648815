import { useTranslation } from "react-i18next"

const HybridCloud = () => {
  const { t } = useTranslation('HybridCloud')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 78,
    title: t('title_bm78'),
    subTitle: t('subTitle_bm78'),
    main: t('main_bm78'),
    when: t('when_bm78'),
    who: t('who_bm78'),
    what: t('what_bm78'),
    how: t('how_bm78'),
    why: t('why_bm78'),
    examples: rtl
      ? [
          'Microsoft - 1975 - الولايات المتحدة',
          'LBM - 1911 - الولايات المتحدة الأمريكية',
          'Amazon Web Services - 2006 - الولايات المتحدة الأمريكية',
          'Google Cloud Platform - 2011 - الولايات المتحدة الأمريكية',
          'Oracle - 1977 - الولايات المتحدة الأمريكية',
        ]
      : [
          'Microsoft - 1975 - USA',
          'IBM - 1911 - USA',
          'Amazon Web Services - 2006 - USA',
          'Google Cloud Platform - 2011 - USA',
          'Oracle - 1977 - USA',
        ],
    tags: rtl
      ? [
          'فعالية التكلفة',
          'قابلية التوسع',
          'تقليل المخاطر',
          'تحسين الأمن',
          'مصداقية',
          'التوفر',
          'السحابة العامة',
          'سحابة خاصة',
        ]
      : [
          'Cost-effectiveness',
          'Scalability',
          'Reduced Risk',
          'Improved Security',
          'Reliability',
          'Availability',
          'Public Cloud',
          'Private Cloud',
        ],
    considerations: t('considerations_bm78'),
    combinations: [],
    hashTagType: 'businessModelTemp',
  }
}

export default HybridCloud
