import HashTagCardsConfigs from '../HashTagCardsConfigs'

const ChosenElementPropertyName = ({
  lastHashTagSymbol,
  chosenHashtagComp,
}) => {
  // this will provide the chosen element property name that i want to appear in the text field in front of the editing user
  const titleProp = HashTagCardsConfigs().filter(
    (card) => card.hashTagType === chosenHashtagComp?.hashTagType
  )[0]?.titleProperty
  // this will be chnaged to match firstname and last name or i just may prepre name instead in the backend
  return lastHashTagSymbol === '@'
    ? 'name'
    : lastHashTagSymbol === '$'
    ? titleProp
    : 'Title' || 'ProductName' || 'title'
}

export default ChosenElementPropertyName
