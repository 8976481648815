import React, { useRef, useState } from 'react'
import { OutlinedInput } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import EditingDialogContent from './FullActiveOutlineInputComponents/EditingDialogContent'
import NewFormDialogModel from '../NewFormDialogModel'
import { CgAttachment } from 'react-icons/cg'
import HashTagSuggestSystem from '../../suggestionsSystem/HashTagSuggestSystem'
import { AiOutlineSend } from 'react-icons/ai'
import deleteFile from '../../../firebase/deleteFile'
import EmojiPopover from './EmojiPopover'
import WindowSize from '../../SmallComponents/WindowSize'

const styles = () => ({
  input: {
    height: 35,
    width: '70%',
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
})

const FullActiveOutlineInput = ({ classes, formsCompsProps }) => {
  const { formComponentIndex, ...props } = formsCompsProps
  const {
    textContent,
    setTextContent,
    placeholder,
    images,
    setImages,
    isDarkModeActive,
    cursorDirection,
    onSend,
    onKeyDown,
    fixed,
    setPollData,
    setLink,
  } = props
  // editing dialog
  const [isEditingDialogOpen, setIsEditingDialogOpen] = useState(false)
  const editingDialogOpener = () => {
    setIsEditingDialogOpen(true)
  }

  const editingDialogClose = () => {
    setIsEditingDialogOpen(false)
  }
  const [isSuggeCardClicked, setIsSuggeCardClicked] = useState(false)
  const formsCompsPropsII = {
    ...formsCompsProps,
    setIsSuggeCardClicked,
    isSuggeCardClicked,
  }
  const rtl = document.body.dir === 'rtl'
  const anchorRef = useRef(null)
  const windowSize = WindowSize()
  return (
    <span
      ref={anchorRef}
      className={classes.wrapper}
      style={{ flexDirection: rtl ? 'row' : 'row-reverse' }}
    >
      <AiOutlineSend
        size={23}
        style={{ margin: 5, cursor: 'pointer' }}
        onClick={() => {
          setTextContent('')
          setImages([])
          onSend()
        }}
      />
      <CgAttachment
        onClick={editingDialogOpener}
        size={23}
        style={{ cursor: 'pointer' }}
      />
      <EmojiPopover {...formsCompsProps} iconSize={23} />
      <OutlinedInput
        className={classes.input}
        onChange={(event) => {
          setTextContent(event.currentTarget.value)
          setIsSuggeCardClicked(false)
        }}
        value={textContent}
        placeholder={placeholder}
        style={{
          color: isDarkModeActive ? 'white' : 'black',
          direction: cursorDirection,
          borderRadius: 20,
          marginRight: 5,
        }}
        onKeyDown={typeof onKeyDown === 'function' ? onKeyDown : undefined}
      />
      <HashTagSuggestSystem
        {...formsCompsPropsII}
        anchorRef={!isEditingDialogOpen && anchorRef}
        cursorDirection={cursorDirection}
        margin={
          fixed
            ? '-100px auto 0px auto'
            : windowSize > 600
            ? '36px 0px auto 0'
            : '-40px auto 220px auto'
        }
      />
      <NewFormDialogModel
        NewFormComponent={
          <EditingDialogContent
            formsCompsProps={{
              editingDialogClose,
              ...props,
            }}
          />
        }
        newFormDialogClose={async () => {
          Promise.all(
            images.map(async (image) => {
              if (image) await deleteFile(image)
            })
          )
          editingDialogClose()
          setTextContent('')
          setImages([])
          if (typeof setPollData === 'function') setPollData(null)
          if (typeof setLink === 'function') setLink('')
        }}
        isNewFormDialogOpen={isEditingDialogOpen}
        isDarkModeActive={isDarkModeActive}
        clickAwayState={true}
        dialogTitle={rtl ? 'تحرير' : 'Editing'}
      />
    </span>
  )
}

const WrappedFullActiveOutlineInput = withStyles(styles)(FullActiveOutlineInput)

export default WrappedFullActiveOutlineInput
