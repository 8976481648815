import { useTranslation } from 'react-i18next'

const DropshippingBusinessModel = () => {
  const { t } = useTranslation('DropshippingBusinessModel')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 62,
    title: t('title_bm62'),
    subTitle: t('subTitle_bm62'),
    main: t('main_bm62'),
    when: t('when_bm62'),
    who: t('who_bm62'),
    what: t('what_bm62'),
    how: t('how_bm62'),
    why: t('why_bm62'),
    examples: rtl
      ? [
          'أمازون - 1994 - الولايات المتحدة الأمريكية',
          'eBay - 1995 - الولايات المتحدة الأمريكية',
          'وول مارت - 1962 - الولايات المتحدة الأمريكية',
          'AliExpress - 2010 - الصين',
          'Oberlo - 2015 - كندا',
          'Wish - 2010 - USA',
        ]
      : [
          'Amazon - 1994 - USA',
          'eBay - 1995 - USA',
          'Walmart - 1962 - USA',
          'AliExpress - 2010 - China',
          'Oberlo - 2015 - Canada',
          'Wish - 2010 - USA',
        ],
    tags: rtl
      ? ['رواد التجارة الإلكترونية', 'اتجاهات المستهلك']
      : [
          'Ecommerce Entrepreneurs',
          'Third-Party Supplier',
          'Minimal Financial',
          'Consumer Trends',
        ],
    considerations: t('considerations_bm62'),
    combinations: ['$LBM60', '$LBM21'],
    hashTagType: 'businessModelTemp',
  }
}

export default DropshippingBusinessModel
