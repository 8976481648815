import React from 'react'
import { ProfileShareModel } from './'

const ProjectProfileShare = ({ shareSystemProps }) => {
  const rtl = document.body.dir === 'rtl'
  return (
    <ProfileShareModel
      shareSystemProps={{
        ...shareSystemProps,
        placeholder: rtl
          ? 'شارك العالم تطورات مشروعك / شركتك'
          : 'Share the World, the Progress of Your Project / Company',
        shareOptionsModelType: 'project',
        feedsSystemCategory: 'page',
      }}
    />
  )
}

export default ProjectProfileShare
