import React from 'react'
import ButtonsSystem from '../../../../../buttonsSystem/ButtonsSystem'
import TitleHeader from '../../../../../SmallComponents/Header'
import ModelStructureConfigs from './BusinessModelModelComps/ModelStructureConfigs'
import ModelStructureUnit from './NavigateModelsComponents/ModelProfileComponents/ModelStructureUnit'

const BusinessModelModel = ({
  buttonsArray,
  headerText,
  DCGProps,
  customModel,
}) => {
  return (
    <div>
      <TitleHeader headerText={headerText} />
      {ModelStructureConfigs({ model: customModel }).map((str, index) => (
        <ModelStructureUnit
          model={str}
          key={index}
          DCGProps={DCGProps}
          customModel={customModel}
        />
      ))}
      <ButtonsSystem buttonsArray={buttonsArray} {...DCGProps} />
    </div>
  )
}

export default BusinessModelModel
