import { Button } from '@mui/material'
import React from 'react'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  button: {
    backgroundColor: 'rgb(210, 221, 240) !important',
    borderRadius: '10px !important',
    margin: '2px !important',
    textTransform: 'none !important',
    color: 'white !important',
    fontWeight: '550 !important',
    width: '20%',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important",
  },
})

const ElementsSelectButton = ({
  classes,
  selectedElementsArray,
  showElements,
}) => {
  const rtl = document.body.dir === 'rtl'
  return (
    <Button
      variant='contained'
      className={classes.button}
      onClick={() => showElements()}
    >
      {selectedElementsArray.length === 0
        ? rtl
          ? 'أضف'
          : 'Add'
        : rtl
        ? 'عَدِّلْ'
        : 'Update'}
    </Button>
  )
}

const WrappedElementsSelectButton = withStyles(styles)(ElementsSelectButton)

export default WrappedElementsSelectButton
