import React, { useEffect, useState } from 'react'
import ImageUpdatorPopover from './ImageUpdatorComps/ImageUpdatorPopover'
import ImageUpdatorPopoverContent from './ImageUpdatorComps/ImageUpdatorPopoverContent'

const ImageUpdator = (props) => {
  const { remImage } = props
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    setIsLoading(false)
  }, [remImage])
  return (
    <ImageUpdatorPopover
      {...props}
      isLoading={isLoading}
      content={
        <ImageUpdatorPopoverContent {...props} setIsLoading={setIsLoading} />
      }
    />
  )
}

export default ImageUpdator
