import React from 'react'
import withStyles from '@mui/styles/withStyles'
import '../../skeleton.css'
import ProfileRightLoader from './ProfileRightLoader'

const styles = () => ({
  projectProfileFeedRight: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: 'auto',
    alignContent: 'center',
    position: 'sticky',
    top: 80,
    height: 'inherit',
    maxHeight: 500,
    paddingTop: 0,
  },
  '@media (max-width: 600px)': {
    projectProfileFeedRight: {
      flexDirection: 'column',
      position: 'relative',
      top: 0,
    },
  },
})

const ProjectFeedRightSkeleton = ({ classes }) => {
  return (
    <section className={classes.projectProfileFeedRight}>
      {Array.from({ length: 2 }, (_, index) => (
        <ProfileRightLoader key={index}/>
      ))}
    </section>
  )
}

const WrappedProjectFeedRightSkeleton = withStyles(styles)(
  ProjectFeedRightSkeleton
)

export default WrappedProjectFeedRightSkeleton
