import React from 'react'
import AreaChartComponent from '../rightbar/rightbar-Components/RightbarChartComponents/ChartBody'
import Media from 'react-media'
import ChartPostDescription from './ChartPostComps/ChartPostDescription'
import withStyles from '@mui/styles/withStyles'
import WindowSize from '../SmallComponents/WindowSize'
import { useTranslation } from 'react-i18next'

const styles = () => ({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'column',
  },
  chartWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    borderRadius: 15,
    background: 'rgba(0,0,0,0.1)',
    margin: 10,
    padding: 10,
    flexDirection: 'column',
    justifyItems: 'center',
    alignItems: 'center',
  },
  chartTitle: {
    fontWeight: 500,
  },
})

const ChartPost = ({ classes, post, isDarkModeActive }) => {
  const windowSize = WindowSize()
  const { t } = useTranslation()
  const dataKey =
    post?.chartData && Object.keys(post?.chartData[0]).find((k) => k !== '_id')
  const divParam =
    windowSize < 600 && windowSize > 400 ? 1.5 : windowSize < 400 ? 1.8 : 1.4
  const chart = (
    <Media query='(max-width: 600px)'>
      {(matches) => {
        return matches ? (
          <AreaChartComponent
            parameterId={0}
            parametersDataArray={[post?.chartData]}
            containerWidth={500 / divParam}
            containerHeight={100 / divParam}
            isDarkModeActive={isDarkModeActive}
          />
        ) : (
          <AreaChartComponent
            parameterId={0}
            parametersDataArray={[post?.chartData]}
            containerWidth={500}
            containerHeight={100}
            isDarkModeActive={isDarkModeActive}
          />
        )
      }}
    </Media>
  )
  return (
    <article
      className={classes.container}
      style={{ color: isDarkModeActive && 'white' }}
    >
      <section className={classes.chartWrapper}>
        <div className={classes.chartTitle}>{t(dataKey)}</div>
        {chart}
      </section>
      {post?.chartData && (
        <ChartPostDescription post={post} isDarkModeActive={isDarkModeActive} />
      )}
    </article>
  )
}

const WrappedChartPost = withStyles(styles)(ChartPost)

export default WrappedChartPost
