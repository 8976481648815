import React from 'react'
import { useNavigate } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
  adCampaignUnit: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem',
    border: '1px solid #ccc',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: '#f5f5f5',
      transform: 'scale(1.05)',
    },
  },
  adCampaignDate: {
    fontSize: '0.9rem',
    marginBottom: '0.5rem',
  },
  adCampaignStatus: {
    fontSize: '0.8rem',
    color: '#666',
  },
})

const AdsCampaignUnit = ({ adCampaign }) => {
  const classes = useStyles()
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(`/manage-ads/${adCampaign._id}`)
  }
  const { t } = useTranslation()
  const rtl = document.body.dir === 'rtl'
  return (
    <div className={classes.adCampaignUnit} onClick={handleClick}>
      <p className={classes.adCampaignDate}>
        {moment(adCampaign?.createdAt).calendar()}
      </p>
      <p className={classes.adCampaignStatus}>{t(adCampaign?.status)}</p>
    </div>
  )
}

export default AdsCampaignUnit
