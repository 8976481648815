import React, { useContext, useState } from 'react'
import { AuthContext } from '../../../../../../../../context/AuthContext'
import ButtonsSystem from '../../../../../../../buttonsSystem/ButtonsSystem'
import ElementRequestsSenders from '../../../../../../../notificationsSystem/ElementRequestsSenders'
import NoPermissionedInteraction from '../../../../../../../notificationsSystem/NoPermissionedInteraction'
import NotificationCreator from '../../../../../../../notificationsSystem/NotificationCreator'
import RequestCreator from '../../../../../../../notificationsSystem/RequestCreator'
import { toast } from 'react-toastify'

const InquiryInteractionButtons = ({ postMode, DCGProps, inquiry }) => {
  const { socket } = DCGProps
  const { user } = useContext(AuthContext)
  const senders = ElementRequestsSenders(inquiry?._id || inquiry?.id)
  const [dec, setDec] = useState(false)
  const [start, setStart] = useState(false)
  const activeFun = async (subgenre) => {
    try {
      socket.current.emit('addNotifUser', inquiry?.userId)
      NotificationCreator({
        associativity: 'inquiry',
        relation: 'inquiryOwner',
        conditionIndex: 0,
        accompaniedData: {
          requestSubGenre: subgenre,
          elements: [
            {
              hashTagType: inquiry?.hashTagType,
              id: inquiry?._id || inquiry?.id,
              title: inquiry?.Title,
            },
          ],
        },
        category: 'contributors',
        userId: inquiry?.userId,
        currentUser: user,
        socket,
        CRNDelete: true,
        element: inquiry,
      })
      RequestCreator({
        currentUser: user,
        genre: 'inquiry',
        subgenre,
        elementId: inquiry?._id || inquiry?.id,
        requestData: {},
      })
    } catch (error) {
      console.log(error)
    }
  }
  const rtl = document.body.dir === 'rtl'
  const buttonsArray = [
    {
      title: rtl ? 'توضيح' : 'Declaration',
      funs: () => {
        setDec(true)
        activeFun('declaration')
        toast.success(
          rtl
            ? 'تم ارسال طلبك بنجاح'
            : 'Your request has been sent successfully'
        )
      },
      cofirmMssg: true,
      disabled: dec,
    },
    {
      title: rtl ? 'إبدأ' : 'Start',
      funs: () => {
        setStart(true)
        activeFun('start')
        toast.success(
          rtl
            ? 'تم ارسال طلبك بنجاح'
            : 'Your request has been sent successfully'
        )
      },
      cofirmMssg: true,
      disabled: start,
    },
  ]
  return senders === undefined ? (
    <NoPermissionedInteraction checking={true} />
  ) : senders?.includes(user?._id) || dec || start ? (
    <NoPermissionedInteraction />
  ) : (
    <ButtonsSystem
      buttonsArray={buttonsArray}
      hide={!postMode || inquiry?.pairedUserId || user?._id === inquiry?.userId}
      {...DCGProps}
    />
  )
}

export default InquiryInteractionButtons
