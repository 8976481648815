import React from 'react'
import GPDFDBodyModel from '../GeneralPDFDocumentComps/GPDFDBodyModel'

const IdeaPDFBody = ({ button }) => {
  const { element } = button.PDFGenProps
  const configs = [
    {
      title: 'Idea_Category',
      titleValue: element?.IdeaCategory,
    },
    {
      title: 'Idea_Nature',
      titleValue: element?.IdeaNature,
    },
    {
      title: 'Intended_Planning',
      titleValue: element?.IntendedPlanning,
    },
    {
      title: 'Plan_Status',
      titleValue: element?.PlanStatus,
    },
  ]
  return (
    <GPDFDBodyModel
      GPDFBodyModelRightProps={{
        button,
        configs: configs,
        mainContent: element?.Content,
        contribPath: `ideas/contributors`,
        elementProp: 'contributors',
      }}
      button={button}
    />
  )
}

export default IdeaPDFBody
