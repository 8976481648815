import { useTranslation } from 'react-i18next'

const OpenBusiness = () => {
  const { t } = useTranslation('OpenBusiness')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 37,
    title: t('title_bm37'),
    subTitle: t('subTitle_bm37'),
    main: t('main_bm37'),
    when: t('when_bm37'),
    who: t('who_bm37'),
    what: t('what_bm37'),
    how: t('how_bm37'),
    why: t('why_bm37'),
    examples: rtl
      ? [
          'بروكتر آند جامبل - 1837 - الولايات المتحدة الأمريكية',
          'BASF - 1865 - ألمانيا',
          'إيلي ليلي - 1876 - الولايات المتحدة الأمريكية',
          'IBM - 1911 - USA',
          'شركة Valve - 1996 - الولايات المتحدة الأمريكية',
          'أبريل مودا - 1991 - البرازيل',
          'هولسيم كوستاريكا - 2002 - كوستاريكا',
        ]
      : [
          'Procter & Gamble - 1837 - USA',
          'BASF - 1865 - Germany',
          'Eli Lilly - 1876 - USA',
          'IBM - 1911 - USA',
          'Valve Corporation - 1996 - USA',
          'ABRIL Moda - 1991 - Brazil',
          'Holcim Costa Rica - 2002 - Costa Rica',
        ],
    tags: rtl
      ? [
          'اللعب النظيف',
          'الفوز للجانبين',
          'بيئة عمل صحية',
          'أعمال ذات كفاءة',
          'تكاليف المنافسة منخفضة',
        ]
      : [
          'Fair Play',
          'Win-win',
          'Healthy Business Environment',
          'Efficient Business',
          'Low Competition Costs',
        ],
    considerations: t('considerations_bm37'),
    combinations: ['$LBM70', '$LBM71'],
    hashTagType: 'businessModelTemp',
  }
}

export default OpenBusiness
