import { TextField } from '@mui/material'
import React, { useRef, useState } from 'react'
import HashTagSuggestSystem from '../../suggestionsSystem/HashTagSuggestSystem'

const FullActiveTextField = ({ formsCompsProps }) => {
  const {
    textContent,
    setTextContent,
    placeholder,
    isDarkModeActive,
    cursorDirection,
  } = formsCompsProps
  const [isSuggeCardClicked, setIsSuggeCardClicked] = useState(false)
  const formsCompsPropsII = {
    ...formsCompsProps,
    setIsSuggeCardClicked,
    isSuggeCardClicked,
  }
  const anchorRef = useRef(null)
  return (
    <>
      <TextField
        ref={anchorRef}
        placeholder={placeholder}
        onChange={(event) => {
          setTextContent(event.currentTarget.value)
          setIsSuggeCardClicked(false)
        }}
        value={textContent}
        InputProps={{
          style: {
            color: isDarkModeActive ? 'white' : 'black',
            direction: cursorDirection,
            fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
          },
        }}
        fullWidth
        margin='dense'
        multiline
        rows='5'
        variant='standard'
      />
      <HashTagSuggestSystem
        {...formsCompsPropsII}
        anchorRef={anchorRef}
        cursorDirection={cursorDirection}
        margin={'125px 0px auto 0'}
      />
    </>
  )
}

export default FullActiveTextField
