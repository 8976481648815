import React from 'react'
import NewFormDialogModel from '../../formsSystem/NewFormDialogModel'
import StateGenerator from '../../SmallComponents/StateGenerator'
import ImagesEditableGalleryForm from './ImagesEditableGalleryComponents/ImagesEditableGalleryForm'
import EmptyEditableGallery from './ImagesEditableGalleryComponents/EmptyEditableGallery'
import NonEmptyEditableGallery from './ImagesEditableGalleryComponents/NonEmptyEditableGallery'
import deleteFile from '../../../firebase/deleteFile'
import DefaultGalleryImage from './ImagesEditableGalleryComponents/DefaultGalleryImage'

const ImagesEditableGallery = (props) => {
  const { images, element, hideEdit } = props
  const { isGeneralDialogOpen, GeneralDialogOpener, GeneralDialogClose } =
    StateGenerator({ GeneralProps: true })
  const newImages = images?.filter((im) => !element?.images?.includes(im))

  const handleClose = async () => {
    GeneralDialogClose()
    try {
      Promise.all(
        newImages.map(async (image) => {
          if (image) await deleteFile(image)
        })
      )
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <>
      {images?.length > 0 ? (
        <NonEmptyEditableGallery
          {...props}
          GeneralDialogOpener={GeneralDialogOpener}
        />
      ) : hideEdit ? (
        <DefaultGalleryImage {...props} />
      ) : (
        <EmptyEditableGallery
          {...props}
          GeneralDialogOpener={GeneralDialogOpener}
        />
      )}
      <NewFormDialogModel
        NewFormComponent={
          <ImagesEditableGalleryForm
            formProps={{
              ...props,
              GeneralDialogClose,
              handleClose,
              newImages,
            }}
          />
        }
        newFormDialogClose={handleClose}
        isNewFormDialogOpen={isGeneralDialogOpen}
        {...props}
        clickAwayState={true}
        dialogTitle='Edit Gallery'
      />
    </>
  )
}

export default ImagesEditableGallery
