import React, { useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import SearchIcon from '@mui/icons-material/Search'
import FormsComponents from '../../formsSystem/FormsComponents'

const styles = () => ({
  searchBar: {
    width: 400,
    height: 30,
    backgroundColor: 'white',
    borderRadius: '1.5rem',
    display: 'flex',
    alignItems: 'center',
    opacity: 0.35,
    '&:hover': {
      opacity: 1.75,
    },
  },
  searchIcon: {
    fontSize: '20px !important',
    marginLeft: 10,
    marginRight: 10,
    cursor: 'pointer',
  },
  searchInput: {
    border: 'none',
    width: '100%',
    '&:focus': {
      outline: 'none',
      opacity: 0.8,
    },
    '&::placeholder': {
      color: 'rgb(26, 23, 23)',
      fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
      fontWeight: 200,
      fontSize: 13.2,
    },
  },
  '@media (max-width: 600px)': {
    searchBar: {
      width: 'auto',
    },
  },
})

const SearchInput = ({
  classes,
  setNewSearchQuerry,
  placeholder,
  isDarkModeActive,
  searchQuerryState,
  setSearchQuerryState,
  project,
  setPage,
  setCurrentResponse,
  newSearchQuerry,
}) => {
  const style =
    document.body.dir === 'rtl' ? { paddingLeft: 20 } : { paddingRight: 20 }

  const [searchQuery, setSearchQuery] = useState(newSearchQuerry)
  const newSearch = () => {
    setPage(1)
    setCurrentResponse(true)
    setNewSearchQuerry(searchQuery)
    setSearchQuerryState(searchQuery)
  }
  const formsCompsProps = {
    formComponentIndex: 2,
    textContent: searchQuerryState,
    setSearchQuery,
    setTextContent: setSearchQuerryState,
    searchInputClass: classes.searchInput,
    placeholder,
    project,
    isDarkModeActive,
    onKeyDown: (e) => {
      if (e.key === 'Enter') newSearch()
    },
  }
  return (
    <div className={classes.searchBar} style={style}>
      <SearchIcon
        className={classes.searchIcon}
        style={{ color: isDarkModeActive ? 'black' : null }}
        onClick={async () => newSearch()}
      />
      <FormsComponents formsCompsProps={formsCompsProps} />
    </div>
  )
}

const WrappedSearchInput = withStyles(styles)(SearchInput)

export default WrappedSearchInput
