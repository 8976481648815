const GeneralPollConfigs = () => {
  const rtl = document.body.dir === 'rtl'
  return [
    {
      title: 'Set_Your_Question',
      formComponentType: 'text',
      value: '',
      placeholder: rtl ? 'غرض التصويت' : 'Voting Subject',
      multiLine: true,
      minRows: 3,
    },
    {
      title: 'Set_Deadline',
      formComponentType: 'date',
      value: '',
    },
  ]
}

export default GeneralPollConfigs
