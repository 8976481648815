import { useTranslation } from 'react-i18next'

const IngredientBrandingBusinessModel = () => {
  const { t } = useTranslation('IngredientBrandingBusinessModel')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 26,
    title: t('title_bm26'),
    subTitle: t('subTitle_bm26'),
    main: t('main_bm26'),
    when: t('when_bm26'),
    who: t('who_bm26'),
    what: t('what_bm26'),
    how: t('how_bm26'),
    why: t('why_bm26'),
    examples: rtl
      ? [
          'DuPont de Nemours - تأسست عام 1802 - الولايات المتحدة الأمريكية',
          'Intel - تأسست عام 1968 - الولايات المتحدة الأمريكية',
          'W.L. Gore & Associates (Gore)  - تأسست عام 1958 - الولايات المتحدة الأمريكية',
          'شيمانو - تأسست عام 1921 - اليابان',
          'بوش - تأسست عام 1886 - ألمانيا',
        ]
      : [
          'DuPont de Nemours - Founded 1802 - USA',
          'Intel - Founded 1968 - USA',
          'W.L. Gore & Associates (Gore) - Founded 1958 - USA',
          'Shimano - Founded 1921 - Japan',
          'Bosch - Founded 1886 - Germany',
        ],
    tags: rtl
      ? [
          'قاعدة عملاء متزايدة',
          'تجربة أفضل',
          'مبيعات فعالة',
          'علامة تجارية سهلة',
          'لا تسويق إضافي',
          'تكلفة منخفضة',
          'وضع يربح فيه الجميع',
          'توعية العملاء',
        ]
      : [
          'Increased Customer Base',
          'Better Experience',
          'Effective Sales',
          'Easy Branding',
          'No Extra Marketing',
          'Low Cost',
          'Win-win Situation',
          'Educate Customer',
        ],
    considerations: t('considerations_bm26'),
    combinations: ['$LBM18'],
    hashTagType: 'businessModelTemp',
  }
}

export default IngredientBrandingBusinessModel
