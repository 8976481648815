import React from 'react'
import withStyles from '@mui/styles/withStyles'
import {
  ShareDialogShareButton,
  SharePostPrivacyOptions,
} from './ShareDialogContentComponents'
import PostPrivacyOptions from './ShareDialogContentComponents/ShareModels/PostPrivacyOptions'

const styles = () => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
  },
})

const ShareDialogTitle = ({ shareSystemProps, classes }) => {
  const { shareTypeIndex, post } = shareSystemProps
  const rtl = document.body.dir === 'rtl'
  const comps = [
    <span className={classes.wrapper}>
      <SharePostPrivacyOptions shareSystemProps={shareSystemProps} />
      <span style={{ marginLeft: !rtl && 10, marginRight: rtl && 10 }}>
        <ShareDialogShareButton shareSystemProps={shareSystemProps} />
      </span>
    </span>,
    <PostPrivacyOptions
      modelType={
        shareTypeIndex === 1 || post?.feedsSystemCategory === 'home'
          ? 'home'
          : 'page'
      }
      shareSystemProps={shareSystemProps}
    />,
  ]
  return comps[shareTypeIndex === 0 || shareTypeIndex === 2 ? 0 : 1]
}
const WrappedShareDialogTitle = withStyles(styles)(ShareDialogTitle)

export default WrappedShareDialogTitle
