import React, { useEffect, useState } from 'react'
import { Grid, Input } from 'react-spreadsheet-grid'
import WindowProps from '../../windowViewer/WindowProps'
import ModelDialogWrappingIII from '../../dialogModelUnit/ModelDialogWrappingIII'
import SPSHeaderEditingDialog from './SpreadsheetGridComps/SPSHeaderEditingDialog'
import SpreadGridAddRowButton from './SpreadsheetGridComps/SpreadGridAddRowButton'
import SpreadGridHeaderIconComp from './SpreadsheetGridComps/SpreadGridHeaderIconComp'

const SpreadsheetGrid = ({
  DCGProps,
  StampValue,
  headers,
  setHeaders,
  rows,
  setRows,
  menuOptions,
  stampType,
  inputValue,
}) => {
  const { isDarkModeActive } = DCGProps
  const { isWindowOpen, windowOpener, windowCloser } = WindowProps()
  const [headerIndex, setHeaderIndex] = useState(0)
  const onFieldChange = (rowId, field, cellId) => (value) => {
    // Find the row that is being changed
    const row = rows.find(({ id }) => id === rowId)

    // Change a value of a field
    row[field] = cellId === 0 ? StampValue(rows.indexOf(row)) : value
    setRows([].concat(rows))
  }
  useEffect(() => {
    rows.map((row, i) => {
      row[`value${0}`] = StampValue(rows.indexOf(row))
      setRows([].concat(rows))
    })
  }, [rows?.length, stampType, inputValue])

  const initColumns = () =>
    headers.map((c, i) => {
      return i === 0
        ? {
            title: () => (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  fontSize: 18,
                  width: '100%',
                }}
              >
                {menuOptions[stampType]?.title}
              </div>
            ),
            value: (row, { focus }) => {
              return (
                <Input
                  value={row[`value${i}`]}
                  focus={focus}
                  onChange={onFieldChange(row.id, `value${i}`, i)}
                />
              )
            },
            readOnly: true,
          }
        : {
            title: () => (
              <SpreadGridHeaderIconComp
                header={c}
                windowOpener={windowOpener}
                setHeaderIndex={setHeaderIndex}
                i={i}
              />
            ),
            value: (row, { focus }) => {
              return (
                <Input
                  value={row[`value${i}`]}
                  focus={focus}
                  onChange={onFieldChange(row.id, `value${i}`)}
                />
              )
            },
          }
    })
  const dialogsArray = [
    {
      open: isWindowOpen,
      content: (
        <SPSHeaderEditingDialog
          windowCloser={windowCloser}
          setHeaders={setHeaders}
          isDarkModeActive={isDarkModeActive}
          headerIndex={headerIndex}
          headers={headers}
        />
      ),
    },
  ]
  return (
    <div
      style={{ fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif" }}
    >
      <Grid
        columns={initColumns()}
        rows={rows}
        getRowKey={(row) => row.id}
      />
      <SpreadGridAddRowButton setRows={setRows} />
      <ModelDialogWrappingIII
        dialogsArray={dialogsArray}
        isDarkModeActive={isDarkModeActive}
        dialogWidth='450px'
        dialogHeight='350px'
      />
    </div>
  )
}

export default SpreadsheetGrid
