import React from 'react'
import ShareSystem from '../sharingSystem/ShareSystem'

const EditingSystem = (props) => {
  const { isPostEditDialogOpen, postEditDialogClose } = props
  const shareSystemProps = {
    ...props,
    disablePostTypeOptions: true,
    shareDialogClose: postEditDialogClose,
    isShareDialogOpen: isPostEditDialogOpen,
  }

  return <ShareSystem shareSystemProps={shareSystemProps} />
}

export default EditingSystem
