import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { MdTrendingUp } from 'react-icons/md'
import HashtagsCounter from '../../../rightbar/rightbar-Components/RightbarChartComponents/ChartProgressiveEngineComponents/HashtagsCounter'
import MultiMillify from '../../../SmallComponents/MultiMillify'

const HashTagResultViewer = ({ newSearchQuerry }) => {
  const counterInput = newSearchQuerry?.slice(1)
  const [count, setCount] = useState(0)
  useEffect(() => {
    const fetchCount = async () => {
      try {
        const res = await axios.get(
          `/searchSystems/hashtags/hashtagCount/?hashtag=${counterInput}`
        )
        setCount(res.data.count)
      } catch (error) {
        console.log(error)
      }
    }
    if (newSearchQuerry.startsWith('#')) fetchCount()
  }, [newSearchQuerry])
  const rtl = document.body.dir === 'rtl'
  return (
    <div style={{ display: newSearchQuerry.startsWith('#') ? null : 'none' }}>
      <section
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          padding: 10,
        }}
      >
        <span
          style={{
            marginRight: !rtl && 10,
            marginLeft: rtl && 10,
            fontWeight: 500,
          }}
        >
          #{counterInput} : {MultiMillify(count)}
        </span>
        <MdTrendingUp size={30} color={'blue'} />
      </section>
    </div>
  )
}

export default HashTagResultViewer
