import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { FaCloudDownloadAlt } from 'react-icons/fa'
import { PDFDownloadLink } from '@react-pdf/renderer'
import PDFDTemplates from '../buttonsSystem/ButtonsSystemComponents/PDFGeneratorButtonComps/PDFDTemplates'
import { Puff } from 'react-loader-spinner'
import { Tooltip } from '@mui/material'

const styles = () => ({
  icon: {
    cursor: 'pointer',
    fontSize: 18,
    '&:hover': {
      opacity: 0.8,
    },
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
})

const DownloadPDF = ({ classes, noFSPanelProps }) => {
  const { showPDFDownloader, isDarkModeActive, element, users, projects } =
    noFSPanelProps
  const fileName =
    element?.title || element?.Title || element?.ProductName || element?.name

  const button = {
    PDFGenProps: {
      element,
      users,
      projects,
      fileName,
    },
  }
  const pdfDocument = PDFDTemplates({ button }).find(
    (temp) => temp?.hashTagType === element?.hashTagType
  )?.comp
  return (
    <Tooltip
      title={`${
        document.body.dir === 'rtl' ? 'حَمِّل' : 'Download'
      } ${fileName}`}
    >
      <span
        className={classes.iconWrapper}
        style={{ display: showPDFDownloader ? null : 'none' }}
      >
        {pdfDocument ? (
          <PDFDownloadLink
            document={pdfDocument}
            fileName={fileName}
            style={{
              textDecoration: 'none',
              marginBottom: -5,
              padding: 0,
              color: isDarkModeActive ? 'white' : 'black',
            }}
          >
            {({ loading }) =>
              loading ? (
                <Puff
                  height='20'
                  width='20'
                  radius={1}
                  color='#4fa94d'
                  ariaLabel='puff-loading'
                  wrapperStyle={{}}
                  wrapperClass=''
                  visible={true}
                />
              ) : (
                <FaCloudDownloadAlt className={classes.icon} />
              )
            }
          </PDFDownloadLink>
        ) : (
          <Puff
            height='20'
            width='20'
            radius={1}
            color='#4fa94d'
            ariaLabel='puff-loading'
            wrapperStyle={{}}
            wrapperClass=''
            visible={true}
          />
        )}
      </span>
    </Tooltip>
  )
}

const WrappedDownloadPDF = withStyles(styles)(DownloadPDF)

export default WrappedDownloadPDF
