import ProjectDepartments from '../../../../../../../newProject/NewProjectComponents/NewBusinessProjectComponents/NewProjectMembersComponents/ProjectDepartmentsArrays'

export default function taskInfoArray({
  task,
  membersProfiles,
  user,
  t,
  pairedMember,
  moderator,
}) {
  return [
    {
      title: 'Title',
      titleValue: task?.Title,
      unEditable: true,
    },
    {
      title: 'Task_Department',
      titleValue: ProjectDepartments().map((dep) => dep.name)[
        task?.departmentId
      ],
      unEditable: true,
    },
    {
      title: 'Pairing',
      titleValue: task.TaskPairedContributorId
        ? pairedMember?.firstName + ' ' + pairedMember?.lastName
        : t('Not_Paired_Yet'),
      unEditable: true,
    },
    {
      title: 'Deadline',
      titleValue: task?.TaskDeadline,
      unEditable:
        user?._id === moderator?._id &&
        (task?.TaskStatus !== 'Done' || task?.TaskStatus !== 'Terminated')
          ? false
          : true,
      type: 'date',
      allowEmpty: true,
    },
    {
      title: 'Moderator',
      titleValue: moderator?.firstName + ' ' + moderator?.lastName,
      unEditable: true,
    },
    {
      title: 'Description',
      titleValue: task.TaskDescription,
      allowEmpty: true,
      wideMode: true,
      minRows: 4,
    },
    {
      title: 'Invest_Method',
      titleValue: t(task?.InvestMethod?.split(' ')?.join('_')),
      unEditable: true,
    },
  ]
}
