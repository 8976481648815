import { useState, useEffect } from 'react'

const FullFormChecker = ({ FormConfig, formData }) => {
  const [isFormDataFilled, setisFormDataFilled] = useState(false)
  useEffect(() => {
    setisFormDataFilled(
      FormConfig.filter(
        (data) =>
          // to test if there is any value for the inputs in the form
          !formData[data.title.split(' ').join('')] &&
          // to exclude that it has a defualt value
          !data.value
      ).length === 0
    )
  })
  return isFormDataFilled
}

export default FullFormChecker
