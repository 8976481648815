import { Grid } from '@mui/material'
import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import WindowSize from '../../SmallComponents/WindowSize'

const styles = () => ({
  image: {
    width: '100%',
    cursor: 'pointer',
    objectFit: 'cover',
  },
  image1: {
    width: '100%',
    cursor: 'pointer',
    objectFit: 'cover',
  },
  gridItem: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column !important',
    height: '405px !important',
  },
  '@media (max-width: 600px)': {
    gridItem: {
      height: '305px !important',
    },
  },
})

const ThreeImagesModel = ({ classes, imagesArray, openFun }) => {
  const rtl = document.body.dir === 'rtl'
  const windowSize = WindowSize()
  return (
    <div>
      <Grid container>
        <Grid item xs={6} style={{ height: '500px !important' }}>
          <LazyLoadImage
            effect='blur'
            src={imagesArray[0]}
            alt='Images Viewer System'
            height={windowSize > 600 ? 407.5 : 307.5}
            className={classes.image1}
            onClick={() => {
              openFun()
            }}
          />
        </Grid>
        <Grid item xs={6} className={classes.gridItem}>
          <LazyLoadImage
            effect='blur'
            height={windowSize > 600 ? 202.5 : 152.5}
            src={imagesArray[1]}
            alt='Images Viewer System'
            style={{
              margin: rtl ? '0 5px 2.5px 0' : '0 0 2.5px 5px',
            }}
            className={classes.image}
            onClick={() => {
              openFun()
            }}
          />
          <LazyLoadImage
            effect='blur'
            height={windowSize > 600 ? 202.5 : 152.5}
            src={imagesArray[2]}
            alt='Images Viewer System'
            style={{
              margin: rtl ? '2.5px 5px 0 0' : '2.5px 0 0 5px',
            }}
            className={classes.image}
            onClick={() => {
              openFun()
            }}
          />
        </Grid>
      </Grid>
    </div>
  )
}

const WrappedThreeImagesModel = withStyles(styles)(ThreeImagesModel)

export default WrappedThreeImagesModel
