import axios from 'axios'
import React, { useEffect, useState } from 'react'
import AccessCardGenerator from '../../../../notificationsSystem/NotificationsComponents/ContractsNotificationsComponents/ContractsNotifsDialogRightComponents/NotificationBodyWithAccessCardComps/AccessCardGenerator'
import TitleHeader from '../../../../SmallComponents/Header'
import Loading from '../../../../SmallComponents/Loading'

const MemberContract = ({ member, DCGProps }) => {
  const { project } = DCGProps
  // not tested yet
  const [contract, setContract] = useState()
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    const fetchContract = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(`/contracts/${member?.contractId}`)
        setContract(res.data.contract)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    if (project?._id && member?._id && member?._id !== project?.userId)
      fetchContract()
  }, [member?._id, project?._id])
  const rtl = document.body.dir === 'rtl'
  return isLoading ? (
    <Loading />
  ) : (
    contract && (
      <>
        <TitleHeader headerText={rtl ? 'الإتفاق' : 'Agreement'} />
        <AccessCardGenerator element={contract} />
      </>
    )
  )
}

export default MemberContract
