import axios from 'axios'
import React, { useEffect, useState } from 'react'
import NavigatorCardsModel from '../../../../../../../navigatorCardsModel/NavigatorCardsModel'
import TitleHeader from '../../../../../../../SmallComponents/Header'
import Loading from '../../../../../../../SmallComponents/Loading'

const ResearchdevelopmentChannels = ({ DCGProps, activity }) => {
  const [channels, setChannels] = useState([])
  const rtl = document.body.dir === 'rtl'
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    const fetchUdeaPosts = async () => {
      setIsLoading(true)
      try {
        const resChannels = await axios.get(
          `/channels/elementChannels/${activity?._id}`
        )
        setChannels(resChannels.data.channels)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    fetchUdeaPosts()
  }, [activity?._id])
  return isLoading ? (
    <Loading />
  ) : (
    channels?.length > 0 && (
      <>
        <TitleHeader headerText={rtl ? 'قنوات' : 'Channels'} />
        <NavigatorCardsModel
          unitsArray={channels}
          {...DCGProps}
          modelUnitProps={{
            imageKey: 'channel',
            windowProps: {
              windowCardName: 'channel',
              windowType: 'channel',
              DCGProps,
            },
          }}
        />
      </>
    )
  )
}

export default ResearchdevelopmentChannels
