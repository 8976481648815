import React from 'react'
import PostProps from './PostProps'
import PostBodyModel from '../../../leftbarDirectoryComponents/feedsModels/PostBodyModel'
import TextPost from '../../../postSystem/TextPost'
import HomePostConatinerModel from './HomePostConatinerModel'
import HomePostBottom from '../commonPostComponents/HomePostBottom'

const HomeGenerlPostModel = ({ postProps }) => {
  const { post, isDarkModeActive, socket } = postProps
  return (
    <HomePostConatinerModel
      postProps={{
        ...postProps,
        postBody: (
          <PostBodyModel
            postBodyProps={{
              post,
              commentingSystemView: true,
              ...PostProps({}, { commentsDialogProps: true }),
              isDarkModeActive,
              socket,
            }}
          />
        ),
        postText: <TextPost {...postProps} isCommentingSystemActive={true} />,
      }}
      mainBody={
        <>
          <PostBodyModel
            postBodyProps={{
              ...postProps,
              ...PostProps(
                {},
                {
                  commentsDialogProps: true,
                }
              ),
            }}
          />
          <HomePostBottom
            postProps={{
              ...postProps,
              postBody: (
                <PostBodyModel
                  postBodyProps={{
                    post,
                    commentingSystemView: true,
                    isDarkModeActive,
                    socket,
                  }}
                />
              ),
              postText: <TextPost {...postProps} noShadowedText={true} />,
            }}
          />
        </>
      }
    />
  )
}

export default HomeGenerlPostModel
