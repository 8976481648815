import { useTranslation } from 'react-i18next'

const SolutionProviderBusinessModel = () => {
  const { t } = useTranslation('SolutionProviderBusinessModel')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 53,
    title: t('title_bm53'),
    subTitle: t('subTitle_bm53'),
    main: t('main_bm53'),
    when: t('when_bm53'),
    who: t('who_bm53'),
    what: t('what_bm53'),
    how: t('how_bm53'),
    why: t('why_bm53'),
    examples: rtl
      ? [
          'ماكينات طباعة هايدلبرج - 1852 - ألمانيا',
          'منسوجات لانتال - 1921 - سويسرا',
          'Würth - 1945 - ألمانيا',
          'تترا باك - 1944 - سويسرا',
          'Best Buy’s Geek Squad - 1994 - الولايات المتحدة',
        ]
      : [
          'Heidelberg Printing Machines - 1852 - Germany',
          'Lantal Textiles - 1921 - Switzerland',
          'Würth - 1945 - Germany',
          'Tetra Pak - 1944 - Switzerland',
          'Best Buy’s Geek Squad - 1994 - United States',
        ],
    tags: rtl
      ? [
          'تطور الأعمال',
          'التغطية الكاملة',
          'خدمات متكاملة',
          'ولاء العميل',
          'رضا العملاء',
          'إيرادات متسقة',
          'على مدار الساعة',
          'استكشاف الأخطاء وإصلاحها',
          'خدمة العملاء',
          'ما بعد البيع',
        ]
      : [
          'Business Evolution',
          'Total Coverage',
          'Integrated Services',
          'Customer Loyalty',
          'Customer Satisfaction',
          'Consistent Revenues',
          'Around the Clock',
          'Troubleshooting',
          'Customer Service',
          'After-Sales',
        ],
    considerations: t('considerations_bm53'),
    combinations: ['$LBM27'],
    hashTagType: 'businessModelTemp',
  }
}

export default SolutionProviderBusinessModel
