import { useTranslation } from 'react-i18next'

const MakeMoreofIt = () => {
  const { t } = useTranslation('MakeMoreofIt')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 33,
    title: t('title_bm33'),
    subTitle: t('subTitle_bm33'),
    main: t('main_bm33'),
    when: t('when_bm33'),
    who: t('who_bm33'),
    what: t('what_bm33'),
    how: t('how_bm33'),
    why: t('why_bm33'),
    examples: rtl
      ? [
          'بورش - تأسست عام 1931 - ألمانيا',
          'سولزر - تأسست عام 1834 - سويسرا',
          'مجموعة فيستو - تأسست عام 1925 - ألمانيا',
          'أمازون - تأسست عام 1994 - الولايات المتحدة',
          'BASF - تأسست عام 1865 - ألمانيا',
          'Sennheiser Electronic GmbH & Co. KG (Sennheiser) - تأسست عام 1945 - ألمانيا',
        ]
      : [
          'Porsche - Founded 1931 - Germany',
          'Sulzer - Founded 1834 - Switzerland',
          'Festo Group - Founded 1925 - Germany',
          'Amazon - Founded 1994 - United States',
          'BASF - Founded 1865 - Germany',
          'Sennheiser Electronic GmbH & Co. KG (Sennheiser) - Founded 1945 - Germany',
        ],
    tags: rtl
      ? [
          'عائدات ثابتة',
          'تدفق الإيرادات الإضافية',
          'إعادة التدوير الموارد',
          'الاستثمار المعرفي',
        ]
      : [
          'Steady Revenues',
          'Extra Revenue Streams',
          'Recycling Resources',
          'Knowledge Investment',
        ],
    considerations: t('considerations_bm33'),
    combinations: ['$LBM30', '$LBM28'],
    hashTagType: 'businessModelTemp',
  }
}

export default MakeMoreofIt
