import { useTranslation } from "react-i18next"

const RetentionAsLoyalty = () => {
  const { t } = useTranslation('RetentionAsLoyalty')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 40,
    category: t('category_ms40'),
    title: t('title_ms40'),
    subTitle: t('subTitle_ms40'),
    main: t('main_ms40'),
    how: t('how_ms40'),
    usedInModels: [],
    tags: [],
    examples: t('examples_ms40'),
    hashTagType: 'markStraAspect',
  }
}

export default RetentionAsLoyalty
