import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../../../../../context/AuthContext'
import ButtonsSystem from '../../../../../../buttonsSystem/ButtonsSystem'
import NotificationCreator from '../../../../../../notificationsSystem/NotificationCreator'
import IssueDownVoteButtonConfig from './IssueInteractionButtonsComps/IssueDownVoteButtonConfig'
import IssueUpVoteButtonConfig from './IssueInteractionButtonsComps/IssueUpVoteButtonConfig'

const IssueInteractionButtons = ({ postMode, issue, DCGProps }) => {
  const { user } = useContext(AuthContext)
  const { socket } = DCGProps
  const rtl = document.body.dir === 'rtl'
  const confirmmationTitles = [
    {
      title: rtl
        ? 'تم إرسال رأيك دون الإفصاح عن هويتك'
        : 'Your Opinion is sent anynoumuosly',
    },
  ]

  const [members, setMembers] = useState([])
  useEffect(() => {
    const fetchCeoIds = async () => {
      try {
        const res = await axios.get(
          `/contracts/contractProjectsCeos/${issue?.contractId}`
        )
        setMembers(res.data.ceosIds)
      } catch (error) {
        console.log(error)
      }
    }
    if (issue?.contractId) fetchCeoIds()
  }, [issue?.contractId])

  const [isDownvoted, setIsDownvoted] = useState(
    issue?.downvotes.includes(user?._id)
  )
  const [isUpvoted, setIsUpvoted] = useState(issue?.upvotes.includes(user?._id))
  const activeFun = async ({ relation, conditionIndex }) => {
    try {
      Promise.all(
        members?.map(async (m) => {
          socket.current.emit('addNotifUser', m)
          try {
            await axios.delete('/notifications/customDelete/vote', {
              headers: {},
              data: {
                userId: m,
                associativity: 'issue',
                relation,
                conditionIndex,
                category: 'deals',
                issueId: issue?._id,
              },
            })
          } catch (error) {
            console.log(error)
          }
          NotificationCreator({
            associativity: 'issue',
            relation,
            conditionIndex,
            accompaniedData: {
              // this should be here as a good indicator
              issueId: issue?._id,
              elements: [
                {
                  hashTagType: issue?.hashTagType,
                  id: issue?._id,
                  title: issue?.Title,
                },
              ],
            },
            category: 'deals',
            userId: m,
            currentUser: user,
            socket,
          })
        })
      )
    } catch (error) {
      console.log(error)
    }
  }
  // the upvoting or downvoting will be with regard to the issue publisher only
  // these values will affect the network
  const buttonsArray = [
    IssueUpVoteButtonConfig({
      activeFun,
      issue,
      DCGProps,
      confirmmationTitles,
      isDownvoted,
      setIsDownvoted,
      isUpvoted,
      setIsUpvoted,
    }),
    IssueDownVoteButtonConfig({
      activeFun,
      issue,
      DCGProps,
      confirmmationTitles,
      isDownvoted,
      setIsDownvoted,
      isUpvoted,
      setIsUpvoted,
    }),
  ]
  return (
    <ButtonsSystem buttonsArray={buttonsArray} hide={!postMode} {...DCGProps} />
  )
}

export default IssueInteractionButtons
