const ResearchActivityFormConfigs = () => {
  const rtl = document.body.dir === 'rtl'
  return [
    {
      title: 'Title',
      formComponentType: 'text',
      value: '',
    },
    {
      title: 'Purpose',
      formComponentType: 'text',
      multiLine: true,
      value: '',
      placeholder: rtl
        ? 'حدد الغرض من هذا النشاط'
        : 'Define the purpose of this activity',
      minRows: 5,
    },
  ]
}

export default ResearchActivityFormConfigs
