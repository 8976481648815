import React from 'react'

const NoElementsModel = ({ elements }) => {
  return (
    <section
      style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
    >
      <span style={{ fontWeight: 500, margin: 20 }}>
        {document.body.dir === 'rtl'
          ? `لا يوجد ${elements} بعد`
          : `No ${elements} yet`}
      </span>
    </section>
  )
}

export default NoElementsModel
