import React, { useContext } from 'react'
import withStyles from '@mui/styles/withStyles'
import ButtonsSystem from '../../../../../buttonsSystem/ButtonsSystem'
import { AuthContext } from '../../../../../../context/AuthContext'

const styles = () => ({
  memberDetailsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  nameAndContractId: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 5,
    padding: 10,
    borderRadius: '10px !important',
    backgroundColor: 'rgba(217,224,252, 0.1) ',
    height: '100%',
  },
  contributorData: {
    fontSize: 12,
    height: 50,
    fontWeight: 500,
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  memberName: {
    fontWeight: 500,
  },
})

const MemberDetailsUnitModel = ({
  classes,
  member,
  hideProfileView,
  actions,
  isDarkModeActive,
  buttonsArray,
}) => {
  const { user } = useContext(AuthContext)
  const rtl = document.body.dir === 'rtl'
  const modButArr = buttonsArray?.map((b) => ({
    ...b,
    funs: () => {
      b?.funs(member)
    },
  }))
  const locButtonsArray = hideProfileView
    ? [].concat(modButArr || [])
    : [
        {
          title: rtl ? 'عرض' : ' View',
          funs: actions,
          disabled: member?._id === user?._id || member?.id === user?._id,
        },
      ].concat(modButArr || [])
  return (
    <section className={classes.memberDetailsWrapper}>
      <span className={classes.nameAndContractId}>
        <ButtonsSystem
          buttonsArray={locButtonsArray}
          isDarkModeActive={isDarkModeActive}
        />
        <span className={classes.memberName}>{member.name}</span>
        <span
          className={classes.contributorData}
          style={{ textAlign: 'center' }}
        >
          {member.departmentManager
            ? rtl
              ? 'مدير القسم'
              : 'Department Manager'
            : member.subDepManager
            ? rtl
              ? 'مدير قسم فرعى'
              : 'Subdepartment Manager'
            : member.RuleTitle}
        </span>
      </span>
    </section>
  )
}

const WrappedMemberDetailsUnitModel = withStyles(styles)(MemberDetailsUnitModel)

export default WrappedMemberDetailsUnitModel
