import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '../../../../../../../context/AuthContext'
import ExpandedCardsSystem from '../../../../../../expandedCardsSystem/ExpandedCardsSystem'
import TitleHeader from '../../../../../../SmallComponents/Header'
import MultiLangNumber from '../../../../../../SmallComponents/MultiLangNumber'
import ResourceRequestDetails from './ResourceRequestsComps/ResourceRequestDetails'
import Loading from '../../../../../../SmallComponents/Loading'

const ResourceRequests = ({ hideREsourceRequests, DCGProps, resource }) => {
  const [requests, setRequests] = useState([])
  const [requestsUsers, setRequestsUsers] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const { user } = useContext(AuthContext)
  const { t } = useTranslation()
  useEffect(() => {
    const fetchRequests = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(
          `/requests/elementRequests/${resource?._id}`
        )
        setRequests(res.data.requests)
        setRequestsUsers(res.data.requestsUsers)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    fetchRequests()
  }, [user?._id])
  return isLoading ? (
    <Loading />
  ) : (
    <span style={{ display: hideREsourceRequests && 'none' }}>
      <TitleHeader
        headerText={`${MultiLangNumber(requests?.length)} ${t('Requests')}`}
      />
      <ExpandedCardsSystem
        cards={requests}
        UnitCardDetailsComponent={ResourceRequestDetails}
        firsButtonConfigs={{
          hideButton: true,
          props: { resource },
        }}
        DCGProps={DCGProps}
        searchSystemProps={{}}
        requestsUsers={requestsUsers}
        setRequests={setRequests}
      />
    </span>
  )
}

export default ResourceRequests
