import React from 'react'
import withStyles from '@mui/styles/withStyles'
import MemberOptionUnitItem from './MemberOptionUnitItem'

const styles = () => ({
  optionsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-around',
  },
})

const MemberOptionUnit = ({ classes, ...props }) => {
  const { memberOptions, hideMemberOptions } = props
  return (
    <article
      className={classes.optionsWrapper}
      style={{
        display:
          hideMemberOptions || typeof memberOptions !== 'object'
            ? 'none'
            : null,
      }}
    >
      {memberOptions?.map((option, index) => (
        <MemberOptionUnitItem option={option} key={index} {...props} />
      ))}
    </article>
  )
}

const WrappedMemberOptionUnit = withStyles(styles)(MemberOptionUnit)

export default WrappedMemberOptionUnit
