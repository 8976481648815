import { useTranslation } from "react-i18next"

const ConsultingAndProfessionalServices = () => {
  const { t } = useTranslation('ConsultingAndProfessionalServices')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 80,
    title: t('title_bm80'),
    subTitle: t('subTitle_bm80'),
    main: t('main_bm80'),
    when: t('when_bm80'),
    who: t('who_bm80'),
    what: t('what_bm80'),
    how: t('how_bm80'),
    why: t('why_bm80'),
    examples: rtl
      ? [
          'McKinsey & Company - تأسست عام 1926 - الولايات المتحدة الأمريكية',
          'Deloitte - تأسست عام 1845 - المملكة المتحدة',
          'SAP - تأسست عام 1972 - ألمانيا',
          'Catalant - تأسست عام 2013 - الولايات المتحدة الأمريكية',
          'Wonder - تأسست عام 2015 - الولايات المتحدة الأمريكية',
        ]
      : [
          'McKinsey & Company - Founded in 1926 - USA',
          'Deloitte - Founded in 1845 - UK',
          'SAP - Founded in 1972 - Germany',
          'Catalant - Founded in 2013 - USA',
          'Wonder - Founded in 2015 - USA',
        ],
    tags: rtl
      ? [
          'نموذج استشاري',
          'خدمات احترافية',
          'نموذج العمل',
          'السوق المستهدف',
          'نموذج النجاح',
          'تطبيق النموذج',
          'نماذج النموذج',
        ]
      : [
          'Consulting Model',
          'Professional Services',
          'Business Model',
          'Target Market',
          'Model Success',
          'Model Application',
          'Model Examples',
        ],
    considerations: t('considerations_bm80'),
    combinations: [],
    hashTagType: 'businessModelTemp',
  }
}

export default ConsultingAndProfessionalServices
