import { Avatar, ListItemText, MenuItem, Select } from '@mui/material'
import React from 'react'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  select: {
    fontSize: '12px !important',
    appearance: 'none !important',
    outline: 'none !important',
    width: '150px !important',
  },
  menuContractorWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '10px',
  },
  contractorName: {
    fontSize: 12,
  },
})

const SelectProfileAvatarModel = ({
  classes,
  setClickAwayState,
  isDarkModeActive,
  elements,
  selectedElements,
  choosingElements,
}) => {
  const rtl = document.body.dir === 'rtl'
  return (
    <Select
      value={selectedElements}
      onChange={choosingElements}
      className={classes.select}
      variant='standard'
      onOpen={() => setClickAwayState(true)}
      style={{ color: isDarkModeActive ? 'white' : null }}
      renderValue={(element) => (
        <ListItemText
          primary={
            element.name ? (
              <span className={classes.menuContractorWrapper}>
                <Avatar src={element.image} style={{ height: 30, width: 30 }} />
                <span
                  className={classes.contractorName}
                  style={{
                    marginLeft: !rtl && '5px',
                    marginRight: rtl && '5px',
                  }}
                >
                  {element.name}
                </span>
              </span>
            ) : rtl ? (
              'لم يتم الاختيار بعد'
            ) : (
              'No Choice Yet'
            )
          }
        />
      )}
    >
      {elements?.map((element, index) => (
        <MenuItem key={index} value={element}>
          <ListItemText
            primary={
              <span className={classes.menuContractorWrapper}>
                <Avatar src={element.image} style={{ height: 30, width: 30 }} />
                <span
                  className={classes.contractorName}
                  style={{
                    marginLeft: !rtl && '5px',
                    marginRight: rtl && '5px',
                  }}
                >
                  {element.name}
                </span>
              </span>
            }
          />
        </MenuItem>
      ))}
    </Select>
  )
}

const WrappedSelectProfileAvatarModel = withStyles(styles)(
  SelectProfileAvatarModel
)

export default WrappedSelectProfileAvatarModel
