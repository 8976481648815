import { Button } from '@mui/material'
import React from 'react'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  confirmButton: {
    backgroundColor: 'rgb(75, 7, 7) !important',
    borderRadius: '10px  !important',
    margin: '2px  !important',
    textTransform: 'none  !important',
    color: 'white  !important',
    fontWeight: '550  !important',
    display: 'flex',
    '&:hover': {
      opacity: 0.8,
    },
  },
  cancelButton: {
    backgroundColor: 'rgb(7, 6, 65) !important',
    borderRadius: '10px  !important',
    margin: '2px  !important',
    textTransform: 'none  !important',
    color: 'white  !important',
    fontWeight: '550  !important',
    display: 'flex',
    '&:hover': {
      opacity: 0.8,
    },
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

const DeleteButtons = ({
  classes,
  confirmDeleteDialogClose,
  confirmDeleteAction,
  deletedObjectId,
}) => {
  const rtl = document.body.dir === 'rtl'
  return (
    <span className={classes.buttonWrapper}>
      <Button
        variant='contained'
        className={classes.cancelButton}
        onClick={() => {
          confirmDeleteDialogClose()
        }}
      >
        {rtl ? 'إلغاء' : 'Cancel'}
      </Button>
      <Button
        variant='contained'
        className={classes.confirmButton}
        onClick={(event) => {
          confirmDeleteAction(event)
          confirmDeleteDialogClose()
        }}
        id={deletedObjectId}
      >
        {rtl ? 'تأكيد' : 'Confirm'}
      </Button>
    </span>
  )
}

const WrappedDeleteButtons = withStyles(styles)(DeleteButtons)

export default WrappedDeleteButtons
