import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ExpandedCardsSystem from '../../../../../../expandedCardsSystem/ExpandedCardsSystem'
import CustomCircularProgress from '../../../../../../SmallComponents/CustomCircularProgress'
import TitleHeader from '../../../../../../SmallComponents/Header'
import MultiLangNumber from '../../../../../../SmallComponents/MultiLangNumber'
import CampaignOfferDetails from './CampaignSpsonsiringOffersComps/CampaignOfferDetails'

const CampaignSponsoringOffers = ({ DCGProps, campaign }) => {
  const { t } = useTranslation()
  const [requests, setRequests] = useState([])
  const [requestsUsers, setRequestsUsers] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    const fetchRequests = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(
          `/requests/elementRequests/${campaign?._id}`
        )
        setRequests(res.data.requests)
        setRequestsUsers(res.data.requestsUsers)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    fetchRequests()
  }, [campaign?._id])
  return isLoading ? (
    <CustomCircularProgress />
  ) : (
    <span>
      <TitleHeader
        headerText={`${MultiLangNumber(requests?.length)} ${t('Requests')}`}
      />
      <ExpandedCardsSystem
        cards={requests}
        UnitCardDetailsComponent={CampaignOfferDetails}
        firsButtonConfigs={{
          hideButton: true,
          props: {
            campaign,
          },
        }}
        searchSystemProps={{}}
        DCGProps={DCGProps}
        requestsUsers={requestsUsers}
        setRequests={setRequests}
      />
    </span>
  )
}

export default CampaignSponsoringOffers
