import React from 'react'
import withStyles from '@mui/styles/withStyles'
import SimpleSelectModel from './SimpleSelectModel'
import SimpleInputModel from './SimpleInputModel'
import RadioButtonsModel from './RadioButtonsModel'
import DateInputModel from './DateInputModel'
import TimeInputModel from './TimeInputModel'
import { useTranslation } from 'react-i18next'

const styles = () => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
    margin: '10px auto',
    borderRadius: '10px !important',
    backgroundColor: 'rgba(217,224,252, 0.2) ',
  },
  radioWrapper: {
    width: '100%',
    backgroundColor: 'rgba(217,224,252, 0.1) ',
    borderRadius: 10,
    margin: '10px auto 10px auto',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontWeight: 500,
    fontSize: 15,
  },
  radioTitle: {
    borderRadius: 10,
    fontWeight: 500,
    fontSize: 15,
    padding: '5px 5px 5px 15px',
    margin: 10,
    backgroundColor: 'rgba(217,224,252, 0.3) ',
  },
  component: {},
  '@media (max-width: 600px)': {
    title: {
      fontWeight: 400,
      fontSize: 13,
    },
    radio: {
      margin: 5,
    },
  },
})

const FormComponent = ({ classes, modelProps }) => {
  const { element, elementIndex, props, customComponent } = modelProps
  const { SelectComponent, TextComponent, RadioComponent, DateComponent } =
    customComponent
  const allComps = [
    {
      formComponentType: 'select',
      Component: SelectComponent,
      ActiveComponent: <SimpleSelectModel {...modelProps} />,
    },
    {
      formComponentType: 'text',
      Component: TextComponent,
      ActiveComponent: <SimpleInputModel {...modelProps} />,
    },
    {
      formComponentType: 'radio',
      Component: RadioComponent,
      ActiveComponent: <RadioButtonsModel {...modelProps} />,
    },
    {
      formComponentType: 'date',
      Component: DateComponent,
      ActiveComponent: <DateInputModel {...modelProps} />,
    },
    {
      formComponentType: 'time',
      Component: DateComponent,
      ActiveComponent: <TimeInputModel {...modelProps} />,
    },
  ]
  const currentComp = allComps.filter(
    (comp) => comp.formComponentType === element.formComponentType
  )[0]
  const { Component, ActiveComponent } = currentComp
  const radioCondition = element.formComponentType === 'radio'
  const { t } = useTranslation()
  return Component ? (
    <Component {...modelProps} component={ActiveComponent} />
  ) : (
    <section
      className={radioCondition ? classes.radioWrapper : classes.wrapper}
      style={{
        flexDirection: element?.multiLine && 'column',
        alignContent: !element?.multiLine && 'center',
        alignItems: !element?.multiLine && 'center',
      }}
    >
      <span className={radioCondition ? classes.radioTitle : classes.title}>
        {t(element.title)}
      </span>
      <span className={radioCondition ? classes.radio : classes.component}>
        {ActiveComponent}
      </span>
    </section>
  )
}

const WrappedFormComponent = withStyles(styles)(FormComponent)

export default WrappedFormComponent
