import React from 'react'
import AllResults from './SearchResultsComponents/AllResults'
import SearchDefaultView from './SearchResultsComponents/SearchDefaultView'
import withStyles from '@mui/styles/withStyles'
import HashTagResultViewer from './SearchResultsComponents/HashTagResultViewer'
import NoSearchSystemResults from './NoSearchSystemResults'
import SearchSystemLoader from './SearchSystemLoader'

const styles = () => ({
  sectionsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 10,
    backgroundColor: 'rgba(217,224,252, 0.2) ',
    paddingBottom: 10,
  },
  noResultWrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center',
    borderRadius: 10,
    backgroundColor: 'rgba(217,224,252, 0.2) ',
  },
  '@media (max-width: 600px)': {
    sectionsWrapper: {
      width: '100%',
    },
  },
})

const SearchResults = ({ classes, searchProps }) => {
  const {
    newSearchQuerry,
    isDarkModeActive,
    posts,
    isLoading,
    page,
    projects,
    users,
    resultSectionId,
    elementHashTag,
  } = searchProps

  const postFilter = (ps, resultSectionId) => {
    return ps?.filter(
      (p) =>
        p?.postElement?.hashTagType ===
        elementHashTag?.find((e) => e?.resultSectionId === resultSectionId)
          ?.hashTagType
    )
  }

  const results =
    resultSectionId === 0
      ? [].concat(posts, projects, users)
      : resultSectionId === 2
      ? users
      : resultSectionId === 3
      ? projects
      : postFilter(posts, resultSectionId)

  return isLoading && page === 1 ? (
    <SearchSystemLoader {...searchProps} />
  ) : newSearchQuerry.length > 0 ? (
    results?.length > 0 ? (
      <article
        className={classes.sectionsWrapper}
        style={{
          color: isDarkModeActive ? 'white' : null,
        }}
      >
        <HashTagResultViewer {...searchProps} results={results} />
        <AllResults searchProps={searchProps} results={results} />
      </article>
    ) : (
      <section
        className={classes.noResultWrapper}
        style={{
          color: isDarkModeActive ? 'white' : null,
        }}
      >
        <NoSearchSystemResults searchQuery={newSearchQuerry} />
      </section>
    )
  ) : (
    <SearchDefaultView {...searchProps} />
  )
}

const WrappedSearchResults = withStyles(styles)(SearchResults)

export default WrappedSearchResults
