import React, { useContext, useEffect, useState } from 'react'
import { AiFillAppstore, AiOutlineAppstoreAdd } from 'react-icons/ai'
import withStyles from '@mui/styles/withStyles'
import NotificationCreator from '../../../../notificationsSystem/NotificationCreator'
import { AuthContext } from '../../../../../context/AuthContext'
import axios from 'axios'

const styles = () => ({
  projectIconsandText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
  },
  projectsIcons: {
    fontSize: 25,
    cursor: 'pointer',
  },
  projectIconsText: {
    fontWeight: 500,
    padding: 4,
    fontSize: 15,
  },
  '@media (max-width: 600px)': {
    projectIconsText: {
      display: 'none',
    },
  },
})

const ProjectFollowButton = ({ classes, projectProps }) => {
  const { project, projectAdmins, socket } = projectProps
  const { user, dispatch } = useContext(AuthContext)

  const [follow, setFollow] = useState(
    user.followedProjects.includes(project?._id)
  )
  useEffect(() => {
    setFollow(user.followedProjects.includes(project?._id))
  }, [user.followedProjects, project?._id])
  const rtl = document.body.dir === 'rtl'
  const followIcons = [
    <>
      <AiOutlineAppstoreAdd className={classes.projectsIcons} />
      <span className={classes.projectIconsText}>
        {rtl ? 'متابعة' : 'Follow'}
      </span>
    </>,
    <>
      <AiFillAppstore className={classes.projectsIcons} />
      <span className={classes.projectIconsText}>
        {rtl ? 'الغاء المتابعة' : 'Unfollow'}
      </span>
    </>,
  ]
  const projectId = project?._id
  const onFollow = async () => {
    if (project?._id) {
      try {
        if (follow) {
          await axios.patch(`/projects/${project?._id}/unfollow`, {
            userId: user?._id,
          })
          setFollow(!follow)
          dispatch({
            type: 'UNFOLLOW_PROJECT',
            payload: projectId,
          })
        } else {
          await axios.patch(`/projects/${project?._id}/follow`, {
            userId: user?._id,
          })
          setFollow(!follow)
          projectAdmins.map((admin) =>
            NotificationCreator({
              associativity: 'project',
              relation: 'admin',
              conditionIndex: 0,
              accompaniedData: {
                projectId,
              },
              category: 'contributors',
              userId: admin?._id,
              currentUser: user,
              socket,
            })
          )
          dispatch({
            type: 'FOLLOW_PROJECT',
            payload: projectId,
          })
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
  return (
    <span className={classes.projectIconsandText} onClick={onFollow}>
      {follow ? followIcons[1] : followIcons[0]}
    </span>
  )
}

const WrappedProjectFollowButton = withStyles(styles)(ProjectFollowButton)

export default WrappedProjectFollowButton
