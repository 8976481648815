import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '../../../../../../../../context/AuthContext'
import ExpandedCardsSystem from '../../../../../../../expandedCardsSystem/ExpandedCardsSystem'
import TitleHeader from '../../../../../../../SmallComponents/Header'
import Loading from '../../../../../../../SmallComponents/Loading'
import MultiLangNumber from '../../../../../../../SmallComponents/MultiLangNumber'
import InquiryRequestDetails from './InquiryRequestsComps/InquiryRequestDetails'

const InquiryRequests = ({ hideInquiryRequests, DCGProps, inquiry }) => {
  //  any contribution requests and the content of the contribution request may be some more ideas or something added
  const { t } = useTranslation()
  const { user } = useContext(AuthContext)
  const { project } = DCGProps
  const [requests, setRequests] = useState([])
  const [requestsUsers, setRequestsUsers] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    const fetchRequests = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(`/requests/elementRequests/${inquiry?._id}`)
        setRequests(res.data.requests)
        setRequestsUsers(res.data.requestsUsers)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    if (inquiry?._id) fetchRequests()
  }, [project?._id])
  return isLoading ? (
    <Loading />
  ) : (
    <div
      style={{
        display:
          (hideInquiryRequests || user?._id !== inquiry?.userId) && 'none',
      }}
    >
      <TitleHeader
        headerText={`${MultiLangNumber(requests?.length)} ${t('Requests')}`}
      />
      <ExpandedCardsSystem
        cards={requests}
        UnitCardDetailsComponent={InquiryRequestDetails}
        firsButtonConfigs={{
          hideButton: true,
          props: {
            inquiry,
          },
        }}
        searchSystemProps={{}}
        DCGProps={DCGProps}
        requestsUsers={requestsUsers}
        setRequests={setRequests}
      />
    </div>
  )
}

export default InquiryRequests
