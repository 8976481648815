import axios from 'axios'
import React, { useEffect, useState } from 'react'
import ButtonsSystem from '../../../../../../buttonsSystem/ButtonsSystem'

const AspectButtons = ({ hideAspectButtons, DCGProps, aspect }) => {
  const { project } = DCGProps
  const [customStrategy, setCustomStrategy] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const rtl = document.body.dir === 'rtl'
  useEffect(() => {
    const fetchCustomStrategy = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(
          `/projectsMarketingStratgies/${project?._id}`
        )
        setCustomStrategy(res.data.strategy)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
 if (project?._id) fetchCustomStrategy()
  }, [project?._id])
  const [clicked, setClicked] = useState(
    customStrategy?.modelTempsIds?.includes(aspect?.id)
  )
  useEffect(() => {
    setClicked(customStrategy?.modelTempsIds?.includes(aspect?.id))
  }, [aspect?.id, customStrategy?.modelTempsIds])
  const buttonsArray = [
    {
      title: rtl ? 'أضف الى النماذج الفعالة' : 'Add to Active Templates',
      funs: async () => {
        if (!customStrategy?.modelTempsIds?.includes(aspect?.id)) {
          setClicked(true)
          try {
            await axios.patch(`/projectsMarketingStratgies/${project?._id}`, {
              modelTempsIds: [...customStrategy?.modelTempsIds, aspect?.id],
            })
          } catch (error) {
            console.log(error)
          }
        }
      },
      disabled: clicked,
    },
    {
      title: rtl ? 'أزل من النماذج الفعالة' : 'Remove from Active Templates',
      funs: async () => {
        if (customStrategy?.modelTempsIds?.includes(aspect?.id)) {
          setClicked(false)
          try {
            await axios.patch(`/projectsMarketingStratgies/${project?._id}`, {
              modelTempsIds: customStrategy?.modelTempsIds.filter(
                (id) => aspect?.id !== id
              ),
            })
          } catch (error) {
            console.log(error)
          }
        }
      },
      disabled: !clicked,
    },
  ]
  return (
    !isLoading && (
      <ButtonsSystem
        buttonsArray={buttonsArray}
        {...DCGProps}
        hide={hideAspectButtons}
      />
    )
  )
}

export default AspectButtons
