import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { Button } from '@mui/material'
import Resizer from 'react-image-file-resizer'
import { v4 as uuidv4 } from 'uuid'
import uploadImage from '../../../../firebase/uploadImage'
import { MdFireplace } from 'react-icons/md'
import deleteFile from '../../../../firebase/deleteFile'

const styles = () => ({
  text: {
    color: 'black',
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    fotnSize: 16,
    fontWeight: 600,
    '&:hover': {
      fotnSize: 18,
    },
    flexDirection: 'row',
    justifyContent: 'space-around',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
  },
  labelButton: {
    textTransform: 'none !important',
    backgroundColor: 'transparent !important',
    '&:hover': {
      opacity: '0.8 !important',
      backgroundColor: 'gray !important',
    },
  },
})

const ImageUpdatorUpdateOption = ({
  classes,
  ResizerProps,
  pathName,
  setImage,
  remImage,
  setIsLoading,
}) => {
  const rtl = document.body.dir === 'rtl'
  const {
    maxWidth, // Is the maxWidth of the resized new image.
    maxHeight, // Is the maxHeight of the resized new image.
    compressFormat, // Is the compressFormat of the resized new image.
    quality, // Is the quality of the resized new image.
  } = ResizerProps
  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        maxWidth,
        maxHeight,
        compressFormat || 'JPEG',
        quality || 100,
        0,
        (uri) => {
          resolve(uri)
        },
        'base64'
      )
    })
  const imageHandler = async (event) => {
    setIsLoading(true)
    const file = event.target.files[0]
    try {
      const uri = await resizeFile(file)
      if (uri) {
        const imageName = uuidv4() + '.' + file?.name?.split('.')?.pop()
        const url = await uploadImage(uri, `${pathName}/${imageName}`)
        setImage(url)
        if (remImage) await deleteFile(remImage)
      }
    } catch (error) {
      console.log(error)
    }
    setIsLoading(false)
  }
  return (
    <Button component='label' color='inherit' className={classes.labelButton}>
      <section className={classes.text}>
        <MdFireplace
          size={20}
          style={{ marginRight: !rtl && 10, marginLeft: rtl && 10 }}
        />
        {rtl
          ? remImage
            ? 'تحديث'
            : 'رفع صورة'
          : remImage
          ? 'Update'
          : 'Upload'}
      </section>
      <input
        type='file'
        hidden
        onChange={imageHandler}
        accept='image/png, image/gif, image/jpeg, image/jpg'
      />
    </Button>
  )
}

const WrappedImageUpdatorUpdateOption = withStyles(styles)(
  ImageUpdatorUpdateOption
)

export default WrappedImageUpdatorUpdateOption
