import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import EditableGridModel from '../../formsSystem/EditableGridModel'
import TrustDistrustButtons from '../../profile/ProfileComponents/ProfileTopComponents/ProfileOverviewComponents/TrustDistrustButtons'
import MultiMillify from '../../SmallComponents/MultiMillify'
import { AuthContext } from '../../../context/AuthContext'

const NetworkBasicInfo = (props) => {
  const { projectIndicator, project, user, portfolio, ceo } = props
  const { user: currentUser } = useContext(AuthContext)
  const userStateInfo = [
    {
      title: 'Name',
      titleValue: user?.firstName + ' ' + user?.lastName,
    },
    {
      title: 'Trust_Opinions',
      titleValue: MultiMillify(portfolio?.trust?.length),
    },
    {
      title: 'DisTrust_Opinions',
      titleValue: MultiMillify(portfolio?.distrust?.length),
    },
  ]
  const rtl = document.body.dir === 'rtl'
  const projectStateInfo = [
    {
      title: rtl ? 'المُؤَسِسْ' : 'Creator',
      titleValue: (
        <Link to={project?.userId}>{ceo?.firstName + ' ' + ceo?.lastName}</Link>
      ),
    },
  ]

  const gridProps = {
    hideEditingTools: true,
    headerText: rtl ? 'معلومات أساسية' : 'Basic Information',
    infoArray: projectIndicator ? projectStateInfo : userStateInfo,
    middleHeader: true,
    ...props,
  }
  return (
    <>
      <EditableGridModel gridProps={gridProps} />
      {!projectIndicator && user?._id !== currentUser?._id && (
        <TrustDistrustButtons {...props} />
      )}
    </>
  )
}

export default NetworkBasicInfo
