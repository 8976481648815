import React from 'react'
import withStyles from '@mui/styles/withStyles'
import SearchEngine from '../../searchSystem/SearchEngine'
import NoFileSystemPanel from '../NoFileSystemPanel'
import MFSCardsView from './MiniFSCardsModelComponents/MFSCardsView'
import EmptyComponentModel from '../../emptyComponentModel/EmptyComponentModel'

const styles = () => ({
  cardsWrapper: {
    display: 'flex',
    flexFlow: 'row wrap',
    width: '100%',
    justifyContent: 'space-around',
  },
})

const MiniFSCardsModel = ({
  classes,
  MFSGProps,
  folderPathProps,
  modelProps,
  noFSPanelPropsFirstView,
  searchSystemProps,
}) => {
  const { cards, MiniFSModelIndex } = MFSGProps
  const { InternalComponent, elementKey } = modelProps
  const {
    FirstViewSearch,
    firstLetterSearch,
    firstViewSearchIdtentifiers,
    searchQuery,
    alphabeticOrderDir,
    placeholderValue,
    // disableSearch,
  } = searchSystemProps
  const rtl = document.body.dir === 'rtl'
  return (
    <article
      className={classes.cardsWrapper}
      // this conditoning to center model 1 cards , and only this model
      style={{
        alignContent: MiniFSModelIndex === 1 ? 'center' : null,
        height: MiniFSModelIndex === 1 ? '100%' : null,
        alignItems: MiniFSModelIndex === 1 ? 'center' : null,
      }}
    >
      <NoFileSystemPanel noFSPanelProps={noFSPanelPropsFirstView} />
      {InternalComponent}
      {cards?.length > 0 || searchQuery ? (
        FirstViewSearch ? (
          // searching view
          <SearchEngine
            searchQuery={searchQuery}
            searchElements={cards}
            SearchedElementComponent={MFSCardsView}
            SearchedElementComponentProps={{
              folderPathProps,
              modelProps,
              searchSystemProps,
              MFSGProps,
            }}
            firstLetterSearch={firstLetterSearch}
            elementKey={elementKey}
            // the elements here depeneds on the properties of objects you want to search
            // the first one is the one searched for the first letter
            searchIdtentifiers={firstViewSearchIdtentifiers}
            alphabeticOrder={true}
            alphabeticOrderDir={alphabeticOrderDir}
            // disableSearch={disableSearch}
          />
        ) : (
          // no searching view
          cards.map((card, cardIndex) => (
            <MFSCardsView
              element={card}
              key={cardIndex}
              elementIndex={cardIndex}
              props={{
                folderPathProps,
                modelProps,
                searchSystemProps,
                MFSGProps,
              }}
            />
          ))
        )
      ) : (
        <EmptyComponentModel
          emptySentence={rtl ? `لا يوجد بيانات` : `No Data Found`}
          fontSize={16}
          fontWeight={500}
        />
      )}
    </article>
  )
}

const WrappedMiniFSCardsModel = withStyles(styles)(MiniFSCardsModel)

export default WrappedMiniFSCardsModel
