import withStyles from '@mui/styles/withStyles'
import axios from 'axios'
import React, { useContext } from 'react'
import { AuthContext } from '../../../../../../../context/AuthContext'
import DefaultChannelButton from '../../../../../../channelsSystem/DefaultChannelButton'
import TimeAgoFormattor from '../../../../../../SmallComponents/TimeAgoFormattor'

const styles = () => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  creationDate: {
    fontSize: 11,
    fontWeight: 500,
  },
  elementTitle: {
    fontWeight: 500,
    fontSize: 25,
    marginBottom: 20,
    marginTop: 10,
  },
})

const CampaignTop = ({ campaign, classes, DCGProps }) => {
  const { project } = DCGProps
  const { user } = useContext(AuthContext)
  const onGetOut = async () => {
    try {
      await axios.patch(`/campaigns/${campaign?._id}`, {
        campaignContributorsIds: campaign?.campaignContributorsIds?.filter(
          (u) => u !== user?._id
        ),
      })
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <section className={classes.wrapper}>
      <span className={classes.elementTitle}>{campaign?.Title}</span>
      <span className={classes.creationDate}>
        <TimeAgoFormattor date={campaign?.createdAt} />
      </span>
      <div
        style={{
          marginTop: 10,
          display:
            campaign?.campaignContributorsIds?.includes(user?._id) ||
            project?.userId === user?._id
              ? null
              : 'none',
        }}
      >
        <DefaultChannelButton
          element={campaign}
          DCGProps={DCGProps}
          onGetOut={onGetOut}
        />
      </div>
    </section>
  )
}

const WrappedCampaignTop = withStyles(styles)(CampaignTop)

export default WrappedCampaignTop
