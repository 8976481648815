import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { Grid } from '@mui/material'

const styles = () => ({
  gridItem: {
    display: 'flex',
    alignContent: 'center !important',
  },
})

const NavigatorCardsGrid = ({ classes, units, unitId, isDialogFullWidth }) => {
  return isDialogFullWidth ? (
    <Grid container>
      <Grid item xs={4} className={classes.gridItem}>
        {units[unitId]}
      </Grid>
      <Grid item xs={4} className={classes.gridItem}>
        {units[unitId + 1]}
      </Grid>
      <Grid item xs={4} className={classes.gridItem}>
        {units[unitId + 2]}
      </Grid>
    </Grid>
  ) : (
    <Grid container>
      <Grid item xs={6} className={classes.gridItem}>
        {units[unitId]}
      </Grid>
      <Grid item xs={6} className={classes.gridItem}>
        {units[unitId + 1]}
      </Grid>
    </Grid>
  )
}

const WrappedNavigatorCardsGrid = withStyles(styles)(NavigatorCardsGrid)

export default WrappedNavigatorCardsGrid
