import React, { useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import { IconButton, TextField, Tooltip } from '@mui/material'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import ExternalLinkGenerator from '../../../SmallComponents/ExternalLinkGenerator'
import SocialMediaShare from './ShareExternalLinkDialogContentComps/SocialMediaShare'
import ShareExternalDialogButtons from './ShareExternalLinkDialogContentComps/ShareExternalDialogButtons'
import CentralTitle from '../../../SmallComponents/CentralTitle'
import { QRCodeSVG } from 'qrcode.react'

const styles = () => ({
  copyButton: {
    margin: 4,
  },
  socialIcon: {
    fontSize: '2rem',
  },
  topWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  bottomWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'column',
  },
})

const ShareExternalLinkDialogContent = ({
  noFSPanelProps,
  windowCloser,
  classes,
}) => {
  const { element, isDarkModeActive, userProfile, projectProfile } =
    noFSPanelProps
  const [copied, setCopied] = useState(false)
  const rtl = document.body.dir === 'rtl'
  const link = ExternalLinkGenerator(element, userProfile, projectProfile)
  const title =
    element?.title ||
    element?.Title ||
    element?.name ||
    element?.ProductName ||
    ''

  const handleCopy = () => {
    navigator.clipboard.writeText(link)
    setCopied(true)
  }

  return (
    <section style={{ color: isDarkModeActive && 'white' }}>
      <CentralTitle title={rtl ? `مشاركة ${title}` : `Sharing ${title}`} />
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-around',
          margin: '10px 0',
        }}
      >
        <QRCodeSVG value={link} size={100} />
      </div>
      <div className={classes.topWrapper}>
        <Tooltip
          title={
            copied
              ? rtl
                ? 'تم نسخ الرابط!'
                : 'Link Copied!'
              : rtl
              ? 'انسخ الرابط'
              : 'Copy Link'
          }
        >
          <IconButton onClick={handleCopy} className={classes.copyButton}>
            <FileCopyIcon style={{ color: isDarkModeActive && 'white' }} />
          </IconButton>
        </Tooltip>
        <TextField
          fullWidth
          value={link}
          variant='outlined'
          margin='dense'
          InputProps={{
            readOnly: true,
            dir: document.body.dir,
            style: { color: isDarkModeActive && 'white', borderRadius: 20 },
          }}
          dir={document.body.dir}
        />
      </div>
      <div className={classes.bottomWrapper}>
        <SocialMediaShare link={link} {...noFSPanelProps} title={title} />
        <ShareExternalDialogButtons link={link} windowCloser={windowCloser} />
      </div>
    </section>
  )
}

const WrappedShareExternalLinkDialogContent = withStyles(styles)(
  ShareExternalLinkDialogContent
)

export default WrappedShareExternalLinkDialogContent
