import React, { useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import ModificationTextForm from './ModificationUnitComps/ModificationTextForm'
import StateGenerator from '../../SmallComponents/StateGenerator'
import ModelDialogWrappingIII from '../../dialogModelUnit/ModelDialogWrappingIII'
import ModificationUnitButtons from './ModificationUnitComps/ModificationUnitButtons'
import ModificationUnitBody from './ModificationUnitComps/ModificationUnitBody'

const styles = () => ({
  wrapper: {
    backgroundColor: 'rgba(97, 165, 65, 0.336)',
    borderRadius: 10,
    padding: 10,
    margin: '10px auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
})

const ModificationUnit = ({
  classes,
  modification,
  isDarkModeActive,
  conEl,
  setModifications,
  members,
  socket,
  DCGProps,
}) => {
  const [defaultText, seTdefaultText] = useState('')
  const { isGeneralDialogOpen, GeneralDialogOpener, GeneralDialogClose } =
    StateGenerator({ GeneralProps: true })

  const props = {
    modification,
    isDarkModeActive,
    conEl,
    setModifications,
    members,
    defaultText,
    GeneralDialogClose,
    seTdefaultText,
    GeneralDialogOpener,
    socket,
    DCGProps,
  }
  const dialogsArray = [
    {
      open: isGeneralDialogOpen,
      content: <ModificationTextForm {...props} />,
    },
  ]

  return (
    <article className={classes.wrapper}>
      <ModificationUnitBody {...props} />
      <ModificationUnitButtons {...props} />
      <ModelDialogWrappingIII
        dialogsArray={dialogsArray}
        isDarkModeActive={isDarkModeActive}
        dialogWidth='450px'
        dialogHeight='400px'
      />
    </article>
  )
}

const WrappedModificationUnit = withStyles(styles)(ModificationUnit)

export default WrappedModificationUnit
