import { IconButton } from '@mui/material'
import axios from 'axios'
import i18next from 'i18next'
import React, { useEffect, useState } from 'react'
import { MdArrowLeft, MdArrowRight } from 'react-icons/md'
import { Circles } from 'react-loader-spinner'
import { ModelDialogWrappingIII } from './WindowViewerSystemComps'
import NotFound from './WindowViewerSystemComps/NotFound'
import WindowViewerSystemConfigs from './WindowViewerSystemComps/WindowViewerSystemConfigs'
import MarketingStrategyAspectsConfigs from '../projectProfile/ProjectProfileComponents/ProjectControlPanelComponents/ProjectModelingComponents/MarketingStrategiesComponents/MarketingStrategyAspectsComps/MarketingStrategyAspectsConfigs'
import BusinessModelsConfigs from '../projectProfile/ProjectProfileComponents/ProjectControlPanelComponents/ProjectModelingComponents/BusinessModelComponents/NavigateModelsComponents/BusinessModelsConfigs'

const Activation = (wins) => {
  const { windowType, isWindowOpen, windowCloser, props, isDarkModeActive } =
    wins
  const [clickAwayState, setClickAwayState] = useState(false)
  const { project } = props
  const { windowCardName, backendRoute } = WindowViewerSystemConfigs(
    props
  )?.find(
    (compo) =>
      compo?.windowType === windowType ||
      compo?.hashTagType === props[compo?.windowCardName]?.hashTagType
  )
  const rtl = document.body.dir === 'rtl'
  const incomingElement = props[windowCardName]
  const [element, setElement] = useState(
    incomingElement?.hashTagType === 'businessModelTemp'
      ? BusinessModelsConfigs().find((m) => m?.id === incomingElement?.id)
      : incomingElement?.hashTagType === 'markStraAspect'
      ? MarketingStrategyAspectsConfigs().find(
          (m) =>
            m?.id === incomingElement?.id || m?.id + 'm' === incomingElement?.id
        )
      : null
  )
  const [isLoading, setIsLoading] = useState(backendRoute)
  const [users, setUsers] = useState([])
  const [projects, setProjects] = useState([])

  const fetchElement = async () => {
    setIsLoading(true)
    try {
      const res = await axios.get(
        windowType === 'projectContributor'
          ? `/${backendRoute}/${incomingElement?.id || incomingElement?._id}/${
              project?._id
            }`
          : `/${backendRoute}/${incomingElement?.id || incomingElement?._id}`
      )
      setElement(
        res.data[
          windowType === 'projectContributor'
            ? 'contributor'
            : windowType === 'userContributor'
            ? 'user'
            : windowCardName
        ]
      )
      if (res.data.users) setUsers(res.data.users)
      if (res.data.projects) setProjects(res.data.projects)
    } catch (error) {
      console.log(error)
    }
    setIsLoading(false)
  }
  useEffect(() => {
    if (
      backendRoute &&
      (incomingElement?.id || incomingElement?._id) &&
      isWindowOpen
    )
      fetchElement()
  }, [
    backendRoute,
    incomingElement?.id,
    isWindowOpen,
    incomingElement?._id,
    project?._id,
  ])

  const propsII = {
    ...props,
    isDarkModeActive,
    users,
    projects,
    [windowCardName]:
      incomingElement?.title === 'Room Discussion' ||
      incomingElement?.title === 'مناقشة' ||
      incomingElement?.title === 'منشور' ||
      incomingElement?.title === 'Channel Discussion' ||
      incomingElement?.title === 'Project Post'
        ? element
        : windowType === 'feedPost'
        ? props?.post
        : windowType === 'projectContributor'
        ? {
            ...element,
            name: incomingElement?.firstName + ' ' + incomingElement?.lastName,
            image: incomingElement?.profilePicture,
            _id: incomingElement?._id,
          }
        : element,
    DCGProps: {
      ...props?.DCGProps,
      isDialogFullWidth: false,
      project: project || props?.DCGProps?.project,
      isDarkModeActive,
      setClickAwayState,
    },
    ...props?.DCGProps,
    setClickAwayState,
    isDarkModeActive,
    isWindowOpen,
    windowCloser,
    isDialogFullWidth: false,
  }

  const component = WindowViewerSystemConfigs(propsII)?.find(
    (compo) =>
      compo?.windowType === windowType ||
      compo?.hashTagType === props[compo?.windowCardName]?.hashTagType
  ).comp

  const dialogsArray = [
    {
      open: isWindowOpen,
      content:
        isLoading && !element ? (
          <Circles
            height='80'
            width='80'
            color='#20257d'
            ariaLabel='circles-loading'
            wrapperStyle={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-around',
              height: '100%',
            }}
            wrapperClass=''
            visible={true}
          />
        ) : element ? (
          component
        ) : (
          <NotFound isDarkModeActive={isDarkModeActive} />
        ),
    },
  ]
  const dialogTitle =
    incomingElement?.title ||
    incomingElement?.Title ||
    incomingElement?.name ||
    incomingElement?.ProductName ||
    (incomingElement?.hashTagType === 'managerNote' &&
      (rtl ? 'ملاحظة' : 'Manager Note'))

  const lang = i18next.language
  return windowType === 'feedPost' ? (
    component
  ) : (
    <ModelDialogWrappingIII
      dialogTitle={dialogTitle}
      dialogsArray={dialogsArray}
      dialogWidth='600px'
      dialogHeight='600px'
      dialogIcon={
        <IconButton
          edge='start'
          color='inherit'
          onClick={() => {
            windowCloser()
          }}
        >
          {dialogTitle && lang === 'en' ? (
            <MdArrowRight style={{ fontSize: 20, cursor: 'pointer' }} />
          ) : (
            <MdArrowLeft style={{ fontSize: 20, cursor: 'pointer' }} />
          )}
        </IconButton>
      }
      inactivateClickaway={clickAwayState}
      isDarkModeActive={isDarkModeActive}
      linkShareElement={element}
    />
  )
}
const WindowViewerSystem = (props) => {
  const { isWindowOpen } = props
  if (isWindowOpen) {
    return <Activation {...props} />
  }
}

export default WindowViewerSystem
