import axios from 'axios'
import React, { useEffect, useState } from 'react'
import NavigatorCardsModel from '../../../../../../navigatorCardsModel/NavigatorCardsModel'
import TitleHeader from '../../../../../../SmallComponents/Header'

const UserPortfolioChannels = ({ user }) => {
  const [channels, setChannels] = useState([])
  useEffect(() => {
    const fetchChannels = async () => {
      try {
        const resChannels = await axios.get(
          `/channels/project_user_av/availableChannels/?projectId=${user?._id}`
        )
        setChannels(resChannels.data.channels)
      } catch (error) {
        console.log(error)
      }
    }
    if (user?._id) fetchChannels()
  }, [user?._id])
  const unitsArray = channels
  const rtl = document.body.dir === 'rtl'
  return (
    <section
      style={{
        display: unitsArray.length === 0 ? 'none' : null,
      }}
    >
      <TitleHeader
        headerText={rtl ? 'القنوات المنشأة' : 'Established Channels'}
      />
      <NavigatorCardsModel
        unitsArray={unitsArray}
        modelUnitProps={{
          elementsImages: [],
          imageKey: 'feedChannel',
          windowProps: {
            windowCardName: 'channel',
            windowType: 'feedChannel',
            DCGProps: {},
          },
        }}
      />
    </section>
  )
}

export default UserPortfolioChannels
