import axios from 'axios'
import React, { useContext, useState } from 'react'
import { AuthContext } from '../../../../../../../context/AuthContext'
import ButtonsSystem from '../../../../../../buttonsSystem/ButtonsSystem'
import ElementRequestsSenders from '../../../../../../notificationsSystem/ElementRequestsSenders'
import NoPermissionedInteraction from '../../../../../../notificationsSystem/NoPermissionedInteraction'
import NotificationCreator from '../../../../../../notificationsSystem/NotificationCreator'
import { toast } from 'react-toastify'

const JobInteractionButtons = ({ postMode, job, DCGProps, depManager }) => {
  const { socket, project } = DCGProps
  const { user } = useContext(AuthContext)
  const senders = ElementRequestsSenders(job?._id || job?.id)
  const [apply, setApply] = useState(false)
  const newRequest = {
    userId: user?._id,
    projectId: job?.projectId,
    genre: 'job',
    subgenre: 'apply',
    elementId: job?._id || job?.id,
    requestData: {},
  }

  const activeFun = async (subgenre) => {
    try {
      const res = await axios.post('/requests', newRequest)
      socket.current.emit('addNotifUser', depManager?._id || project?.userId)
      NotificationCreator({
        associativity: 'job',
        relation: 'creator',
        conditionIndex: 0,
        accompaniedData: {
          requestSubGenre: subgenre,
          projectId: job?.projectId,
          requestId: res.data.request._id,
          elements: [
            {
              hashTagType: job?.hashTagType,
              id: job?._id || job?.id,
              title: job?.Title,
            },
          ],
        },
        category: 'contributors',
        userId: depManager?._id || project?.userId,
        currentUser: user,
        socket,
        CRNDelete: true,
        element: job,
      })
    } catch (error) {
      console.log(error)
    }
  }
  const rtl = document.body.dir === 'rtl'
  const buttonsArray = [
    {
      title: rtl ? 'تقديم' : 'Apply',
      funs: () => {
        setApply(true)
        activeFun()
        toast.success(
          rtl
            ? 'تم ارسال طلبك بنجاح'
            : 'Your request has been sent successfully'
        )
      },
      cofirmMssg: true,
      disabled: apply,
    },
  ]
  return senders === undefined ? (
    <NoPermissionedInteraction checking={true} />
  ) : senders?.includes(user?._id) || apply ? (
    <NoPermissionedInteraction />
  ) : (
    <ButtonsSystem
      buttonsArray={buttonsArray}
      hide={
        !postMode ||
        user?.createdProjects?.includes(job?.projectId) ||
        user?._id === project?.userId ||
        user?.contractedProjectsIds?.includes(project?._id)
      }
      {...DCGProps}
    />
  )
}

export default JobInteractionButtons
