import React from 'react'

const AspectTitle = ({ aspect }) => {
  return (
    <section
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        alignItems: 'center',
        alignContent: 'center',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          fontSize: 20,
          fontWeight: 500,
          marginTop: 15,
        }}
      >
        {aspect.title}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          fontSize: 15,
        }}
      >
        {aspect.category}
      </div>
    </section>
  )
}

export default AspectTitle
