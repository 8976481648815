import React from 'react'
import AdsManagmentModel from './AdsManagmentModel'
import PostAdsPage from './PostAdsManagementComps/PostAdsPage'
import DocumentTitleChanger from './SmallComponents/DocumentTitleChanger'

const PostAdsManagement = (props) => {
  const rtl = document.body.dir === 'rtl'
  DocumentTitleChanger(rtl ? 'صفحة الاعلان' : 'Ad Page')
  return <AdsManagmentModel Component={PostAdsPage} ComponentProps={props} />
}

export default PostAdsManagement
