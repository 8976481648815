import { useTranslation } from 'react-i18next'

const NetworkBusinessModel = () => {
  const { t } = useTranslation('NetworkBusinessModel')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 70,
    title: t('title_bm70'),
    subTitle: t('subTitle_bm70'),
    main: t('main_bm70'),
    when: t('when_bm70'),
    who: t('who_bm70'),
    what: t('what_bm70'),
    how: t('how_bm70'),
    why: t('why_bm70'),
    examples: rtl
      ? [
          'Airbnb - أغسطس 2008 - الولايات المتحدة الأمريكية',
          'أمازون - يوليو 1994 - الولايات المتحدة الأمريكية',
          'أوبر - مارس 2009 - الولايات المتحدة الأمريكية',
          'Lyft - يونيو 2012 - الولايات المتحدة الأمريكية',
          'Instacart - يوليو 2012 - الولايات المتحدة الأمريكية',
        ]
      : [
          'Airbnb - August 2008 - USA',
          'Amazon - July 1994 - USA',
          'Uber - March 2009 - USA',
          'Lyft - June 2012 - USA',
          'Instacart - July 2012 - USA',
        ],
    tags: rtl
      ? ['شبكات التعاون', 'الشراكات']
      : ['Collaborate Networks', 'Partnerships', 'Business Leverage'],
    considerations: t('considerations_bm70'),
    combinations: ['$LBM73'],
    hashTagType: 'businessModelTemp',
  }
}

export default NetworkBusinessModel
