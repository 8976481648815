import React, { useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import ImagesEditableGallery from '../../../../../../postSystem/ImagesSystemComponents/ImagesEditableGallery'
import axios from 'axios'
import CustomCircularProgress from '../../../../../../SmallComponents/CustomCircularProgress'

const styles = () => ({
  wrapper: {
    position: 'relative',
  },
  '@media (max-width: 600px)': {
    wrapper: {
      width: '100%',
    },
  },
})

const ModelImagePreview = ({ classes, galleryProps }) => {
  const { element, setElements, patchPath, DCGProps } = galleryProps
  const { isDialogFullWidth } = DCGProps
  const [images, setImages] = useState(element?.images)
  const [isLoading, setIsLoading] = useState(false)

  const onContinue = async (tempImages) => {
    setIsLoading(true)
    try {
      await axios.patch(`/${patchPath}/${element?._id}`, {
        images,
      })
      if (typeof setElements === 'function')
        setElements((els) => [
          ...els.filter((p) => p._id !== element?._id),
          { ...element, images },
        ])
    } catch (error) {
      console.log(error)
    }
    setIsLoading(false)
  }

  return (
    <section
      className={classes.wrapper}
      style={{
        width: isDialogFullWidth ? '50%' : '100%',
        flex: isDialogFullWidth && 1,
      }}
    >
      {isLoading ? (
        <CustomCircularProgress />
      ) : (
        <ImagesEditableGallery
          images={images}
          setImages={setImages}
          {...galleryProps}
          onContinue={onContinue}
        />
      )}
    </section>
  )
}

const WrappedModelImagePreview = withStyles(styles)(ModelImagePreview)
export default WrappedModelImagePreview
