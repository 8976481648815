import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { MiniFSInsideModel, MiniFSCardsModel } from './MiniFileSystemComponents'

const MiniFileSystem = (props) => {
  const { MFSGProps, folderPathProps, searchSystemProps, modelProps } = props
  const { cardId, setCardId, cardIdDependencyIndex, cards } = MFSGProps
  const {
    subdepartment,
    setIsSubDepMemberProfileOpen,
    disablePorfileOpener,
    InfiniteScrollProps,
  } = modelProps
  const { setPlaceholder, placeholderValue } = searchSystemProps
  const {
    setThirdLevelFolderPath,
    setStepBackFun2,
    stepBackFun2,
    stepBackFun,
    setSecondLevelFolderPath,
    setStepBackFun,
    z,
  } = folderPathProps
  const { t } = useTranslation()
  useEffect(() => {
    if (subdepartment) {
      if (typeof setStepBackFun2 === 'function')
        setStepBackFun2(stepBackFun2 ? () => setCardId(undefined) : null)
      if (
        typeof setIsSubDepMemberProfileOpen === 'function' &&
        typeof setStepBackFun2 === 'function'
      )
        setStepBackFun2(
          stepBackFun2 ? () => setIsSubDepMemberProfileOpen(false) : null
        )
      if (cardId === undefined && typeof setThirdLevelFolderPath === 'function')
        setThirdLevelFolderPath('')
    }
    if (!subdepartment) {
      if (
        cardId === undefined &&
        typeof setSecondLevelFolderPath === 'function'
      )
        setSecondLevelFolderPath('')
      if (typeof setStepBackFun === 'function')
        setStepBackFun(stepBackFun ? () => setCardId(undefined) : null)
      if (typeof setStepBackFun === 'function')
        setStepBackFun(stepBackFun ? () => setCardId(undefined) : null)
    }
    if (typeof setPlaceholder === 'function')
      setPlaceholder(`${t('Search')} ${placeholderValue}`)
  })

  return (
    <>
      {(cardId && !disablePorfileOpener) ||
      (cardId === 0 && !disablePorfileOpener) ? (
        <MiniFSInsideModel
          cardIdDependencyIndex={
            cardIdDependencyIndex !== undefined ? cardIdDependencyIndex : 1
          }
          {...props}
        />
      ) : (
        <section style={{ width: '100%' }}>
          <InfiniteScroll dataLength={cards?.length} {...InfiniteScrollProps}>
            <MiniFSCardsModel {...props} />
          </InfiniteScroll>
        </section>
      )}
    </>
  )
}

export default MiniFileSystem
