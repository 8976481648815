import axios from 'axios'
import React, { useState } from 'react'
import EditableGridModel from '../../../../../../formsSystem/EditableGridModel'
import InfoDataIntialStateGen from '../../../../../../formsSystem/InfoDataIntialStateGen'
import HashTagElementsRefiner from '../../../../../../SmallComponents/HashTagElementsRefiner'
import TextEngineToolsFooter from '../../../../../../SmallComponents/TextEngineToolsFooter'
import ProductPartnershipChannels from './ProductDesignProfileComps/ProductPartnershipChannels'
import FormDataLangModulator from '../../../../../../formsSystem/FormDataLangModulator'
import { useTranslation } from 'react-i18next'

const ProductDesignProfile = ({ design, DCGProps, setDesigns }) => {
  const { isDarkModeActive } = DCGProps
  const { t } = useTranslation()
  const rtl = document.body.dir === 'rtl'
  const productInfo = [
    {
      title: 'Product_Idea',
      titleValue: design?.ProductIdea,
      helpertext: rtl
        ? 'ماذا تبيع وكيف يتم تصنيعها أو توفيرها؟ قم بتضمين تفاصيل العلاقات مع الموردين والمصنعين و / أو الشركاء الضروريين لتقديم المنتج أو الخدمة للعملاء.'
        : 'What do you sell, and how is it manufactured or provided? Include details of relationships with suppliers, manufacturers and/or partners that are essential to delivering the product or service to customers.',
      allowEmpty: true,
      wideMode: true,
      minRows: 5,
    },
    {
      title: 'Special_Benefits',
      titleValue: design?.SpecialBenefits,
      helpertext: rtl
        ? 'يحتاج كل عمل إلى حل مشكلة يواجهها عملاؤه. اشرح ما هي المشكلة وكيف يحلها منتجك أو خدمتك. ما هي الفوائد والميزات وعرض البيع الفريد؟ لن يكون الحل الوحيد (كل عمل تجاري له منافسون) ، ولكن عليك أن تشرح سبب كون الحل الذي تقدمه أفضل من الحلول الأخرى ، أو لماذا يستهدف قاعدة عملاء يتجاهلها منافسوك ، أو أن لديه بعض الخصائص الأخرى التي تمنحه ميزة تنافسية.'
        : 'Every business needs to solve a problem that its customers face. Explain what the problem is and how your product or service solves it. What are its benefits, features and unique selling proposition? Yours won’t be the only solution (every business has competitors), but you need to explain why your solution is better than the others, targets a customer base your competitors are ignoring, or has some other characteristic that gives it a competitive edge.',
      allowEmpty: true,
      wideMode: true,
      minRows: 5,
    },
    {
      title: 'Unique_Features',
      titleValue: design?.UniqueFeatures,
      helpertext: rtl
        ? 'هل لديك براءة اختراع على منتجك أو براءة اختراع معلقة؟ هل لديك اتفاقيات حصرية مع الموردين أو البائعين لبيع منتج أو خدمة لا يبيعها أي من منافسيك؟ هل لديك ترخيص لمنتج أو تقنية أو خدمة مطلوبة بشدة و/أو تعاني من نقص في العرض؟'
        : 'Do you have a patent on your product or a patent pending? Do you have exclusive agreements with suppliers or vendors to sell a product or service that none of your competitors sell? Do you have the license for a product, technology or service that’s in high demand and/or short supply?',
      allowEmpty: true,
      wideMode: true,
      minRows: 5,
    },
    {
      title: 'Pricing_Plan',
      titleValue: design?.PricingPlan,
      helpertext: rtl
        ? 'صِف هيكل التسعير أو الرسوم أو الاشتراك أو التأجير لمنتجك أو خدمتك. كيف يتناسب منتجك أو خدمتك مع المشهد التنافسي من حيث التسعير - هل أنت من فئة منخفضة أو متوسطة أو عالية؟ كيف ستساعدك استراتيجية التسعير هذه على جذب العملاء؟ ما هو هامش الربح المتوقع؟'
        : 'Describe the pricing, fee, subscription or leasing structure of your product or service. How does your product or service fit into the competitive landscape in terms of pricing—are you on the low end, mid-range or high end? How will that pricing strategy help you attract customers? What is your projected profit margin?',
      allowEmpty: true,
      wideMode: true,
      minRows: 5,
    },
    {
      title: 'Limits_and_Liabilities',
      titleValue: design?.LimitsandLiabilities,
      helpertext: rtl ? '' : '',
      allowEmpty: true,
      wideMode: true,
      minRows: 5,
    },
    {
      title: 'Production_and_Delivery',
      titleValue: design?.ProductionandDelivery,
      helpertext: rtl ? '' : '',
      allowEmpty: true,
      wideMode: true,
      minRows: 5,
    },
    {
      title: 'Suppliers',
      titleValue: design?.Suppliers,
      helpertext: rtl ? '' : '',
      allowEmpty: true,
      wideMode: true,
      minRows: 5,
    },
    {
      title: 'Product_Description',
      titleValue: design?.ProductDescription,
      helpertext: rtl ? '' : '',
      allowEmpty: true,
      wideMode: true,
      minRows: 5,
    },
    {
      title: 'Market_Channels',
      titleValue: design?.MarketChannels,
      helpertext: rtl ? '' : '',
      allowEmpty: true,
      wideMode: true,
      minRows: 5,
    },
  ]

  const [infoData, setInfoData] = useState(InfoDataIntialStateGen(productInfo))
  const [chosenHashTagElements, setChosenHashTagElements] = useState(
    HashTagElementsRefiner(design) || []
  )
  const newInfoData = FormDataLangModulator(infoData, t)
  const onSave = async () => {
    if (design?._id)
      try {
        const res = await axios.patch(`/productDesigns/${design?._id}`, {
          ...newInfoData,
          chosenHashTagElements,
        })
        setDesigns((ds) => [
          ...ds.filter((t) => t._id !== design._id),
          res.data.productDesign,
        ])
      } catch (error) {
        console.log(error)
      }
  }
  const gridProps = {
    // who can design ?
    hideEditingTools: false,
    headerText: rtl ? `تصميم ${design.Title}` : `${design.Title} Design`,
    infoArray: productInfo,
    setInfoData,
    infoData,
    isDarkModeActive,
    setChosenHashTagElements,
    chosenHashTagElements,
    onSave,
    ...DCGProps,
    departmentId: 0,
  }

  return (
    <div style={{ color: isDarkModeActive ? 'white' : 'black' }}>
      <ProductPartnershipChannels design={design} DCGProps={DCGProps} />
      <EditableGridModel gridProps={gridProps} />
      <TextEngineToolsFooter isDarkModeActive={isDarkModeActive} />
    </div>
  )
}

export default ProductDesignProfile
