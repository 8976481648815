import React from 'react'
import withStyles from '@mui/styles/withStyles'
import FreeBar from '../FreeBar'
import '../../skeleton.css'
import { Grid } from '@mui/material'

const styles = () => ({
  profileTopWrapper: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'space-between',
    margin: '0 10px',
    height: 'auto',
  },
  defaultViewWrapper: {
    width: '100%',
    height: '270.5px',
    marginBottom: '10px',
    display: 'flex',
    flexDirection: 'row',
  },
  profileImageName: {
    display: 'flex',
    justifyContent: 'center !important',
    width: 280,
    borderRadius: '20px !important',
    alignItems: 'center',
  },

  typo: {
    padding: 5,
  },
  name: {
    fontWeight: '600',
    fontSize: '20px',
  },
  blockAuthIcon: {
    cursor: 'pointer',
    color: 'blue',
  },

  followChatOptionsIcons: {
    justifyContent: 'space-between',
    margin: 5,
    width: '80%',
  },

  overViewContainer: {
    flex: 6,
    borderRadius: '15px !important',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'space-between',
  },
  cardWrapper: {
    width: '100% !important',
    height: '97% !important',
    borderRadius: '15px !important',
    display: 'flex',
    justifyContent: 'center !important',
    alignContent: 'center !important',
    alignItems: 'center !important',
    backgroundColor: '#313131',
    margin: 4,
  },
  cardContainer: {
    height: '50%',
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  '@media (max-width: 600px)': {
    defaultViewWrapper: {
      flexDirection: 'column',
      height: 'auto',
      width: '100%',
    },
    profileTopWrapper: {
      margin: 0,
    },
    profileImageName: {
      width: '100%',
      borderRadius: '0 !important',
    },
    cardWrapper: {
      width: '100% !important',
      height: '50px!important',
      borderRadius: '0px !important',
      display: 'flex',
      justifyContent: 'center !important',
      alignContent: 'center !important',
      alignItems: 'center !important',
    },
  },
})

const ProfileTopSkeleton = ({ classes, hide, isDarkModeActive }) => {
  const rtl = document.body.dir === 'rtl'
  return (
    <article className={classes.profileTopWrapper}>
      <section className={classes.defaultViewWrapper}>
        <div
          className={classes.profileImageName}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <span
            style={{
              position: 'relative',
              animation: 'skeleton 1s ease infinite alternate',
            }}
          >
            <div
              className='postSkAvatar'
              style={{ width: 150, height: 150 }}
            ></div>
          </span>
          <span className={classes.typo}>
            <span className={classes.name}>
              <FreeBar
                height={20}
                width={150}
                isDarkModeActive={isDarkModeActive}
              />
            </span>
          </span>
          <section
            className={classes.followChatOptionsIcons}
            style={{ display: hide ? 'none' : 'flex' }}
          >
            {Array.from({ length: 4 }, (_, index) => (
              <span key={index} style={{ width: '100%', margin: 5 }}>
                <FreeBar height={50} />
              </span>
            ))}
          </section>
        </div>
        <article
          className={classes.overViewContainer}
          style={{ margin: rtl ? '0 10px 0 0' : '0 0 0 10px' }}
        >
          <Grid
            container
            style={{
              height: '100%',
              width: '100%',
              animation: 'skeleton 1s ease infinite alternate',
            }}
          >
            {Array.from({ length: 4 }, (_, index) => (
              <Grid item xs={6} className={classes.cardContainer} key={index}>
                <div className={classes.cardWrapper}></div>
              </Grid>
            ))}
          </Grid>
        </article>
      </section>
    </article>
  )
}

const WrappedProfileTopSkeleton = withStyles(styles)(ProfileTopSkeleton)

export default WrappedProfileTopSkeleton
