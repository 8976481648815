import { useTranslation } from 'react-i18next'

const RobinHoodBusinessModel = () => {
  const { t } = useTranslation('RobinHoodBusinessModel')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 49,
    title: t('title_bm49'),
    subTitle: t('subTitle_bm49'),
    main: t('main_bm49'),
    when: t('when_bm49'),
    who: t('who_bm49'),
    what: t('what_bm49'),
    how: t('how_bm49'),
    why: t('why_bm49'),
    examples: rtl
      ? [
          'مستشفى أرافيند للعناية بالعيون - الهند',
          'أحذية تومز - تأسست عام 2006 - الولايات المتحدة الأمريكية',
          'الكمبيوتر المحمول الواحد لكل طفل (OLPC) - تم العثور عليه عام 2005 - الولايات المتحدة الأمريكية',
          'واربي باركر - الولايات المتحدة الأمريكية',
        ]
      : [
          'Aravind Eye Care Hospital - India',
          'TOMS Shoes - Founded 2006 - USA',
          'The One Laptop per Child (OLPC) - Found 2005 - USA',
          'Warby Parker - USA',
        ],
    tags: rtl
      ? [
          'منظمات غير ربحية',
          'إصلاح السمعة',
          'Economies of Scale',
          'الوعي المنتج',
        ]
      : [
          'Non-profit Organizations',
          'Reputation Fix',
          'Economies of Scale',
          'Product Awareness',
        ],
    considerations: t('considerations_bm49'),
    combinations: [],
    hashTagType: 'businessModelTemp',
  }
}

export default RobinHoodBusinessModel
