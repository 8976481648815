import React from 'react'
import { createContext, useReducer } from 'react'
import FeedReducer from './FeedReducer'

const INITIAL_STATE = {
  post: {},
  notification: {},
  error: false,
}

export const FeedContext = createContext(INITIAL_STATE)
export const FeedContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(FeedReducer, INITIAL_STATE)
  return (
    <FeedContext.Provider
      value={{
        post: state.post,
        error: state.error,
        notification: state.notification,
        dispatch,
      }}
    >
      {children}
    </FeedContext.Provider>
  )
}
