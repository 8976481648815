import React, { useState } from 'react'
import PollCard from '../projectProfile/ProjectProfileComponents/ProjectControlPanelComponents/ProjectInteractionsComponents/ProjectInteractionsDialogContentComps/ProjectVotingsComps/PollProfileComps/PollCard'
import axios from 'axios'
import CentralTitle from '../SmallComponents/CentralTitle'
import WindowSize from '../SmallComponents/WindowSize'
import TextEngine from '../textProcessingEngine/TextEngine'
import ToastifyGeneralError from '../SmallComponents/ToastifyGeneralError'

const GeneralPollBody = (props) => {
  const { pollData, isDarkModeActive, setPosts, post, socket } = props
  const windowSize = WindowSize()
  const [options, setOptions] = useState(
    pollData?.options || post?.pollData?.options
  )
  const onExVote = async (updatedOptions) => {
    try {
      const res = await axios.patch(`/posts/${post?._id}`, {
        pollData: {
          ...pollData,
          options: updatedOptions,
        },
      })
      if (typeof setPosts === 'function')
        setPosts((posts) => {
          let oldIndex = posts?.findIndex((p) => p._id === post?._id)
          console.log({ oldIndex })
          return posts.map((p, i) => (i === oldIndex ? res.data.post : p))
        })
    } catch (error) {
      console.log(error)
      ToastifyGeneralError(error)
    }
  }

  return (
    pollData && (
      <div
        style={{
          margin: windowSize > 600 ? 'auto 10px' : 'auto',
          color: isDarkModeActive && 'white',
          // added to solve
          width: windowSize > 600 ? 'auto' : '100%',
        }}
      >
        <div style={{ margin: windowSize < 600 && 'auto 10px' }}>
          <CentralTitle
            title={
              <TextEngine
                textContent={pollData?.SetYourQuestion || ''}
                textPackage={post}
                {...props}
              />
            }
          />
        </div>
        <div
          style={{
            margin: windowSize > 600 ? '10px auto auto auto' : '10px',
          }}
        >
          {options?.length > 0 && (
            <PollCard
              onExVote={onExVote}
              poll={{
                ...pollData,
                Deadline: pollData?.SetDeadline,
                chosenHashTagElements: post?.chosenHashTagElements,
              }}
              options={options}
              setOptions={setOptions}
              DCGProps={{ isDarkModeActive, socket }}
            />
          )}
        </div>
      </div>
    )
  )
}

export default GeneralPollBody
