import React from 'react'

const Loading = ({ isDarkModeActive }) => {
  const rtl = document.body.dir === 'rtl'
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-around',
        alignContent: 'center',
        alignItems: 'center',
        color: isDarkModeActive && 'white'
      }}
    >
      <span
        style={{
          fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
          fontWeight: 500,
        }}
      >
        {rtl ? 'جارى التحميل...' : 'Loading...'}
      </span>
    </div>
  )
}

export default Loading
