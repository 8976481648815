import React from 'react'
import RescueProtocol from './ProtocolProfileComps/RescueProtocol'
import ProjectTermination from './ProtocolProfileComps/ProjectTermination'

const ProtocolProfile = (props) => {
  const { protocol, rescueProtocol } = props
  const comps = [
    <ProjectTermination {...props} />,
    <RescueProtocol {...props} />,
  ]
  return rescueProtocol ? comps[1] : comps[protocol?.id]
}

export default ProtocolProfile
