import axios from 'axios'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { PostGeneratorEngine } from '../../'
import { AuthContext } from '../../../../../context/AuthContext'
import { FeedContext } from '../../../../../context/FeedContext/FeedContext'
import NotificationCreator from '../../../../notificationsSystem/NotificationCreator'
import ActivePostChangeDetector from './ActivePostChangeDetector'

export default function PostPublishButtonConfig({ shareSystemProps }) {
  const {
    shareDialogClose,
    disablePostTypeOptions,
    setIsPublishClicked,
    postData,
    feedsSystemCategory,
    shareOptionsModelType,
    project,
    socket,
    setPosts,
    setPostsUsers,
  } = shareSystemProps
  const { user } = useContext(AuthContext)
  const { dispatch, notification } = useContext(FeedContext)
  const { t } = useTranslation()
  return {
    title: t('Publish'),
    funs: async () => {
      shareDialogClose()
      try {
        const res = await axios.post(`/posts`, {
          userId: shareOptionsModelType === 'home' ? user?._id : project?._id,
          ...PostGeneratorEngine(postData, 0, feedsSystemCategory),
        })
        if (res.data.post?._id && notification?.mentionedUsersIds) {
          Promise.all(
            notification?.mentionedUsersIds?.map(async (id) =>
              NotificationCreator({
                associativity: 'post',
                relation: 'mentioned',
                conditionIndex: 0,
                accompaniedData: { postId: res.data.post?._id },
                category: 'general',
                userId: id,
                currentUser: user,
                socket,
              })
            )
          )
          dispatch({
            type: 'REMOVE_MENTION',
          })
        }
        if (res.data.post?._id) {
          dispatch({
            type: 'CREATE_POST',
            payload: res.data.post,
          })
          setPosts((posts) => [res.data.post, ...posts])
          if (typeof setPostsUsers === 'function')
            setPostsUsers((us) => [...us, res.data.postUser])
        }

        setIsPublishClicked(true)
      } catch (error) {
        console.log(error)
      }
    },
    displayConditions: disablePostTypeOptions ? 'none' : null,
    disabled:
      !postData?.postTextContent &&
      !postData?.images?.length > 0 &&
      !postData?.playerLink &&
      !postData?.pollData,
  }
}
