import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import BillingButton from './PostAdsPageComps/BillingButton'
import AdPostView from './PostAdsPageComps/AdPostView'
import PostAdConfigsForm from './PostAdsPageComps/PostAdConfigsForm'
import { Grid, Toolbar } from '@mui/material'
import Loading from '../SmallComponents/Loading'
import { Container } from '@mui/material'
import Media from 'react-media'
import withStyles from '@mui/styles/withStyles'
import CentralTitle from '../SmallComponents/CentralTitle'
import AdCampaignAnalytics from './PostAdsPageComps/AdCampaignAnalytics'
import AvailableTargetUsers from './PostAdsPageComps/AvailableTargetUsers'
import AdCampaignDeleteButton from './PostAdsPageComps/AdCampaignDeleteButton'
import DeniedAccess from '../SmallComponents/DeniedAccess'
import NotFound from '../windowViewer/WindowViewerSystemComps/NotFound'
import PostAdCostView from './PostAdsPageComps/PostAdCostView'

const styles = () => ({
  main: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '80vh',
    padding: '20px 10px auto 10px',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
  },
})

const PostAdsPage = ({ classes, ...props }) => {
  const { adCampaignId } = useParams()
  const [post, setPost] = useState()
  const [adCampaign, setAdCampaign] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [project, setProject] = useState()
  const { isDarkModeActive, user } = props
  const [error, setError] = useState()

  useEffect(() => {
    const fetchPost = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(`/adsManagment/${adCampaignId}`)
        setPost(res.data.post)
        setAdCampaign(res.data.adCampaign)
        setProject(res.data.project)
      } catch (error) {
        setError(error)
      }
      setIsLoading(false)
    }
    if (adCampaignId) fetchPost()
  }, [adCampaignId])

  const [processing, setProcessing] = useState()
  const propsII = {
    ...props,
    post,
    adCampaign,
    processing,
    setProcessing,
    project,
    setAdCampaign,
    adCampaignId,
  }
  const rtl = document.body.dir === 'rtl'
  const authorized =
    post?.userId === user?._id || user?.createdProjects.includes(project?._id)
  const component = (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Toolbar />
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <AdPostView {...propsII} />
          <br />
          {adCampaign?.status === 'Promoted' ? (
            <>
              <CentralTitle
                title={rtl ? 'تحليلات الحملة' : 'Campaign Analytics'}
              />
              <br />
              <AdCampaignAnalytics {...propsII} />
            </>
          ) : (
            <div style={{ backgroundColor: 'white' }}>
              <CentralTitle
                title={rtl ? 'قم بتخصيص حملتك' : 'Customize Your Campaign'}
              />
              <CentralTitle
                title={
                  <div
                    style={{
                      fontSize: 17,
                      padding: 'auto 10px',
                    }}
                  >
                    {rtl
                      ? 'في حال عدم توفر العدد المطلوب، يمكنك تعديل المدخلات حتى تحصل على العدد المطلوب.'
                      : 'If the desired number is not available, you can modify the parameters until you obtain the required number'}
                  </div>
                }
              />
              <br />
              <div style={{ backgroundColor: 'white', padding: '15px auto' }}>
                <PostAdConfigsForm {...propsII} />
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <AvailableTargetUsers {...propsII} />
                  </Grid>
                  <Grid item xs={6}>
                    <PostAdCostView {...propsII} />
                  </Grid>
                </Grid>
                <BillingButton {...propsII} />
              </div>
            </div>
          )}
        </>
      )}
      <AdCampaignDeleteButton {...propsII} />
    </div>
  )
  const errComponent = (
    <div className={classes.main}>
      <Toolbar />
      <div style={{ maxWidth: 500, marginTop: 50 }}>
        <NotFound isDarkModeActive={isDarkModeActive} />
      </div>
    </div>
  )
  const Main = ({ component }) => {
    return (
      <Container
        maxWidth='md'
        className={classes.main}
        style={{ background: isDarkModeActive ? null : '#fff' }}
      >
        {component}
      </Container>
    )
  }
  return error ? (
    errComponent
  ) : authorized ? (
    <Media query='(max-width: 600px)'>
      {(matches) =>
        matches ? (
          <div className={classes.main}>{component}</div>
        ) : (
          <Main component={component} />
        )
      }
    </Media>
  ) : (
    post && <DeniedAccess />
  )
}

export default withStyles(styles)(PostAdsPage)
