import React from 'react'
import { Tooltip } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { FaDAndD } from 'react-icons/fa'
import StateGenerator from '../../SmallComponents/StateGenerator'
import ProjectPartnershipChannelsViewer from './AddToPartnershipChannelComps/ProjectPartnershipChannelsViewer'
import NewFormDialogModel from '../../formsSystem/NewFormDialogModel'

const styles = () => ({
  icon: {
    cursor: 'pointer',
    fontSize: 18,
    '&:hover': {
      opacity: 0.8,
    },
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
})

const AddToPartnershipChannel = ({ classes, noFSPanelProps }) => {
  const { showAddToChannel } = noFSPanelProps
  const { isGeneralDialogOpen, GeneralDialogOpener, GeneralDialogClose } =
    StateGenerator({ GeneralProps: true })
  const { isDarkModeActive } = noFSPanelProps
  const rtl = document.body.dir === 'rtl'
  return (
    <>
      <Tooltip
        title={rtl ? 'أضف الى قناة تشارك' : 'Add to Partnership Channel'}
      >
        <span
          className={classes.iconWrapper}
          style={{ display: showAddToChannel ? null : 'none' }}
          onClick={() => GeneralDialogOpener()}
        >
          <FaDAndD className={classes.icon} />
        </span>
      </Tooltip>
      <NewFormDialogModel
        NewFormComponent={
          <ProjectPartnershipChannelsViewer
            noFSPanelProps={noFSPanelProps}
            GeneralDialogClose={GeneralDialogClose}
          />
        }
        newFormDialogClose={GeneralDialogClose}
        isNewFormDialogOpen={isGeneralDialogOpen}
        isDarkModeActive={isDarkModeActive}
        clickAwayState={true}
        dialogTitle={rtl ? 'إختر قناة' : 'Choose a Channel'}
      />
    </>
  )
}

const WrappedAddToPartnershipChannel = withStyles(styles)(
  AddToPartnershipChannel
)

export default WrappedAddToPartnershipChannel
