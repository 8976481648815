import { CardMedia } from '@mui/material'
import React from 'react'
import withStyles from '@mui/styles/withStyles'
import DefaultImageGrapper from '../../../SmallComponents/DefaultImageGrapper'

const styles = () => ({
  media: {
    height: 0,
    paddingTop: '56.25%',
    marginTop: '30',
    borderRadius: '0',
    cursor: 'pointer',
  },
})

const ModelUnitCardMedia = ({ classes, modelUnitProps, unit }) => {
  const { elementsImages, defaultImage, distributedCard } = modelUnitProps
  const elemntImage = elementsImages?.find(
    (image) => image.id === unit.id || image.id === unit._id
  )?.image
  const wImageKey = distributedCard ? unit.imageKey : modelUnitProps.imageKey
  const generatedImage =
    unit.hashTagType === 'feedPost'
      ? (unit?.images && unit?.images[0]) ||
        (wImageKey && DefaultImageGrapper(wImageKey))
      : wImageKey && DefaultImageGrapper(wImageKey)
  const image =
    elementsImages?.length > 0
      ? elemntImage
        ? elemntImage
        : defaultImage || generatedImage
      : defaultImage || generatedImage

  return (
    <CardMedia className={classes.media} image={unit?.image || image || ' '} />
  )
}

const WrappedModelUnitCardMedia = withStyles(styles)(ModelUnitCardMedia)

export default WrappedModelUnitCardMedia
