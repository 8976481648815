import React, { useState, useEffect } from 'react'
import { GiEarthAmerica } from 'react-icons/gi'
import { FaHome } from 'react-icons/fa'
import FreeSelectComponent from '../../../formsSystem/FreeSelectComponent'
import HashTagCardsConfigs from '../../../suggestionsSystem/HashTagCardsConfigs'

const PostPrivacyOptions = ({ shareSystemProps, modelType }) => {
  const { setPostData, postData, post, disablePostTypeOptions } =
    shareSystemProps
  const postPrivicies = ['Global', 'Local']
  const menuOptions = [
    {
      title: 'Global',
      pureIcon: GiEarthAmerica,
    },
    {
      title: 'Local',
      pureIcon: FaHome,
    },
  ]

  const defaultValue =
    typeof post === 'object' && post?.privacyType === 'Global' ? 0 : 1

  const [option, setOption] = useState(
    defaultValue !== undefined && defaultValue
  )

  useEffect(() => {
    if (!disablePostTypeOptions && modelType === 'home') setOption(1)
  }, [postData])

  useEffect(() => {
    setPostData({
      ...postData,
      privacyType: postPrivicies[option],
    })
  }, [option])

  const element = HashTagCardsConfigs()
    .filter((con) => con?.hashTagType === post?.postElement?.hashTagType)[0]
    ?.dataBase?.filter((el) => el.id === post?.postElement.id)[0]
  return (
    <FreeSelectComponent
      {...shareSystemProps}
      onChange={(event) => {}}
      menuOptions={menuOptions}
      option={option}
      setOption={setOption}
      disabled={
        post?.postTypeIndex !== 2 &&
        !post?.postElement &&
        (post?.sharedPostId ||
          // social post will always remain in the local profile
          // for editing dialog
          (!element && modelType === 'home') ||
          // for new post dialog
          (!disablePostTypeOptions && modelType === 'home'))
      }
    />
  )
}

export default PostPrivacyOptions
