import React from 'react'
import {
  ProductStrategyPDFDocument,
  ResourcePDFDocument,
  ProductPDFDocument,
  ProductDesignPDFDocument,
  InvestPackPDFDocument,
  IdeaPDFDocument,
  FinStatPDFDocument,
  ContractPDFDocument,
  CampaignPDFDocument,
  BusinessModelPDFDocument,
} from './PDFTemplatesComps'
import ManagerialPlanPDFDocument from './PDFTemplatesComps/ManagerialPlanPDFDocument'
import MarketingStrategyPDFDocument from './PDFTemplatesComps/MarketingStrategyPDFDocument'

const PDFDTemplates = (props) => {
  return [
    {
      hashTagType: 'contract',
      comp: <ContractPDFDocument {...props} />,
    },
    {
      hashTagType: 'idea',
      comp: <IdeaPDFDocument {...props} />,
    },
    {
      hashTagType: 'product',
      comp: <ProductPDFDocument {...props} />,
    },
    {
      hashTagType: 'resource',
      comp: <ResourcePDFDocument {...props} />,
    },
    {
      hashTagType: 'campaign',
      comp: <CampaignPDFDocument {...props} />,
    },
    {
      hashTagType: 'businessModel',
      comp: <BusinessModelPDFDocument {...props} />,
    },
    {
      hashTagType: 'investPack',
      comp: <InvestPackPDFDocument {...props} />,
    },
    {
      hashTagType: 'productStrategy',
      comp: <ProductStrategyPDFDocument {...props} />,
    },
    {
      hashTagType: 'finStat',
      comp: <FinStatPDFDocument {...props} />,
    },
    {
      hashTagType: 'productDesign',
      comp: <ProductDesignPDFDocument {...props} />,
    },
    {
      hashTagType: 'managerialPlan',
      comp: <ManagerialPlanPDFDocument {...props} />,
    },
    {
      hashTagType: 'marketStrategy',
      comp: <MarketingStrategyPDFDocument {...props} />,
    },
  ]
}

export default PDFDTemplates
