import { useTranslation } from "react-i18next"

const Crowdfunding = () => {
    const { t } = useTranslation('Crowdfunding')
    const rtl = document.body.dir === 'rtl'
  return {
    id: 10,
    title: t('title_bm10'),
    subTitle: t('subTitle_bm10'),
    main: t('main_bm10'),
    when: t('when_bm10'),
    who: t('who_bm10'),
    what: t('what_bm10'),
    how: t('how_bm10'),
    why: t('why_bm10'),
    examples: rtl
      ? [
          ' استخدمت Pebble Technology منصة Kickstarter في عام 2009 ، جمعت 100،000 دولار في ساعتين ، ثم رفعت إلى 10 ملايين ',
          ' استخدم الشتات منصة كيك ستارتر لإنشاء شبكة اجتماعية لا مركزية. جمعت 200000 دولار وهو 20 ضعف هدفها (10000 دولار) ',
        ]
      : [
          'Pebble Technology used Kickstarter Platform in 2009, Gathered 100,000$ in 2 hours, then raised to 10 millions',
          'Diaspora used Kickstarter Platform to create decentralized social network. Gathered 200,000$ which was 20 times its target (10,000$)',
        ],
    tags: rtl
      ? [
          'تمويل سهل',
          'لا فوائد',
          'التمويل العام',
          'اختبار المرحلة المبكرة',
          'لا توجد نماذج أولية',
          'التأثير العاطفي',
          'الدوائر المالية الموسعة',
        ]
      : [
          'Easy Funding',
          'No Interest',
          'Public Financing',
          'Early Stage Testing',
          'No Prototyping',
          'Emotional Impact',
          'Enlarged Financial Circles',
        ],
    considerations: t('considerations_bm10'),
    combinations: ['$LBM54', '$LBM40', '$LBM2'],
    hashTagType: 'businessModelTemp',
  }
}

export default Crowdfunding
