import React from 'react'
import { CirclesWithBar } from 'react-loader-spinner'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    alignContent: 'space-around',
    alignItems: 'center',
    flexDirection: 'column',
  },
  searching: {
    fontWeight: 500,
    margin: 10,
    fontSize: 15,
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
  },
})

const SearchSystemLoader = ({ isDarkModeActive, classes }) => {
  const rtl = document.body.dir === 'rtl'
  return (
    <div
      className={classes.container}
      style={{
        color: isDarkModeActive && 'white',
      }}
    >
      <div className={classes.wrapper}>
        <CirclesWithBar
          height='100'
          width='100'
          color='#4fa94d'
          wrapperStyle={{}}
          wrapperClass=''
          visible={true}
          outerCircleColor=''
          innerCircleColor=''
          barColor=''
          ariaLabel='circles-with-bar-loading'
        />
        <div className={classes.searching}>
          {rtl ? 'جارى البحث' : 'Searching'}
        </div>
      </div>
    </div>
  )
}

const WrappedSearchSystemLoader = withStyles(styles)(SearchSystemLoader)

export default WrappedSearchSystemLoader
