import React from 'react'
import { FaFilePdf } from 'react-icons/fa'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  mentionWrapper: {
    color: 'orange',
    fontWeight: 500,
    cursor: 'pointer',
  },
  pdfIcon: {
    margin: '2px 5px -2px 0',
    color: 'rgb(150, 13, 13)',
  },
})

const PDFDetectorElement = ({ classes, textPackage, word }) => {
  const file = textPackage?.chosenHashTagElements?.filter(
    (element) => element?.identifier === word
  )[0]?.mentionElement

  return file?.name ? (
    <a href={file.url + '.pdf'} download={file.name} target='_blank'>
      <FaFilePdf className={classes.pdfIcon} />
      {file?.name}
    </a>
  ) : (
    word
  )
}

const WrappedPDFDetectorElement = withStyles(styles)(PDFDetectorElement)

export default WrappedPDFDetectorElement
