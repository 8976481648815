import axios from 'axios'
import React from 'react'
import FullInteractiveRequestDetailsModel from '../../../../../../../notificationsSystem/FullInteractiveRequestDetailsModel'

const TaskRequestDetails = (props) => {
  const { card, exprops, requestsUsers } = props
  const { task } = exprops
  const request = card
  const requestingUser = requestsUsers?.find((u) => u?._id === request?.userId)

  const rtl = document.body.dir === 'rtl'
  return (
    <FullInteractiveRequestDetailsModel
      RequestText={
        <section>
          {requestingUser?.firstName + ' ' + requestingUser?.lastName}{' '}
          {rtl ? 'جاهز لهذه المهمة' : 'is Ready for this Task'}
        </section>
      }
      onAccept={async () => {
        await axios.patch(`/tasks/${task?._id || task?.id}`, {
          TaskPairedContributorId: request?.userId,
        })
      }}
      NotificationCreatorAcceptProps={{
        associativity: 'task',
        relation: 'pairedWith',
        conditionIndex: 4,
        category: 'contributors',
        elements: [
          {
            hashTagType: task?.hashTagType,
            id: task?._id || task?.id,
            title: task?.Title || task?.title,
          },
        ],
      }}
      NotificationCreatorRefuseProps={{
        associativity: 'task',
        relation: 'pairedWith',
        conditionIndex: 4,
        category: 'contributors',
        elements: [
          {
            hashTagType: task?.hashTagType,
            id: task?._id || task?.id,
            title: task?.Title || task?.title,
          },
        ],
      }}
      contributorProps={{
        projectId: task?.projectId,
        departmentId: task?.departmentId,
        subDepId: task?.subDepId,
      }}
      requestingUser={requestingUser}
      {...props}
      disableAccept={task?.TaskPairedContributorId}
    />
  )
}

export default TaskRequestDetails
