import React from 'react'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  cardWrapper: {
    display: 'flex',
    height: 'auto',
    flexDirection: 'column',
    padding: '10px',
    margin: '10px',
    width: '100%'
  },
})

const ExpandedUnitDetailsCard = ({
  classes,
  showDetails,
  card,
  UnitCardDetailsComponent,
  exprops,
  DCGProps,
  requestsUsers,
  setRequests,
}) => {
  const { isDarkModeActive } = DCGProps
  return (
    <div
      className={classes.cardWrapper}
      style={{
        display: showDetails ? null : 'none',
        color: isDarkModeActive ? 'white' : 'inherit',
      }}
    >
      <UnitCardDetailsComponent
        card={card}
        exprops={exprops}
        DCGProps={DCGProps}
        requestsUsers={requestsUsers}
        setRequests={setRequests}
      />
    </div>
  )
}

const WrappedExpandedUnitDetailsCard = withStyles(styles)(
  ExpandedUnitDetailsCard
)

export default WrappedExpandedUnitDetailsCard
