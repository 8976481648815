import React from 'react'
import NewFormDialogModel from '../../formsSystem/NewFormDialogModel'
import { Tooltip } from '@mui/material'
import WindowProps from '../../windowViewer/WindowProps'
import RemoveProjectContributorDialogContent from './RemoveProjectContributorComps/RemoveProjectContributorDialogContent'
import withStyles from '@mui/styles/withStyles'
import { GiBrokenHeart } from 'react-icons/gi'

const styles = () => ({
  icon: {
    cursor: 'pointer',
    fontSize: 18,
    '&:hover': {
      opacity: 0.8,
    },
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
})

const RemoveProjectContributor = ({ classes, noFSPanelProps }) => {
  const { showEndContribution } = noFSPanelProps
  const { isWindowOpen, windowOpener, windowCloser } = WindowProps()
  const rtl = document.body.dir === 'rtl'
  return (
    <>
      <Tooltip title={rtl ? 'انهاء التشارك' : 'End Contribution'}>
        <span
          className={classes.iconWrapper}
          style={{ display: showEndContribution ? null : 'none' }}
          onClick={() => windowOpener()}
        >
          <GiBrokenHeart className={classes.icon} />
        </span>
      </Tooltip>
      <NewFormDialogModel
        NewFormComponent={
          <RemoveProjectContributorDialogContent
            noFSPanelProps={noFSPanelProps}
            windowCloser={windowCloser}
          />
        }
        newFormDialogClose={windowCloser}
        isNewFormDialogOpen={isWindowOpen}
        {...noFSPanelProps}
        clickAwayState={true}
        dialogTitle={rtl ? 'انهاء مشاركة' : 'End Contribution'}
      />
    </>
  )
}

const WrappedRemoveProjectContributor = withStyles(styles)(
  RemoveProjectContributor
)

export default WrappedRemoveProjectContributor
