import React from 'react'
import ImageUpdatorUpdateOption from './ImageUpdatorPopoverContentComps/ImageUpdatorUpdateOption'
import ImageUpdatorDeleteOption from './ImageUpdatorPopoverContentComps/ImageUpdatorDeleteOption'
import ImageUpdatorPopoverWrapper from './ImageUpdatorPopoverContentComps/ImageUpdatorPopoverWrapper'

const ImageUpdatorPopoverContent = (props) => {
  return (
    <ImageUpdatorPopoverWrapper
      component={
        <>
          <ImageUpdatorUpdateOption {...props} />
          <ImageUpdatorDeleteOption {...props} />
        </>
      }
    />
  )
}

export default ImageUpdatorPopoverContent
