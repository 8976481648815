import React, { useEffect, useState } from 'react'
import RoomSMDisTopLeft from './RoomSMDisTopComponents/RoomSMDisTopLeft'
import withStyles from '@mui/styles/withStyles'
import RoomSMDisTopRight from './RoomSMDisTopComponents/RoomSMDisTopRight'
import axios from 'axios'

const styles = () => ({
  topWrapper: {
    marginRight: 10,
    marginLeft: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  '@media (max-width: 600px)': {
    topWrapper: {
      marginRight: 0,
      marginLeft: 0,
      justifyContent: 'space-between',
      width: 'auto',
      alignItems: 'center',
    },
  },
})

const RoomSMDisTop = ({ classes, postProps }) => {
  const { post, project } = postProps
  const [reproject, setProject] = useState(project)
  const [subDep, setSubDep] = useState()
  useEffect(() => {
    const fetchDara = async () => {
      try {
        if (post?.projectId && !project) {
          const resProj = await axios.get(
            `/projects/oneProject/${post?.projectId}`
          )
          setProject(resProj.data.project)
        }
        if (post?.subDepId) {
          const resSubDep = await axios.get(`/subdepartments/${post?.subDepId}`)
          setSubDep(resSubDep.data.subdepartment)
        }
      } catch (error) {
        console.log(error)
      }
    }
    fetchDara()
  }, [post?.projectId, post?.subDepId, project?._id])
  return (
    <article className={classes.topWrapper}>
      <RoomSMDisTopLeft
        postProps={{ ...postProps, project: project || reproject }}
        subDep={subDep}
      />
      <RoomSMDisTopRight
        postProps={{ ...postProps, project: project || reproject }}
        subDep={subDep}
      />
    </article>
  )
}

const WrappedRoomSMDisTop = withStyles(styles)(RoomSMDisTop)

export default WrappedRoomSMDisTop
