import axios from 'axios'
import React, { useState } from 'react'
import ButtonsSystem from '../../../../../../../buttonsSystem/ButtonsSystem'
import CardTitlesModel from '../../../../../../../fileSystem/MiniFileSystemComponents/MiniFSCardsModelComponents/MFSCardsViewModels/StandardCardComps/CardTitlesModel'

const NRAConceptCardContent = ({
  element,
  opener,
  isDarkModeActive,
  setChosenConceptsIds,
  researchActivityId,
}) => {
  const [Added, setAdded] = useState(
    element?.researchActivityIds?.includes(researchActivityId)
  )
  const rtl = document.body.dir === 'rtl'
  const buttonsArray = [
    {
      title: rtl ? 'عرض' : 'Open',
      funs: () => opener(),
    },
    {
      title: Added ? (rtl ? 'أزل' : 'Remove') : rtl ? 'أضف' : 'Add',
      funs: async () => {
        setAdded(!Added)
        if (
          researchActivityId &&
          typeof setChosenConceptsIds !== 'function' &&
          !Added
        ) {
          try {
            await axios.patch(
              `/researchConcepts/addResearchActivity/${element._id}`,
              { researchActivityId }
            )
          } catch (error) {
            console.log(error)
          }
          console.log('added')
        } else if (
          researchActivityId &&
          typeof setChosenConceptsIds !== 'function' &&
          Added
        ) {
          try {
            await axios.patch(
              `/researchConcepts/removeResearchActivity/${element._id}`,
              { researchActivityId }
            )
          } catch (error) {
            console.log(error)
          }
          console.log('removed')
        }

        if (typeof setChosenConceptsIds === 'function') {
          if (!Added) {
            setChosenConceptsIds((ids) => [...ids, element._id])
          } else if (Added) {
            setChosenConceptsIds((ids) =>
              ids.filter((id) => id !== element._id)
            )
          }
        }
      },
    },
  ]
  const concept = element
  return (
    <CardTitlesModel
      title={concept?.title}
      isDarkModeActive={isDarkModeActive}
      cardFooter={
        <section style={{ width: '100%' }}>
          <ButtonsSystem
            buttonsArray={buttonsArray}
            isDarkModeActive={isDarkModeActive}
          />
        </section>
      }
    />
  )
}

export default NRAConceptCardContent
