import axios from 'axios'

const CustomNewElementRequestNotifDeletor = async ({
  element,
  associativity,
  relation,
  category,
  conditionIndex,
}) => {
  try {
    await axios.delete('/notifications/customDelete/requests', {
      headers: {},
      data: {
        elementId: element?._id || element?.id,
        associativity,
        relation,
        category,
        conditionIndex,
      },
    })
  } catch (error) {
    console.log(error)
  }
}

export default CustomNewElementRequestNotifDeletor
