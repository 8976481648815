import React, { createContext, useEffect, useReducer } from 'react'
import SettingsReducer from './SettingsReducer'

const st = localStorage.getItem('hutupia_settings')

let INITIAL_STATE = {
  settings: null,
  isFetching: false,
  error: false,
}

if (typeof st === 'string' && st.trim() !== '') {
  try {
    INITIAL_STATE.settings = JSON.parse(st)
  } catch (e) {
    console.error('Error parsing settings from localStorage:', e)
    // handle the error as appropriate, such as falling back to a default value
  }
}

if (INITIAL_STATE.settings === null) {
  INITIAL_STATE.settings = null // set default value to null
}

export const SettingsContext = createContext(INITIAL_STATE)

export const SettingsContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(SettingsReducer, INITIAL_STATE)
  useEffect(() => {
    if (state.settings) {
      localStorage.setItem('hutupia_settings', JSON.stringify(state.settings))
    }
  }, [state.settings])
  return (
    <SettingsContext.Provider
      value={{
        settings: state.settings,
        isFetching: state.isFetching,
        error: state.error,
        dispatch,
      }}
    >
      {children}
    </SettingsContext.Provider>
  )
}
