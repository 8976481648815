import React, { useContext, useState } from 'react'
import { AuthContext } from '../../../../../../../../../../context/AuthContext'
import PopoverMenu from '../../../../../../../../../popoverMenu/PopoverMenu'
import axios from 'axios'
import deleteFile from '../../../../../../../../../../firebase/deleteFile'

const NoteTopRight = ({ notesProps }) => {
  const {
    hideRightOptions,
    note,
    editNoEditIndex,
    setEditNoEditIndex,
    noteUpdateData,
    setManagerPinnedNotes,
    setManagerNotes,
    departmentId,
    depManager,
    DCGProps,
  } = notesProps
  const { project } = DCGProps
  const [pinned, setPinned] = useState(note?.pinned)
  const rtl = document.body.dir === 'rtl'
  const { user } = useContext(AuthContext)
  const [saving, setSaving] = useState(false)
  const notesStateUpdate = (res) => {
    setManagerNotes((notes) => {
      let oldIndex = notes?.findIndex((p) => p._id === note?._id)
      return notes.map((p, i) => (i === oldIndex ? res.data.note : p))
    })
  }
  const optionsArray = [
    {
      type: 'delete',
      funs: async () => {
        try {
          setManagerNotes((notes) => notes.filter((n) => n?._id !== note?._id))
          Promise.all(
            note?.images?.map(async (image) => {
              if (image) await deleteFile(image)
            })
          )
          await axios.delete(`/managerNotes/${note?._id}`)
        } catch (error) {
          console.log(error)
        }
      },
    },
    editNoEditIndex === 0
      ? {
          type: 'edit',
          funs: () => setEditNoEditIndex(1),
        }
      : {
          type: 'save',
          funs: async () => {
            setSaving(true)
            try {
              await axios.patch(`/managerNotes/${note?._id}`, noteUpdateData)
              setEditNoEditIndex(0)
            } catch (error) {
              console.log(error)
            }
            setSaving(false)
          },
          loading: saving,
          loadingText: rtl ? 'جارى الحفظ' : 'Saving',
        },
    pinned
      ? {
          type: 'unpin',
          funs: async () => {
            try {
              const res = await axios.patch(`/managerNotes/${note?._id}`, {
                pinned: false,
              })
              setPinned(false)
              setManagerPinnedNotes((pns) =>
                pns.filter((n) => n?._id !== note?._id)
              )
              notesStateUpdate(res)
            } catch (error) {
              console.log(error)
            }
          },
        }
      : {
          type: 'pin',
          funs: async () => {
            try {
              const res = await axios.patch(`/managerNotes/${note?._id}`, {
                pinned: true,
              })
              setPinned(true)
              setManagerPinnedNotes((pns) => [note, ...pns])
              notesStateUpdate(res)
            } catch (error) {
              console.log(error)
            }
          },
        },
  ]
  return (
    <div style={{ margin: 'auto 10px' }}>
      <PopoverMenu
        hide={
          hideRightOptions || departmentId === 2
            ? user?._id !== project?.userId
            : user?._id !== note?.managerId ||
              !depManager ||
              (user?._id === note?.managerId &&
                depManager?.userId !== user?._id)
        }
        optionsArray={optionsArray}
      />
    </div>
  )
}

export default NoteTopRight
