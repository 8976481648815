import React from 'react'
import HashTagDetector from './TextProcessorComponents/Detectors/HashTagDetector'
import MentionDetector from './TextProcessorComponents/Detectors/MentionDetector'

const TextProcessor = (props) => {
  const { textContent } = props
  // hashtag algorithm
  const separateWords = textContent.split(' ')
  const taggedWords = separateWords
    .filter((word) => word.startsWith('#'))
    .concat(' ')
  const restTextFragments = textContent
    .split('#')
    .map((fragment, index) =>
      fragment.replace(
        taggedWords.map((hash) => hash.substring(1, hash.length))[index - 1],
        ''
      )
    )

  return taggedWords.map((word, wordIndex) => (
    // note that order of systems matter, so you have to put mention system first then hashtag due to algorithms issues
    <span key={wordIndex}>
      <MentionDetector
        textIndex={wordIndex}
        text={restTextFragments}
        {...props}
      />
      <HashTagDetector word={word} {...props} />
    </span>
  ))
}

export default TextProcessor
