import RepetionRemover from '../../../../../SmallComponents/RepetionRemover'

const ModTasks = ({ projectTasks }) => {
  const TaskStatus = (t) => {
    const states = ['Done', 'Due Today', 'Delayed', 'Still', 'Terminated']
    const taskDeadline = new Date(t.TaskDeadline).getTime()
    if (t.TaskStatus !== 'Done' && t.TaskStatus !== 'Terminated') {
      return new Date(Date.now()).toLocaleDateString() ===
        new Date(t.TaskDeadline).toLocaleDateString()
        ? states[1]
        : Date.now() < taskDeadline
        ? states[3]
        : Date.now() > taskDeadline
        ? states[2]
        : null
    } else {
      return t.TaskStatus === 'Done'
        ? states[0]
        : t.TaskStatus === 'Terminated'
        ? states[4]
        : null
    }
  }
  const preModCards = RepetionRemover(projectTasks)
  return preModCards.map((ta) => ({
    ...ta,
    id: ta._id,
    TaskStatus: TaskStatus(ta),
  }))
}

export default ModTasks
