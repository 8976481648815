import React from 'react'
import { Tooltip } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { FaCloudDownloadAlt } from 'react-icons/fa'

const styles = () => ({
  icon: {
    cursor: 'pointer',
    fontSize: 18,
    '&:hover': {
      opacity: 0.8,
    },
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
})

const DownloadAllContent = ({ classes, noFSPanelProps }) => {
  const { downloadFolderTippy, showDownloadIcon } = noFSPanelProps
  return (
    <Tooltip title={downloadFolderTippy}>
      <span
        className={classes.iconWrapper}
        style={{ display: showDownloadIcon ? null : 'none' }}
      >
        <FaCloudDownloadAlt className={classes.icon} />
      </span>
    </Tooltip>
  )
}

const WrappedDownloadAllContent = withStyles(styles)(DownloadAllContent)

export default WrappedDownloadAllContent
