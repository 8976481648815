import React from 'react'
import { MdRemoveCircle } from 'react-icons/md'
import withStyles from '@mui/styles/withStyles'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

const styles = () => ({
  imageWrapper: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    padding: '5px 2.5px',
  },
  closeIconWrapper: {
    position: 'absolute',
    top: 10,
    right: 20,
    backgroundColor: 'rgba(255,255,255, 0.6)',
    color: 'black',
    borderRadius: '50%',
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      fotnSize: 18,
      backgroundColor: 'rgba(255,255,255, 0.8)',
    },
  },
  image: {
    width: '95%',
    borderRadius: 4,
  },
})

const ImageUnit = ({ classes, image, imageIndex, removeImage }) => {
  return (
    <section className={classes.imageWrapper}>
      <span
        className={classes.closeIconWrapper}
        onClick={() => removeImage(imageIndex, image)}
      >
        <MdRemoveCircle
          size={23}
          onClick={() => removeImage(imageIndex, image)}
        />
      </span>
      <LazyLoadImage
        effect='blur'
        src={image}
        alt='uploadedImage'
        className={classes.image}
      />
    </section>
  )
}

const WrappedImageUnit = withStyles(styles)(ImageUnit)

export default WrappedImageUnit
