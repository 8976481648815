import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { Link } from 'react-router-dom'

const styles = () => ({
  mentionWrapper: {
    fontWeight: 500,
    color: '#6C85BD',
    cursor: 'pointer',
  },
})

const MentionDetectorElement = ({ classes, textPackage, word }) => {
  const user = textPackage?.chosenHashTagElements?.find(
    (element) => element?.identifier === word
  )?.mentionElement

  return user ? (
    <Link className={classes.mentionWrapper} to={`/profile/${user?._id}`}>
      {user?.name}
    </Link>
  ) : (
    <span>{word}</span>
  )
}

const WrappedMentionDetectorElement = withStyles(styles)(MentionDetectorElement)

export default WrappedMentionDetectorElement
