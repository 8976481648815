import { useState, useEffect } from 'react'

const WindowSize = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth)

  useEffect(() => {
    setWindowSize(window.innerWidth)
  }, [window.innerWidth])
  return windowSize
}

export default WindowSize
