import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../../../../../../context/AuthContext'
import ButtonsSystem from '../../../../../../../buttonsSystem/ButtonsSystem'
import ElementRequestsSenders from '../../../../../../../notificationsSystem/ElementRequestsSenders'
import NoPermissionedInteraction from '../../../../../../../notificationsSystem/NoPermissionedInteraction'
import NotificationCreator from '../../../../../../../notificationsSystem/NotificationCreator'
import RequestCreator from '../../../../../../../notificationsSystem/RequestCreator'
import { toast } from 'react-toastify'
import axios from 'axios'

const IdeaInteractionButtons = ({ postMode, DCGProps, idea }) => {
  const { socket } = DCGProps
  const { user } = useContext(AuthContext)
  const senders = ElementRequestsSenders(idea?._id || idea?.id)
  const [dec, setDec] = useState(false)
  const [contrib, setContrib] = useState(false)
  const rtl = document.body.dir === 'rtl'
  const activeFun = async (subgenre) => {
    try {
      socket.current.emit('addNotifUser', idea?.userId)
      NotificationCreator({
        associativity: 'idea',
        relation: 'visionOwner',
        conditionIndex: 0,
        accompaniedData: {
          requestSubGenre: subgenre,
          elements: [
            {
              hashTagType: idea?.hashTagType,
              id: idea?._id || idea?.id,
              title: idea?.Title,
            },
          ],
        },
        category: 'contributors',
        userId: idea?.userId,
        currentUser: user,
        socket,
        CRNDelete: true,
        element: idea,
      })
      RequestCreator({
        currentUser: user,
        genre: 'idea',
        subgenre,
        elementId: idea?._id || idea?.id,
        requestData: {},
      })
    } catch (error) {
      console.log(error)
    }
  }
  const [isContributorOrFollower, setIsContributorOrFollower] = useState(false)
  useEffect(() => {
    const fetchResult = async () => {
      try {
        const res = await axios.get(
          `/ideas/contributorChecker/${user?._id}/${idea?._id}`
        )
        setIsContributorOrFollower(res.data.result)
      } catch (error) {
        console.log(error)
      }
    }
    // if (
    //   idea?.ContributionRequests === 'Only Contributors and Followers' &&
    //   user?._id !== idea?.userId
    // )
    fetchResult()
  }, [user?._id, idea?.ContributionRequests])
  const buttonsArray =
    idea?.ContributionRequests === 'Everyone' ||
    (idea?.ContributionRequests === 'Only Contributors and Followers' &&
      isContributorOrFollower)
      ? [
          {
            title: rtl ? 'توضيح أكثر' : 'Declaration',
            funs: () => {
              setDec(true)
              activeFun('declaration')
            },
            cofirmMssg: true,
            disabled: dec,
          },
          {
            title: rtl ? 'شارك' : 'Contribute',
            funs: () => {
              setContrib(true)
              activeFun('contribute')
              toast.success(
                rtl
                  ? 'تم ارسال طلبك بنجاح'
                  : 'Your request has been sent successfully'
              )
            },
            cofirmMssg: true,
            disabled: contrib,
          },
        ]
      : [
          {
            title: rtl ? 'توضيح أكثر' : 'Declaration',
            funs: () => {
              setDec(true)
              activeFun('declaration')
              toast.success(
                rtl
                  ? 'تم ارسال طلبك بنجاح'
                  : 'Your request has been sent successfully'
              )
            },
            cofirmMssg: true,
            disabled: dec,
          },
        ]
  return senders === undefined ? (
    <NoPermissionedInteraction checking={true} />
  ) : senders?.includes(user?._id) || dec || contrib ? (
    <NoPermissionedInteraction />
  ) : (
    <ButtonsSystem
      buttonsArray={buttonsArray}
      hide={
        !postMode ||
        idea?.userId === user?._id ||
        idea?.ideaContributorsIds?.includes(user?._id)
      }
      {...DCGProps}
    />
  )
}

export default IdeaInteractionButtons
