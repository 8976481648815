import React from 'react'
import EmptyComponentModel from '../emptyComponentModel/EmptyComponentModel'
import MemberCardModel from '../fileSystem/MiniFileSystemComponents/MiniFSCardsModelComponents/MFSCardsViewModels/MemberCardModel'
import WindowProps from '../windowViewer/WindowProps'
import WindowViewerSystem from '../windowViewer/WindowViewerSystem'

const SingleContributorModel = (props) => {
  const { hide, DCGProps, member, windowType } = props
  const { isWindowOpen, windowOpener, windowCloser } = WindowProps()
  return (
    <article
      style={{
        width: '100%',
        display: hide ? 'none' : 'flex',
        justifyContent: 'center',
      }}
    >
      {member?.id ? (
        <>
          <MemberCardModel
            element={member}
            props={{
              modelProps: {
                windowOpener,
              },
              MFSGProps: DCGProps,
              folderPathProps: {},
              searchSystemProps: {},
            }}
          />
          {windowType && (
            <WindowViewerSystem
              isWindowOpen={isWindowOpen}
              windowCloser={windowCloser}
              {...DCGProps}
              {...props}
              props={{
                member,
                DCGProps,
                ...DCGProps,
              }}
            />
          )}
        </>
      ) : (
        <EmptyComponentModel {...props} fontSize={16} fontWeight={500} />
      )}
    </article>
  )
}

export default SingleContributorModel
