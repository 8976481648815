import { Avatar } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  nameAvatar: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 10,
  },
  memberName: {
    fontWeight: 500,
    fontSize: 20,
    marginTop: 5,
  },
})

const MemberAvatarName = ({ classes, member }) => {
  return (
    <section className={classes.nameAvatar}>
      <Link to={`/profile/${member?.userId}`}>
        <Avatar
          style={{ width: 100, height: 100 }}
          src={member?.image || member?.profilePicture}
        />
      </Link>
      <span className={classes.memberName}>
        {member?.name || member?.firstName + ' ' + member?.lastName}
      </span>
    </section>
  )
}

const WrappedMemberAvatarName = withStyles(styles)(MemberAvatarName)

export default WrappedMemberAvatarName
