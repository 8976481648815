import { useTranslation } from "react-i18next"

const CustomerCatchingAndKeeping = () => {
  const { t } = useTranslation('CustomerCatchingAndKeeping')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 13,
    category: t('category_ms11'),
    title: t('title_ms13'),
    subTitle: t('subTitle_ms13'),
    main: t('main_ms13'),
    how: t('how_ms13'),
    usedInModels: [],
    tags: rtl
      ? [
          'العملاء البقاء',
          'حوافز الخصومات',
          'تكاليف وسيطة',
          'اقل الاسعار',
          'عروض المبيعات',
          'دفع الاستثمار',
          'ادعاءات أخلاقية',
        ]
      : [
          'Customers Stay',
          'Discounts Incentives',
          'Intermediary Costs',
          'Lower Prices',
          'Sales Promotions',
          'Investment Pay',
          'Moral Claims',
        ],
    examples: t('examples_ms13'),
    hashTagType: 'markStraAspect',
  }
}

export default CustomerCatchingAndKeeping
