import React, { useState, useEffect } from 'react'
import StandardCard from '../../fileSystem/MiniFileSystemComponents/MiniFSCardsModelComponents/MFSCardsViewModels/StandardCard'
import DistributedWinCardsConfigurer from '../../navigatorCardsModel/NavigatorCardsModelComps/DistributedWinCardsConfigurer'
import DefaultImageGrapper from '../../SmallComponents/DefaultImageGrapper'
import WindowProps from '../../windowViewer/WindowProps'
import WindowViewerSystem from '../../windowViewer/WindowViewerSystem'

const ECWFreeCardModel = ({ element, props, accessCardMode }) => {
  const { MFSGProps, modelProps } = props
  const { isDarkModeActive } = MFSGProps
  const { isWindowOpen, windowOpener, windowCloser } = WindowProps()
  const { modelUnitProps, imageKey } = DistributedWinCardsConfigurer([
    element,
  ])[0]
  const {
    StandardCardContent,
    profileProps,
    elementsImages,
    setClickAwayState,
  } = modelProps
  const [windowSize, setWindowSize] = useState(window.innerWidth)
  useEffect(() => {
    setWindowSize(window.innerWidth)
  }, [window.innerWidth])
  // window props
  const wProps = {
    windowCloser,
    [element?.windowProps?.windowCardName]: element,
    ...element?.windowProps,
    ...modelUnitProps,
    DCGProps: MFSGProps,
    hidCustomEditing: true,
    hideModelButtons: true,
    ...profileProps,
    ...modelProps,
  }

  const windowType = element?.windowProps?.windowType
  const defaultImage = elementsImages
    ? elementsImages.filter((image) => image.id === element.id)[0]
    : DefaultImageGrapper(imageKey)

  return (
    <>
      <StandardCard
        element={element}
        props={{
          MFSGProps: { isDarkModeActive, disableHover: windowSize <= 600 },
          folderPathProps: {},
          modelProps: {
            defaultImage,
            StandardCardContent,
            [accessCardMode ? 'windowOpener' : 'opener']: () => {
              windowOpener()
              if (typeof setClickAwayState === 'function')
                setClickAwayState(true)
            },
            ...modelProps,
          },
          searchSystemProps: {},
        }}
      />
      {element.hashTagType && (
        <WindowViewerSystem
          isWindowOpen={isWindowOpen}
          windowCloser={() => {
            windowCloser()
            if (typeof setClickAwayState === 'function')
              setClickAwayState(false)
          }}
          isDarkModeActive={isDarkModeActive}
          props={wProps}
          windowType={windowType}
        />
      )}
    </>
  )
}

export default ECWFreeCardModel
