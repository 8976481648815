import React from 'react'
import Media from 'react-media'
import BookDialogContentMobView from './BookDialogContentComponents/BookDialogContentMobView'
import BookDialogContentPCView from './BookDialogContentComponents/BookDialogContentPCView'

const BookDialogContent = (props) => {
  const mediaComponents = [
    <BookDialogContentPCView {...props} />,
    <BookDialogContentMobView {...props} />,
  ]
  return (
    <Media query='(max-width: 1000px)'>
      {(matches) => {
        return matches ? mediaComponents[1] : mediaComponents[0]
      }}
    </Media>
  )
}

export default BookDialogContent
