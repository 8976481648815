import React, { useState } from 'react'
import SpreadsheetChartConfigs from './SpreadsheetChartConfigs'
import SpreadsheetChart from './SpreadsheetChart'
import SpreadsheetGrid from './SpreadsheetGrid'
import ButtonsSystem from '../../buttonsSystem/ButtonsSystem'
import axios from 'axios'

const SpreadsheetEditing = (props) => {
  const {
    windowCloser,
    charted,
    rows,
    headers,
    setCards,
    chartType,
    stampType,
    inputValue,
  } = props
  const rtl = document.body.dir === 'rtl'
  const [isSaving, setIsSaving] = useState(false)
  const buttonsArray = [
    {
      title: !charted
        ? rtl
          ? 'حفظ'
          : 'Save'
        : rtl
        ? isSaving
          ? 'جارى الحفظ'
          : 'حفظ التغييرات'
        : isSaving
        ? 'Saving'
        : 'Save Changes',
      funs: async () => {
        if (charted) {
          setIsSaving(true)
          const res = await axios.patch(`/chartedData/${charted?._id}`, {
            rows,
            headers,
            chartType,
            stampType,
            startingPointInput: inputValue,
          })
          setCards((charteds) => [
            ...charteds.filter((p) => p._id !== charted?._id),
            res.data.charted,
          ])
          setIsSaving(false)
        }
        windowCloser()
      },
    },
    {
      title: rtl ? 'الغاء' : 'Cancel',
      funs: () => {
        windowCloser()
      },
    },
  ]
  return (
    <>
      <SpreadsheetChart {...props} hideUpdate />
      <SpreadsheetChartConfigs {...props} />
      <SpreadsheetGrid {...props} />
      <ButtonsSystem buttonsArray={buttonsArray} />
    </>
  )
}

export default SpreadsheetEditing
