import React from 'react'
import Media from 'react-media'
import ImageViewerPCView from './ImageViewerComponents/ImageViewerPCView'
import ImageViewerMobView from './ImageViewerComponents/ImageViewerMobView'

const ImageViewer = (props) => {
  const mediaComponents = [
    <ImageViewerPCView {...props} />,
    <ImageViewerMobView {...props} />,
  ]
  return (
    <Media query='(max-width: 1000px)'>
      {(matches) => {
        return matches ? mediaComponents[1] : mediaComponents[0]
      }}
    </Media>
  )
}

export default ImageViewer
