const FileSystemGetOutAction = ({
  setCardId,
  setSecondLevelFolderPath,
  setThirdLevelFolderPath,
  setFourthLevelFolderPath,
  members,
  setShowSearchInput,
  setSearchQuery,
  setSearchInputValue,
  setIsSubDepSubdDepMemberProfileOpen,
  subdepartment,
  setHideFSPanel,
}) => {
  if (typeof setCardId === 'function') setCardId(undefined)
  if (subdepartment) {
    if (typeof setIsSubDepSubdDepMemberProfileOpen === 'function')
      setIsSubDepSubdDepMemberProfileOpen(false)
    if (typeof setHideFSPanel === 'function') setHideFSPanel(false)
  }
  if (typeof setSearchInputValue === 'function' && members)
    setSearchInputValue('')
  if (typeof setSecondLevelFolderPath === 'function')
    setSecondLevelFolderPath('')
  if (typeof setThirdLevelFolderPath === 'function') setThirdLevelFolderPath('')
  if (typeof setFourthLevelFolderPath === 'function')
    setFourthLevelFolderPath('')
  if (typeof setShowSearchInput === 'function') setShowSearchInput(false)
  if (typeof setSearchQuery === 'function') setSearchQuery('')
  if (typeof setSearchInputValue === 'function') setSearchInputValue('')
}

export default FileSystemGetOutAction
