import React from 'react'
import withStyles from '@mui/styles/withStyles'
import ImageUnit from './ImageUnit'

const styles = () => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
})

const UpImagesViewerMob = ({ classes, images, removeImage }) => {
  return (
    <article className={classes.wrapper}>
      {images.map((image, imageIndex) => (
        <ImageUnit
          image={image}
          key={imageIndex}
          imageIndex={imageIndex}
          removeImage={removeImage}
        />
      ))}
    </article>
  )
}

const WrappedUpImagesViewerMob = withStyles(styles)(UpImagesViewerMob)

export default WrappedUpImagesViewerMob
