import React, { useContext, useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import EditingSystem from '../../../../../../../../../editingSystem/EditingSystem'
import PostProps from '../../../../../../../../../homeFeed/homeFeedComponents/HomeFeedPostModelComponents/PostProps'
import axios from 'axios'
import PopoverMenu from '../../../../../../../../../popoverMenu/PopoverMenu'
import { AuthContext } from '../../../../../../../../../../context/AuthContext'

const styles = () => ({
  postTopRight: {
    display: 'flex',
    cursor: 'pointer',
    padding: 7,
  },
})

const RoomSMDisTopRight = ({ classes, postProps }) => {
  const { post, hideRightOptions, setPinnedDiscussions, setPosts } = postProps
  const { user } = useContext(AuthContext)
  const { postEditDialogOpener, postEditDialogClose, isPostEditDialogOpen } =
    PostProps(
      {},
      {
        editingSystemProps: true,
      }
    )
  const [pinned, setPinned] = useState(post?.roomPinned)
  const postsStateUpdate = (res) => {
    setPosts((posts) => {
      let oldIndex = posts?.findIndex((p) => p._id === post?._id)
      return posts.map((p, i) => (i === oldIndex ? res.data.post : p))
    })
  }
  const pinPostHanlder = async () => {
    try {
      const res = await axios.patch(`/posts/${post?._id}`, { roomPinned: true })
      setPinned(true)
      setPinnedDiscussions((pns) => [post, ...pns])
      postsStateUpdate(res)
    } catch (error) {
      console.log(error)
    }
  }

  const unPinPostHanlder = async () => {
    try {
      const res = await axios.patch(`/posts/${post?._id}`, {
        roomPinned: false,
      })
      setPinned(false)
      setPinnedDiscussions((pns) => pns.filter((n) => n?._id !== post?._id))
      postsStateUpdate(res)
    } catch (error) {
      console.log(error)
    }
  }
  const optionsArray = [
    {
      type: 'edit',
      funs: () => postEditDialogOpener(),
    },
    pinned
      ? {
          type: 'unpin',
          funs: unPinPostHanlder,
        }
      : {
          type: 'pin',
          funs: pinPostHanlder,
        },
  ]
  return (
    <div
      className={classes.postTopRight}
      style={{
        display:
          hideRightOptions || post?.userId !== user?._id ? 'none' : 'flex',
      }}
    >
      <PopoverMenu optionsArray={optionsArray} />
      <EditingSystem
        {...postProps}
        disabledPostTypesDefaultValue={0}
        shareTypeIndex={4}
        isPostEditDialogOpen={isPostEditDialogOpen}
        postEditDialogClose={postEditDialogClose}
      />
    </div>
  )
}

const WrappedRoomSMDisTopRight = withStyles(styles)(RoomSMDisTopRight)

export default WrappedRoomSMDisTopRight
