import React from 'react'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
    alignContent: 'center',
    flexDirection: 'column',
  },
  title: {
    fontSize: 30,
    fontWeight: 500,
    margin: 20,
  },
  type: {
    fontSize: 20,
  },
  status: {
    fontSize: 20,
  },
})

const ContractIntro = ({ classes, contract, DCGProps }) => {
  const { isDarkModeActive } = DCGProps
  return (
    <div className={classes.wrapper}>
      <div
        className={classes.title}
        style={{
          color: isDarkModeActive ? 'white' : 'inherit',
        }}
      >
        {contract?.title || contract?.Title}
      </div>
    </div>
  )
}

const WrappedContractIntro = withStyles(styles)(ContractIntro)

export default WrappedContractIntro
