import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { Avatar, ListItemIcon } from '@mui/material'
import { Link } from 'react-router-dom'

const styles = () => ({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    justifycontent: 'center',
    alignItems: 'center',
  },
  avatar: {
    width: '80px !important',
    height: '80px !important',
    borderRadius: '10px !important',
    cursor: 'pointer',
  },
  gridElementName: {
    fontSize: 12,
    fontWeight: 600,
    marginTop: 10,
  },
})

const ChosenMemberModel = ({ classes, element }) => {
  return (
    <article className={classes.listItem}>
      <ListItemIcon>
        <Link to={`/rofile/${element?.userId}`}>
          <Avatar
            src={element.image || element?.profilePicture}
            variant='square'
            className={classes.avatar}
          />
        </Link>
      </ListItemIcon>
      <span className={classes.gridElementName}>
        {element.name || element?.firstName + ' ' + element?.lastName}
      </span>
    </article>
  )
}

const WrappedChosenMemberModel = withStyles(styles)(ChosenMemberModel)

export default WrappedChosenMemberModel
