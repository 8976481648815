import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { AiFillFastForward } from 'react-icons/ai'
import WindowViewerSystem from '../../../../windowViewer/WindowViewerSystem'
import WindowProps from '../../../../windowViewer/WindowProps'
import MarketingStrategyAspectsConfigs from '../../../../projectProfile/ProjectProfileComponents/ProjectControlPanelComponents/ProjectModelingComponents/MarketingStrategiesComponents/MarketingStrategyAspectsComps/MarketingStrategyAspectsConfigs'

const styles = () => ({
  mentionWrapper: {
    fontWeight: 500,
    color: 'brown',
    cursor: 'pointer',
  },
  businessMTIcon: {
    margin: '2px 0 -2px 0',
    color: 'rgb(20, 88, 62)',
  },
})

const LocalMarStarAspectDetectorElement = ({
  classes,
  textPackage,
  word,
  isDarkModeActive,
  notEngineProps,
}) => {
  const aspectId = Number(word.slice(5, word.length))
  const aspect = MarketingStrategyAspectsConfigs().filter(
    (asp) => asp.id === aspectId
  )[0]
  const { isWindowOpen, windowOpener, windowCloser } = WindowProps()
  return (
    <>
      <span
        onClick={() => windowOpener()}
        className={classes.mentionWrapper}
        style={{ display: word.trim() ? null : 'none' }}
      >
        <AiFillFastForward className={classes.businessMTIcon} /> {aspect?.title}
      </span>
      <WindowViewerSystem
        isWindowOpen={isWindowOpen}
        windowCloser={windowCloser}
        isDarkModeActive={isDarkModeActive}
        windowType='markStraAspect'
        props={{
          DCGProps: notEngineProps?.DCGProps,
          aspect,
        }}
      />
    </>
  )
}

const WrappedLocalMarStarAspectDetectorElement = withStyles(styles)(
  LocalMarStarAspectDetectorElement
)

export default WrappedLocalMarStarAspectDetectorElement
