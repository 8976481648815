import React from 'react'
import GPDFDBodyModel from '../GeneralPDFDocumentComps/GPDFDBodyModel'

const ManagerialPlanPDFBody = ({ button }) => {
  const { plan } = button.PDFGenProps
  const configs = [
    {
      title: 'How_Your_Resources_will_be_managed?',
      titleValue: plan?.resources,
      disableTrans: true,
    },
    {
      title: 'How_Your_Marketing_Campaigns_will_be_managed?',
      titleValue: plan?.campaigns,
      disableTrans: true,
    },
    {
      title: 'How_Your_Tasks_will_be_managed?',
      titleValue: plan?.tasks,
      disableTrans: true,
    },
    {
      title: 'How_Your_Jobs_will_be_managed?',
      titleValue: plan?.jobs,
      disableTrans: true,
    },
    {
      title: 'How_Your_Channels_will_be_managed?',
      titleValue: plan?.channels,
      disableTrans: true,
    },
    {
      title: 'What_is_your_plan_for_expansion?',
      titleValue: plan?.expansion,
      disableTrans: true,
    },
    {
      title: 'How_to_manage_interactions_with_other_projects?',
      titleValue: plan?.projects,
      disableTrans: true,
    },
    {
      title: 'How_to_manage_interactions_with_other_investors?',
      titleValue: plan?.investros,
      disableTrans: true,
    },
    {
      title: 'How_to_manage_interactions_with_other_competitors?',
      titleValue: plan?.competitors,
      disableTrans: true,
    },
    {
      title: 'How_to_manage_products?',
      titleValue: plan?.products,
      disableTrans: true,
    },
    {
      title: 'Miscellaneous_Notes',
      titleValue: plan?.various,
      disableTrans: true,
    },

    {
      title: 'How_departments_will_be_managed?_What_are_the_departments_rules?',
      titleValue: plan?.departments,
      disableTrans: true,
    },
    {
      title: 'How_Sub-departments_and_teams_will_be_managed?',
      titleValue: plan?.subDeparments,
      disableTrans: true,
    },
    {
      title: 'What_is_the_project_policy_for_dealing_with_different_problems?',
      titleValue: plan?.issues,
      disableTrans: true,
    },
    {
      title: 'What_is_allowed_and_not_allowed_for_department_managers?',
      titleValue: plan?.depManager,
      disableTrans: true,
    },
    {
      title:
        'What_is_allowed_and_not_allowed_for_teams_and_sub-departments_managers?',
      titleValue: plan?.subDepManager,
      disableTrans: true,
    },
    {
      title: 'What_is_about_moving_members?',
      titleValue: plan?.moveMember,
      disableTrans: true,
    },
    {
      title: 'What_about_shares?',
      titleValue: plan?.shares,
      disableTrans: true,
    },
    {
      title: 'What_about_meetings?',
      titleValue: plan?.meetings,
      disableTrans: true,
    },
    {
      title: 'Miscellaneous_Notes',
      titleValue: plan?.various_2,
      disableTrans: true,
    },
  ]
  return (
    <GPDFDBodyModel
      hideCreator
      GPDFBodyModelRightProps={{
        button,
        configs: configs,
      }}
      button={button}
    />
  )
}

export default ManagerialPlanPDFBody
