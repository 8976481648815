import React, { useState, useEffect } from 'react'
import SearchInput from './SearchSystemComponents/SearchInput'
import ModelDialogWrappingII from '../dialogModelUnit/ModelDialogWrappingII'
import ResultsFilter from './SearchSystemComponents/ResultsFilter'
import SearchResults from './SearchSystemComponents/SearchResults'
import { useContext } from 'react'
import { AuthContext } from '../../context/AuthContext'
import axios from 'axios'
import { SettingsContext } from '../../context/SettingsContext/SettingsContext'

const Activation = (props) => {
  const { isSearchDialogOpen, searchDialogClose, searchQuerry } = props
  const [isSectionClicked, setIsSectionClicked] = useState(false)
  const [resultSectionId, setResultSectionId] = useState(0)
  const [clickAwayState, setClickAwayState] = useState(false)
  const { settings } = useContext(SettingsContext)
  const [isDarkModeActive, setIsDarkModeActive] = useState(
    settings?.Mode === 'Dark'
  )
  const [newSearchQuerry, setNewSearchQuerry] = useState(searchQuerry)
  const [searchQuerryState, setSearchQuerryState] = useState(searchQuerry)
  const [posts, setPosts] = useState([])
  const [sharedPosts, setSharedPosts] = useState([])
  const [sharedPostsUsers, setSharedPostsUsers] = useState([])
  const [postsUsers, setPostsUsers] = useState([])
  const [users, setUsers] = useState([])
  const [projects, setProjects] = useState([])
  const { user } = useContext(AuthContext)
  const [currentResponse, setCurrentResponse] = useState(true)
  const [page, setPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)

  const elementHashTag = [
    {
      hashTagType: 'job',
      resultSectionId: 4,
    },
    {
      hashTagType: 'task',
      resultSectionId: 5,
    },
    {
      hashTagType: 'product',
      resultSectionId: 6,
    },
    {
      hashTagType: 'resource',
      resultSectionId: 7,
    },
    {
      hashTagType: 'idea',
      resultSectionId: 8,
    },
    {
      hashTagType: 'inquiry',
      resultSectionId: 9,
    },
    {
      hashTagType: 'globalInquiry',
      resultSectionId: 10,
    },
    {
      hashTagType: 'campaign',
      resultSectionId: 11,
    },
    {
      hashTagType: 'investPack',
      resultSectionId: 12,
    },
    {
      hashTagType: 'issue',
      resultSectionId: 13,
    },
    {
      hashTagType: 'channel',
      resultSectionId: 14,
    },
  ]

  const hashTagType = elementHashTag.find(
    (h) => h?.resultSectionId === resultSectionId
  )?.hashTagType

  useEffect(() => {
    setNewSearchQuerry(searchQuerry)
    setSearchQuerryState(searchQuerry)
  }, [searchQuerry])

  const PostsDataSetter = (res) => {
    setPosts(page === 1 ? res.data.posts : [...posts, ...res.data.posts])
    setPostsUsers(
      page === 1 ? res.data.postsUsers : [...postsUsers, ...res.data.postsUsers]
    )
    setSharedPosts(
      page === 1
        ? res.data.sharedPosts
        : [...sharedPosts, ...res.data.sharedPosts]
    )
    setSharedPostsUsers(
      page === 1
        ? res.data.sharedPostsUsers
        : [...sharedPostsUsers, ...res.data.sharedPostsUsers]
    )
  }

  const UsersProjetsDataSetter = (res) => {
    setProjects(
      page === 1 ? res.data.projects : [...projects, ...res.data.projects]
    )
    setUsers(page === 1 ? res.data.users : [...users, ...res.data.users])
  }

  const fetchResults = async () => {
    setIsLoading(true)
    try {
      const res = await axios.post(
        `/searchSystems/generalsearch/?page=${page}&limit=4`,
        {
          searchQuery: newSearchQuerry,
          userId: user?._id,
        }
      )
      setCurrentResponse(
        Boolean(
          res.data.posts.length +
            res.data.users.length +
            res.data.projects.length
        )
      )
      PostsDataSetter(res)
      UsersProjetsDataSetter(res)
    } catch (error) {
      console.log(error)
    }
    setIsLoading(false)
  }
  const fetchSectionResults = async () => {
    setIsLoading(true)
    try {
      const res = await axios.get(
        `/searchSystems/generalsearch/elementSections/?page=${page}&limit=4&hashTagType=${hashTagType}&searchQuery=${newSearchQuerry}&userId=${user?._id}`
      )
      setCurrentResponse(Boolean(res.data.posts.length))
      PostsDataSetter(res)
    } catch (error) {
      console.log(error)
    }
    setIsLoading(false)
  }
  const fetchSocialResults = async () => {
    setIsLoading(true)
    try {
      const res = await axios.get(
        `/searchSystems/generalsearch/elementSections/social/?page=${page}&limit=4&searchQuery=${newSearchQuerry}&userId=${user?._id}`
      )
      setCurrentResponse(Boolean(res.data.posts.length))
      PostsDataSetter(res)
    } catch (error) {
      console.log(error)
    }
    setIsLoading(false)
  }
  const fetchUsersProjectsResults = async () => {
    setIsLoading(true)
    try {
      const res = await axios.get(
        `/searchSystems/generalsearch/elementSections/usersProjects/?page=${page}&limit=4&searchQuery=${newSearchQuerry}&userId=${
          user?._id
        }&target=${resultSectionId === 2 ? 'people' : 'pages'}`
      )
      setCurrentResponse(
        resultSectionId === 2
          ? Boolean(res.data.users.length)
          : Boolean(res.data.projects.length)
      )
      UsersProjetsDataSetter(res)
    } catch (error) {
      console.log(error)
    }
    setIsLoading(false)
  }
  useEffect(() => {
    if (newSearchQuerry && user?._id) {
      if (resultSectionId > 3) fetchSectionResults()
      if (resultSectionId === 0) fetchResults()
      if (resultSectionId === 1) fetchSocialResults()
      if (resultSectionId === 2 || resultSectionId === 3)
        fetchUsersProjectsResults()
    }
  }, [newSearchQuerry, user?._id, page, resultSectionId, hashTagType])

  const searchProps = {
    ...props,
    // funs
    setNewSearchQuerry,
    setSearchQuerryState,
    setResultSectionId,
    setIsSectionClicked,
    setClickAwayState,
    setIsDarkModeActive,
    setPage,
    setCurrentResponse,
    // states
    isDarkModeActive,
    isSectionClicked,
    resultSectionId,
    searchQuerryState,
    newSearchQuerry,
    page,
    currentResponse,
    isLoading,
    // data
    posts,
    projects,
    users,
    postsUsers,
    sharedPosts,
    sharedPostsUsers,
    elementHashTag,
  }

  const dialogsArray = [
    {
      title: (
        <>
          <SearchInput {...searchProps} />
          <ResultsFilter {...searchProps} />
        </>
      ),
      open: isSearchDialogOpen,
      closeFun: () => searchDialogClose(),
      content: <SearchResults searchProps={searchProps} />,
    },
  ]
  return (
    <ModelDialogWrappingII
      {...searchProps}
      dialogsArray={dialogsArray}
      inactivateClickAway={clickAwayState}
      mdialogMargins={'auto -24px auto -24px'}
    />
  )
}
const SearchSystem = (props) => {
  const { isSearchDialogOpen, searchDialogClose, searchQuerry } = props
  if (isSearchDialogOpen) {
    return <Activation {...props} />
  }
}

export default SearchSystem
