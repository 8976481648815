import { CardContent } from '@mui/material'
import React, { useContext } from 'react'
import withStyles from '@mui/styles/withStyles'
import ButtonsSystem from '../../../buttonsSystem/ButtonsSystem'
import FeedPostModelUnitCardContent from './ModelUnitCardContentComps/FeedPostModelUnitCardContent'
import { AuthContext } from '../../../../context/AuthContext'

const styles = () => ({
  button: {
    borderRadius: '10px !important',
    margin: '2px !important',
    textTransform: 'none !important',
    fontWeight: '550 !important',
    width: '100% !important',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important",
  },
  title: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-evenly',
    fontWeight: 600,
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'space-between',
  },
  cardContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  subtitle: {
    fontSize: 12,
    margin: 'auto 5px',
  },
  '@media (max-width: 600px)': {
    title: {
      fontSize: 15,
    },
  },
})

const ModelUnitCardContent = ({
  classes,
  setChosenCard,
  setChosenCardId,
  windowOpener,
  unit,
  isDarkModeActive,
  modelUnitProps,
}) => {
  const pathName = () => {
    return typeof modelUnitProps?.pathName === 'function'
      ? modelUnitProps?.pathName(unit)
      : modelUnitProps?.pathName
  }
  const { user } = useContext(AuthContext)
  const rtl = document.body.dir === 'rtl'
  const buttonsArray = [
    {
      title: rtl ? 'عرض' : 'Open',
      funs: () => {
        if (!modelUnitProps?.pathName) {
          windowOpener()
          setChosenCardId(unit?.id || unit?._id)
          setChosenCard(unit)
        }
      },
      pathName: pathName(),
      // this for the case someone trying to open his own profile
      disabled: unit?._id === user?._id || unit?.id === user?._id,
    },
    ...(modelUnitProps?.buttonsArray ? modelUnitProps?.buttonsArray : []),
  ]

  return (
    <CardContent
      className={classes.cardContentWrapper}
      sx={{
        color: isDarkModeActive ? 'white' : null,
      }}
    >
      <article className={classes.titleWrapper}>
        {unit?.hashTagType === 'feedPost' ? (
          <FeedPostModelUnitCardContent
            unit={unit}
            modelUnitProps={modelUnitProps}
          />
        ) : (
          <span className={classes.title}>
            {unit?.Title ||
              unit?.ProductName ||
              unit?.title ||
              unit?.name ||
              unit?.ProjectName}
          </span>
        )}
      </article>
      <ButtonsSystem
        buttonsArray={buttonsArray}
        element={unit}
        isDarkModeActive={isDarkModeActive}
      />
    </CardContent>
  )
}
const WrappedModelUnitCardContent = withStyles(styles)(ModelUnitCardContent)

export default WrappedModelUnitCardContent
