import React, { useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import { Chip, OutlinedInput } from '@mui/material'
import ButtonsSystem from '../../buttonsSystem/ButtonsSystem'
import { toast } from 'react-toastify'

const styles = () => ({
  input: {
    height: 35,
    width: '100%',
    marginTop: 10,
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  sites_wrapper: {
    fontSize: 15,
    fontWeight: 500,
    padding: 10,
    margin: 10,
    borderRadius: 10,
    background: 'rgba(217,224,252, 0.1)',
    fontStyle: 'oblique',
  },
})

const LinkAdditionDialogContent = ({
  classes,
  setLink,
  windowCloser,
  isDarkModeActive,
  link,
}) => {
  const [change, setChange] = useState(link)
  const rtl = document.body.dir === 'rtl'
  const buttonsArray = [
    {
      title: rtl ? 'إضافة' : 'Add',
      funs: () => {
        setLink(change)
        windowCloser()
          toast.success(
            rtl
              ? 'تم اضافة الرابط بنجاح'
              : 'The link has been added successfully'
          )
      },
      disabled: link === change,
    },
    {
      title: rtl ? 'إلغاء' : 'Cancel',
      funs: () => {
        windowCloser()
      },
    },
  ]
  const supportedSites = [
    'YouTube',
    'SoundCloud',
    'Facebook',
    'Vimeo',
    'Wistia',
    'Mixcloud',
    'Dailymotion',
    'Twitch',
  ]
  return (
    <div style={{ color: isDarkModeActive && 'white' }}>
      <OutlinedInput
        className={classes.input}
        onChange={(event) => {
          setChange(event.currentTarget.value)
        }}
        value={change || ''}
        placeholder={rtl ? 'أضف رابط' : 'Add link'}
        style={{
          color: isDarkModeActive ? 'white' : 'black',
          borderRadius: 20,
          marginRight: 5,
        }}
      />
      <ButtonsSystem buttonsArray={buttonsArray} />
      <article className={classes.sites_wrapper}>
        <div
          style={{
            padding: 5,
            marginBottom: 10,
            fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
          }}
        >
          {rtl
            ? 'يمكنك إضافة رابط إلى مقطع فيديو أو صوت للمواقع المدعومة للمشاركة ، عن طريق إدراج الرابط الخاص به'
            : 'You can add a link to a video or an audio of the supported sites to share, by inserting its link to the input'}
        </div>
        <section
          style={{ display: 'flex', flexFlow: 'row wrap', width: '100%' }}
        >
          {supportedSites?.map((site, idnex) => (
            <Chip
              key={idnex}
              label={site}
              style={{
                color: isDarkModeActive ? 'white' : null,
                fontSize: 12,
                margin: '2px',
                fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
              }}
            />
          ))}
        </section>
      </article>
    </div>
  )
}

const WrappedLinkAdditionDialogContent = withStyles(styles)(
  LinkAdditionDialogContent
)

export default WrappedLinkAdditionDialogContent
