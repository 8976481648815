import { useTranslation } from 'react-i18next'

const DirectSellingBusinessModel = () => {
  const { t } = useTranslation('DirectSellingBusinessModel')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 14,
    title: t('title_bm14'),
    subTitle: t('subTitle_bm14'),
    main: t('main_bm14'),
    when: t('when_bm14'),
    who: t('who_bm14'),
    what: t('what_bm14'),
    how: t('how_bm14'),
    why: t('why_bm14'),
    examples: rtl
      ? [
          'تابروير - 1983 - الولايات المتحدة الأمريكية',
          'هيلتي - 1941 - ليختنشتاين',
          'Dell - 1984 - الولايات المتحدة الأمريكية',
        ]
      : [
          'Tupperware - 1983 - USA',
          'Hilti - 1941 - Liechtenstein',
          'Dell - 1984 - USA',
        ],
    tags: rtl
      ? [
          'لا يوجد بائعي تجزئة',
          ' لا حواجز للعملاء',
          'اتصال مباشر',
          'تكاليف هامشية منخفضة',
          'تجربة عملاء أفضل',
          'خدمة منتجي',
          'تطوير المنتجات',
          'تفاعل العملاء القياسي',
          'معلومات دقيقة عن المبيعات',
          'تحكم أفضل',
          'تنبؤ أفضل',
          'التوزيع الخاص',
          'التسويق الخاص',
          'تحليل أفضل',
          'خدمات معقدة',
          'منتجات متطورة',
          'تثقيف العملاء',
          'منتجات جديدة',
          'اختبار المنتج',
          'منتجات باهظة الثمن',
          'مندوبي المبيعات',
        ]
      : [
          'No Retailers',
          'No Customer Barriers',
          'Direct Contact',
          'Low Marginal Costs',
          'Better Customer Experience',
          'Serve My Product',
          'Product Development',
          'Standard Customer Interaction',
          'Accurate Sales Information',
          'Better Control',
          'Better Forecasting',
          'Own Distribution',
          'Own Marketing',
          'Better Analysis',
          'Complex Services',
          'Sophisticated Products',
          'Educating the Customer',
          'New Products',
          'Product Testing',
          'Expensive Products',
          'Sales Representatives',
        ],
    considerations: t('considerations_bm14'),
    combinations: ['$LBM16', '$LBM22', '$LBM61'],
    hashTagType: 'businessModelTemp',
  }
}

export default DirectSellingBusinessModel
