import React, { useState } from 'react'
import ChooseElements from './MultiSelectViewerSystemComponents/ChooseElements'
import ChosenElementsViewed from './MultiSelectViewerSystemComponents/ChosenElementsViewed'

const MultiSelectViewerSystem = (props) => {
  const { elements, selectedElements, setSelectedElements } = props
  const isSelectedElements =
    elements.length > 0 && selectedElements.length === elements.length
  const choosingElements = (event) => {
    const value = event.target.value
    if (value[value.length - 1] === 'all') {
      setSelectedElements(
        selectedElements.length === elements.length
          ? []
          : elements.map((dep) => dep?._id || dep?.id)
      )
      return
    }
    setSelectedElements(value)
  }
  const [selectedElementsArray, setselectedElementsArray] = useState([])
  const showElements = () => {
    setselectedElementsArray(
      elements.filter((dep) => selectedElements.includes(dep?._id || dep?.id))
    )
  }

  const propsII = {
    ...props,
    selectedElementsArray,
    choosingElements,
    isSelectedElements,
    showElements,
  }

  return (
    <article style={{ width: '100%' }}>
      <ChooseElements {...propsII} />
      <ChosenElementsViewed {...propsII} />
    </article>
  )
}

export default MultiSelectViewerSystem
