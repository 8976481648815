import React from 'react'
import TitleHeader from '../../../../../../../SmallComponents/Header'
import SingleContributorModel from '../../../../../../../membersSystems/SingleContributorModel'
import OneUserCall from '../../../../../../../apiCalls/OneUserCall'
import Loading from '../../../../../../../SmallComponents/Loading'

const InquirPairing = ({ DCGProps, inquiry, user }) => {
  const { isLoading, modUser } = OneUserCall({
    userId: inquiry?.pairedUserId,
  })
  const rtl = document.body.dir === 'rtl'
  return isLoading ? (
    <Loading />
  ) : (
    <>
      <TitleHeader headerText={rtl ? 'إقتران المتطلب' : 'Inquiry Pairing'} />
      <SingleContributorModel
        windowType={'userContributor'}
        member={{
          ...modUser,
          hashTagType: 'userContributor',
        }}
        DCGProps={DCGProps}
        emptySentence={
          rtl
            ? 'هذا المتطلب لم يتم ربطه بعد بأى مساهم، يمكنك ان تختار من طلبات الاقتران'
            : 'This inquiry is not paired yet, Choose a contributor from the requests to Pair it with.'
        }
      />
    </>
  )
}

export default InquirPairing
