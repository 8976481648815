const RepetionRemover = (elements) => {
  const hashMap = {}
  const uniqueResults = elements
    ?.filter((u) => u?._id)
    ?.filter((item, _) => {
      let alreadyExists = hashMap?.hasOwnProperty(item._id)
      return alreadyExists ? false : (hashMap[item._id] = 1)
    })
  return uniqueResults
}

export default RepetionRemover
