export default function coreEngine(searchIdtentifiers, element, tempArray2) {
  let tempArray = []
  //   for arrays
  const arrayPortions = (searchIdtentifiers, arr) => {
    arr.forEach((el) => {
      if (typeof el === 'string') {
        tempArray.push(el)
      } else if (typeof el === 'object' && typeof el?.length === 'undefined') {
        rescursiveObjectGrapper(searchIdtentifiers, el)
      } else if (typeof el === 'object' && typeof el?.length === 'number') {
        arrayPortions(searchIdtentifiers, el)
      }
    })
  }

  const rescursiveObjectGrapper = (searchIdtentifiers, element, tempArray2) => {
    searchIdtentifiers?.forEach((identifier) => {
      if (element[identifier])
        if (typeof element[identifier] === 'string') {
          tempArray.push(element[identifier])
        } else if (
          typeof element[identifier] === 'object' &&
          typeof element[identifier]?.length === 'undefined'
        ) {
          rescursiveObjectGrapper(searchIdtentifiers, element[identifier])
        } else if (
          typeof element[identifier] === 'object' &&
          typeof element[identifier]?.length === 'number'
        ) {
          arrayPortions(searchIdtentifiers, element[identifier])
        }
    })
    tempArray2 = tempArray
    return tempArray2
  }
  return rescursiveObjectGrapper(searchIdtentifiers, element, tempArray2)
}
