import React, { useState, useEffect } from 'react'
import coreEngine from './SearchEngineComponents/EngineCore'
import SearchAlphebetic from './SearchEngineComponents/SearchAlphebetic'
import SearchNonAlphebetic from './SearchEngineComponents/SearchNonAlphebetic'

const SearchEngine = ({
  searchElements,
  SearchedElementComponent,
  SearchedElementComponentProps,
  firstLetterSearch,
  searchIdtentifiers,
  searchQuery,
  freeSearch,
  alphabeticOrder,
  alphabeticOrderDir,
  // for file system
  extraCondition,
  elementKey,
  rawResults,
  disableSearch,
}) => {
  // searching elements
  const [searchResults, setSearchResults] = useState(searchElements)

  useEffect(() => {
    if (!disableSearch)
      if (firstLetterSearch) {
        //checking if searching first letter is required
        if (searchQuery.length > 0) {
          if (searchQuery.length === 1) {
            // check those start with the first letter - in case of simple filter by 1 character - the first element in the identifiers array is the one searched here so make sure to make it the first in the array if the single letter search is active
            const starters = searchElements.filter((element) => {
              let tempArr2 = []
              return (
                coreEngine(searchIdtentifiers, element, tempArr2).filter(
                  (str) =>
                    str
                      ?.toString()
                      .toUpperCase()
                      .startsWith(searchQuery.toUpperCase()[0])
                ).length > 0
              )
            })
            setSearchResults(starters)
          } else if (searchQuery.length > 1) {
            //actively searching the data using the identifier after determining it
            setSearchResults(
              searchElements.filter((element) => {
                let tempArr2 = []
                return (
                  coreEngine(searchIdtentifiers, element, tempArr2).filter(
                    (str) =>
                      (typeof str === 'string' &&
                        str
                          .toUpperCase()
                          .includes(searchQuery.toUpperCase())) ||
                      (typeof str === 'number' &&
                        str.toString().includes(searchQuery))
                  ).length > 0
                )
              })
            )
          }
        }
      } else {
        //actively searching the data using the identifier after determining it
        setSearchResults(
          searchElements.filter((element) => {
            let tempArr2 = []
            return (
              coreEngine(searchIdtentifiers, element, tempArr2).filter(
                (str) =>
                  (typeof str === 'string' &&
                    str.toUpperCase().includes(searchQuery.toUpperCase())) ||
                  (typeof str === 'number' &&
                    str.toString().includes(searchQuery))
              ).length > 0
            )
          })
        )
      }
  }, [searchQuery])
  return rawResults ? (
    disableSearch ? (
      searchElements
    ) : (
      searchResults
    )
  ) : alphabeticOrder ? (
    <SearchAlphebetic
      searchResults={disableSearch ? searchElements : searchResults}
      SearchedElementComponent={SearchedElementComponent}
      freeSearch={freeSearch}
      SearchedElementComponentProps={SearchedElementComponentProps}
      searchQuery={searchQuery}
      searchIdtentifiers={searchIdtentifiers}
      searchElements={searchElements}
      alphabeticOrderDir={alphabeticOrderDir}
      // for file system
      extraCondition={extraCondition}
      elementKey={elementKey}
    />
  ) : (
    <SearchNonAlphebetic
      searchResults={disableSearch ? searchElements : searchResults}
      SearchedElementComponent={SearchedElementComponent}
      freeSearch={freeSearch}
      SearchedElementComponentProps={SearchedElementComponentProps}
      searchQuery={searchQuery}
      searchElements={searchElements}
      // for file system
      extraCondition={extraCondition}
      elementKey={elementKey}
    />
  )
}

export default SearchEngine
