import React, { createContext, useEffect, useReducer } from 'react'
import AuthReducer from './AuthReducer'
import CryptoJS from 'crypto-js'
import cookies from 'js-cookie'

const USER_DECRYPT_SECRET_KEY = String(process.env.USER_DECRYPT_SECRET_KEY)

const INITIAL_STATE = {
  user: null, 
  isFetching: false,
  error: false,
}

try {
  // getting the data from the cookie
  const encryptedUser = cookies.get('user')
  if (encryptedUser) {
    const decryptedData = CryptoJS.AES.decrypt(
      encryptedUser,
      USER_DECRYPT_SECRET_KEY
    ).toString(CryptoJS.enc.Utf8)
    if (decryptedData) {
      INITIAL_STATE.user = JSON.parse(decryptedData)
    }
  }
} catch (error) {
  console.error(error)
}

export const AuthContext = createContext(INITIAL_STATE)

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE)

  const ecryptedData = CryptoJS.AES.encrypt(
    JSON.stringify(state.user),
    USER_DECRYPT_SECRET_KEY
  ).toString()

  useEffect(() => {
    if (state.user) {
      // adding to the local storage of this domain
      localStorage.setItem('hutupia_user', ecryptedData)

      // sending it to other subdomians through cookies
      cookies.set('user', ecryptedData, {
        domain: '.hutupia.com',
        expires: 7,
        secure: true,
      })
    }
  }, [state.user])

  return (
    <AuthContext.Provider
      value={{
        user: state.user,
        isFetching: state.isFetching,
        error: state.error,
        dispatch,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
