import React, { useContext, useState } from 'react'
import { RiUserAddLine, RiUserFollowFill } from 'react-icons/ri'
import withStyles from '@mui/styles/withStyles'
import NotificationCreator from '../notificationsSystem/NotificationCreator'
import { AuthContext } from '../../context/AuthContext'
import axios from 'axios'

const styles = () => ({
  followChatOptionsIcons: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: 5,
    width: '80%',
  },
  followChatOptionsIconsItem: {
    fontSize: 21,
    padding: 5,
    cursor: 'pointer',
  },
  '@media (max-width: 600px)': {},
})

const UserProfileFollowUnfollow = ({ classes, socket, userId }) => {
  const { user, dispatch } = useContext(AuthContext)
  const [follow, setFollow] = useState(user?.followings.includes(userId))
  const onFollow = async () => {
    if (userId)
      try {
        if (follow) {
          await axios.put(`/users/${userId}/unfollow`, { userId: user?._id })
          dispatch({ type: 'UNFOLLOW', payload: userId })
        } else {
          await axios.put(`/users/${userId}/follow`, { userId: user?._id })
          dispatch({ type: 'FOLLOW', payload: userId })
        }
        setFollow(!follow)
      } catch (error) {
        console.log(error)
      }
  }
  const followIcons = [
    <RiUserAddLine className={classes.followChatOptionsIconsItem} />,
    <RiUserFollowFill className={classes.followChatOptionsIconsItem} />,
  ]
  return (
    <span
      onClick={() => {
        onFollow()
        if (!follow)
          NotificationCreator({
            associativity: 'userProfile',
            relation: 'owner',
            conditionIndex: 0,
            accompaniedData: {},
            category: 'general',
            userId,
            currentUser: user,
            socket,
          })
      }}
    >
      {follow ? followIcons[1] : followIcons[0]}
    </span>
  )
}

const WrappedUserProfileFollowUnfollow = withStyles(styles)(
  UserProfileFollowUnfollow
)

export default WrappedUserProfileFollowUnfollow
