import React from 'react'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  image: {
    width: '100%',
    borderRadius: 10,
    cursor: 'pointer',
    height: 'auto',
    borderRadius: 0,
    objectFit: 'cover',
    maxHeight: 400,
  },
  galleryWrapper: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    overflowX: 'auto',
  },
})

const GalleryMobVersion = ({ classes, openFun, imagesArray }) => {
  const rtl = document.body.dir === 'rtl'
  return (
    <div className={classes.galleryWrapper}>
      {imagesArray.map((image, imageIndex) => (
        <img
          key={imageIndex}
          src={image}
          alt='Images Viewer System'
          className={classes.image}
          onClick={() => {
            openFun()
          }}
          style={{
            // marginRight: imageIndex === imagesArray.length - 1 ? 0 : 4,
            marginRight: !rtl && imageIndex === imagesArray.length - 1 ? 0 : 4,
            marginLeft: rtl && imageIndex === imagesArray.length - 1 ? 0 : 4,
          }}
        />
      ))}
    </div>
  )
}

const WrappedGalleryMobVersion = withStyles(styles)(GalleryMobVersion)

export default WrappedGalleryMobVersion
