import React, { useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import {
  HomeProjectButtonsModel,
  PostPublishButtonConfig,
  PostUpdateButtonConfig,
  PostCancelButtonConfig,
  PostDeleteButtonConfig,
  PostAdvertiseButtonConfig,
  GeneralPost,
} from '../'
import ShareTopProfileInfo from './ShareTopProfileInfo'

const styles = () => ({
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
})

const ProfileShareModel = ({ classes, shareSystemProps }) => {
  const { shareTypeIndex, placeholder, post } = shareSystemProps
  const [deletePost, setDeletePost] = useState(false)
  const homeButtonsArray = [
    PostPublishButtonConfig({
      shareSystemProps,
    }),
    PostCancelButtonConfig({
      shareSystemProps,
    }),
    PostUpdateButtonConfig({
      shareSystemProps,
    }),
    PostDeleteButtonConfig({
      shareSystemProps,
      setDeletePost,
    }),
    PostAdvertiseButtonConfig({
      shareSystemProps,
    }),
  ]

  const deleteButtons = [
    PostDeleteButtonConfig({
      shareSystemProps,
    }),
    PostCancelButtonConfig({
      shareSystemProps,
    }),
  ]

  return (
    <article>
      <ShareTopProfileInfo shareSystemProps={shareSystemProps} />
      <GeneralPost
        placeholder={placeholder}
        shareSystemProps={shareSystemProps}
        hideAddPostImages={
          post?.postTypeIndex === 2 ||
          (post?.postTypeIndex !== 3 &&
            post?.postElement &&
            shareTypeIndex !== 0) ||
          (shareTypeIndex !== 0 && post?.sharedPostId) ||
          post?.chartData?.length > 0
        }
      />
      <section className={classes.buttonsWrapper}>
        {deletePost ? (
          <HomeProjectButtonsModel buttonsArray={deleteButtons} />
        ) : (
          <HomeProjectButtonsModel
            buttonsArray={
              shareTypeIndex === 4
                ? homeButtonsArray.slice(1, 4)
                : homeButtonsArray
            }
          />
        )}
      </section>
    </article>
  )
}

const WrappedProfileShareModel = withStyles(styles)(ProfileShareModel)

export default WrappedProfileShareModel
