import React from 'react'
import TimePicker from 'react-time-picker'

const TimeInputEditingZone = ({ setInfoValue, infoValue, info }) => {
  return (
    <div>
      <TimePicker
        onChange={(e) => setInfoValue(e)}
        value={infoValue}
        required={info?.required}
        locale={document.body.dir === 'rtl' && 'ar'}
        disabled={info?.disabled}
      />
    </div>
  )
}

export default TimeInputEditingZone
