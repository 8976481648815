import React, { useContext, useState } from 'react'
import ButtonsSystem from '../../../buttonsSystem/ButtonsSystem'
import CentralTitle from '../../../SmallComponents/CentralTitle'
import axios from 'axios'
import { AuthContext } from '../../../../context/AuthContext'
import NotificationCreator from '../../../notificationsSystem/NotificationCreator'
import FileSystemGetOutAction from '../../FileSystemGetOutAction'
import ToastifyGeneralError from '../../../SmallComponents/ToastifyGeneralError'

const AddToBoardDialogContent = ({ noFSPanelProps, windowCloser }) => {
  const { socket, member, project, isDarkModeActive } = noFSPanelProps
  const rtl = document.body.dir === 'rtl'
  const { user } = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(false)
  const buttonsArray = [
    {
      title: rtl ? 'إلغاء' : 'Cancel',
      funs: windowCloser,
      disabled: isLoading,
    },
    {
      title: rtl ? 'إزالة' : 'Remove',
      disabled: isLoading,
      funs: async () => {
        try {
          setIsLoading(true)
          await axios.patch(
            `/projectContributors/${member?.userId}/${project?._id}`,
            {
              MembershipState: 'Board',
            }
          )

          socket.current.emit('addNotifUser', member?.userId)
          NotificationCreator({
            associativity: 'project',
            relation: 'board',
            conditionIndex: 0,
            accompaniedData: {
              projectId: project?._id,
            },
            category: 'contributors',
            userId: member?.userId,
            currentUser: user,
            socket,
          })
          setIsLoading(false)
          windowCloser()
          FileSystemGetOutAction(noFSPanelProps)
        } catch (error) {
          console.log(error)
          ToastifyGeneralError(error)
        }
      },
    },
  ]
  return (
    <article style={{ color: isDarkModeActive && 'white' }}>
      <CentralTitle
        title={rtl ? 'إضافة الى مجلي الإدارة' : 'Add to Board Members'}
      />
      <p>
        {rtl
          ? 'بإضافة العضو الجديد إلى أعضاء المجلس، سيتم إخطاره تلقائيًا، ويمكن أن يكون من المفيد التفكير في إجراء استطلاع لآراء أعضاء المجلس الآخرين قبل اتخاذ قرار إضافة العضو الجديد.'
          : 'By opting to add a new member to the board, they will receive an automatic notification upon being added. To ensure informed decision-making, it may be beneficial to consider adding a poll to gather input from other board members before proceeding with the addition.'}
      </p>
      <ButtonsSystem buttonsArray={buttonsArray} />
    </article>
  )
}

export default AddToBoardDialogContent
