import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { LinearProgress } from '@mui/material'

const styles = () => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    flexDirection: 'column',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
  },
  linearProgress: {
    width: 200,
  },
})

const ProtocolRunnerLoader = ({ text, classes }) => {
  return (
    <div className={classes.wrapper}>
      <span >{text}</span>
      <LinearProgress className={classes.linearProgress} />
    </div>
  )
}

const WrappedProtocolRunnerLoader = withStyles(styles)(ProtocolRunnerLoader)

export default WrappedProtocolRunnerLoader
