import { useEffect } from 'react'

const DocumentTitleChanger = (text) => {
  const rtl = document.body.dir === 'rtl'
  useEffect(() => {
    if (text) document.title = `${rtl ? 'هيوتوبيا' : 'Hutupia'} | ${text}`
  }, [text, rtl])
}

export default DocumentTitleChanger
