import React, { useState, useEffect } from 'react'
import { Tooltip } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import DeleteConfirmDialog from '../../confirmationSystem/DeleteConfirmDialog'
import { MdDelete } from 'react-icons/md'
import NotificationsSentConfirmDialog from '../../confirmationSystem/NotificationsSentConfirmDialog'

const styles = () => ({
  icon: {
    cursor: 'pointer',
    fontSize: 18,
    '&:hover': {
      opacity: 0.8,
    },
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
})

const DeleteWindowFolderConfirmSent = ({ classes, noFSPanelProps }) => {
  const {
    deleteTippy,
    showDeleteIcon,
    Name,
    deletionAlert,
    onDelete,
    isDarkModeActive,
    element,
    confirmmationTitles,
    setCardId,
    setSecondLevelFolderPath,
    setThirdLevelFolderPath,
    setFourthLevelFolderPath,
    members,
    setShowSearchInput,
    setSearchQuery,
    setSearchInputValue,
    setIsSubDepSubdDepMemberProfileOpen,
    subdepartment,
    setHideFSPanel,
  } = noFSPanelProps
  // ConfirmDelete dialog
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState(false)
  const ConfirmDeleteDialogOpener = () => {
    setIsConfirmDeleteDialogOpen(true)
  }
  const ConfirmDeleteDialogClose = () => {
    setIsConfirmDeleteDialogOpen(false)
  }

  // confirm dialog funs
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setIsConfirmDialogOpen(false)
    }, 10000)
  }, [isConfirmDialogOpen === true])
  const rtl = document.body.dir === 'rtl'
  const confirmmationTitlesII = [
    {
      title: rtl
        ? 'تم إرسال الطلب إلى المدير التنفيذي'
        : 'Request is Sent to the CEO',
      tip: rtl
        ? 'ستتلقى إشعارًا بالرد في قوائم إشعارات المشاركين'
        : 'You will recieve a notification with response in contributors Notifications rolls',
    },
  ]

  return (
    <>
      <Tooltip title={deleteTippy}>
        <span
          className={classes.iconWrapper}
          style={{ display: showDeleteIcon ? null : 'none' }}
        >
          <MdDelete
            onClick={() => {
              ConfirmDeleteDialogOpener()
            }}
            className={classes.icon}
          />
        </span>
      </Tooltip>
      <DeleteConfirmDialog
        confirmDeleteAction={() => {
          setIsConfirmDialogOpen(true)
          onDelete(element)
          setCardId(undefined)
          if (typeof setCardId === 'function') setCardId(undefined)
          if (subdepartment) {
            if (typeof setIsSubDepSubdDepMemberProfileOpen === 'function')
              setIsSubDepSubdDepMemberProfileOpen(false)
            if (typeof setHideFSPanel === 'function') setHideFSPanel(false)
          }
          if (typeof setSearchInputValue === 'function' && members)
            setSearchInputValue('')
          if (typeof setSecondLevelFolderPath === 'function')
            setSecondLevelFolderPath('')
          if (typeof setThirdLevelFolderPath === 'function')
            setThirdLevelFolderPath('')
          if (typeof setFourthLevelFolderPath === 'function')
            setFourthLevelFolderPath('')
          if (typeof setShowSearchInput === 'function')
            setShowSearchInput(false)
          if (typeof setSearchQuery === 'function') setSearchQuery('')
          if (typeof setSearchInputValue === 'function') setSearchInputValue('')
        }}
        confirmDeleteDialogClose={ConfirmDeleteDialogClose}
        isConfirmDeleteDialogOpen={isConfirmDeleteDialogOpen}
        deletedObjectName={Name}
        // deletedObjectId={}
        deletionAlert={deletionAlert}
        isDarkModeActive={isDarkModeActive}
      />
      <NotificationsSentConfirmDialog
        confirmmationTitles={confirmmationTitles || confirmmationTitlesII}
        confirmationRTitleIndex={0}
        isConfirmDialogOpen={isConfirmDialogOpen}
        setIsConfirmDialogOpen={setIsConfirmDialogOpen}
        isDarkModeActive={isDarkModeActive}
      />
    </>
  )
}

const WrappedDeleteWindowFolderConfirmSent = withStyles(styles)(
  DeleteWindowFolderConfirmSent
)

export default WrappedDeleteWindowFolderConfirmSent
