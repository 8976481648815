import { useTranslation } from 'react-i18next'

const NewEntrants = () => {
  const { t } = useTranslation('NewEntrants')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 21,
    category: t('category_ms21'),
    title: t('title_ms21'),
    subTitle: t('subTitle_ms21'),
    main: t('main_ms21'),
    how: t('how_ms21'),
    usedInModels: [],
    tags: rtl
      ? [
          'العوائق المحتملة',
          'تقييم الدخول',
          'هيكل الصناعة',
          'هيكل التكاليف',
          'البيئة التنظيمية',
          'اللاعبون الحاليون',
          'إعتراف بعلامة تجارية',
          'ولاء العميل',
          'الوصول إلى الموارد',
          "تكنولوجيا'",
          'براءات الاختراع',
          'الترخيص',
        ]
      : [
          'Potential Barriers',
          'Assess Entry',
          'Industry Structure',
          'Cost Structure',
          'Regulatory Environment',
          'Existing Players',
          'Brand Recognition',
          'Customer Loyalty',
          'Access to Resources',
          'Technology',
          'Patents',
          'Licensing',
        ],
    examples: t('examples_ms21'),
    hashTagType: 'markStraAspect',
  }
}

export default NewEntrants
