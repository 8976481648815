import React from 'react'
import Media from 'react-media'
import ModelDialogWrappingIIIPC from './ModelDialogWrappingIIIComps/ModelDialogWrappingIIIPC'
import ModelDialogWrappingIIIMob from './ModelDialogWrappingIIIComps/ModelDialogWrappingIIIMob'

const ModelDialogWrappingIII = (props) => {
  const mediaComponents = [
    <ModelDialogWrappingIIIPC {...props} />,
    <ModelDialogWrappingIIIMob {...props} />,
  ]

  return (
    <Media query='(max-width: 600px)'>
      {(matches) => {
        return matches ? mediaComponents[1] : mediaComponents[0]
      }}
    </Media>
  )
}

export default ModelDialogWrappingIII
