import React, { useEffect, useState } from 'react'
import ModelDialogWrappingIII from '../dialogModelUnit/ModelDialogWrappingIII'
import { IconButton } from '@mui/material'
import { MdArrowLeft, MdArrowRight } from 'react-icons/md'
import ListedDialogModelContent from './ListedDialogModelComps/ListedDialogModelContent'
import axios from 'axios'
import CustomCircularProgress from '../SmallComponents/CustomCircularProgress'

const ListedDialogModel = ({
  listDialogClose,
  isListDialogOpen,
  isDarkModeActive,
  clickAwayState,
  dialogTitle,
  ListUnitModelComponent,
  ListUnitModelComponentProps,
  list,
  note,
  socket,
  interactionType,
}) => {
  const [viewers, setViewers] = useState()
  const [isLoading, setIsLoading] = useState()

  useEffect(() => {
    const fetchNoteViewers = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(`/managerNotes/noteViewers/${note?._id}`)
        setViewers(res.data.viewers)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    if (note?._id && isListDialogOpen) fetchNoteViewers()
  }, [note?._id, isListDialogOpen])
  const dialogsArray = [
    {
      open: isListDialogOpen,
      content: isLoading ? (
        <CustomCircularProgress />
      ) : (
        <ListedDialogModelContent
          list={note ? viewers : list}
          ListUnitModelComponent={ListUnitModelComponent}
          ListUnitModelComponentProps={ListUnitModelComponentProps}
          socket={socket}
          isDarkModeActive={isDarkModeActive}
          interactionType={interactionType}
        />
      ),
    },
  ]
  return (
    <ModelDialogWrappingIII
      inactivateClickAway={clickAwayState}
      dialogsArray={dialogsArray}
      dialogWidth='400px'
      dialogHeight='600px'
      hideCloseIcon={true}
      isDarkModeActive={isDarkModeActive}
      dialogTitle={dialogTitle}
      dialogIcon={
        <IconButton
          edge='start'
          color='inherit'
          onClick={() => {
            listDialogClose()
          }}
        >
          {document.body.dir === 'rtl' ? (
            <MdArrowLeft style={{ fontSize: 20, cursor: 'pointer' }} />
          ) : (
            <MdArrowRight style={{ fontSize: 20, cursor: 'pointer' }} />
          )}
        </IconButton>
      }
    />
  )
}

export default ListedDialogModel
