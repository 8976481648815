import React, { useEffect, useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import ImageUpdator from '../../../../../../../uploadImageSystem/ImageUpdator'
import ImageUploaderFrame from '../../../../../../../uploadImageSystem/ImageUploaderFrame'
import { useContext } from 'react'
import { AuthContext } from '../../../../../../../../context/AuthContext'
import DefaultImageGrapper from '../../../../../../../SmallComponents/DefaultImageGrapper'
import CustomCircularProgress from '../../../../../../../SmallComponents/CustomCircularProgress'
import axios from 'axios'

const styles = () => ({
  imageWrapper: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  image: {
    width: '100%',
    borderRadius: 10,
    marginTop: 10,
    maxHeight: 300,
    objectFit: 'cover',
  },

  '@media (max-width: 600px)': {
    image: {
      width: '100%',
    },
  },
})

const ProductSerProfileImage = ({
  classes,
  product,
  isDarkModeActive,
  managerProfile,
  setProjectProducts,
}) => {
  const { user } = useContext(AuthContext)
  const [image, setImage] = useState(product?.image)
  const noAdminMode =
    product?.ProductDesignerId !== user?._id &&
    managerProfile?._id !== user?._id
  const noAdminImage = DefaultImageGrapper('NoPhotoNoAdmin')
  const ResizerProps = {
    maxWidth: 500,
    maxHeight: 500,
  }
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    const updateCover = async () => {
      setIsLoading(true)
      try {
        await axios.patch(`/products/${product?._id}`, {
          image,
        })
        if (typeof setProjectProducts === 'function')
          setProjectProducts((prods) => [
            ...prods.filter((p) => p._id !== product?._id),
            { ...product, image },
          ])
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    if (image && image !== product?.image) updateCover()
  }, [image])

  return isLoading ? (
    <CustomCircularProgress />
  ) : image ? (
    <section className={classes.imageWrapper}>
      <ImageUpdator
        setImage={setImage}
        bottom={20}
        right={20}
        pathName={`/projects/${product?.projectId}/products`}
        ResizerProps={ResizerProps}
        updateProps={{
          path: `/products/${product?._id}`,
          payload: { image: '' },
        }}
        remImage={image}
        mui_pop
      />
      <img src={image} alt={product?.productName} className={classes.image} />
    </section>
  ) : noAdminMode ? (
    <img
      src={noAdminImage}
      alt={product?.productName}
      className={classes.image}
    />
  ) : (
    <ImageUploaderFrame
      setImage={setImage}
      isDarkModeActive={isDarkModeActive}
      pathName={`/projects/${product?.projectId}/products`}
      ResizerProps={ResizerProps}
    />
  )
}

const WrappedProductSerProfileImage = withStyles(styles)(ProductSerProfileImage)

export default WrappedProductSerProfileImage
