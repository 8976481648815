import axios from 'axios'
import React, { useContext, useState } from 'react'
import ButtonsSystem from '../../../../../../../../buttonsSystem/ButtonsSystem'
import EditableGridModel from '../../../../../../../../formsSystem/EditableGridModel'
import ModelImagePreview from '../../../../../../../../profile/ProfileComponents/ProfileControlPanelComponents/ControlPanelDialogsContentComponents/IdeasInquiriesComponents/GalleryInfoProfileTopModelComps/ModelImagePreview'
import TextEngineToolsFooter from '../../../../../../../../SmallComponents/TextEngineToolsFooter'
import NewConceptFormConfigs from './NewConceptFormComps/NewConceptFormConfigs'
import { AuthContext } from '../../../../../../../../../context/AuthContext'

const ResearchConceptProfile = (props) => {
  const {
    concept,
    DCGProps,
    setResActConcepts,
    setProjectResearchConcepts,
    windowCloser,
    depManager,
    project,
  } = props

  const { isDarkModeActive } = DCGProps
  const [chosenHashTagElements, setChosenHashTagElements] = useState(
    concept?.chosenHashTagElements || []
  )
  const { user } = useContext(AuthContext)
  const rtl = document.body.dir === 'rtl'
  const infoArray = NewConceptFormConfigs()
    .filter((info) => info.formComponentType === 'text')
    .map((config, index) => ({
      title: config.title,
      titleValue: concept[config.title.split('_').join('')] || '',
      allowEmpty: true,
      wideMode: index,
      minRows: 5,
    }))
  const [infoData, setInfoData] = useState({
    Title: concept?.Title,
    Content: concept?.Content,
  })
  const onSave = async () => {
    if (concept?._id)
      try {
        const res = await axios.patch(`/researchConcepts/${concept?._id}`, {
          ...infoData,
          chosenHashTagElements,
        })
        setResActConcepts((concepts) => [
          ...concepts.filter((p) => p._id !== concept?._id),
          res.data.concept,
        ])
      } catch (error) {
        console.log(error)
      }
  }

  const authorized =
    user?._id === project?.userId || user?._id === depManager?.userId
  const gridProps = {
    hideEditingTools: !authorized,
    infoArray,
    setChosenHashTagElements,
    chosenHashTagElements,
    hideHeader: true,
    infoData,
    setInfoData,
    onSave,
    ...DCGProps,
  }

  const buttonsArray = [
    {
      title: rtl ? 'حذف' : 'Delete',
      funs: async () => {
        windowCloser()
        try {
          await axios.delete(`/researchConcepts/${concept?._id}`)
          if (typeof setResActConcepts === 'function')
            setResActConcepts((cons) =>
              cons.filter((c) => c?._id !== concept?._id)
            )
          if (typeof setProjectResearchConcepts === 'function')
            setProjectResearchConcepts((cons) =>
              cons.filter((c) => c?._id !== concept?._id)
            )
        } catch (error) {
          console.log(error)
        }
      },
    },
  ]
  return (
    <section
      style={{ width: '100%', color: isDarkModeActive ? 'white' : null }}
    >
      <ModelImagePreview
        galleryProps={{
          element: concept,
          emptyGalleryTitle: rtl ? 'مبدأ' : 'Concept',
          DCGProps,
          patchPath: 'researchConcepts',
          hideEdit: !authorized,
        }}
      />
      <EditableGridModel gridProps={gridProps} />
      <ButtonsSystem buttonsArray={buttonsArray} />
      <TextEngineToolsFooter {...DCGProps} hideTETFooter={!authorized} />
    </section>
  )
}

export default ResearchConceptProfile
