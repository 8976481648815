import { Button } from '@material-ui/core'
import React, { useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import ToastifyGeneralError from '../../SmallComponents/ToastifyGeneralError'

const styles = () => ({
  button: {
    borderRadius: '10px !important',
    margin: '2px !important',
    textTransform: 'none !important',
    fontWeight: '550 !important',
    width: '100% !important',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important",
  },
})

const AdCampaignDeleteButton = ({ classes, adCampaignId, adCampaign }) => {
  const navigate = useNavigate()
  const [deleting, setIsDeleting] = useState(false)
  const onDelete = async () => {
    try {
      setIsDeleting(true)
      await axios.delete(`/adsManagment/${adCampaignId}`)
      navigate('/ads_management/manage-ads')
    } catch (error) {
      ToastifyGeneralError(error)
    }
  }
  const rtl = document.body.dir === 'rtl'
  return (
    <Button
      className={classes.button}
      disabled={Number(adCampaign?.targetUsers?.length) > 0 || deleting}
      onClick={onDelete}
    >
      {deleting ? (rtl ? 'جارى الحذف' : 'Deleting') : rtl ? 'حذف' : 'Delete'}
    </Button>
  )
}

export default withStyles(styles)(AdCampaignDeleteButton)
