import { Button } from '@mui/material'
import React from 'react'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  button: {
    borderRadius: '20px !important',
    margin: '2px !important',
    textTransform: 'none !important',
    fontWeight: '550 !important',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important",
    width: '100% !important'
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'row',
    margin: 10,
    padding: 10,
  },
})


const ShareExternalDialogButtons = ({ classes, link, windowCloser }) => {
  const rtl = document.body.dir === 'rtl'
  return (
    <section className={classes.buttonsWrapper}>
      <Button
        onClick={windowCloser}
        variant='outlined'
        className={classes.button}
      >
        {rtl ? 'إغلاق' : 'Close'}
      </Button>
      <Button
        target='_blank'
        type='link'
        href={link}
        variant='outlined'
        className={classes.button}
      >
        {rtl ? 'فتح الرابط' : 'Open Link'}
      </Button>
    </section>
  )
}

const WrappedShareExternalDialogButtons = withStyles(styles)(
  ShareExternalDialogButtons
)

export default WrappedShareExternalDialogButtons
