import React from 'react'
import withStyles from '@mui/styles/withStyles'
import NoFileSystemPanel from '../NoFileSystemPanel'

const styles = () => ({
  wrapper: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
})

const MiniFSCardInsideModel = ({
  classes,
  component,
  noFSPanelPropsSecondView,
  MFSGProps,
  folderPathProps,
}) => {
  const { CardProfileComponent, isDarkModeActive } = MFSGProps
  const { setSecondLevelFolderPath, setThirdLevelFolderPath } = folderPathProps

  return (
    <article
      className={classes.wrapper}
      style={{ color: isDarkModeActive ? 'white' : null }}
    >
      <NoFileSystemPanel
        noFSPanelProps={{
          ...noFSPanelPropsSecondView,
          setSecondLevelFolderPath,
          setThirdLevelFolderPath,
        }}
      />
      {CardProfileComponent}
    </article>
  )
}

const WrappedMiniFSCardInsideModel = withStyles(styles)(MiniFSCardInsideModel)

export default WrappedMiniFSCardInsideModel
