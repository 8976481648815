import axios from 'axios'
import React, { useEffect, useState } from 'react'
import GPDFDBodyModel from '../GeneralPDFDocumentComps/GPDFDBodyModel'
import ContractPDFElements from './ContractPDFBodyComps/ContractPDFElements'
import ContractPDFProjects from './ContractPDFBodyComps/ContractPDFProjects'
import ContractPDFUsers from './ContractPDFBodyComps/ContractPDFUsers'

const ContractPDFBody = ({ button }) => {
  const { contract, users, projects } = button.PDFGenProps
  const [elements, setElements] = useState([])
  useEffect(() => {
    const fetchContractElements = async () => {
      try {
        const res = await axios.get(`/contractsElements/g/${contract?._id}`)
        setElements(res.data.elements)
      } catch (error) {
        console.log(error)
      }
    }
    fetchContractElements()
  }, [contract?._id])
  return (
    <GPDFDBodyModel
      hideCreator
      GPDFBodyModelRightCo={<ContractPDFElements elements={elements} />}
      GPDFBodyModelImageBottom={
        <>
          <ContractPDFProjects projects={projects} />
          <ContractPDFUsers users={users} />
        </>
      }
      button={button}
    />
  )
}

export default ContractPDFBody
