import {
  ClickAwayListener,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import i18next from 'i18next'
import React from 'react'
import UsePathnameEffect from '../../SmallComponents/UsePathnameEffect'
import ExternalLinkSharing from '../../fileSystem/NoFileSystemPanelComponents/ExternalLinkSharing'

const Activation = (props) => {
  const lang = i18next.language
  const {
    dialogWidth,
    dialogHeight,
    inactivateClickAway,
    dialogTitle,
    dialogIcon,
    isDarkModeActive,
    element,
    linkShareElement,
  } = props
  UsePathnameEffect(element.closeFun)
  return (
    <Dialog
      dir={lang === 'ar' ? 'rtl' : 'ltr'}
      maxWidth='md'
      fullWidth={!dialogWidth}
      PaperProps={{
        style: {
          borderRadius: 10,
          background: isDarkModeActive ? 'rgb(49, 48, 53)' : 'white',
          // background: !isDarkModeActive && 'white',
          // backgroundImage: isDarkModeActive
          //   ? `url(${PF}various/dialogBack.jpg)`
          //   : null,
          // backgroundPosition: isDarkModeActive && 'center center',
          // backgroundSize: isDarkModeActive && 'cover',
          // backgroundRepeat: isDarkModeActive && 'no-repeat',
          // backgroundAttachment: isDarkModeActive && 'fixed',
          // backdropFilter: isDarkModeActive && 'brightness(50%)',
        },
      }}
      open={element.open}
    >
      <DialogTitle sx={{ color: isDarkModeActive ? 'white' : 'inherit' }}>
        <section
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            alignContent: 'center',
            fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
          }}
        >
          {linkShareElement && (
            <ExternalLinkSharing
              noFSPanelProps={{
                element: linkShareElement,
                showExternalLinkShare: linkShareElement,
                isDarkModeActive,
              }}
            />
          )}
          {dialogTitle}
          {dialogIcon}
        </section>
      </DialogTitle>
      <ClickAwayListener
        onClickAway={() => {
          if (typeof element.closeFun === 'function' && !inactivateClickAway)
            element.closeFun()
        }}
      >
        <DialogContent
          style={{
            height: dialogHeight ? dialogHeight : '400px',
            width: dialogWidth ? dialogWidth : 'auto',
          }}
        >
          {element.content}
        </DialogContent>
      </ClickAwayListener>
    </Dialog>
  )
}

const ModelDialogWrappingIIIPC = (props) => {
  const { dialogsArray } = props
  return dialogsArray.map((element, elemntIdex) => {
    return (
      element.open && (
        <Activation {...props} key={elemntIdex} element={element} />
      )
    )
  })
}

export default ModelDialogWrappingIIIPC
