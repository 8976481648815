import React from 'react'
import withStyles from '@mui/styles/withStyles'
import TextEngine from '../../../textProcessingEngine/TextEngine'
import TextDirectionDetector from '../../../textProcessingEngine/TextEngineComponents/TextProcessorComponents/DetectionCoreComponents/TextDirectionDetector'

const styles = () => ({
  textPostContainer: {
    width: '100%',
    overflow: 'hidden',
    display: 'block',
    fontSize: 16,
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
    fontWeight: 400,
    marginBottom: 10,
    marginTop: 7,
  },

  '@media (max-width: 600px)': {
    textPostContainerCollapsed: {
      fontSize: 13,
    },
    textPostContainerExpanded: {
      fontSize: 13,
    },
  },
})

const CommentTextModel = ({ classes, ...props }) => {
  const { comment } = props
  return (
    <article
      dir={TextDirectionDetector(comment?.text)}
      className={classes.textPostContainer}
    >
      <TextEngine
        {...props}
        textContent={comment?.text || ''}
        textPackage={comment}
        charLimit={150}
        activateReadMore={true}
      />
    </article>
  )
}

const WrappedCommentTextModel = withStyles(styles)(CommentTextModel)

export default WrappedCommentTextModel
