import React, { useState, useEffect } from 'react'
import { MenuItem, Select, FormControl } from '@mui/material'
import { MdSettingsApplications } from 'react-icons/md'
import withStyles from '@mui/styles/withStyles'
import { useTranslation } from 'react-i18next'
import WindowSize from '../../SmallComponents/WindowSize'

const styles = () => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  select: {
    fontSize: '12px',
    appearance: 'none',
    outline: 'none',
  },
})

const ResultsFilter = ({
  classes,
  setResultSectionId,
  setIsSectionClicked,
  setClickAwayState,
  isDarkModeActive,
}) => {
  const [section, setSection] = useState(0)
  const selectSectionHandler = (event) => {
    setSection(event.target.value)
  }

  useEffect(() => {
    setSection(0)
  }, [])

  useEffect(() => {
    setClickAwayState(false)
    setResultSectionId(section)
    setIsSectionClicked(true)
    if (section === 0) setIsSectionClicked(false)
  }, [section])

  const { t } = useTranslation()
  const sections = [
    t('All'),
    t('Social'),
    t('People'),
    t('Projects'),
    t('Jobs'),
    t('Tasks'),
    t('Products'),
    t('Resources'),
    t('Ideas'),
    t('Inquiries'),
    t('Global_Visions'),
    t('Campaigns'),
    t('Investments'),
    t('Issues'),
    t('Channels'),
  ]
  const rtl = document.body.dir === 'rtl'
  const windowSize = WindowSize()
  return (
    <article className={classes.wrapper}>
      <MdSettingsApplications
        size={23}
        style={{
          margin: rtl
            ? windowSize > 600
              ? '2px 20px -2px 10px'
              : '2px 10px -2px 5px'
            : windowSize > 600
            ? '2px 10px -2px 20px'
            : '2px 5px -2px 10px',
          color: isDarkModeActive ? 'white' : 'blue',
        }}
      />
      <FormControl>
        <Select
          id='section'
          value={section}
          onChange={(event) => {
            selectSectionHandler(event)
          }}
          defaultValue={0}
          className={classes.select}
          autoWidth
          style={{
            color: isDarkModeActive ? 'white' : null,
            fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
            margin:
              windowSize <= 600 &&
              (rtl ? 'auto 5px auto 10px' : 'auto 10px auto 5px'),
          }}
          variant='standard'
          onOpen={() => setClickAwayState(true)}
        >
          {sections.map((section, sectionIndex) => (
            <MenuItem
              key={sectionIndex}
              value={sectionIndex}
              style={{
                fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
              }}
            >
              {section}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </article>
  )
}

const WrappedResultsFilter = withStyles(styles)(ResultsFilter)

export default WrappedResultsFilter
