import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { Blocks } from 'react-loader-spinner'

const styles = () => ({
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'center',
  },
  text: {
    fontWeight: 500,
    fontSize: 15,
    margin: '10px auto',
  },
  // under testing - may cause problems
  '@media (max-width: 600px)': {
    wrapper: {
      height: '100vh',
    },
  },
})

const BlocksLoader = ({ classes, loaderText, isDarkModeActive }) => {
  const rtl = document.body.dir === 'rtl'
  return (
    <div
      className={classes.wrapper}
      style={{ color: isDarkModeActive && 'white' }}
    >
      <Blocks
        visible={true}
        height='80'
        width='80'
        ariaLabel='blocks-loading'
        wrapperStyle={{}}
        wrapperClass='blocks-wrapper'
      />
      <div className={classes.text}>
        {loaderText
          ? loaderText
          : rtl
          ? 'جارى تحضير البيانات'
          : 'Preparing Data'}
      </div>
    </div>
  )
}

const WrappedBlocksLoader = withStyles(styles)(BlocksLoader)

export default WrappedBlocksLoader
