import React from 'react'
import { Divider } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import ResultModel from './ResultModel'
import UserProfileFollowUnfollow from '../../../profilesMicroComponents/UserProfileFollowUnfollow'
import UserProfileAddContributor from '../../../profilesMicroComponents/UserProfileAddContributor'
import ProjectFollowButton from '../../../projectProfile/ProjectProfileComponents/ProjectIntroComponents/ProjectCommBarComponents/ProjectFollowButton'

const styles = () => ({
  followChatOptionsIcons: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: 5,
    width: '80%',
  },
  followChatOptionsIconsItem: {
    fontSize: 21,
    padding: 5,
    cursor: 'pointer',
  },
})

const UserResult = ({ isDarkModeActive, result, socket }) => {
  const resultData = [
    {
      name: result?.firstName ? result?.firstName : result?.ProjectName,
      image: result?.profilePicture || result?.projectPhoto,
      path: result?.firstName
        ? `/profile/${result?._id}`
        : `/projectProfile/${result?._id}`,
      verified: result?.verified,
      userId: result?.ProjectName && result?.userId,
    },
    {
      resultInteractionsArray: result?.firstName
        ? [
            <UserProfileFollowUnfollow socket={socket} userId={result?._id} />,
            <UserProfileAddContributor socket={socket} userId={result?._id} />,
          ]
        : [
            <ProjectFollowButton
              projectProps={{
                project: result,
                projectAdmins: [{ _id: result?.userId }],
                socket,
              }}
            />,
          ],
      gridXs: [12, 6],
    },
  ]

  return (
    <>
      <ResultModel resultData={resultData} result={result} />
      <Divider style={{ backgroundColor: isDarkModeActive ? 'white' : null }} />
    </>
  )
}

const WrappedUserResult = withStyles(styles)(UserResult)

export default WrappedUserResult
