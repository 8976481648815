const DefaultImageGrapper = (imageKey) => {
  const PF = process.env.REACT_APP_PUBLIC_FOLDER
  const images = [
    {
      key: 'chart',
      image: `${PF}various/chart.png`,
    },
    {
      key: 'noManager',
      image: `${PF}various/noManager.png`,
    },
    {
      key: 'noGSResults',
      image: `${PF}various/noGSResults.png`,
    },
    {
      key: 'noSearchResults',
      image: `${PF}various/noSearchResults.png`,
    },
    {
      key: 'noTasks',
      image: `${PF}various/noTasks.png`,
    },
    {
      key: 'idea',
      image: `${PF}various/plans2.webp`,
    },
    {
      key: 'noCover',
      image: `${PF}back/noCover.jpg`,
    },
    {
      key: 'inquiry',
      image: `${PF}various/inquiryElement.jpeg`,
    },
    {
      key: 'globalInquiry',
      image: `${PF}various/globalInquiryElement.jpg`,
    },
    {
      key: 'task',
      image: `${PF}various/tasks2.webp`,
    },
    {
      key: 'job',
      image: `${PF}various/newJob1.webp`,
    },
    {
      key: 'checked',
      image: `${PF}various/check1.webp`,
    },
    {
      key: 'model',
      image: `${PF}various/businessModel.jpg`,
    },
    {
      key: 'channel',
      image: `${PF}various/projectMeeting1.webp`,
    },
    {
      key: 'contract',
      image: `${PF}modelingVector/contractRequest1.webp`,
    },
    {
      key: 'markStraAspect',
      image: `${PF}various/expand2.webp`,
    },
    {
      key: 'researchActivity',
      image: `${PF}various/researchActivity1.jpg`,
    },
    {
      key: 'researchConcept',
      image: `${PF}various/researchConcept1.jpg`,
    },
    {
      key: 'researchApp',
      image: `${PF}various/researchApp.jpg`,
    },
    {
      key: 'issue',
      image: `${PF}various/issueElement2.jpg`,
    },
    {
      key: 'product',
      image: `${PF}various/ProductElement.jpg`,
    },
    {
      key: 'investPack',
      image: `${PF}various/investPack.jpg`,
    },
    {
      key: 'post',
      image: `${PF}various/post.jpg`,
    },
    {
      key: 'campaign',
      image: `${PF}various/campaign.jpg`,
    },
    {
      key: 'resource',
      image: `${PF}various/resource.png`,
    },
    {
      key: 'NoPhotoNoAdmin',
      image: `${PF}various/NoPhotoNoAdmin.jpg`,
    },
    {
      key: 'protocol',
      image: `${PF}compDepProtocols/adminProtocols/rescueProtocol1.jpg`,
    },
    {
      key: 'finStat',
      image: `${PF}compDepProtocols/adminProtocols/rescueProtocol1.jpg`,
    },
    {
      key: 'subDep',
      image: `${PF}various/sub-dep1.webp`,
    },
    {
      key: 'settings',
      image: `${PF}various/settings.jpg`,
    },
    {
      key: 'library',
      image: `${PF}various/library.jpg`,
    },
    {
      key: 'noUserAvatar',
      image: `${PF}person/noAvatar.png`,
    },
    {
      key: 'noProjectAvatar',
      image: `${PF}various/noProjectVector.jpg`,
    },
    {
      key: 'meeting',
      image: `${PF}various/meeting.jpg`,
    },
    {
      key: 'noMembers',
      image: `${PF}various/noMembers.png`,
    },
    {
      key: 'poll',
      image: `${PF}various/poll.jpg`,
    },
    {
      key: 'charted',
      image: `${PF}various/charted.jpg`,
    },
  ]
  return images.find((im) => im.key === imageKey)?.image
}

export default DefaultImageGrapper
