import React, { useState, useContext } from 'react'
import { Tooltip } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { MdLocalActivity } from 'react-icons/md'
import NewFormDialogModel from '../../formsSystem/NewFormDialogModel'
import MoveToAnotherSubDepForm from '../../projectProfile/ProjectProfileComponents/ProjectControlPanelComponents/ProjectDepartmentsComponents/DepartmentsComponents/MembersComps/MoveToAnotherSubDepForm'
import { AuthContext } from '../../../context/AuthContext'

const styles = () => ({
  icon: {
    cursor: 'pointer',
    fontSize: 18,
    '&:hover': {
      opacity: 0.8,
    },
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
})

const MoveContributorToSubDepartment = ({ classes, noFSPanelProps }) => {
  // move contributor from one sub-dep to another
  const { DCGProps, member, depManager, showMoveContSubDepIcon } =
    noFSPanelProps
  const { user } = useContext(AuthContext)
  const [isComponentOpen, setIsComponentOpen] = useState(false)
  const componentOpener = () => {
    setIsComponentOpen(true)
  }
  const componentClose = () => {
    setIsComponentOpen(false)
  }
  const rtl = document.body.dir === 'rtl'

  const condition =
    showMoveContSubDepIcon &&
    (DCGProps?.project?.userId === user?._id ||
      depManager?.userId === user?._id) &&
    member?.userId !== depManager?.userId

  return (
    <>
      <Tooltip
        title={
          rtl
            ? 'نقل العضو إلى قسم فرعي آخر'
            : 'Move Contributor to Another Sub-Department'
        }
      >
        <span
          className={classes.iconWrapper}
          style={{ display: condition ? null : 'none' }}
          onClick={componentOpener}
        >
          <MdLocalActivity className={classes.icon} />
        </span>
      </Tooltip>
      <NewFormDialogModel
        NewFormComponent={
          <MoveToAnotherSubDepForm
            {...noFSPanelProps}
            componentClose={componentClose}
          />
        }
        newFormDialogClose={componentClose}
        isNewFormDialogOpen={isComponentOpen}
        {...DCGProps}
        clickAwayState={true}
        dialogTitle={
          rtl ? 'النقل بين الأقسام الفرعية' : 'Inter-SubDepartments Trasnfer'
        }
      />
    </>
  )
}

const WrappedMoveContributorToSubDepartment = withStyles(styles)(
  MoveContributorToSubDepartment
)

export default WrappedMoveContributorToSubDepartment
