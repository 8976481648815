import { Grid } from '@mui/material'
import React from 'react'
import withStyles from '@mui/styles/withStyles'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

const styles = () => ({
  image: {
    width: '100%',
    cursor: 'pointer',
    objectFit: 'cover',
  },
})

const TwoImagesModel = ({ classes, imagesArray, openFun }) => {
  const rtl = document.body.dir === 'rtl'
  return (
    <Grid container>
      {imagesArray.map((image, imageIndex) => (
        <Grid
          item
          xs={6}
          style={{ display: 'flex', justifyContent: 'center' }}
          key={imageIndex}
        >
          <LazyLoadImage
            effect='blur'
            src={image}
            height={'100%'}
            alt='Images Viewer System'
            key={imageIndex}
            className={classes.image}
            style={{
              margin:
                imageIndex === 0
                  ? rtl
                    ? '0 0 3.75px 5px'
                    : '0 5px 3.75px 0'
                  : rtl
                  ? '0 5px 3.75px 0'
                  : '0 0 3.75px 5px',
            }}
            onClick={() => {
              openFun()
            }}
          />
        </Grid>
      ))}
    </Grid>
  )
}

const WrappedTwoImagesModel = withStyles(styles)(TwoImagesModel)

export default WrappedTwoImagesModel
