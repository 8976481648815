import { Card, CardContent, CardMedia } from '@mui/material'
import React from 'react'
import withStyles from '@mui/styles/withStyles'
import DefaultImageGrapper from '../../../../SmallComponents/DefaultImageGrapper'
import WindowSize from '../../../../SmallComponents/WindowSize'

const styles = () => ({
  elementCardWrapper: {
    borderRadius: '10px !important',
    margin: '10px 0 10px 0 !important',
    width: 200,
    minHeight: 260,
    maxHeight: 300,
    cursor: 'pointer',
    opacity: 0.8,
    transition: '0.3s !important',
    boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
    '&:hover': {
      boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)',
      width: 220,
      opacity: 1,
    },
  },
  elementCardWrapperNoHover: {
    borderRadius: '10px !important',
    margin: '10px 0 10px 0 !important',
    width: 200,
    minHeight: 260,
    maxHeight: 300,
    cursor: 'pointer',
    opacity: 0.8,
    transition: '0.3s !important',
    boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
  },
  media: {
    height: 0,
    paddingTop: '56.25%',
    marginTop: '30',
    borderRadius: '0',
  },
  postMode: {
    borderRadius: '10px !important',
    margin: '0 !important',
    width: '100%',
    cursor: 'pointer',
  },
})

const StandardCard = ({ classes, element, props }) => {
  const { MFSGProps, folderPathProps, searchSystemProps, modelProps } = props
  const { setCardId, isDarkModeActive, disableHover } = MFSGProps
  const { setSecondLevelFolderPath, setFourthLevelFolderPath } = folderPathProps
  const { setShowSearchInput } = searchSystemProps
  const {
    StandardCardContent,
    defaultImage,
    windowOpener,
    imageKey,
    otherCardFuns,
    elementsImages,
    postMode,
    customCardFuns,
  } = modelProps
  const windowSize = WindowSize()
  const elemntImage = elementsImages?.find(
    (image) => image?.id === element.id
  )?.image
  const generatedImage = imageKey && DefaultImageGrapper(imageKey)
  const image =
    elementsImages?.length > 0
      ? elemntImage
        ? elemntImage
        : defaultImage || generatedImage
      : defaultImage || generatedImage
  return (
    <Card
      className={
        postMode
          ? classes.postMode
          : disableHover
          ? classes.elementCardWrapperNoHover
          : classes.elementCardWrapper
      }
      onClick={() => {
        if (typeof setCardId === 'function') setCardId(element.id)
        if (
          typeof setSecondLevelFolderPath === 'function' &&
          typeof setFourthLevelFolderPath !== 'function'
        )
          setSecondLevelFolderPath(
            element.title || element.name || element.Title
          )
        if (typeof setFourthLevelFolderPath === 'function')
          setFourthLevelFolderPath(
            element.title || element.name || element.Title
          )
        if (typeof windowOpener === 'function') windowOpener()
        if (typeof setShowSearchInput === 'function') setShowSearchInput(false)
        if (typeof otherCardFuns === 'function') otherCardFuns()
        if (typeof customCardFuns === 'function') customCardFuns()
      }}
      style={{
        width: windowSize <= 600 && windowSize > 400 && !postMode && '45vw',
      }}
      sx={{
        backgroundColor: isDarkModeActive
          ? 'rgba(255, 255, 255, 0.192)'
          : 'inherit',
        color: isDarkModeActive ? 'white' : 'inherit',
      }}
    >
      <CardMedia className={classes.media} image={image} />
      <CardContent>
        {StandardCardContent && (
          <StandardCardContent
            element={element}
            isDarkModeActive={isDarkModeActive}
            {...modelProps}
          />
        )}
      </CardContent>
    </Card>
  )
}

const WrappedStandardCard = withStyles(styles)(StandardCard)

export default WrappedStandardCard
