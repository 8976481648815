import React, { useEffect, useRef, useState } from 'react'
import { Link, Text, View, StyleSheet, Image } from '@react-pdf/renderer'
import ExternalLinkGenerator from '../../../../../SmallComponents/ExternalLinkGenerator'

const PF = process.env.REACT_APP_PUBLIC_FOLDER

// roboto refers to arabic

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderBottomWidth: 2,
    borderBottomColor: '#112131',
    borderBottomStyle: 'solid',
    alignItems: 'stretch',
    marginBottom: 10,
  },
  detailColumn: {
    flexDirection: 'column',
    flexGrow: 9,
    textTransform: 'uppercase',
  },
  linkColumn: {
    flexDirection: 'column',
    flexGrow: 2,
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
  },
  name: {
    fontSize: 20,
    fontFamily: 'Lato',
  },
  nameArabic: {
    fontSize: 20,
    fontFamily: 'Roboto',
  },
  subtitle: {
    fontSize: 16,
    fontFamily: 'Lato',
    color: '#6c757d',
  },
  subtitleArabic: {
    fontSize: 16,
    fontFamily: 'Roboto',
    color: '#6c757d',
  },
  link: {
    fontFamily: 'Lato',
    fontSize: 10,
    color: 'black',
    textDecoration: 'none',
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
  },
  linkArabic: {
    fontFamily: 'Roboto',
    fontSize: 10,
    color: 'black',
    textDecoration: 'none',
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
  },
  logo: {
    width: 80,
    height: 80,
    marginLeft: 10,
    objectFit: 'contain',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 10,
    fontFamily: 'Lato',
    bottom: 0,
    right: 0,
    paddingBottom: 10,
    paddingRight: 10,
  },
  pageNumberArabic: {
    position: 'absolute',
    fontSize: 10,
    fontFamily: 'Roboto',
    bottom: 0,
    right: 0,
    paddingBottom: 10,
    paddingRight: 10,
  },
  date: {
    fontSize: 10,
    fontFamily: 'Lato',
    marginBottom: 5,
  },
  dateArabic: {
    fontSize: 10,
    fontFamily: 'Roboto',
    marginBottom: 5,
  },
  url: {
    fontSize: 9,
    fontFamily: 'Roboto',
    color: 'blue',
    textDecoration: 'underline',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '60%',
    textTransform: 'none',
    lineHieght: '1em',
  },
})

function isArabic(text) {
  return /[\u0600-\u06FF]/.test(text)
}

export default ({ title, element }) => {
  const elementLink = decodeURIComponent(ExternalLinkGenerator(element))
  return (
    <View fixed style={{ top: 0 }}>
      <View style={styles.container}>
        <Image src={`${PF}HutupiaSub.png`} style={styles.logo} />
        <View style={styles.detailColumn}>
          <Text style={[styles.name, isArabic(title) && styles.nameArabic]}>
            {title}
          </Text>
          <Text
            style={[
              styles.date,
              isArabic(new Date().toLocaleString()) && styles.dateArabic,
            ]}
          >
            {new Date().toLocaleString()}
          </Text>
          {element?.hashTagType !== 'projectMarketingStrategy' &&
            element?.hashTagType !== 'projectBusinessModel' &&
            element?.hashTagType !== 'managerialplan' && (
              <Text style={styles.url}>
                <Link href={elementLink} style={styles.url}>
                  {elementLink}
                </Link>
              </Text>
            )}
        </View>
        <View style={styles.linkColumn}>
          <Link href='https://www.hutupia.com' style={styles.link}>
            Hutupia.com
          </Link>
        </View>
      </View>
    </View>
  )
}
