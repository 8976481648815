import React, { useState, useEffect, useContext } from 'react'
import withStyles from '@mui/styles/withStyles'
import FormsComponents from '../../../formsSystem/FormsComponents'
import ImageUploaderSystem from '../../../uploadImageSystem/ImageUploaderSystem'
import { Button } from '@mui/material'
import { MdCamera } from 'react-icons/md'
import { AuthContext } from '../../../../context/AuthContext'
import EmojiPopover from '../../../formsSystem/FormsComponentsComponents/EmojiPopover'
import LinkPlayerAddButton from '../../../postSystem/LinksPlayerSystemComps/LinkPlayerAddButton'
import LinkPlayerPreview from '../../../postSystem/LinksPlayerSystemComps/LinkPlayerPreview'
import PollAddButton from '../../../generalPollSystem/PollAddButton'
import GeneralPollBody from '../../../generalPollSystem/GeneralPollBody'

const styles = () => ({
  button: {
    borderRadius: '10px !important',
    margin: '2px !important',
    textTransform: 'none !important',
    fontWeight: '550 !important',
    width: '100% !important',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important",
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
})

const NormalPostModel = ({
  classes,
  placeholder,
  setPostData,
  postData,
  post,
  isDarkModeActive,
  hideAddPostImages,
  project,
  // indicator that editing system is running
  disabledPostTypesDefaultValue,
  setPosts,
}) => {
  const { user } = useContext(AuthContext)
  const [textContent, setTextContent] = useState(
    disabledPostTypesDefaultValue !== undefined ? post.postTextContent : ''
  )
  const [playerLink, setPlayerLink] = useState(
    disabledPostTypesDefaultValue !== undefined ? post.playerLink : ''
  )
  const [chosenHashTagElements, setChosenHashTagElements] = useState(
    disabledPostTypesDefaultValue !== undefined
      ? post.chosenHashTagElements
      : []
  )
  const [images, setImages] = useState(
    disabledPostTypesDefaultValue !== undefined
      ? post?.images
        ? post?.images
        : []
      : []
  )

  const [pollData, setPollData] = useState(post?.pollData || postData?.pollData)

  useEffect(() => {
    setPostData({
      ...postData,
      postTextContent: textContent,
      chosenHashTagElements: chosenHashTagElements,
      images,
      createdAt: Date.now(),
      playerLink,
      pollData,
    })
  }, [textContent, chosenHashTagElements, images, playerLink, pollData])

  const formsCompsProps = {
    placeholder,
    formComponentIndex: 0,
    chosenHashTagElements,
    setChosenHashTagElements,
    textContent,
    setTextContent,
    isDarkModeActive,
    project,
  }
  return (
    <>
      <FormsComponents formsCompsProps={formsCompsProps} />
      <section className={classes.buttonsWrapper}>
        <Button
          variant='outlined'
          className={classes.button}
          disabled={
            Boolean(playerLink) || hideAddPostImages || Boolean(pollData)
          }
        >
          <ImageUploaderSystem
            imageIcon={<MdCamera size={20} />}
            funtionalityIndex={0}
            images={images}
            setImages={setImages}
            pathName={`/users/${user?._id}/posts`}
          />
        </Button>
        <Button variant='outlined' className={classes.button}>
          <EmojiPopover {...formsCompsProps} iconSize={20} />
        </Button>
        <LinkPlayerAddButton
          setLink={setPlayerLink}
          link={playerLink}
          isDarkModeActive={isDarkModeActive}
          disabled={
            Boolean(images?.length > 0) ||
            hideAddPostImages ||
            Boolean(pollData)
          }
        />
        <PollAddButton
          isDarkModeActive={isDarkModeActive}
          setPollData={setPollData}
          pollData={pollData}
          chosenHashTagElements={chosenHashTagElements}
          setChosenHashTagElements={setChosenHashTagElements}
          disabled={
            Boolean(images?.length > 0) ||
            hideAddPostImages ||
            Boolean(playerLink) ||
            disabledPostTypesDefaultValue !== undefined
          }
        />
      </section>
      <LinkPlayerPreview setLink={setPlayerLink} link={playerLink} />
      {pollData && (
        <GeneralPollBody
          setPosts={setPosts}
          post={post}
          pollData={pollData}
          isDarkModeActive={isDarkModeActive}
        />
      )}

      <ImageUploaderSystem
        funtionalityIndex={1}
        images={images}
        setImages={setImages}
        pathName={`/users/${user?._id}/posts`}
      />
    </>
  )
}

const WrappedNormalPostModel = withStyles(styles)(NormalPostModel)

export default WrappedNormalPostModel
