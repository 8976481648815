import { IconButton } from '@mui/material'
import React from 'react'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  sideButtonWrapper: {
    position: 'sticky',
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    height: '100%',
    right: 0,
  },
  iconButton: {
    height: '30px !important',
    borderRadius: '50% !important',
    cursor: 'pointer !important',
    backgroundColor: 'rgb(210, 221, 240) !important',
  },
})

const SideButton = ({ classes, shiftFun, shiftIcon, iconButtonEdge }) => {
  return (
    <section className={classes.sideButtonWrapper}>
      <IconButton
        edge={iconButtonEdge}
        color='inherit'
        className={classes.iconButton}
        onClick={shiftFun}
      >
        {shiftIcon}
      </IconButton>
    </section>
  )
}

const WrappedSideButton = withStyles(styles)(SideButton)

export default WrappedSideButton
