import { useTranslation } from 'react-i18next'

const PayPerClickBusinessModel = () => {
  const { t } = useTranslation('PayPerClickBusinessModel')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 63,
    title: t('title_bm63'),
    subTitle: t('subTitle_bm63'),
    main: t('main_bm63'),
    when: t('when_bm63'),
    who: t('who_bm63'),
    what: t('what_bm63'),
    how: t('how_bm63'),
    why: t('why_bm63'),
    examples: rtl
      ? [
          'إعلانات Google - 1998 - الولايات المتحدة الأمريكية',
          'إعلانات Bing - 2009 - الولايات المتحدة الأمريكية',
          'إعلانات Yahoo - 1996 - الولايات المتحدة الأمريكية',
          'إعلانات فيسبوك - 2004 - الولايات المتحدة الأمريكية',
          'أمازون - 1994 - الولايات المتحدة الأمريكية',
          'ينكدين - 2003 - الولايات المتحدة الأمريكية',
          'تويتر - 2006 - الولايات المتحدة الأمريكية',
          'AdRoll - 2007 - الولايات المتحدة الأمريكية',
          'AdStage - 2012 - الولايات المتحدة الأمريكية',
          'Marin Software - 2006 - الولايات المتحدة الأمريكية',
        ]
      : [
          'Google Ads - 1998 - USA',
          'Bing Ads - 2009 - USA',
          'Yahoo Ads - 1996 - USA',
          'Facebook Ads - 2004 - USA',
          'Amazon - 1994 - USA',
          'LinkedIn - 2003 - USA',
          'Twitter - 2006 - USA',
          'AdRoll - 2007 - USA',
          'AdStage - 2012 - USA',
          'Marin Software - 2006 - USA',
        ],
    tags: rtl
      ? [
          'الإعلان عبر الإنترنت',
          'برامج الشركات التابعة للدفع لكل نقرة',
          'التسويق عبر نتائج البحث المدفوعة',
          'موضع الإعلان على شبكة البحث المدفوعة',
          'مقاييس نقاط الجودة',
          'معدل النقر',
        ]
      : [
          'Online Advertising',
          'Pay-Per-Click Affiliate Programs',
          'Paid Search Marketing',
          'Paid Search Ad Position',
          'Quality Score Measures',
          'Click Through Rate',
        ],
    considerations: t('considerations_bm63'),
    combinations: ['$LBM22', '$LBM21', '$LBM60', '$LBM2'],
    hashTagType: 'businessModelTemp',
  }
}

export default PayPerClickBusinessModel
