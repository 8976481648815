import React from 'react'
import CentralTitle from '../SmallComponents/CentralTitle'

const NoPermissionedInteraction = ({ checking }) => {
  const rtl = document.body.dir === 'rtl'
  const title = checking
    ? rtl
      ? 'جارى المراجعة'
      : 'Checking'
    : rtl
    ? 'لقد قمت بالتفاعل مسبقا'
    : 'You have already interacted'
  return <CentralTitle title={title} />
}

export default NoPermissionedInteraction
