import { Button } from '@mui/material'
import React, { useContext } from 'react'
import withStyles from '@mui/styles/withStyles'
import NotificationCreator from '../../../../../notificationsSystem/NotificationCreator'
import axios from 'axios'
import { AuthContext } from '../../../../../../context/AuthContext'
import { toast } from 'react-toastify'

const styles = () => ({
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: 10,
  },
  button: {
    borderRadius: '10px !important',
    margin: '2px !important',
    textTransform: 'none !important',
    color: 'white !important',
    fontWeight: '550 !important',
    width: '100% !important',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important",
  },
})

const DepNewContributorFormButtons = ({
  classes,
  newDepContribClose,
  isDataFilled,
  departmentId,
  socket,
  project,
  selectedMembers,
  setMembers,
}) => {
  const rtl = document.body.dir === 'rtl'
  const { user } = useContext(AuthContext)
  // if the contributor already a member in the project, then i will add the dpeartment id only and notify him
  //if the user is freshly to be added to the project, then a contract will be snet
  return (
    <section className={classes.buttonsWrapper}>
      <Button
        variant='contained'
        className={classes.button}
        onClick={() => newDepContribClose()}
      >
        {rtl ? 'رجوع' : 'Back'}
      </Button>
      <Button
        disabled={!isDataFilled}
        sx={{ backgroundColor: isDataFilled ? null : 'GrayText' }}
        variant='contained'
        className={classes.button}
        onClick={async () => {
          Promise.all(
            selectedMembers.map(async (member) => {
              if (member?.userId) {
                // here the incoming member is a projectContributor model
                // this for the contributor who is already in the project
                // adding the contributor to dep
                const res = await axios.patch(
                  `/projectContributors/${member?.userId}/${project?._id}`,
                  { departmentId }
                )
                setMembers((ms) => [
                  ...ms?.filter((m) => m?.userId !== member?.userId),
                  res.data.contributor,
                ])
                // notifying the contributor
                socket?.current.emit('addNotifUser', member?.userId)
                NotificationCreator({
                  associativity: 'project',
                  relation: 'member',
                  conditionIndex: 4,
                  accompaniedData: { projectId: project?._id, departmentId },
                  category: 'contributors',
                  userId: member?.userId,
                  currentUser: user,
                  socket,
                })
                toast.success(
                  rtl
                    ? 'لقد تم ارسال اشعارا للمشارك بالتغييرات'
                    : 'A notifications has been sent to the contributor about the changes'
                )
                newDepContribClose()
              } else {
                // here the incoming user is User model
                socket.current.emit('addNotifUser', member?._id)
                NotificationCreator({
                  associativity: 'contribution',
                  relation: 'projectMemberCandidate',
                  conditionIndex: 0,
                  accompaniedData: {
                    projectId: project?._id,
                    departmentId,
                  },
                  category: 'contributors',
                  userId: member?._id,
                  currentUser: user,
                  socket,
                })
                toast.success(
                  rtl
                    ? 'لقد تم ارسال اشعارا للمشارك بالعرض'
                    : 'A notification is sent to your contributor with your offer'
                )
                newDepContribClose()
              }
            })
          )
        }}
      >
        {rtl ? 'أضف' : 'Add'}
      </Button>
    </section>
  )
}

const WrappedDepNewContributorFormButtons = withStyles(styles)(
  DepNewContributorFormButtons
)

export default WrappedDepNewContributorFormButtons
