import { useTranslation } from 'react-i18next'

const PayWhatYouWantBusinessModel = () => {
  const { t } = useTranslation('PayWhatYouWantBusinessModel')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 41,
    title: t('title_bm41'),
    subTitle: t('subTitle_bm41'),
    main: t('main_bm41'),
    when: t('when_bm41'),
    who: t('who_bm41'),
    what: t('what_bm41'),
    how: t('how_bm41'),
    why: t('why_bm41'),
    examples: rtl
      ? [
          'عالم واحد كل شخص يأكل - 2003 - الولايات المتحدة الأمريكية',
          'راديوهيد - روك باند 2007 - المملكة المتحدة',
          'NoiseTrade - تأسست عام 2006 - الولايات المتحدة الأمريكية',
          'Humble Bundle - تأسست عام 2010 - الولايات المتحدة الأمريكية',
        ]
      : [
          'One World Everybody Eats - 2003 - USA',
          'Radiohead - Rock Band 2007 - UK',
          'NoiseTrade - Founded 2006 - USA',
          'Humble Bundle - Founded 2010 - USA',
        ],
    tags: rtl
      ? [
          'أسعار عادلة',
          'تبرعات',
          'قاعدة عملاء ضخمة',
          'لا توجد استراتيجية تسعير',
          'المنتجات العاطفية',
        ]
      : [
          'Fair Prices',
          'Donations',
          'Huge Customer Base',
          'No Pricing Strategy',
          'Emotional Products',
        ],
    considerations: t('considerations_bm41'),
    combinations: ['$LBM18', '$LBM22'],
    hashTagType: 'businessModelTemp',
  }
}

export default PayWhatYouWantBusinessModel
