import axios from 'axios'
import React, { useEffect, useState } from 'react'
import NavigatorGenerator from '../../../../../../navigatorCardsModel/NavigatorGenerator'

const ResourceNavigators = (props) => {
  const { resource } = props
  const [sharedPosts, setSharedPosts] = useState([])
  const [channels, setChannels] = useState([])
  useEffect(() => {
    const fetchResourcePosts = async () => {
      try {
        const res = await axios.get(`/resources/resourcePosts/${resource?._id}`)
        const resChannels = await axios.get(
          `/channels/elementChannels/${resource?._id}`
        )
        setChannels(resChannels.data.channels)
        setSharedPosts(res.data.resourcePosts)
      } catch (error) {
        console.log(error)
      }
    }
    if (resource?._id) fetchResourcePosts()
  }, [resource?._id])
  const rtl = document.body.dir === 'rtl'
  const navigatorsArray = [
    {
      headerText: rtl ? 'منشورات' : 'Posts',
      unitsArray: sharedPosts,
      NavigatorCardsModelProps: {},
    },
    {
      headerText: rtl
        ? `قنوات التشارك الخاصة بـ ${resource?.Title || resource.title}`
        : `Partnership Channels for ${resource.Title || resource.title}`,
      unitsArray: channels,
      NavigatorCardsModelProps: {},
    },
  ]
  return <NavigatorGenerator {...props} navigatorsArray={navigatorsArray} />
}

export default ResourceNavigators
