import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '../../../../../../../context/AuthContext'
import ButtonsSystem from '../../../../../../buttonsSystem/ButtonsSystem'
import EditableGridModel from '../../../../../../formsSystem/EditableGridModel'
import FormDataLangModulator from '../../../../../../formsSystem/FormDataLangModulator'
import InfoDataIntialStateGen from '../../../../../../formsSystem/InfoDataIntialStateGen'
import NotificationCreator from '../../../../../../notificationsSystem/NotificationCreator'
import HashTagElementsRefiner from '../../../../../../SmallComponents/HashTagElementsRefiner'
import MeetingAttendees from './MeetingAttendees'
import { Switch } from '@mui/material'
import { toast } from 'react-toastify'

const MeetingProfile = (props) => {
  const { meeting, DCGProps, setCards } = props
  const { project, socket, isDarkModeActive } = DCGProps
  const [users, setUsers] = useState([])
  const [projects, setProjects] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const { user } = useContext(AuthContext)
  const [allowModifications, setAllowModifications] = useState(false)
  const rtl = document.body.dir === 'rtl'

  useEffect(() => {
    const fetchMeeting = async () => {
      setIsLoading(true)
      try {
        const res = await axios.get(`/meetings/${meeting?._id}`)
        setUsers(res.data.users)
        setProjects(res.data.projects)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    if (meeting?._id) fetchMeeting()
  }, [meeting?._id])
  const infoArray = [
    {
      title: 'Title',
      titleValue: meeting?.Title,
      allowEmpty: true,
    },
    {
      title: 'Meeting_Purpose',
      titleValue: meeting?.Content,
      allowEmpty: true,
      minRows: 4,
    },
    {
      title: 'Date',
      titleValue: meeting?.Date,
      allowEmpty: true,
      type: 'date',
    },
    {
      title: 'Time',
      titleValue: meeting?.Time,
      allowEmpty: true,
      type: 'time',
      required: true,
    },
    {
      title: 'Location',
      titleValue: meeting?.Location,
      allowEmpty: true,
    },
  ]

  const [infoData, setInfoData] = useState(InfoDataIntialStateGen(infoArray))
  const { t } = useTranslation()
  const newInfoData = FormDataLangModulator(infoData, t)

  const [chosenHashTagElements, setChosenHashTagElements] = useState(
    HashTagElementsRefiner(meeting) || []
  )
  const onSave = async () => {
    if (meeting?._id) {
      try {
        const res = await axios.patch(`/meetings/${meeting?._id}`, {
          ...newInfoData,
          chosenHashTagElements,
        })
        setCards((inqs) => [
          ...inqs.filter((p) => p._id !== meeting?._id),
          meeting,
        ])
      } catch (error) {
        console.log(error)
      }
    }
  }
  const onReSend = async () => {
    try {
      projects?.map((m) => {
        socket.current.emit('addNotifUser', m?.userId)
        NotificationCreator({
          associativity: 'project',
          relation: 'ceo',
          conditionIndex: 0,
          accompaniedData: {
            projectId: project?._id,
            allowModifications,
            elements: [
              {
                hashTagType: meeting?.hashTagType,
                id: meeting?._id,
                title: meeting?.Title,
              },
            ],
          },
          category: 'contributors',
          userId: m?.userId,
          currentUser: user,
          socket,
        })
      })
      users?.map((m) => {
        socket.current.emit('addNotifUser', m?._id)
        NotificationCreator({
          associativity: 'project',
          relation: 'member',
          conditionIndex: 2,
          accompaniedData: {
            projectId: project?._id,
            allowModifications,
            elements: [
              {
                hashTagType: meeting?.hashTagType,
                id: meeting?._id,
                title: meeting?.Title,
              },
            ],
          },
          category: 'contributors',
          userId: m?._id,
          currentUser: user,
          socket,
        })
      })
    } catch (error) {
      console.log(error)
    }
  }
  const gridProps = {
    hideEditingTools: user?._id !== project?.userId,
    headerText: rtl ? 'تفاصيل الإجتماع' : 'Meeting Details',
    infoArray,
    setChosenHashTagElements,
    chosenHashTagElements,
    onSave,
    infoData,
    setInfoData,
    ...DCGProps,
  }
  const [resent, setResent] = useState(false)
  const buttonsArray = [
    {
      title: rtl ? 'إعادة إرسال' : 'Resend',
      funs: () => {
        onReSend()
        setResent(true)
        toast.success(
          rtl
            ? 'تم إعادة ارسال بيانات الاجتماع بنجاح'
            : 'Meeting has been resent successfully'
        )
      },
      disabled: resent,
    },
  ]
  return (
    <article
      style={{ width: '100%', color: isDarkModeActive ? 'white' : 'black' }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          fontSize: 20,
          fontWeight: 500,
          marginTop: 15,
        }}
      >
        {meeting?.Title}
      </div>
      <EditableGridModel gridProps={gridProps} />
      <MeetingAttendees
        users={users}
        projects={projects}
        meeting={meeting}
        isLoading={isLoading}
        isDarkModeActive={isDarkModeActive}
      />
      {user?._id === project?.userId && (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
            }}
          >
            <Switch
              checked={allowModifications}
              onChange={() => setAllowModifications(!allowModifications)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <section style={{ fontSize: 15 }}>
              {rtl ? 'إسمح بالتعديلات' : 'Allow Modifications'}
            </section>
          </div>
          <ButtonsSystem buttonsArray={buttonsArray} {...DCGProps} />
        </>
      )}
    </article>
  )
}

export default MeetingProfile
