const newProductFormConfigs = (designer) => {
  const rtl = document.body.dir === 'rtl'
  return [
    {
      title: 'Product_Designer',
      formComponentType: 'text',
      value: `${designer?.firstName + ' ' + designer?.lastName}`,
      disabled: true,
      hideInPost: true,
    },
    {
      title: 'Type',
      formComponentType: 'select',
      value: 'Product',
      menuOptions: ['Product', 'Service'],
      hideInPost: true,
    },
    {
      title: 'Product_Name',
      formComponentType: 'text',
      value: '',
      placeholder: rtl ? 'اسم المنتج' : 'Name Your Product',
    },
  ]
}

export default newProductFormConfigs
