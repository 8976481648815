import { Card, CardContent, CardMedia } from '@mui/material'
import React from 'react'
import withStyles from '@mui/styles/withStyles'
import DefaultImageGrapper from '../../../../SmallComponents/DefaultImageGrapper'

const styles = () => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    height: 200,
    margin: 10,
    borderRadius: '10px !important',
    cursor: 'pointer',
    opacity: 0.8,
    transition: '0.3s !important',
    boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
    '&:hover': {
      boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)',
      height: 220,
      opacity: 1,
    },
  },
  image: {
    width: '40%',
    objectFit: 'cover',
  },
})

const WideCardModel = ({ classes, element, elementIndex, props }) => {
  const { MFSGProps, folderPathProps, searchSystemProps, modelProps } = props
  const { setCardId, isDarkModeActive } = MFSGProps
  const { setSecondLevelFolderPath } = folderPathProps
  const { setShowSearchInput } = searchSystemProps
  const { defaultImage, imageKey, elementsImages, WideCardModelContent } =
    modelProps
  const action = () => {
    if (typeof setCardId === 'function') setCardId(element?.id || element?._id)
    if (typeof setSecondLevelFolderPath === 'function')
      setSecondLevelFolderPath(element.title || element?.Title)
    if (typeof setShowSearchInput === 'function') setShowSearchInput(false)
  }
  const elemntImage = elementsImages?.filter(
    (image) => image.id === element.id
  )[0].image
  const generatedImage = imageKey && DefaultImageGrapper(imageKey)
  const image =
    elementsImages?.length > 0
      ? elemntImage
        ? elemntImage
        : defaultImage || generatedImage
      : defaultImage || generatedImage

  return (
    <section style={{ width: '100%' }}>
      <Card
        className={classes.wrapper}
        onClick={action}
        sx={{
          backgroundColor: isDarkModeActive
            ? 'rgba(255, 255, 255, 0.192)'
            : 'inherit',
          color: isDarkModeActive ? 'white' : 'inherit',
        }}
      >
        <img src={image} alt='default model image' className={classes.image} />
        <CardContent sx={{ width: '100%', height: '100%' }}>
          {WideCardModelContent ? (
            <WideCardModelContent
              element={element}
              isDarkModeActive={isDarkModeActive}
            />
          ) : null}
        </CardContent>
      </Card>
    </section>
  )
}

const WrappedWideCardModel = withStyles(styles)(WideCardModel)

export default WrappedWideCardModel
