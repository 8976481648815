import React, { useState, useEffect } from 'react'
import uploadFile from '../../../firebase/uploadFile'
import UploadButton from './UploaderComponents/UploadButton'
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'

const UploaderComponent = ({
  files,
  setFiles,
  seterrorMsg,
  UploadButtonComponent,
  cutimizedUpload,
  maxFilesNumber,
  uploadHeaderText,
  DCGProps,
  departmentId,
  folder,
}) => {
  const [totalFilesSize, setTotalFilesSize] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    let total = 0
    files?.map((file) => (total += file.size))
    setTotalFilesSize(total)
  }, [files])

  const uploadPDFFile = async (file) => {
    setIsLoading(true)
    try {
      const pdfName = uuidv4() + '.' + file?.name?.split('.')?.pop()
      const pdfURL = await uploadFile(
        file,
        `/documents/${DCGProps?.project?._id}/${pdfName}`
      )
      setFiles([
        ...files,
        {
          name: file?.name,
          size: file?.size,
          url: pdfURL,
          createdAt: Date.now(),
        },
      ])
      await axios.patch(`/documents/${folder?._id}`, {
        PDFs: [
          ...files,
          {
            name: file?.name,
            size: file?.size,
            url: pdfURL,
            createdAt: Date.now(),
          },
        ],
      })
    } catch (error) {}
    setIsLoading(false)
  }

  const filesHnalder = (event) => {
    const maxSingleFileSize = 30000000
    const maxtotalFilesSize = 50000000
    const file = event.target.files[0]
    seterrorMsg('')
    totalFilesSize + file.size <= maxtotalFilesSize
      ? !files.filter((f) => f.name === file.name).length > 0
        ? file.size < maxSingleFileSize
          ? uploadPDFFile(file) && seterrorMsg()
          : seterrorMsg('File Cannot Exceed 10 MBs')
        : seterrorMsg(`Another File of the Same Name '${file.name}' exists`)
      : seterrorMsg([
          'You need to upgrade your plan to exceed 50 MB for this project',
        ])
  }

  return (
    <UploadButton
      filesHnalder={filesHnalder}
      UploadButtonComponent={UploadButtonComponent}
      cutimizedUpload={cutimizedUpload}
      files={files}
      maxFilesNumber={maxFilesNumber}
      uploadHeaderText={uploadHeaderText}
      isLoading={isLoading}
    />
  )
}

export default UploaderComponent
