import { useTranslation } from 'react-i18next'

const Crowdsourcing = () => {
  const { t } = useTranslation('Crowdsourcing')
  const rtl = document.body.dir === 'rtl'
  return {
    id: 11,
    title: t('title_bm11'),
    subTitle: t('subTitle_bm11'),
    main: t('main_bm11'),
    when: t('when_bm11'),
    who: t('who_bm11'),
    what: t('what_bm11'),
    how: t('how_bm11'),
    why: t('why_bm11'),
    examples: rtl
      ? [
          'InnoCentive - 2001 - الولايات المتحدة الأمريكية',
          'NineSigma - 2002 - الولايات المتحدة الأمريكية',
          'freelancer.com - 2009 - أستراليا',
          'atizo.com - 2009 - هولندا',
        ]
      : [
          'InnoCentive - 2001 - USA',
          'NineSigma - 2002 - USA',
          'freelancer.com - 2009 - Australia',
          'atizo.com - 2009 - Netherlands',
        ],
    tags: rtl
      ? [
          'المهام المنجزة',
          'منتجات رخيصة',
          'مسابقات',
          'موارد مجانية',
          'ولاء العميل',
        ]
      : [
          'Tasks Done',
          'Cheap Products',
          'Competitions',
          'Free Resources',
          'Customer Loyalty',
        ],
    considerations: t('considerations_bm11'),
    combinations: ['$LBM40'],
    hashTagType: 'businessModelTemp',
  }
}

export default Crowdsourcing
