import React, { useContext, useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import { Button } from '@mui/material'
import NotificationCreator from '../../../notificationsSystem/NotificationCreator'
import axios from 'axios'
import { AuthContext } from '../../../../context/AuthContext'
import ToastifyGeneralError from '../../../SmallComponents/ToastifyGeneralError'

const styles = () => ({
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: 5,
  },
  button: {
    borderRadius: '10px !important',
    margin: '2px !important',
    textTransform: 'none !important',
    fontWeight: '550 !important',
    width: '100% !important',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important",
  },
})

const ModificationUnitButtons = ({
  classes,
  seTdefaultText,
  GeneralDialogOpener,
  modification,
  setModifications,
  members,
  conEl,
}) => {
  const { user } = useContext(AuthContext)
  const [interacted, setInteracted] = useState(
    modification?.refusedUsersIds?.includes(user?._id) ||
      modification?.agreedUsersIds?.includes(user?._id)
  )
  const rtl = document.body.dir === 'rtl'
  return (
    <section className={classes.buttonsWrapper}>
      <Button
        className={classes.button}
        disabled={interacted}
        onClick={async () => {
          setInteracted(!interacted)
          try {
            const res = await axios.patch(
              `/modifications/${modification?._id}`,
              {
                refusedUsersIds: [...modification?.refusedUsersIds, user?._id],
              }
            )
            setModifications((mods) => [
              ...mods?.filter((m) => m?._id !== modification?._id),
              ,
              res.data.modification,
            ])
          } catch (error) {
            console.log(error)
            ToastifyGeneralError(error)
          }
        }}
      >
        {rtl ? 'رفض' : 'Refuse'}
      </Button>
      <Button
        disabled={interacted}
        className={classes.button}
        onClick={async () => {
          setInteracted(!interacted)
          try {
            const res = await axios.patch(
              `/contractElementModifications/${modification?._id}`,
              {
                agreedUsersIds: [...modification?.agreedUsersIds, user?._id],
              }
            )
            setModifications((mods) => [
              ...mods?.filter((m) => m?._id !== modification?._id),
              ,
              res.data.modification,
            ])

            if (
              res.data.modification.agreedUsersIds.length === members?.length
            ) {
              // delete the modification
              await axios.delete(
                `/contractElementModifications/${modification?._id}`
              )
              setModifications((mods) =>
                mods?.filter((m) => m?._id !== modification?._id)
              )
              // update the agreement with these new data
              await axios.patch(`/contractsElements/${conEl?._id}`, {
                agreement: modification?.Content,
                chosenHashTagElements: modification?.chosenHashTagElements,
              })
            }
          } catch (error) {
            ToastifyGeneralError(error)
          }
        }}
      >
        {rtl ? 'قبول' : 'Accept'}
      </Button>
      <Button
        className={classes.button}
        onClick={async () => {
          seTdefaultText(modification?.Content)
          GeneralDialogOpener()
        }}
      >
        {rtl ? 'تعديل' : 'Edit'}
      </Button>
      <Button
        className={classes.button}
        onClick={async () => {
          //  this will delete the modification
          await axios.delete(
            `/contractElementModifications/${modification?._id}`
          )
          setModifications((mods) =>
            mods?.filter((m) => m?._id !== modification?._id)
          )
        }}
        disabled={user?._id !== modification?.userId}
      >
        {rtl ? 'حذف' : 'Delete'}
      </Button>
    </section>
  )
}

const WrappedModificationUnitButtons = withStyles(styles)(
  ModificationUnitButtons
)

export default WrappedModificationUnitButtons
